import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ErrorIcon, SuccesIcon } from '../../../../libs/svg-icons/icons';

/**
 * Custom textarea field.
 *
 * @param {string} id - The `id` of `textarea` element.
 * @param {string} label - The `label` of `textarea` element.
 * @param {string} name - The `name` of `textarea` element.
 * @param {string} value - The `value` of `textarea` element.
 * @param {function} onChange - The function that is triggered when there is a change in the `textarea` element.
 * @param {function} onBlur - The function that is triggered when the`textarea` element is blurred.
 * @param {boolean} required - If `required` is true, the `textarea` element will require a value and an asteric will be shown after the label.
 * @param {string} placeholder - The short hint displayed in the `textarea` element before the user enters a value.
 * @param {boolean} disabled - If `disabled` is true, the `textarea` element will be disabled.
 * @param {string} error - If `error` message is present, it will error success message at the bottom of the `textarea` element and the border of the `textarea` element will be of color error.
 * @param {string} success - If `success` message is present, it will show success message at the bottom of the `textarea` element and the border of the `textarea` element will be of color success.
 * @param {string} helperText - Any text that we want to show at the bottom of the `textarea` element, as a description.
 * @returns {Element} The `textarea` element.
 */

const TextArea = (
  {
    id = '',
    label = '',
    name = '',
    minRows = 4,
    rows = 4,
    value = '',
    onChange = () => null,
    onBlur = () => null,
    required = false,
    placeholder = '',
    disabled = false,
    error = '',
    success = '',
    helperText = '',
  },
  ref,
) => {
  const [textareaValue, setTextareaValue] = useState(value);
  const theme = useTheme();

  useEffect(() => {
    setTextareaValue(value);
  }, [value]);

  const handleChange = e => {
    setTextareaValue(e.target.value);
    onChange(e);
  };

  const inputColor = useMemo(() => {
    if (!!error) return 'error';
    if (!!success) return 'success';
    return 'primary';
  }, [error, success]);

  const helperTextContent = useMemo(() => {
    if (helperText) return helperText;
    if (!!error) return error;
    if (!!success) return success;
    return null;
  }, [helperText, error, success]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: '4px',
      }}
    >
      {label && (
        <Typography color={theme.palette.text[100]} component="span" variant="Medium-16">
          {label} {required && '*'}
        </Typography>
      )}
      <FormControl error={!!error} color={inputColor} disabled={disabled}>
        {/* <TextareaAutosize
          ref={ref}
          id={id ?? undefined}
          aria-label={label}
          placeholder={placeholder}
          value={textareaValue}
          onChange={handleChange}
          onBlur={onBlur}
          disabled={disabled}
          minRows={minRows}
          sx={{
            width: '100%',
            minHeight: '100px',
            padding: '10px',
            resize: 'vertical',
            borderColor: !!error
              ? theme.palette.error.main
              : success
                ? theme.palette.success.main
                : theme.palette.primary.main,
            borderRadius: '4px',
            '&:focus': {
              outline: 'none',
              boxShadow: `0 0 0 2px ${theme.palette.primary.main}33`,
            },
          }}
        /> */}

        <textarea
          ref={ref}
          id={id ?? undefined}
          aria-label={label}
          placeholder={placeholder}
          value={textareaValue}
          onBlur={onBlur}
          onChange={handleChange}
          disabled={disabled}
          minRows={minRows}
          rows={rows}
          style={{
            width: '100%',
            minHeight: '130px',
            padding: '10px',
            resize: 'none',
            borderColor: 'rgb(205, 209, 215)',
            borderRadius: '4px',
            '&:focus': {
              outline: 'none',
              boxShadow: `0 0 0 2px ${theme.palette.primary.main}33`,
            },
            outline: 'none',
            fontSize: '14px',
            fontWeight: '400',
            fontFamily: 'Poppins',
          }}
        />
        <FormHelperText
          id="component-helper-text"
          sx={{
            color: !!success && 'var(--success)',
            margin: '0 !important',
          }}
        >
          {helperTextContent}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

export default forwardRef(TextArea);
