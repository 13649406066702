import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const Card = ({ sx = null, children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.white,
        padding: '24px',
        borderRadius: '8px',
        boxShadow: '0px 4px 18px 0px #0A27561A',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        flexDirection: 'column',
        gap: '1.5rem',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default Card;
