import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  clientId: null,
  brandId: null,
  categoryId: null,
  businessUnitId: null,
  mediaPlanTypeId: null,
  name: null,
  year: null,
  startDate: null,
  endDate: null,
  mediaPlanTemplate: null,
  unmappedHeader: null,
  unmatchedHeader: null,
  mappedColumn: null,
  formData: null,
  errors: null,
};

export const createMediaPlanSlice = createSlice({
  name: 'createMediaPlan',
  initialState,
  reducers: {
    create: (state, action) => {
      state.clientId = action.payload.clientId;
      state.brandId = action.payload.brandId;
      state.categoryId = action.payload.categoryId;
      state.businessUnitId = action.payload.businessUnitId;
      state.mediaPlanTypeId = action.payload.mediaPlanTypeId;
      state.name = action.payload.name;
      state.year = action.payload.year;
      state.type = action.payload.type;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.mediaPlanName = action.payload.mediaPlanName;
      state.mediaPlanTemplate = action.payload.mediaPlanTemplate;
      state.unmappedHeader = action.payload.unmappedHeader;
      state.unmatchedHeader = action.payload.unmatchedHeader;
      state.mappedColumn = action.payload.mappedColumn;
      state.formData = action.payload.formData;
      state.errors = null;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    clear: state => {
      state.clientId = null;
      state.brandId = null;
      state.categoryId = null;
      state.businessUnitId = null;
      state.mediaPlanTypeId = null;
      state.name = null;
      state.year = null;
      state.type = null;
      state.startDate = null;
      state.endDate = null;
      state.mediaPlanName = null;
      state.mediaPlanTemplate = null;
      state.unmappedHeader = null;
      state.unmatchedHeader = null;
      state.mappedColumn = null;
      state.formData = null;
    },
  },
});

export const createMediaPlanActions = createMediaPlanSlice.actions;
export const createMediaPlanReducer = createMediaPlanSlice.reducer;
