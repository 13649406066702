import React from 'react';
import { Card, CardContent, Typography, ButtonBase } from '@mui/material';

const CLickableCard = ({ icon, headline, description, onClick }) => {
  const cardStyle = {
    width: '300px',
    height: '144px',
    border: '1px solid #CDD1D7',
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
  };

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <ButtonBase onClick={handleClick} style={{ borderRadius: '6px' }}>
      <Card sx={cardStyle}>
        <CardContent style={{ textAlign: 'center', padding: '16px' }}>
          {icon && <div style={{ marginBottom: '8px' }}>{icon}</div>}
          <Typography
            variant="Medium-14"
            gutterBottom
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: '1',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {headline}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: '2',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {description}
          </Typography>
        </CardContent>
      </Card>
    </ButtonBase>
  );
};

export default CLickableCard;
