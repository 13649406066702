import React from 'react';
import Box from '@mui/material/Box';
import { LogoutIcon } from '../../../libs/svg-icons/icons';
import Typography from '@mui/material/Typography';
import Dialog from '../../shared/dialog';
import Button from '../../shared/buttons';
import { useTheme } from '@mui/material/styles';
import { dispatcher } from '../../../redux/store';
import { authActions } from '../../../redux/slice';
import { useAuth } from 'react-oidc-context';
import { ContactUsIcon } from '../../../libs/svg-icons/icons/ContactUsIcon';

const ContactusModel = ({ open = false, handleClose = () => null }) => {
  const theme = useTheme();
  const auth = useAuth();

  const supportEmail = 'id.support.marvel@groupm.com';

  const openEmailClient = () => {
    const subject = encodeURIComponent('');
    const body = encodeURIComponent('');
    const recipient = supportEmail;
    const mailtoLink = `mailto:${recipient}?subject=${subject}&body=${body}`;

    window.location.href = mailtoLink;
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      icon={
        <Box
          sx={{
            width: '52px',
            height: '52px',
            borderRadius: '50%',
            background: theme.palette.background,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto',
            marginBottom: '24px',
          }}
        >
          <ContactUsIcon width="32px" height="32px" fill="var(--text-100)" />
        </Box>
      }
      headline={
        <Box
          sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Typography variant="SemiBold-22" color={theme.palette.text['100']}>
            Contact us for any help
          </Typography>
        </Box>
      }
      description={
        <Typography variant="Regular-14" sx={{ marginBottom: '24px' }}>
          You can contact us on our email for any help or query{' '}
          <span style={{ color: 'blue', cursor: 'pointer' }} onClick={openEmailClient}>
            <u>{supportEmail}</u>
          </span>
        </Typography>
      }
      // buttons={[]}
      width="520px"
      headlineAlignment="center"
    />
  );
};

export default ContactusModel;
