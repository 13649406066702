import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Table from '../../../../components/shared/table';
import { useTheme } from '@emotion/react';
import { formatDate } from '../../../../libs/date/format';
import { Box, Typography } from '@mui/material';
import Button from '../../../../components/shared/buttons';
import SearchableSelect from '../../../../components/shared/form/SearchableSelect/searchable-select';
import OverflowTooltip from '../../../../components/shared/tooltip/OverflowTooltip';
import { searchPOClient } from '../../../../services/media-plan-service';
import { AppConstants } from '../../../../constants/app-constants';

const PoVendorSelectionTable = ({ tableRows = [], readOnly = true }) => {
  const rowData = useMemo(() => [...tableRows], [tableRows]);

  const columns = useMemo(() => {
    let screenWidth = window.innerWidth;
    let colWidth = readOnly ? 0.325 : 0.25;
    let widthInPx = colWidth * screenWidth - 53;

    let temp = [
      {
        colId: 'poNumber',
        headerName: 'PO Number',
        field: 'poNumber',
        minWidth: widthInPx,
        width: widthInPx,
        maxWidth: widthInPx,
        valueFormatter: params => params.value ?? '-',
      },
      {
        colId: 'poAmount',
        headerName: 'PO Amount',
        field: 'poAmount',
        minWidth: widthInPx,
        width: widthInPx,
        maxWidth: widthInPx,
        valueFormatter: params => params.value ?? '-',
      },
      {
        colId: 'poIssueDate',
        headerName: 'PO Issue Date',
        field: 'remarks',
        minWidth: widthInPx,
        width: widthInPx,
        maxWidth: widthInPx,
        valueFormatter: params => params.value ?? '-',
      },
    ];

    if (!readOnly) {
      temp.push({
        colId: 'action',
        headerName: 'Action',
        field: 'poNumber',
        minWidth: widthInPx,
        width: widthInPx,
        maxWidth: widthInPx,
        valueFormatter: params => {
          return (
            <Button
              varient={'outlined'}
              label="Remove"
              onClick={() => {
                console.log('Delete this PO: ', params.value);
              }}
            />
          );
        },
        cellStyle: () => {
          return {
            background: theme.palette.light,
            borderRight: 'none',
          };
        },
      });
    }

    return temp;
  }, [rowData, window.innerWidth]);

  const theme = useTheme();

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      editable: false,
      flex: 1,
      cellStyle: () => ({
        background: theme.palette.light,
      }),
    }),
    [theme.palette.text],
  );

  const tableHeight = useMemo(() => {
    let sample = rowData.length * 61 + 60;
    if (sample > 125) {
      return sample;
    } else {
      return 125;
    }
  }, [rowData]);

  const tableComp = useMemo(() => {
    return (
      <Table columns={columns} data={rowData} defaultColDef={defaultColDef} height={tableHeight} />
    );
  }, [rowData, window.innerWidth]);

  const getPOClientSearchResults = searchText => {
    return searchPOClient({ searchText })
      .then(response => {
        const { data } = response?.data ?? {};
        return data;
      })
      .catch(error => {
        const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
      });
  };

  const totalDetails = [
    { label: 'Subtotal exclusive taxes', value: '68,000,000.00 IDR' },
    {
      label: 'ID VAT Standard 11% of 68,000,000.00 IDR (Valid from 01-08-2023)',
      value: '7,480,000.00 IDR',
    },
  ];

  const TotleIDRComp = useMemo(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '20px',
          flexWrap: 'wrap',
        }}
      >
        <Box key={'1'} sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <Box sx={{ display: 'flex', width: '70%' }}>
            <Typography variant="Regular-18">Total PO Amount</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '30%',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Typography variant="Regular-18">68,000,000.00 IDR</Typography>
          </Box>
        </Box>

        <Box key={'2'} sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <Box sx={{ display: 'flex', width: '70%' }}>
            <Typography variant="Regular-18">PO Issue Period</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '30%',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Typography variant="Regular-18">10/08/24 to 10/12/24</Typography>
          </Box>
        </Box>
      </Box>
    );
  }, [rowData, totalDetails]);

  return (
    <Box sx={{ width: '100%' }}>
      {!readOnly && (
        <Box sx={{ maxWidth: '100%', width: '600px', marginBottom: '25px' }}>
          <SearchableSelect
            onOptionSearch={getPOClientSearchResults}
            optionMapFunction={options =>
              options?.map(x => ({
                id: x.clientPoNumber,
                label: x.clientPoNumber,
                value: x.clientPoNumber,
                status: x.status,
                bbgId: x.webappBbgNumber,
              }))
            }
            placeholder="Search Client PO by PO number..."
            optionRenderer={option => (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="Regular-16">{option.label}</Typography>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px',
                  }}
                >
                  <Box
                    sx={{
                      width: '175px',
                      height: '30px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      backgroundColor: theme.palette.text['600'],
                      padding: '6px 12px',
                      borderRadius: '5px',
                      border: `1px solid ${theme.palette.text['500']}`,
                    }}
                  >
                    <Typography variant="Medium-12" color="#6D7581" sx={{ width: '70px' }}>
                      BBG ID:
                    </Typography>
                    <OverflowTooltip text={option.bbgId} sx={{ textAlign: 'right' }}>
                      <Typography variant="Medium-12" color="#16181A">
                        {option.bbgId ?? '-'}
                      </Typography>
                    </OverflowTooltip>
                  </Box>
                </Box>
              </Box>
            )}
            onOptionSelect={e => {
              console.log('onSelect: ', e);
            }}
            onSearchCloseClick={() => {}}
            noSelectAction={true}
          />
        </Box>
      )}
      <Box style={{ width: `100%` }}>{rowData.length ? tableComp : ''}</Box>;
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          borderRadius: '8px',
          border: '1px solid #CDD1D7',
          backgroundColor: '#F4F4F4',
          padding: '16px',
        }}
      >
        {TotleIDRComp}
      </Box>
    </Box>
  );
};

export default PoVendorSelectionTable;
