import { createIcon } from '../createSVGIcon';

const path = (
  <g>
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M19.938 8.00002H21C21.5304 8.00002 22.0391 8.21074 22.4142 8.58581C22.7893 8.96088 23 9.46959 23 10V14C23 14.5305 22.7893 15.0392 22.4142 15.4142C22.0391 15.7893 21.5304 16 21 16H19.938C19.6942 17.9333 18.7533 19.7112 17.2917 21C15.8302 22.2888 13.9486 23 12 23V21C13.5913 21 15.1174 20.3679 16.2426 19.2427C17.3679 18.1174 18 16.5913 18 15V9.00002C18 7.40872 17.3679 5.8826 16.2426 4.75738C15.1174 3.63216 13.5913 3.00002 12 3.00002C10.4087 3.00002 8.88258 3.63216 7.75736 4.75738C6.63214 5.8826 6 7.40872 6 9.00002V16H3C2.46957 16 1.96086 15.7893 1.58579 15.4142C1.21071 15.0392 1 14.5305 1 14V10C1 9.46959 1.21071 8.96088 1.58579 8.58581C1.96086 8.21074 2.46957 8.00002 3 8.00002H4.062C4.30603 6.06692 5.24708 4.2893 6.70857 3.0007C8.17007 1.7121 10.0516 1.0011 12 1.0011C13.9484 1.0011 15.8299 1.7121 17.2914 3.0007C18.7529 4.2893 19.694 6.06692 19.938 8.00002ZM3 10V14H4V10H3ZM20 10V14H21V10H20ZM7.76 15.785L8.82 14.089C9.77308 14.6861 10.8754 15.0018 12 15C13.1246 15.0018 14.2269 14.6861 15.18 14.089L16.24 15.785C14.9693 16.5812 13.4995 17.0023 12 17C10.5005 17.0023 9.03074 16.5812 7.76 15.785Z"
    />
  </g>
);

export const ContactUsIcon = createIcon(path, {
  width: 22,
  height: 20,
  viewBox: '0 0 22 20',
  fill: 'none',
});
