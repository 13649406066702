import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React from 'react';
import { ChevronRightIcon } from '../../../libs/svg-icons/icons';
import { styled, useTheme } from '@mui/material/styles';

const StyledAccordion = styled(MuiAccordion, { shouldForwardProp: prop => true })(({ theme }) => {
  return {
    backgroundColor: theme.palette.white,
    border: `1px solid ${theme.palette.text['500']}`,
    borderRadius: '6px !important',
    boxShadow: 'none',
    overflow: 'hidden',
    height: 'auto',
    maxHeight: '44px',
    transition: 'max-height 0.2s ease-in',

    '&.Mui-expanded': {
      maxHeight: '1000000000px',
    },

    '& > .MuiAccordionSummary-root': {
      backgroundColor: theme.palette.background,
      padding: '0 24px',
      height: '45px !important',
      minHeight: '45px !important',
      maxHeight: '45px !important',
      borderRadius: '6px !important',
      borderBottom: `1px solid ${theme.palette.text['500']}`,

      '&.Mui-expanded': {
        borderBottomRightRadius: '0px !important',
        borderBottomLeftRadius: '0px !important',
      },

      '& > .MuiAccordionSummary-content': {
        margin: '10px 0',
      },
      '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
      },
    },
    '& .MuiAccordionDetails-root': {
      padding: '24px',
    },
  };
});

const Accordion = ({
  id = '',
  controls = '',
  summary = null,
  details = null,
  defaultExpanded = false,
}) => {
  const theme = useTheme();
  return (
    <StyledAccordion defaultExpanded={defaultExpanded}>
      <AccordionSummary
        expandIcon={<ChevronRightIcon width={12} height={12} fill={theme.palette.text['300']} />}
        aria-controls={controls}
        id={id}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails>{details}</AccordionDetails>
    </StyledAccordion>
  );
};

export default Accordion;
