import { createIcon } from '../createSVGIcon';

const path = (
  <g>
    <path
      d="M14.0003 27.3334C6.63633 27.3334 0.666992 21.3641 0.666992 14.0001C0.666992 6.63608 6.63633 0.666748 14.0003 0.666748C21.3643 0.666748 27.3337 6.63608 27.3337 14.0001C27.3337 21.3641 21.3643 27.3334 14.0003 27.3334ZM14.0003 24.6667C16.8293 24.6667 19.5424 23.5429 21.5428 21.5426C23.5432 19.5422 24.667 16.8291 24.667 14.0001C24.667 11.1711 23.5432 8.458 21.5428 6.45761C19.5424 4.45722 16.8293 3.33341 14.0003 3.33341C11.1713 3.33341 8.45824 4.45722 6.45785 6.45761C4.45747 8.458 3.33366 11.1711 3.33366 14.0001C3.33366 16.8291 4.45747 19.5422 6.45785 21.5426C8.45824 23.5429 11.1713 24.6667 14.0003 24.6667ZM12.667 7.33342H15.3337V10.0001H12.667V7.33342ZM12.667 12.6667H15.3337V20.6667H12.667V12.6667Z"
      fill="#16181A"
    />
  </g>
);

export const InfoIcon = createIcon(path, {
  width: 20,
  height: 20,
  viewBox: '0 0 30 30',
  fill: 'none',
});
