import { createIcon } from '../createSVGIcon';

const path = (
  <g>
    <path d="M7.00072 5.17168L11.9507 0.22168L13.3647 1.63568L7.00072 7.99968L0.636719 1.63568L2.05072 0.22168L7.00072 5.17168Z" />
  </g>
);

export const ChevronDownIcon = createIcon(path, {
  width: 14,
  height: 8,
  viewBox: '0 0 14 8',
  fill: 'none',
});
