import { Box, Typography, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import UserDetails from '../user';
import { themePalette } from '../../../constants/theme/color';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '../buttons';
import DatePicker from '../form/date-picker';
import { UploadIcon } from '../../../libs/svg-icons/icons';
import { useTheme } from '@emotion/react';
import DragAndDropFilePicker from '../form/fileupload';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Textarea from '../form/textarea';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import Loader from '../loader';
import {
  GenerateClientPOException,
  SendPoExceptionRequestToApproval,
} from '../../../services/media-plan-service';
import { hasAllPermissions } from '../../../libs/permissions';
import { uploadFile } from '../../../services/file-upload-service';
import { useEnqueueSnackbar } from '../toast-provider/toastHook';
import { PermissionsConstants } from '../../../constants/permissions-constants';
import { AppConstants } from '../../../constants/app-constants';
import { formatDate } from '../../../libs/date/format';
import { formatCurrency } from '../../../utils/string';

const schema = yup.object().shape({
  description: yup
    .string()
    .required('Reason for not getting the Client PO is required')
    .max(200, 'Must not exceed 200 characters	'),
  expiredDate: yup.object().required('Expiry Date is required'),
  clientPOFileUpload: yup.mixed().required('PO client document is required'),
});

const SendPoClientExceptionDialog = ({
  handleCloseSendPoClientExceptionRequest,
  fetchBuyingBriefGroup,
  exceptionData = null,
}) => {
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const {
    formState: { isValid },
  } = methods;

  const theme = useTheme();
  const params = useParams();
  const enqueueSnackbar = useEnqueueSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [exceptionId, setExceptionId] = useState('');
  const [exceptionDetails, setExceptionDetails] = useState([
    { label: 'Agency', value: '-' },
    { label: 'Client', value: '-' },
    { label: 'Brand', value: '-' },
    { label: 'Campaign Start Date', value: '-' },
    { label: 'Media Type', value: '-' },
    { label: 'Total Amount', value: '-' },
  ]);
  const [teamDetails, setTeamDetails] = useState([
    { name: 'Buying Team Head', value: '-' },
    { name: 'Finance Head', value: '-' },
    { name: 'Agency Head', value: '-' },
  ]);

  const handleUploadClientConfirmation = file => {
    methods.setValue('clientPOFileUpload', file, { shouldValidate: true });
    methods.trigger();
  };

  const handleUploadClientConfirmationRemoved = () => {
    methods.setValue('clientPOFileUpload', undefined, { shouldValidate: true });

    methods.trigger();
  };

  const maxDateForExpireDate = () => {
    var d = new Date();
    d.setMonth(d.getMonth() + 3);
    return dayjs(d);
  };

  const GenerateExceptionRequest = async () => {
    setIsLoading(true);
    await GenerateClientPOException({
      'bbgId': params?.bgid,
    })
      .then(response => {
        const { data = null } = response?.data || {};
        if (data) {
          const {
            id,
            agency,
            agencyHead,
            brand,
            buyingTeamHead,
            campaignStartDate,
            client,
            financeHead,
            mediaType,
            totalAmount,
          } = data;
          setExceptionId(id);
          setExceptionDetails([
            { label: 'Agency', value: agency ?? '-' },
            { label: 'Client', value: client ?? '-' },
            { label: 'Brand', value: brand ?? '-' },
            {
              label: 'Campaign Start Date',
              value: campaignStartDate ? formatDate(campaignStartDate, 'dd/MM/yyyy') : '-',
            },
            { label: 'Media Type', value: mediaType ?? '-' },
            {
              label: 'Total Amount',
              value: totalAmount ? `${formatCurrency(totalAmount)} IDR` : '-',
            },
          ]);

          setTeamDetails([
            { name: 'Buying Team Head', value: buyingTeamHead ?? '-' },
            { name: 'Finance Head', value: financeHead ?? '-' },
            { name: 'Agency Head', value: agencyHead ?? '-' },
          ]);
        }
      })
      .catch(error => {
        const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
        enqueueSnackbar(message, 'error');
        handleCloseSendPoClientExceptionRequest();
      })
      .finally(() => setIsLoading(false));
  };

  const handleSendForApproval = () => {
    if (hasAllPermissions([PermissionsConstants?.CREATE_MEDIA_PLAN])) {
      const fileUploadDto = {
        id: params?.bgid,
        fileOrigin: 'INTERNAL_BRIEF', //'PO_CLIENT_FILE',
      };
      const formData = new FormData();
      formData.append(
        'fileUploadDto',
        new Blob([JSON.stringify(fileUploadDto)], {
          type: 'application/json',
        }),
      );
      formData.append('file', methods.getValues('clientPOFileUpload'));

      setIsLoading(true);
      uploadFile(formData)
        .then(response => {
          const { data } = response.data;
          if (data) {
            return data;
          }
          return null;
        })
        .then(async data => {
          if (data) {
            const payload = {
              'bbgId': params?.bgid,
              'requestId': exceptionId,
              'reason': methods.getValues('description'),
              'estimatedDate': formatDate(new Date(methods.getValues('expiredDate')), 'yyyy-MM-dd'),
              'clientConfirmationUrl': data?.url,
            };

            setIsLoading(true);
            await SendPoExceptionRequestToApproval(payload)
              .then(response => {
                const { data = null } = response?.data || {};
                if (data) {
                  enqueueSnackbar(
                    'PO client exception request send for approval successfully!',
                    'success',
                  );
                  fetchBuyingBriefGroup();
                  handleCloseSendPoClientExceptionRequest();
                }
              })
              .catch(error => {
                const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
                enqueueSnackbar(message, 'error');
              });
          }
        })
        .catch(error => {
          const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
          enqueueSnackbar(message, 'error');
        })
        .finally(() => setIsLoading(false));
    }
  };

  const init = () => {
    const {
      id,
      agency,
      agencyHead,
      brand,
      buyingTeamHead,
      campaignStartDate,
      client,
      financeHead,
      mediaType,
      totalAmount,
    } = exceptionData;
    setExceptionId(id);
    setExceptionDetails([
      { label: 'Agency', value: agency || '-' },
      { label: 'Client', value: client || '-' },
      { label: 'Brand', value: brand || '-' },
      { label: 'Campaign Start Date', value: campaignStartDate || '-' },
      { label: 'Media Type', value: mediaType || '-' },
      { label: 'Total Amount', value: `${totalAmount || ''} IDR` },
    ]);

    setTeamDetails([
      { name: 'Buying Team Head', value: buyingTeamHead },
      { name: 'Finance Head', value: financeHead },
      { name: 'Agency Head', value: agencyHead },
    ]);
  };

  useEffect(() => {
    // GenerateExceptionRequest();
    if (exceptionData) {
      init();
    }
  }, []);

  return (
    <>
      <Loader isLoading={isLoading} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          gap: '40px',
          overflowY: 'auto',
          WebkitOverflowScrolling: 'touch',
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#FFFFFF',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#CDD1D7',
            borderRadius: '4px',
          },
          paddingRight: '13px',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {exceptionDetails?.map(detail => (
            <Box
              sx={{ display: 'flex', gap: '24px', alignItems: 'flex-start', flexDirection: 'row' }}
            >
              <Box
                sx={{
                  minWidth: '200px',
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                }}
              >
                <Typography variant="Medium-16">{detail.label}</Typography>
              </Box>
              <Box
                sx={{
                  minWidth: '400px',
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                }}
              >
                {isLoading ? (
                  <Skeleton variant="rectangular" width="80%" height="38px" />
                ) : (
                  <Typography variant="Medium-16">{detail.value}</Typography>
                )}
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', gap: '16px' }}
        >
          {teamDetails?.map(detail => (
            <Box
              sx={{
                display: 'flex',

                alignItems: 'flex-start',
                flexDirection: 'row',
                width: '616px',
              }}
            >
              <Box
                sx={{
                  minWidth: '200px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  height: '58px',
                }}
              >
                <Typography variant="Medium-16">{detail.name}</Typography>
              </Box>
              <Box
                sx={{
                  height: '58px',
                  width: '432px',
                  flexGrow: 1,
                  border: '1px solid #CDD1D7',
                  padding: '13px 16px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                {isLoading ? (
                  <Skeleton variant="rectangular" width="80%" height="38px" />
                ) : (
                  <UserDetails
                    sx={{ width: '100%' }}
                    fullName={detail.value}
                    fullNameColor={themePalette.palette.text['100']}
                    initials={detail.value
                      .split(' ')
                      .map(x => x[0])
                      .join('')}
                    initialsBackgroundColor={themePalette.palette.primary.main}
                    initialsColor={themePalette.palette.white}
                    initialsVariant="SemiBold-12"
                    initailsContainer="32px"
                    fullNameVariant="Medium-14"
                  />
                )}
              </Box>
            </Box>
          ))}
        </Box>
        <Box sx={{ width: '620px', flexDirection: 'column', gap: '40px' }}>
          <FormProvider {...methods}>
            <form>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ width: '100%' }}>
                  <Textarea
                    id="po-client-description"
                    inputHeight="130px"
                    type="text"
                    label="Reason for not getting the Client PO"
                    placeholder="Write reason for not getting the client PO on time..."
                    {...methods.register('description')}
                  />
                </Box>
              </Box>

              <Box sx={{ marginTop: '25px' }}>
                <DatePicker
                  label="Estimated Date of getting the PO"
                  id="expired-date-picker"
                  placeholder="Select expired date"
                  textAlign="left"
                  {...methods.register('expiredDate')}
                  minDate={dayjs(new Date())}
                  maxDate={maxDateForExpireDate()}
                  onChange={() => null}
                />
              </Box>

              <Box sx={{ display: 'flex', marginTop: '25px' }}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                  }}
                >
                  <Box
                    sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}
                  >
                    <Typography variant="Medium-16">Upload Client Confirmation</Typography>
                  </Box>
                  <DragAndDropFilePicker
                    height="100px"
                    multi={false}
                    icon={<UploadIcon fill={theme.palette.primary.main} />}
                    onUpload={handleUploadClientConfirmation}
                    headline="Upload Client Confirmation"
                    description="(maximum 5MB file size)"
                    onRemove={handleUploadClientConfirmationRemoved}
                    maxFileSize={5}
                    {...methods.register('clientPOFileUpload')}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                  marginTop: '40px',
                }}
              >
                <Button
                  variant="contained"
                  label="Send for Approval"
                  disabled={!isValid}
                  onClick={() => {
                    // setShowPoClientExceptionRequestChip(true);
                    // handleCloseSendPoClientExceptionRequest();
                    handleSendForApproval();
                  }}
                />
              </Box>
            </form>
          </FormProvider>
        </Box>
      </Box>
    </>
  );
};

export default SendPoClientExceptionDialog;
