import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';

export default function Loader({ isLoading, msg = '' }) {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 111 }} open={isLoading}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', textAlign: 'center' }}>
        <CircularProgress color="inherit" style={{ margin: 'auto' }} />
        {msg && (
          <Typography variant="Medium-16" style={{ color: '#fff' }}>
            {msg}
          </Typography>
        )}
      </div>
    </Backdrop>
  );
}
