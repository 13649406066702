import { createIcon } from '../createSVGIcon';

const path = (
  <g>
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M19.1275 0.386027L27.5902 8.84736C27.6774 8.93442 27.7387 9.04394 27.7674 9.16376C27.7962 9.28359 27.7911 9.40903 27.7529 9.52616C27.7146 9.64329 27.6446 9.74753 27.5507 9.82729C27.4568 9.90706 27.3426 9.95924 27.2208 9.97803L25.2555 10.2847L17.7142 2.74336L17.9968 0.76336C18.0144 0.640921 18.0657 0.525785 18.145 0.430831C18.2242 0.335877 18.3283 0.264849 18.4457 0.225692C18.563 0.186535 18.6889 0.180793 18.8093 0.209109C18.9297 0.237425 19.0399 0.298681 19.1275 0.386027ZM3.12615 25.866C8.08882 21.4247 13.7862 20.0954 19.9835 18.506L20.5782 13.1487L14.8488 7.41936L9.49149 8.01403C7.90215 14.2114 6.57282 19.9074 2.13148 24.8714L0.271484 23.0127C4.04215 18.6127 5.45815 13.742 7.34215 5.57136L15.8275 4.62869L23.3702 12.1714L22.4275 20.6567C14.2568 22.542 9.38482 23.9567 4.98615 27.7274L3.12615 25.866ZM10.1715 17.826C9.91679 17.58 9.71364 17.2858 9.57388 16.9604C9.43412 16.6351 9.36056 16.2852 9.35748 15.9311C9.35441 15.577 9.42188 15.2259 9.55596 14.8982C9.69004 14.5704 9.88805 14.2727 10.1384 14.0223C10.3888 13.7719 10.6866 13.5739 11.0143 13.4398C11.342 13.3058 11.6931 13.2383 12.0472 13.2414C12.4013 13.2444 12.7512 13.318 13.0766 13.4578C13.4019 13.5975 13.6962 13.8007 13.9422 14.0554C14.4212 14.5594 14.6844 15.2307 14.6757 15.9261C14.6669 16.6215 14.3868 17.2859 13.8952 17.7777C13.4035 18.2695 12.7392 18.5498 12.0438 18.5589C11.3484 18.5679 10.6771 18.3049 10.1728 17.826H10.1715Z"
      fill="currentColor"
    />
  </g>
);

export const PenSignIcon = createIcon(path, {
  width: 28,
  height: 28,
  viewBox: '0 0 28 28',
  fill: 'none',
});

const mailPath = (
  <g>
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M1.99935 0H25.9993C26.353 0 26.6921 0.140476 26.9422 0.390524C27.1922 0.640573 27.3327 0.979711 27.3327 1.33333V22.6667C27.3327 23.0203 27.1922 23.3594 26.9422 23.6095C26.6921 23.8595 26.353 24 25.9993 24H1.99935C1.64573 24 1.30659 23.8595 1.05654 23.6095C0.806491 23.3594 0.666016 23.0203 0.666016 22.6667V1.33333C0.666016 0.979711 0.806491 0.640573 1.05654 0.390524C1.30659 0.140476 1.64573 0 1.99935 0ZM24.666 5.65067L14.0953 15.1173L3.33268 5.62133V21.3333H24.666V5.65067ZM4.01402 2.66667L14.0807 11.5493L24.002 2.66667H4.01402Z"
      fill="currentColor"
    />
  </g>
);

export const MailSignIcon = createIcon(mailPath, {
  width: 28,
  height: 28,
  viewBox: '0 0 28 28',
  fill: 'none',
});
