import Chip from '../../../../components/shared/chip';
import { Box, Typography } from '@mui/material';
import { capitalize, formatCurrency } from '../../../../utils/string';
import Button from '../../../../components/shared/buttons';
import { RefreshIcon } from '../../../../libs/svg-icons/icons';
import UserDetails from '../../../../components/shared/user';
import ViewMaterial from '../../../../components/shared/viewMaterial';

export const columns = [
  {
    id: 'minderMarvelId',
    headerName: 'BB Number / Marvel ID',
    field: 'marvelBBId',
    minWidth: 350,
    maxWidth: 350,
    sortable: true,
    filter: true,
    colSpan: 2,
  },
  {
    id: 'buyingBriefStatus',
    headerName: 'Buying Brief Status',
    field: 'buyingBriefStatus',
    minWidth: 300,
    maxWidth: 300,
    cellRenderer: params => {
      let status = 'warning';
      switch (params.buyingBriefStatus) {
        case 'LIVE':
          status = 'success';
          break;
        case 'CANCELLED':
          status = 'error';
          break;
        case 'APPROVED':
          status = 'success';
          break;
        default:
          break;
      }
      if (params.buyingBriefStatus) {
        return (
          <Chip
            label={capitalize(params.buyingBriefStatus)}
            status={status}
            styles={{ margin: 'auto' }}
          />
        );
      } else {
        return <Chip label="Draft" status={status} styles={{ margin: 'auto' }} />;
      }
    },
    sortable: true,
    filter: true,
  },
  {
    id: 'cancelStatus',
    headerName: 'Cancelled Status',
    field: 'cancelStatus',
    minWidth: 300,
    maxWidth: 300,
    sortable: false,
    filter: true,
  },
  {
    id: 'minderData',
    headerName: 'Data From Minder',
    minWidth: 300,
    maxWidth: 300,
    cellRenderer: data => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '16px',
        }}
      >
        <Button
          height="30px"
          sx={{
            minWidth: '21px',
            padding: '6px 14px',
          }}
          variant="outlined"
          startIcon={<RefreshIcon fill="#0A2756" />}
          label={<Typography variant="Medium-12">Refresh</Typography>}
        />
      </Box>
    ),
  },
  {
    id: 'agency',
    headerName: 'Agency',
    field: 'agency',
    minWidth: 300,
    maxWidth: 300,
    sortable: false,
    filter: true,
  },
  {
    id: 'client',
    headerName: 'Client',
    field: 'client',
    minWidth: 300,
    maxWidth: 300,
    sortable: false,
    filter: true,
  },
  {
    id: 'clientPoNumber',
    headerName: 'Client PO Number',
    field: 'clientPoNumber',
    minWidth: 300,
    maxWidth: 300,
    sortable: false,
    filter: true,
  },
  {
    id: 'businessUnit',
    headerName: 'Business Unit',
    field: 'businessUnit',
    minWidth: 300,
    maxWidth: 300,
    sortable: false,
    filter: true,
  },
  {
    id: 'category',
    headerName: 'Category',
    field: 'category',
    minWidth: 300,
    maxWidth: 300,
    sortable: false,
    filter: true,
  },
  {
    id: 'brand',
    headerName: 'Brand',
    field: 'brand',
    minWidth: 300,
    maxWidth: 300,
    sortable: false,
    filter: true,
  },
  {
    id: 'productCode',
    headerName: 'Variant (Finance) Code',
    field: 'productCode',
    minWidth: 300,
    maxWidth: 300,
    sortable: false,
    filter: true,
  },
  {
    id: 'productName',
    headerName: 'Variant (Finance) Name',
    field: 'productName',
    minWidth: 300,
    maxWidth: 300,
    sortable: false,
    filter: true,
  },
  {
    id: 'ioNumber',
    headerName: 'IO Number',
    field: 'ioNumber',
    minWidth: 300,
    maxWidth: 300,
    sortable: false,
    filter: true,
  },
  {
    id: 'mediaType',
    headerName: 'Media Type',
    field: 'mediaType',
    minWidth: 300,
    maxWidth: 300,
    sortable: false,
    filter: true,
  },
  {
    id: 'minderCampaignName',
    headerName: 'Campaign Name',
    field: 'campaignName',
    minWidth: 300,
    maxWidth: 300,
    sortable: false,
    filter: true,
  },
  {
    id: 'issueDate',
    headerName: 'Issue Date (DD/MM/YY)',
    field: 'issueDate',
    minWidth: 300,
    maxWidth: 300,
    sortable: false,
    filter: true,
  },
  {
    id: 'startDate',
    headerName: 'Start Date (DD/MM/YY)',
    field: 'startDate',
    minWidth: 300,
    maxWidth: 300,
    sortable: false,
    filter: true,
  },
  {
    id: 'endDate',
    headerName: 'End Date (DD/MM/YY)',
    field: 'endDate',
    minWidth: 300,
    maxWidth: 300,
    sortable: false,
    filter: true,
  },
  {
    id: 'minderTA',
    headerName: 'Minder TA',
    field: 'targetAudience',
    minWidth: 300,
    maxWidth: 300,
    sortable: false,
    filter: true,
  },
  {
    id: 'material',
    headerName: 'Material (String / List)',
    field: 'material',
    minWidth: 300,
    maxWidth: 300,
    sortable: false,
    filter: false,
    cellRenderer: params => {
      const { material = [] } = params;
      return <ViewMaterial materialData={material} />;
    },
  },
  {
    id: 'totalBudget',
    headerName: 'Total Budget',
    field: 'totalBudget',
    minWidth: 300,
    maxWidth: 300,
    sortable: false,
    filter: true,
  },
  {
    id: 'actualCost',
    headerName: 'Actual Cost',
    field: 'actualCost',
    minWidth: 300,
    maxWidth: 300,
    sortable: false,
    filter: true,
  },
  // formala fields
  {
    id: 'remainingBbBudget',
    headerName: 'Remaining BB Budget',
    field: 'remainingBbBudget',
    minWidth: 300,
    maxWidth: 300,
    sortable: false,
    filter: true,
  },
  {
    id: 'vendorInvoiceAmount',
    headerName: 'Vendor Invoice Amount',
    field: 'vendorInvoiceAmount',
    minWidth: 300,
    maxWidth: 300,
    sortable: false,
    filter: true,
  },
  {
    id: 'billToClientAmount',
    headerName: 'Billed To Client Amount',
    field: 'billToClientAmount',
    minWidth: 300,
    maxWidth: 300,
    sortable: false,
    filter: true,
  },
  {
    id: 'paidFromClientAmount',
    headerName: 'Paid From Client Amount',
    field: 'paidFromClientAmount',
    minWidth: 300,
    maxWidth: 300,
    sortable: false,
    filter: true,
  },
  // fields end here
  {
    id: 'remarks',
    headerName: 'Remark',
    field: 'remarks',
    minWidth: 300,
    maxWidth: 300,
    sortable: false,
    filter: true,
  },
  {
    id: 'attachment',
    headerName: 'Attachment',
    field: 'attachment',
    minWidth: 300,
    maxWidth: 300,
    sortable: false,
    filter: true,
  },
  {
    id: 'lastUpdated',
    headerName: 'Last Updated',
    field: 'lastUpdated',
    minWidth: 300,
    maxWidth: 300,
    sortable: false,
    filter: true,
  },
];

export const columnFilterProps = {
  marvelBBId: {
    type: 'text-filter',
  },
  buyingBriefStatus: {
    type: 'set-filter',
    options: ['Draft'],
  },
  cancelStatus: {
    type: 'set-filter',
    options: ['Yes', 'No'],
  },
  agency: {
    type: 'set-filter',
    options: [],
  },
  client: {
    type: 'set-filter',
    options: [],
  },
  businessUnit: {
    type: 'set-filter',
    options: [],
  },
  category: {
    type: 'set-filter',
    options: [],
  },
  brand: {
    type: 'set-filter',
    options: [],
  },
  variantFinanceCode: {
    type: 'set-filter',
    options: [],
  },
  variantFinanceName: {
    type: 'set-filter',
    options: [],
  },
  mediaType: {
    type: 'set-filter',
    options: [],
  },
  campaignName: {
    type: 'set-filter',
    options: [],
  },
  issueDate: {
    type: 'text-filter',
    options: [],
  },
  startDate: {
    type: 'text-filter',
    options: [],
  },
  endDate: {
    type: 'text-filter',
    options: [],
  },
  minderTA: {
    type: 'set-filter',
    options: [],
  },
  material: {
    type: 'set-filter',
    options: [],
  },
  totalBudget: {
    type: 'text-filter',
    options: [],
  },
  actualCost: {
    type: 'text-filter',
    options: [],
  },
  remarks: {
    type: 'text-filter',
    options: [],
  },
  attachment: {
    type: 'text-filter',
    options: [],
  },
  lastUpdated: {
    type: 'text-filter',
    options: [],
  },
};

export const childColumns = (handleViewPOClientDetails, theme) => {
  return [
    {
      id: 'serialNo',
      headerName: 'Sr.No.',
      field: 'id',
      minWidth: 80,
      maxWidth: 80,
      cellStyle: params => ({
        cursor: 'pointer',
      }),
      onCellClicked: handleViewPOClientDetails,
    },
    {
      id: 'vendorPoNumber',
      headerName: 'PO Vendor Number',
      field: 'vendorPoNumber',
      sortable: false,
      minWidth: 270,
      maxWidth: 270,
      cellStyle: params => ({
        cursor: 'pointer',
      }),
      onCellClicked: handleViewPOClientDetails,
    },
    {
      id: 'agency',
      headerName: 'Agency',
      field: 'agency',
      sortable: false,
      minWidth: 300,
      maxWidth: 300,
    },
    {
      id: 'client',
      headerName: 'Client',
      field: 'client',
      sortable: false,
      minWidth: 300,
      maxWidth: 300,
    },
    {
      id: 'productName',
      headerName: 'Product Name',
      field: 'productName',
      sortable: false,
      minWidth: 300,
      maxWidth: 300,
    },
    {
      id: 'campaignTitle',
      headerName: 'Campaign Title',
      field: 'campaignTitle',
      sortable: false,
      minWidth: 300,
      maxWidth: 300,
    },
    {
      id: 'bbNumber',
      headerName: 'BB Number',
      field: 'marvelBBId',
      sortable: false,
      minWidth: 300,
      maxWidth: 300,
    },
    {
      id: 'poAmount',
      headerName: 'PO Amount',
      field: 'poAmount',
      minWidth: 300,
      maxWidth: 300,
    },
    {
      id: 'type',
      headerName: 'Type',
      field: 'type',
      sortable: false,
      minWidth: 300,
      maxWidth: 300,
    },
    {
      id: 'media',
      headerName: 'Media',
      field: 'media',
      sortable: false,
      minWidth: 300,
      maxWidth: 300,
    },
    {
      id: 'month',
      headerName: 'Month',
      field: 'month',
      sortable: false,
      minWidth: 300,
      maxWidth: 300,
    },
    {
      id: 'year',
      headerName: 'Year',
      field: 'year',
      sortable: false,
      minWidth: 300,
      maxWidth: 300,
    },
    {
      id: 'vendorAssociate',
      headerName: 'Vendor Associated',
      field: 'vendorName',
      sortable: false,
      minWidth: 300,
      maxWidth: 300,
      cellRenderer: data => (
        <UserDetails
          fullName={data?.vendorName}
          fullNameColor={theme.palette.text['100']}
          initials={data?.vendorName.split(' ').map(x => x[0])}
          initialsBackgroundColor={theme.palette.primary.main}
          initialsColor={theme.palette.white}
          initialsVariant="SemiBold-12"
          initailsContainer="32px"
          fullNameVariant="Medium-14"
        />
      ),
    },
    {
      id: 'invoiceNumber',
      headerName: 'PO Vendor Invoice Number',
      field: 'invoiceNumber',
      sortable: false,
      minWidth: 300,
      maxWidth: 300,
      cellStyle: params => ({
        cursor: 'pointer',
      }),
    },
    {
      id: 'vendorPOInvoiceAmount',
      headerName: 'PO Vendor Invoice Amount',
      field: 'vendorPoAmount',
      minWidth: 300,
      maxWidth: 300,
      cellStyle: params => ({
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'left',
      }),
    },
    {
      id: 'date',
      headerName: 'PO Vendor Invoice Date',
      field: 'vendorPoInvoiceDate',
      minWidth: 300,
      maxWidth: 300,
      cellStyle: params => ({
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'left',
      }),
    },
    {
      id: 'clientInvoiceNumber',
      headerName: 'Client Invoice Number',
      field: 'clientInvoiceNumber',
      sortable: false,
      minWidth: 300,
      maxWidth: 300,
    },
    {
      id: 'linkedBB',
      headerName: 'Linked BB',
      field: 'linkedBB',
      minWidth: 300,
      maxWidth: 300,
      cellRenderer: params => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '16px',
          }}
        >
          <Button
            height="30px"
            sx={{
              minWidth: '21px',
              padding: '6px 14px',
            }}
            variant="outlined"
            label={<Typography variant="Medium-12">View</Typography>}
          />
        </Box>
      ),
    },
    {
      id: 'paidFromClient',
      headerName: 'Paid From Client',
      field: 'paidFromClient',
      cellRenderer: data => {
        if (data?.paidFromClient === 'PAID') {
          return <Chip status="success" label="Paid" />;
        } else {
          return <Chip status="warning" label="Unpaid" />;
        }
      },
      minWidth: 300,
      maxWidth: 300,
    },
    {
      id: 'paidToVendor',
      headerName: 'Paid To Vendor',
      field: 'paidToVendor',
      cellRenderer: data => {
        if (data?.paidFromClient === 'PAID') {
          return <Chip status="success" label="Paid" />;
        } else {
          return <Chip status="warning" label="Unpaid" />;
        }
      },
      minWidth: 300,
      maxWidth: 300,
      colSpan: 1,
    },
    {
      id: 'paidToVendor',
      headerName: '',
      field: 'paidToVendor',
      cellRenderer: data => {
        return '';
      },
      minWidth: 2990,
      maxWidth: 2990,
      colSpan: 10,
    },
  ];
};
