import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './pdf.css';
import { useEffect, useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/core/lib/styles/index.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const IBPdfViewer = ({ pdfUrl }) => {
  const [width, setWidth] = useState(window?.innerWidth);

  const updateDimensions = () => {
    setWidth(window?.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const zoomPluginInstance = zoomPlugin();
  const { zoomTo } = zoomPluginInstance;

  useEffect(() => {
    const zoomVal = window.innerWidth / document.querySelector('.react-pdf__Page')?.offsetWidth;
    if (zoomVal) {
      zoomTo(zoomVal);
    }
  }, [width]);

  return (
    <div style={{ overflow: 'hidden' }}>
      <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
        <Viewer fileUrl={pdfUrl} plugins={[zoomPluginInstance]} />
      </Worker>
    </div>
  );
};
