import React, { useState, useEffect } from 'react';
import { DownloadIcon } from '../../../../../libs/svg-icons/icons';
import { Box, Typography } from '@mui/material';
import Button from '../../../../../components/shared/buttons';
import { useTheme } from '@emotion/react';
import Chip from '../../../../../components/shared/chip';
import RequestDetailsDialog from '../../../../../components/shared/dialog/RequestDetailsDialog';
import RequestDialogCard from '../../../../../components/shared/dialog/RequestDailogCard';
import Dialog from '../../../../../components/shared/dialog';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Select from '../../../../../components/shared/form/Select';
import InputField from '../../../../../components/shared/form/Input';
import SignPurchaseOrder from './SignPurchaseOrder';

const schema = yup.object().shape({
  vendor: yup.string().required('Vendor Required'),
  proposalid: yup.string().required('Proposal Id Required'),
});

const HeaderActions = ({
  setShowApproveReject,
  setIsApproved,
  isApproved,
  setOpenManagerApprovalPending,
  openManagerApprovalPending,
  handleChipClick,
  setOpenSign,
  openSign,
}) => {
  const theme = useTheme();

  const schemaMethods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      vendor: '',
      proposalid: '',
    },
  });

  const { control } = schemaMethods;
  const watchedFields = useWatch({ control, name: ['vendor', 'proposalid'] });

  const [openAssociateVendorDialog, setOpenAssociateVendorDialog] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isApprovalSent, setIsApprovalSent] = useState(false);

  useEffect(() => {
    const [vendor, proposalid] = watchedFields;
    setIsButtonDisabled(!(vendor && proposalid));
  }, [watchedFields]);

  const handleOpenManagerApprovalPending = () => {
    setOpenManagerApprovalPending(true);
  };

  const handleCloseManagerApprovalPending = () => {
    setOpenManagerApprovalPending(false);
  };

  const handleOpenAssociateVendorDialog = () => {
    setOpenAssociateVendorDialog(true);
  };

  const handleCloseAssociateVendorDialog = () => {
    setOpenAssociateVendorDialog(false);
  };

  const handleSendForApproval = () => {
    setOpenSign(true);
    // setIsApprovalSent(true);
  };

  const associateOption = [
    { value: 'digitalvendor', label: 'Digital Vendor (digitalvendor@gmail.com)' },
    { value: 'notContains', label: 'Does Not Contain' },
    { value: 'equals', label: 'Equals' },
    { value: 'notEquals', label: 'Does Not Equal' },
  ];

  const cards = [
    <RequestDialogCard
      headline="Buying Team Executive"
      description={
        isApproved ? 'Approved on 07-08-2023 at 10:00 AM' : 'Requested on 07-08-2023 at 11:00 AM'
      }
      ManagerName="Lark Mccarthy"
      style={{
        color: isApproved ? theme.palette.success.main : theme.palette.text['100'],
      }}
    />,
    <RequestDialogCard
      headline="Campaign Delivery Manager"
      description={
        isApproved ? 'Approved on 07-08-2023 at 10:00 AM' : 'Requested on 07-08-2023 at 11:00 AM'
      }
      style={{
        color: isApproved ? theme.palette.success.main : theme.palette.text['100'],
      }}
      ManagerName="Woody Marshall"
    />,
    <RequestDialogCard headline="Requested by Planner" ManagerName="Harry Setyono" />,
  ];

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'row',
      }}
    >
      <Box sx={{ width: '60%', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Button
          width="40px"
          height="40px"
          sx={{
            minWidth: '21px',
            padding: '5px',
          }}
          variant="outlined"
          startIcon={
            <Box
              component={'span'}
              sx={{
                width: '21px',
                height: '21px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <DownloadIcon fill={theme.palette.primary.main} />
            </Box>
          }
        />
      </Box>

      <Box
        sx={{
          width: '40%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: '20px',
        }}
      >
        {isApprovalSent ? (
          <>
            <Chip
              label={isApproved ? 'Approved' : 'Approval Pending'}
              status={isApproved ? 'success' : 'warning'}
              onClick={handleOpenManagerApprovalPending}
            />
            <RequestDetailsDialog
              open={openManagerApprovalPending}
              onClose={handleCloseManagerApprovalPending}
              width="668px"
              headline={
                <Typography
                  variant="SemiBold-22"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  View Request
                </Typography>
              }
              cards={cards}
              description={
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    gap: '5px',
                  }}
                >
                  <Typography variant="Medium-18">Comment :</Typography>
                  <Typography variant="Regular-16" sx={{ textAlign: 'left' }}>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Saepe enim
                    perspiciatis illo ut libero. Ea blanditiis ipsam fugiat iste! Expedita placeat
                    soluta odit maiores illo veritatis ducimus consequuntur, culpa quam.{' '}
                  </Typography>
                </Box>
              }
              chips={
                <Chip
                  label={isApproved ? 'Approved' : 'Approval Pending'}
                  height="40px"
                  status={isApproved ? 'success' : 'warning'}
                  onClick={isApproved ? null : handleChipClick}
                />
              }
              buttons={
                isApproved ? null : (
                  <Button
                    variant="outlined"
                    label="Cancel Request"
                    width="152px"
                    height="40px"
                    status="success"
                    onClick={handleCloseManagerApprovalPending}
                  />
                )
              }
            />
          </>
        ) : (
          <Button label="Send for Approval" variant="contained" onClick={handleSendForApproval} />
        )}
        {isApproved && (
          <Button
            label="Associate Vendor"
            variant="contained"
            onClick={handleOpenAssociateVendorDialog}
          />
        )}
        <FormProvider {...schemaMethods}>
          <Dialog
            open={openAssociateVendorDialog}
            onClose={handleCloseAssociateVendorDialog}
            headline={<Typography variant="SemiBold-22">Associate Vendor</Typography>}
            description={
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  flexDirection: 'column',
                  gap: '24px',
                }}
              >
                <Box sx={{ width: '100%', marginTop: '24px', textAlign: 'left' }}>
                  <Select
                    id="vendor"
                    label="Select Vendor"
                    options={associateOption}
                    selectHeight="48px"
                    placeholder="Select vendor"
                    {...schemaMethods.register('vendor')}
                  />
                </Box>
                <Box sx={{ width: '100%' }}>
                  <InputField
                    type="text"
                    id="proposalid"
                    inputHeight="48px"
                    placeholder="Enter Proposal ID"
                    label="Proposal ID"
                    {...schemaMethods.register('proposalid')}
                  />
                </Box>

                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginTop: '16px',
                  }}
                >
                  <Button
                    label="Associate Vendor"
                    variant="contained"
                    disabled={isButtonDisabled}
                    onClick={handleCloseAssociateVendorDialog}
                  />
                </Box>
              </Box>
            }
            width="688px"
            showDialogActions={false}
          />
        </FormProvider>
      </Box>

      <SignPurchaseOrder
        open={openSign}
        onClose={() => {
          setOpenSign(false);
        }}
        handleIBSignData={() => {
          setIsApprovalSent(true);
          setShowApproveReject(true);
        }}
        showTeamDetails={true}
      />
    </Box>
  );
};

export default HeaderActions;
