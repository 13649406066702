import React, { useMemo } from 'react';
import { AddIcon, CopyIcon, CrossIcon, DeleteIcon } from '../../../../../libs/svg-icons/icons';
import ButtonMenu from '../../../../../components/shared/menu/ButtonMenu';

const CheckboxCell = ({ params, onMenuClick }) => {
  const isBBGCreated = !!params.data?.bbgCreated;
  const isRowLocked = !!params.data?.isLocked;
  const isRowCancelled = params.data?.mediaPlanRowsStatus === 'CANCELLED';
  const options = useMemo(() => {
    if (isRowCancelled) {
      return ['Add New Row', 'Duplicate Row'];
    }
    if (isBBGCreated) {
      if (isRowLocked) {
        return ['Add New Row', 'Duplicate Row'];
      } else {
        return ['Add New Row', 'Duplicate Row', 'Cancel Row'];
      }
    } else {
      return ['Add New Row', 'Duplicate Row', 'Delete Row', 'Cancel Row'];
    }
  }, [isBBGCreated, isRowCancelled, isRowLocked]);

  const icons = useMemo(() => {
    if (isRowCancelled) {
      return [<AddIcon fill="var(--text-300)" />, <CopyIcon fill="var(--text-300)" />];
    }
    if (isBBGCreated) {
      if (isRowLocked)
        return [<AddIcon fill="var(--text-300)" />, <CopyIcon fill="var(--text-300)" />];
      else
        return [
          <AddIcon fill="var(--text-300)" />,
          <CopyIcon fill="var(--text-300)" />,
          <CrossIcon fill="var(--text-300)" />,
        ];
    } else {
      return [
        <AddIcon fill="var(--text-300)" />,
        <CopyIcon fill="var(--text-300)" />,
        <DeleteIcon fill="var(--text-300)" />,
        <CrossIcon fill="var(--text-300)" />,
      ];
    }
  }, [isBBGCreated, isRowLocked]);

  return (
    <ButtonMenu
      options={options}
      icons={icons}
      onMenuClick={onMenuClick}
      rowId={params.data?.id}
      params={params}
    />
  );
};

export default CheckboxCell;
