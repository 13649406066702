import { createIcon } from '../createSVGIcon';

const path = (
  <g>
    <path d="M16 0L20 5H17V17H15V5H12L16 0ZM11 15V17H0V15H11ZM11 8V10H0V8H11ZM9 1V3H0V1H9Z" />
  </g>
);

export const SortAscIcon = createIcon(path, {
  width: 20,
  height: 17,
  viewBox: '0 0 20 17',
  fill: 'none',
});
