import Chip from '../../../../../components/shared/chip';
import SetFilter from '../../../../../components/shared/table/filters/SetFilter';
import TextFilter from '../../../../../components/shared/table/filters/TextFilter';
import UserDetails from '../../../../../components/shared/user';
import { capitalize, formatCurrency } from '../../../../../utils/string';
import CheckboxCell from './CheckboxCell';

export const columns = onMenuClick => [
  {
    colId: 'checkbox',
    cellRenderer: params => <CheckboxCell params={params} onMenuClick={onMenuClick} />,
    cellClass: 'menu-utils',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    pinned: 'left',
    lockPosition: true,
    sortable: false,
    width: 84,
    maxWidth: 84,
    resizable: false,
    suppressSizeToFit: true,
  },
  {
    colId: 'externalId',
    headerName: 'ID',
    field: 'externalId',
    sortable: true,
    pinned: 'left',
    lockPosition: true,
    minWidth: 200,
    width: 200,
    suppressSizeToFit: true,
  },
  {
    colId: 'category',
    headerName: 'Category',
    field: 'category.id',
    sortable: true,
    minWidth: 165,
    suppressSizeToFit: true,
    clipboard: true,
    cellStyle: params => ({
      background: 'var(--text-600)',
      cursor: 'not-allowed',
    }),
    filter: SetFilter,
  },
  {
    colId: 'brand',
    headerName: 'Brand',
    field: 'brand.id',
    sortable: true,
    minWidth: 191,
    suppressSizeToFit: true,
    clipboard: true,
    cellStyle: params => ({
      background: 'var(--text-600)',
      cursor: 'not-allowed',
    }),
    filter: SetFilter,
  },
  {
    colId: 'brandFinance',
    headerName: 'Brand Finance',
    field: 'brandFinance.id',
    minWidth: 191,
    suppressSizeToFit: true,
    clipboard: true,
    filter: SetFilter,
  },
  {
    colId: 'campaign',
    headerName: 'Campaign',
    field: 'campaign.id',
    minWidth: 200,
    suppressSizeToFit: true,
    clipboard: true,
    filter: SetFilter,
    suppressFiltersToolPanel: true,
  },
  {
    colId: 'variantFinanceCode',
    headerName: 'Variant (Finance) Code',
    field: 'variantFinanceName.externalId',
    sortable: true,
    minWidth: 250,
    suppressSizeToFit: true,
    filter: SetFilter,
  },
  {
    colId: 'variantFinance',
    headerName: 'Variant (Finance) Name',
    field: 'variantFinanceName.id',
    sortable: true,
    minWidth: 250,
    suppressSizeToFit: true,
    filter: SetFilter,
  },
  {
    colId: 'materialName',
    headerName: 'Material Name',
    field: 'materialName.id',
    sortable: true,
    minWidth: 250,
    suppressSizeToFit: true,
  },
  {
    colId: 'materialCode',
    headerName: 'Material Code',
    field: 'materialCode.materialCode',
    sortable: true,
    minWidth: 250,
    suppressSizeToFit: true,
  },
  {
    colId: 'clusterTA',
    headerName: 'Cluster TA',
    field: 'clusterTA.id',
    sortable: true,
    minWidth: 200,
    suppressSizeToFit: true,
    filter: SetFilter,
  },
  {
    colId: 'minderTA',
    headerName: 'Minder TA',
    field: 'minderTA.id',
    sortable: true,
    minWidth: 200,
    suppressSizeToFit: true,
    clipboard: true,
    filter: SetFilter,
    suppressFiltersToolPanel: true,
  },
  {
    colId: 'Media',
    headerName: 'Media',
    field: 'mediaType.id',
    sortable: true,
    minWidth: 108,
    suppressSizeToFit: true,
    filter: SetFilter,
  },
  {
    colId: 'Format',
    headerName: 'Format',
    field: 'format.id',
    sortable: true,
    minWidth: 180,
    suppressSizeToFit: true,
    filter: SetFilter,
  },
  {
    colId: 'Placement',
    headerName: 'Placement',
    field: 'placement.id',
    sortable: true,
    minWidth: 150,
    suppressSizeToFit: true,
    filter: SetFilter,
  },
  {
    colId: 'Type',
    headerName: 'Type',
    field: 'type.id',
    sortable: true,
    minWidth: 181,
    suppressSizeToFit: true,
    filter: SetFilter,
  },
  {
    colId: 'Partner',
    headerName: 'Partner',
    field: 'partner.id',
    sortable: true,
    minWidth: 226,
    suppressSizeToFit: true,
    filter: SetFilter,
  },
  {
    colId: 'taDigital',
    headerName: 'TA Digital',
    field: 'taDigital',
    sortable: true,
    minWidth: 200,
    suppressSizeToFit: true,
    editable: params =>
      params.data?.mediaPlanRowsStatus === 'CANCELLED' ? false : !params.data.isLocked,
    filter: TextFilter,
    valueFormatter: params => (params.value ? params.value : 'NA'),
  },
  {
    colId: 'startDate',
    headerName: 'Start Date',
    field: 'startDate',
    sortable: true,
    minWidth: 180,
    suppressSizeToFit: true,
    filter: 'agDateColumnFilter',
    valueGetter: params => (params.data?.startDate ? new Date(params.data?.startDate) : null),
  },
  {
    colId: 'endDate',
    headerName: 'End Date',
    field: 'endDate',
    sortable: true,
    minWidth: 180,
    suppressSizeToFit: true,
    filter: 'agDateColumnFilter',
    valueGetter: params => (params.data?.endDate ? new Date(params.data?.endDate) : null),
  },
  {
    colId: 'month',
    headerName: 'Month',
    field: 'month.id',
    sortable: true,
    minWidth: 150,
    suppressSizeToFit: true,
    filter: SetFilter,
  },
  {
    colId: 'quarter',
    headerName: 'Quarter',
    field: 'quarter.id',
    sortable: true,
    minWidth: 100,
    suppressSizeToFit: true,
    filter: SetFilter,
  },
  {
    colId: 'half',
    headerName: 'Half',
    field: 'half.id',
    sortable: true,
    minWidth: 100,
    suppressSizeToFit: true,
    filter: SetFilter,
  },
  {
    colId: 'frequency',
    headerName: 'Frequency',
    field: 'frequency.id',
    sortable: true,
    minWidth: 150,
    suppressSizeToFit: true,
    filter: SetFilter,
  },
  {
    colId: 'tvReach',
    headerName: 'TV Reach',
    field: 'tvReach',
    sortable: true,
    minWidth: 150,
    editable: params =>
      params.data?.mediaPlanRowsStatus === 'CANCELLED' ? false : !params.data.isLocked,
    cellEditor: 'agNumberCellEditor',
    cellEditorParams: {
      precision: 2,
      preventStepping: true,
      min: 0,
      max: 100,
    },
    suppressSizeToFit: true,
    valueFormatter: params => (params.value ? params.value : '-'),
    filter: TextFilter,
  },
  {
    colId: 'duration',
    headerName: 'Duration',
    field: 'duration.id',
    sortable: true,
    minWidth: 150,
    suppressSizeToFit: true,
    filter: SetFilter,
  },
  {
    colId: 'grp',
    headerName: 'GRP',
    field: 'grp',
    sortable: true,
    minWidth: 100,
    suppressSizeToFit: true,
    filter: TextFilter,
    valueFormatter: params => (params.value ? params.value : '-'),
    filterParams: {
      valueFormatter: value => (!!value ? value : '-'),
    },
  },
  {
    colId: 'ngrp',
    headerName: 'NGRP',
    field: 'ngrp',
    sortable: true,
    minWidth: 100,
    suppressSizeToFit: true,
    filter: TextFilter,
    valueFormatter: params =>
      params.value === null || params.value === undefined ? '-' : params.value,
    filterParams: {
      valueFormatter: value => (!!value ? value : '-'),
    },
  },
  {
    colId: 'ngrpReport',
    headerName: 'NGRP Report',
    field: 'ngrpReport',
    sortable: true,
    minWidth: 200,
    suppressSizeToFit: true,
    filter: TextFilter,
    valueFormatter: params => (params.value ? params.value : '-'),
    filterParams: {
      valueFormatter: value => (!!value ? value : '-'),
    },
  },
  {
    colId: 'acd',
    headerName: 'ACD',
    field: 'acd',
    sortable: true,
    minWidth: 150,
    suppressSizeToFit: true,
    filter: TextFilter,
    valueFormatter: params => (params.value ? params.value : '-'),
    filterParams: {
      valueFormatter: value => (!!value ? value : '-'),
    },
  },
  {
    colId: 'plannedBudget',
    headerName: 'Plan Budget',
    field: 'planBudget',
    sortable: true,
    minWidth: 158,
    suppressSizeToFit: true,
    filter: TextFilter,
    valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
    filterParams: {
      valueFormatter: value => (!!value ? value : '-'),
    },
  },
  {
    colId: 'ioNumber',
    headerName: 'IO Number',
    field: 'ioNumber',
    sortable: true,
    minWidth: 150,
    editable: params =>
      params.data?.mediaPlanRowsStatus === 'CANCELLED' ? false : !params.data.bbgCreated,
    suppressSizeToFit: true,
    filter: TextFilter,
  },
  {
    colId: 'bbNumberMinder',
    headerName: 'BB Number Minder',
    field: 'bbNumberMinder',
    sortable: true,
    minWidth: 250,
    suppressSizeToFit: true,
    filter: TextFilter,
  },
  {
    colId: 'clientPoNumber',
    headerName: 'DO Number',
    field: 'doNumber',
    sortable: true,
    minWidth: 250,
    suppressSizeToFit: true,
    filter: TextFilter,
  },
  {
    colId: 'notes',
    headerName: 'Notes',
    field: 'notes',
    sortable: true,
    minWidth: 300,
    editable: params =>
      params.data?.mediaPlanRowsStatus === 'CANCELLED' ? false : !params.data.isLocked,
    suppressSizeToFit: true,
    filter: TextFilter,
  },
  {
    colId: 'status',
    headerName: 'Status',
    field: 'mediaPlanRowsStatus',
    valueFormatter: params => capitalize(params.value),
    cellRenderer: params => {
      if (!params.value) return '-';
      let status = 'warning';
      switch (params.value) {
        case 'LIVE':
          status = 'success';
          break;
        case 'CANCELLED':
          status = 'error';
          break;
        default:
          break;
      }
      return <Chip status={status} label={params.valueFormatted} styles={{ margin: 'auto' }} />;
    },
    minWidth: 165,
    filter: SetFilter,
  },
  {
    colId: 'planer',
    headerName: 'Planner',
    field: 'plannerName',
    sortable: false,
    minWidth: 210,
    cellRenderer: params => (
      <UserDetails
        fullName={params?.value}
        fullNameColor="var(--text-100)"
        initials={params?.value?.split(' ').map(x => x[0])}
        initialsBackgroundColor="var(--primary)"
        initialsColor="var(--white)"
        initialsVariant="SemiBold-12"
        initailsContainer="32px"
        fullNameVariant="Medium-14"
      />
    ),
    filter: SetFilter,
  },
  {
    colId: 'expenditureType',
    headerName: 'CBT Code',
    field: 'expenditureType.id',
    sortable: true,
    minWidth: 250,
    suppressSizeToFit: true,
    filter: SetFilter,
  },
  {
    colId: 'activityInput',
    headerName: 'Activity Input',
    field: 'activityInput.id',
    sortable: true,
    minWidth: 270,
    suppressSizeToFit: true,
    filter: SetFilter,
  },
  {
    colId: 'jtbd',
    headerName: 'JTBD',
    field: 'jtbd',
    sortable: true,
    minWidth: 500,
    suppressSizeToFit: true,
    filter: TextFilter,
  },
];
