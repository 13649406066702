import { useNavigate } from 'react-router-dom';
import { AppConstants } from '../constants/app-constants';

export const handleError = error => {
  const { message = AppConstants.SOMETHING_WENT_WRONG, status } = error?.response?.data || {};
  let msg = '';

  if (status === AppConstants.HTTP_FORBIDDEN) {
    msg = AppConstants.FORBIDDEN_ERROR;
  } else {
    msg = message;
  }

  return msg;
};
