import axiosInstance from './api';
import { ApiURLConstants } from '../constants/api-url-constants';

const globalHeaders = {
  'Content-Type': 'application/json',
};

// const ngrokURL = 'https://708e-2402-8100-3022-9263-b198-f027-a7ba-c4bb.ngrok-free.app';
// const API_BASE_URL = `${ngrokURL}/m2/v1`;
const API_BASE_URL = process.env.REACT_APP_MEDIA_API_BASE_URL;
const API_USER_API_BASE_URL = process.env.REACT_APP_USER_API_BASE_URL;
const API = axiosInstance;
const NOTIFICATION_URL = process.env.REACT_APP_NOTIFICATION_URL;

export const listMediaPlan = async params => {
  const uri = `${API_BASE_URL}${ApiURLConstants.LIST_MEDIA_PLAN}${params}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const getMediaPlanMasterData = async brandId => {
  const headers = { ...globalHeaders };
  // const url = `${API_BASE_URL}${ApiURLConstants.MEDIA_PLAN_MASTER_DATA}`;
  const url = `${API_BASE_URL}${ApiURLConstants.MEDIA_PLAN_MASTER_DATA}?brandId=${brandId}`;
  const response = await API.get(url, {
    headers,
  });
  return response;
};

export const getNGRPReportData = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.GET_NGRP_FROM_MASTER_DATA(payload)}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const getPlanBudgetData = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.GET_PLAN_BUDGET_FROM_MASTER_DATA(payload)}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const getMediaPlan = async id => {
  const uri = `${API_BASE_URL}${ApiURLConstants.GET_MEDIA_PLAN}/${id}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const getMediaPlanValidations = async params => {
  const uri = `${API_BASE_URL}${ApiURLConstants.GET_MEDIA_PLAN_VALIDATION(params)}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const createMediaPlanRow = async (params, request) => {
  const uri = `${API_BASE_URL}${ApiURLConstants.CREATE_MEDIA_PLAN_ROW(params)}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, request, {
    headers,
  });
  return response;
};

export const cancelMediaPlanRow = async params => {
  const uri = `${API_BASE_URL}${ApiURLConstants.CANCEL_MEDIA_PLAN_ROW(params)}`;
  const headers = { ...globalHeaders };
  const response = await API.put(
    uri,
    {},
    {
      headers,
    },
  );
  return response;
};

export const getMediaPlanVersions = async params => {
  const uri = `${API_BASE_URL}${ApiURLConstants.GET_MEDIA_PLAN_VERSION_LIST(params)}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const getMediaPlanVersionDetails = async params => {
  const uri = `${API_BASE_URL}${ApiURLConstants.GET_MEDIA_PLAN_VERSION_DETAILS(params)}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const saveNewVersionForMediaPlan = async (params, request) => {
  const uri = `${API_BASE_URL}${ApiURLConstants.SAVE_AS_NEW_VERSION(params)}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, request, {
    headers,
  });
  return response;
};

export const getManagerList = async params => {
  const uri = `${API_USER_API_BASE_URL}${ApiURLConstants.GET_MANAGER_LIST(params)}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const changeMediaPlanStatus = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.CHANGE_MEDIA_PLAN_STATUS}`;
  const headers = { ...globalHeaders };
  const response = await API.put(uri, payload, {
    headers,
  });
  return response;
};

export const updateApprovalStatus = async request => {
  const uri = `${API_BASE_URL}${ApiURLConstants.SEND_APPROVAL_FOR_MANAGER}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, request, {
    headers,
  });

  return response;
};

export const getApprovalStatus = async params => {
  const uri = `${API_BASE_URL}${ApiURLConstants.VIEW_APPROVAL_REQUEST(params)}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const createMediaPlan = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.CREATE_MEDIA_PLAN}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const validateMediaPlan = async (params, payload) => {
  const uri = `${API_BASE_URL}${ApiURLConstants.VALIDATE_MEDIA_PLAN(params)}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const cancelValidateMediaPlan = async (params, payload) => {
  const uri = `${API_BASE_URL}${ApiURLConstants.VALIDATE_MEDIA_PLAN(params)}`;
  const headers = { ...globalHeaders };
  const response = await API.put(uri, payload, {
    headers,
  });
  return response;
};

export const getCreateMediaPlanMasterData = async () => {
  const uri = `${API_BASE_URL}${ApiURLConstants.GET_CREATE_MEDIA_PLAN_MASTER_DATA}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const createMediaPlanWithTemplate = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.CREATE_MEDIA_PLAN_WITH_TEMPLATE}`;
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  const response = await API.post(uri, payload, { headers });
  return response;
};

export const downloadMediaPlan = async id => {
  const uri = `${API_BASE_URL}${ApiURLConstants.DOWNLOAD_MEDIA_PLAN}?id=${id}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    responseType: 'arraybuffer',
    headers,
  });
  return response;
};

export const saveGivenBudget = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.SAVE_GIVEN_BUDGET}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const deleteMediaPlan = async id => {
  const uri = `${API_BASE_URL}${ApiURLConstants.DELETE_MEDIA_PLAN}/${id}`;
  const headers = { ...globalHeaders };
  const response = await API.delete(uri, {
    headers,
  });
  return response;
};

export const getAllBrand = async () => {
  const uri = `${API_BASE_URL}${ApiURLConstants.GET_ALL_BRANDS}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const createBuyingBriefGroup = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.CREATE_BUYING_BRIEF_GROUP}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const getBuyingBriefGroup = async params => {
  const uri = `${API_BASE_URL}${ApiURLConstants.GET_BUYING_BRIEF_GROUP(params)}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const getBuyingBriefGroupList = async params => {
  const uri = `${API_BASE_URL}${ApiURLConstants.GET_LIST_BUYING_BRIEF_GROUP(params)}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

// Internal Brief services

export const generateInternalBrief = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.GENERATE_INTERNAL_BRIEF}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const saveInternalBrief = async (id, payload) => {
  const uri = `${API_BASE_URL}${ApiURLConstants.SAVE_INTERNAL_BRIEF(id)}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const viewInternalBrief = async id => {
  const uri = `${API_BASE_URL}${ApiURLConstants.VIEW_INTERNAL_BRIEF(id)}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const sendInternalBriefApprovalRequest = async (id, payload) => {
  const uri = `${API_BASE_URL}${ApiURLConstants.SEND_INTERNAL_BRIEF_APPROVAL_REQUEST(id)}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const viewInternalBriefApprovalRequest = async id => {
  const uri = `${API_BASE_URL}${ApiURLConstants.VIEW_INTERNAL_BRIEF_REQUEST(id)}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const changeInternalBriefStatus = async (id, payload) => {
  const uri = `${API_BASE_URL}${ApiURLConstants.CHANGE_INTERNAL_BRIEF_STATUS(id)}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const getAllSummary = async params => {
  const uri = `${API_BASE_URL}${ApiURLConstants.GET_ALL_SUMMARY(params)}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const searchPOClient = async params => {
  const uri = `${API_BASE_URL}${ApiURLConstants.SEARCH_PO_CLIENT(params)}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const getPOClientDetails = async id => {
  const uri = `${API_BASE_URL}${ApiURLConstants.GET_PO_CLIENT_DETAILS(id)}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const assignPOToBBG = async (poClient, payload) => {
  const uri = `${API_BASE_URL}${ApiURLConstants.ASSIGN_PO_TO_BBG(poClient)}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const unlockMediaPlanRows = async (params, payload) => {
  const uri = `${API_BASE_URL}${ApiURLConstants.UNLOCK_MEDIA_PLAN_ROWS(params)}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const lockMediaPlanRows = async (params, payload) => {
  const uri = `${API_BASE_URL}${ApiURLConstants.LOCK_MEDIA_PLAN_ROWS(params)}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const GenerateClientPOException = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.GENERATE_CLIENT_PO_EXCEPTION}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const SendPoExceptionRequestToApproval = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.SEND_PO_EXCEPTION_TO_APPROVAL}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const changePoExceptionStatus = async (id, payload) => {
  const uri = `${API_BASE_URL}${ApiURLConstants.CHANGE_PO_EXCEPTION_STATUS(id)}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const getPOClientExceptionRequest = async params => {
  const uri = `${API_BASE_URL}${ApiURLConstants.GET_CLIENT_PO_EXCEPTION_REQUEST(params)}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const createBBGOnMinder = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.CREATE_BBG_ON_MINDER}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const updateBBGOnMinder = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.UPDATE_BBG_ON_MINDER}`;
  const headers = { ...globalHeaders };
  const response = await API.put(uri, payload, {
    headers,
  });
  return response;
};

export const createBuyingBrief = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.CREATE_BUYING_BRIEF}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const getBuyingBrief = async params => {
  const uri = `${API_BASE_URL}${ApiURLConstants.GET_BUYING_BRIEF(params)}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const getBuyingBriefDetails = async id => {
  const uri = `${API_BASE_URL}${ApiURLConstants.GET_BUYING_BRIEF_DETAILS(id)}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const cancelRestoreBuyingBrief = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.CANCEL_RESTORE_BUYING_BRIEF}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const createBBOnMinder = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.CREATE_BB_ON_MINDER}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const updateBBOnMinder = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.UPDATE_BB_ON_MINDER}`;
  const headers = { ...globalHeaders };
  const response = await API.put(uri, payload, {
    headers,
  });
  return response;
};

// export const saveFCMToken = async fcmToken => {
//   const uri = `${NOTIFICATION_URL}${ApiURLConstants.SAVE_FCM_TOKEN}`;
//   const headers = { ...globalHeaders };
//   const payload = { fcmToken };

//   const response = await API.put(uri, payload, {
//     headers,
//   });
//   return response;
// };
