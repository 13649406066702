import React, { forwardRef, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { themePalette } from '../../../../constants/theme/color';
import { CrossIcon } from '../../../../libs/svg-icons/icons';
import Button from '../../buttons/index';
import { Controller, useFormContext } from 'react-hook-form';
import { getMimeType } from '../../../../libs/document/file';
import OverflowTooltip from '../../tooltip/OverflowTooltip';

const DragAndDropFilePicker = (
  {
    multi = false,
    icon = null,
    headline = '',
    description = '',
    disabled = false,
    name = '',
    acceptedfileExtensions = [],
    maxFileSize = 20,
    draggable = true,
    height = '',
  },
  _,
) => {
  const {
    control,
    setValue,
    setError,
    formState: { errors },
  } = useFormContext();

  const [selectedFiles, setSelectedFiles] = useState([]);

  const acceptedfileTypes = useMemo(
    () => acceptedfileExtensions.map(x => getMimeType(x)),
    [acceptedfileExtensions],
  );

  const handleDrop = e => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    if (files && files.length > 0) {
      validateFiles(files);
    }
  };

  const handleDragOver = e => {
    e.preventDefault();
  };

  const handleFileChange = e => {
    const files = e.target.files;
    if (!multi && files.length > 1) {
      setError(name, {
        message: `Multiple files are not allowed`,
      });
      return;
    }
    if (files && files.length > 0) {
      validateFiles(Array.from(files));
    }
  };

  const validateFiles = files => {
    const selectedFile = files[0];

    if (acceptedfileTypes.length && !acceptedfileTypes.includes(selectedFile.type)) {
      setError(name, {
        message:
          acceptedfileExtensions.length === 1
            ? `The accepted file type is ${acceptedfileExtensions.join(', ')}.`
            : `The accepted file types are ${acceptedfileExtensions.join(', ')}.`,
      });
      return;
    }

    if (selectedFile.size > maxFileSize * 1024 * 1024) {
      setError(name, {
        message: `File size exceeds ${maxFileSize}MB.`,
      });
      return;
    }

    setSelectedFiles(files);
    if (name)
      if (multi)
        setValue(name, files, {
          shouldValidate: true,
        });
      else
        setValue(name, files[0], {
          shouldValidate: true,
        });
    setError(name, undefined);
  };

  const handleRemoveFiles = () => {
    setSelectedFiles([]);
    if (name)
      setValue(name, undefined, {
        shouldValidate: true,
      });
  };

  return (
    <Controller
      name={name}
      control={control}
      render={() => {
        return (
          <Box
            sx={{
              border: '2px dashed #ccc',
              borderRadius: '10px',
              padding: '20px',
              textAlign: 'center',
              minHeight: '200px',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              background: disabled ? 'rgba(0, 0, 0, 0.05)' : 'transparent',
            }}
            onDrop={draggable ? handleDrop : () => {}}
            onDragOver={draggable ? handleDragOver : () => {}}
            onClick={() => document.getElementById('fileInput')?.click()}
          >
            <Box>{icon}</Box>
            {selectedFiles.length > 0 ? (
              <Box sx={{ width: '100%', maxWidth: '50ch' }}>
                {selectedFiles.map((file, index) => {
                  const fileName = file.name?.split('.')[0];
                  const fileExtension = file.name?.split('.')[1];

                  return (
                    <Box
                      key={index}
                      sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <OverflowTooltip text={file?.name} sx={{ width: 'auto' }}>
                        <Typography variant="Regular-14">{fileName}</Typography>
                      </OverflowTooltip>
                      .{fileExtension}
                    </Box>
                  );
                })}

                <Button
                  startIcon={<CrossIcon fill="var(--deep-navy)" />}
                  label="Remove"
                  variant="outlined"
                  onClick={handleRemoveFiles}
                />
              </Box>
            ) : (
              <>
                <Typography variant="Regular-14">{headline}</Typography>
                <Typography variant="Regular-12" sx={{ color: themePalette.palette.text['300'] }}>
                  {description}
                </Typography>
                <input
                  type="file"
                  id="fileInput"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  accept={acceptedfileExtensions.map(x => `.${x}`).join(',')}
                  multiple={multi}
                  height={height}
                  disabled={disabled}
                />
                {errors[name]?.message && (
                  <Typography color="error">{errors[name]?.message}</Typography>
                )}
              </>
            )}
          </Box>
        );
      }}
    />
  );
};

export default forwardRef(DragAndDropFilePicker);
