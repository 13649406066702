import React, { useEffect, useMemo, useState } from 'react';
import Button from '../../../../../../components/shared/buttons';
import { Box, Typography } from '@mui/material';
import Dialog from '../../../../../../components/shared/dialog';
import InputField from '../../../../../../components/shared/form/Input';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Loader from '../../../../../../components/shared/loader';
import { useNavigate, useParams } from 'react-router-dom';
import { createProject } from '../../../../../../services/project-service';
import { useEnqueueSnackbar } from '../../../../../../components/shared/toast-provider/toastHook';
import { AppConstants } from '../../../../../../constants/app-constants';
import { useHasAllPermission } from '../../../../../../hooks/permissions';
import { PermissionsConstants } from '../../../../../../constants/permissions-constants';
import { handleError } from '../../../../../../utils/errorHandling';

const CreateProjectSchema = yup.object().shape({
  projectName: yup.string().required(''),
});

const CreateProjectDialog = ({ data = {} }) => {
  const params = useParams();
  const navigate = useNavigate();
  const enqueueSnackbar = useEnqueueSnackbar();
  const hasAllPermissions = useHasAllPermission();

  const [openCreateProjectDialog, setOpenCreateProjectDialog] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  const handleOpenCreateProjectDialog = () => {
    setOpenCreateProjectDialog(true);
  };

  const handleCloseCreateProjectDialog = () => {
    setOpenCreateProjectDialog(false);
  };

  const monthNameToNumber = month => {
    const date = new Date(`${month} 1, 2000`);
    const monthNumber = date.getMonth() + 1;

    return monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
  };

  const getYear = date => {
    const temp = new Date(date);
    return temp.getFullYear();
  };

  const removeAllSpace = str => {
    return str.replace(/\s+/g, '');
  };

  const createProjectName = useMemo(() => {
    const {
      startDate,
      startDateYear,
      month,
      businessUnit,
      brand,
      campaign,
      mediaType,
      placement,
      type,
      externalId,
      year = '',
      format = '',
      partner = '',
    } = data || {};
    const str = `${getYear(startDateYear)}_${monthNameToNumber(month)}_${removeAllSpace(businessUnit?.toUpperCase())}_${removeAllSpace(brand)}_${removeAllSpace(campaign)}_${removeAllSpace(mediaType)}_${format}_${removeAllSpace(placement)}_${removeAllSpace(type)}_${partner}_${externalId}`;
    return str;
  }, [data, openCreateProjectDialog]);

  const CreateProjectMethods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(CreateProjectSchema),
    defaultValues: {
      projectName: createProjectName || '2023_August_Lifebuoy_Sun_Digital_Youtube_BB1234567890',
    },
  });

  const CreateProjectAPICall = async () => {
    const { id, minderBbNumber, bbId } = data || {};
    let payload = {
      mediaPlanId: parseInt(params.id),
      mediaPlanRowId: id,
      bbNumber: minderBbNumber,
      bbId: bbId,
      name: CreateProjectMethods.getValues('projectName'),
    };

    setIsloading(true);

    await createProject(payload)
      .then(res => {
        const { data } = res.data;
        enqueueSnackbar('Project created successfully.', 'success');
        navigate(`/app/projects/${data?.id}/view-project`);
      })
      .catch(error => {
        let msg = handleError(error);
        enqueueSnackbar(msg, 'error');
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const isDisable = useMemo(() => {
    const {
      minderBuyingBriefStatus = '',
      webAppBuyingBriefStatus = '',
      discrepancyStatus = '',
      mediaPlanRowsStatus = '',
    } = data || {};
    return (
      discrepancyStatus !== AppConstants.CREATED_AND_MATCHED ||
      minderBuyingBriefStatus === AppConstants.MEDIA_PLAN_STATUS_CANCELLED ||
      webAppBuyingBriefStatus === AppConstants.MEDIA_PLAN_STATUS_CANCELLED ||
      mediaPlanRowsStatus === AppConstants.IB_STATUS_CLIENT_CANCELLED
    );
  }, [data]);

  return (
    <>
      <Loader isLoading={isLoading} />
      <Button
        label="Create"
        variant="contained"
        height="32px"
        onClick={handleOpenCreateProjectDialog}
        disabled={isDisable || !hasAllPermissions([PermissionsConstants.CREATE_PROJECT])}
      />
      <FormProvider {...CreateProjectMethods}>
        <Dialog
          open={openCreateProjectDialog}
          onClose={handleCloseCreateProjectDialog}
          headline={
            <Typography variant="SemiBold-22" alignItems="flex-start" justifyContent="flex-start">
              Create Project{' '}
            </Typography>
          }
          description={
            <Box sx={{ width: '100%' }}>
              <form>
                <InputField
                  id="createproject"
                  type="text"
                  label="Project Name"
                  disabled={true}
                  value={CreateProjectMethods.getValues('projectName')}
                />
              </form>
            </Box>
          }
          buttons={[
            <Button
              type="submit"
              onClick={CreateProjectAPICall}
              width="167px"
              variant="contained"
              label="Create Project"
            />,
          ]}
          width="700px"
          height="120px"
          backgroundColor="white"
        />
      </FormProvider>
    </>
  );
};

export default CreateProjectDialog;
