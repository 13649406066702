import { createIcon } from '../createSVGIcon';

const path = (
  <g>
    <path d="M2.8105 10.9996L10.417 3.39307L9.3565 2.33257L1.75 9.93908V10.9996H2.8105ZM3.43225 12.4996H0.25V9.31732L8.82625 0.741074C8.9669 0.600471 9.15763 0.521484 9.3565 0.521484C9.55537 0.521484 9.7461 0.600471 9.88675 0.741074L12.0085 2.86282C12.1491 3.00347 12.2281 3.1942 12.2281 3.39307C12.2281 3.59195 12.1491 3.78268 12.0085 3.92332L3.43225 12.4996ZM0.25 13.9996H13.75V15.4996H0.25V13.9996Z" />
  </g>
);

export const EditIcon = createIcon(path, {
  width: 14,
  height: 16,
  viewBox: '0 0 14 16',
  fill: 'none',
});
