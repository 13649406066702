import { createIcon } from '../createSVGIcon';

const path = (
  <g>
    <path d="M10.828 11.9997L15.778 16.9497L14.364 18.3637L8 11.9997L14.364 5.63574L15.778 7.04974L10.828 11.9997Z" />
  </g>
);

export const ArrowIcon = createIcon(path, {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
  fill: 'none',
});
