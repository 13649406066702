import { useParams } from 'react-router-dom';

import { Box, Divider } from '@mui/material';
import Tabs from '../../../../../../components/shared/tabs';
import Card from '../../../../../../components/shared/card';
import Button from '../../../../../../components/shared/buttons';
import Comments from '../../../../../../components/shared/comments';
import Header from '../../../../../../components/shared/header';
import HeaderActions from './HeaderActions';
import { html2pdf } from 'html2pdf.js';

const htmlStr = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Purchase Order Vendor</title>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
    <style>
        .bodyclass {
            width: 1270px;
            padding: 0% 0% 20% 0%;
            margin: 0;
            padding: 0;
            background-color: #F4F4F4;
            
        }

        .header {
    display: flex;
    height: 205px;
    padding: 40px;
    gap: 40px;
    border-bottom: 1px solid black;
    justify-content: space-between;
    align-items: center;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  margin-left: 24px;
  margin-top: 24px;
  margin-bottom: 40px;
}


        .header img {
            width: 100px;
        }

        table.first {
          width: 1180px;
          height: 180px;
          padding: 24px;
          gap: 40px;
          font-family: "Poppins", sans-serif;
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          margin-left: 24px;
          margin-right: 24px;
          margin-bottom: 40px;
         
          border: 1px solid #CDD1D7;
  
}
table.first th,
table.first td {
  padding: 10px;
  text-align: center; /* Center align text within cells */
  text-align: left;
}

.table-container {
  width: 1204px; /* Set maximum width */
  overflow: hidden; /* Hide overflow */
}

.scroll-wrapper {
  width: calc(100% + 17px); /* Adjust for scrollbar width */
  overflow-x: auto; /* Enable horizontal scrolling */
  margin-bottom: -17px; /* Adjust for scrollbar height */
}

.tg  {
  border-collapse: collapse;
  border-spacing: 0;
  width: 1715px; /* Adjust as needed */
  margin-top: 50px;
  margin-bottom: 20px;
  
}

.tg td {
  border-color: #CDD1D7;
  border-style: solid;
  border-width: 1px;
  font-family: Poppins;
  font-size: 14px;
  overflow: hidden;
  padding: 20px;
  word-break: normal;
}

.tg th {
  border-color: #CDD1D7;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 20px;
  word-break: normal;
}

.tg .tg-0pky {
  border-color: inherit;
  text-align: left;
  vertical-align: top;
}
.tg-0pky.no-border {
  border: none;
}

.tg .tg-9u2q {
  background-color: #CDD1D7;
  border-color: inherit;
  text-align: center;
  vertical-align: top;
}

        
       .budget {
        padding: 24px 20px 24px 0px;
  width: 1204px;
  gap: 303px;
  display: flex;


.first {
  width: calc((246 / 1204) * 100%);
  
  
}

.second {
  width: 655px;
  display: flex;
  border: 1px solid black;



.third {
  width: calc((190 / 655) * 100%);
  border: 1px solid black;
}

.fourth {
  width: calc((465 / 655) * 100%);
  border: 1px solid black;
 
}
}



}


      .attachment {
        padding: 24px 20px 24px 0px;
  width: 1204px;
  gap: 303px;
  display: flex;


.first {
  width: calc((246 / 1204) * 100%);
  
  
}

.second {
  width: 655px;
  display: flex;
  border-bottom: 1px solid black;
}



}


        .content {
            padding: 1rem;
        }

        .title {
            font-size: 1.5rem;
            text-align: center;
        }

        .sub-title {
            font-size: 1rem;
            text-align: center;
        }

        .signatures {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 2rem;
            border: 1px solid #CDD1D7;
        }
        .Signheading{
          width: 113px;
          height: 29px;
          margin-left: 30px;
          margin-bottom: 30px;
          
          font-size: 24px;
          font-weight: 600;
          color: #16181A;
        }

        .sign {

            display: flex;
            justify-content: space-between;
            
        }

        .signature {
            width: 25%;
            text-align: center;
            justify-content: space-between;
            
        }

        .signature canvas {
            border-bottom: 1px solid black;
            width: 80%;
            height: 100px;
            margin-bottom: 1rem;
            cursor: crosshair; 
            display: block; 
            margin-left: 30px;
            margin-right: 30px;
        }

        .signature img {
            display: none; /* Initially hide image */
        }

        .signature p {
            margin-top: 5px;
        }

        .attention {
    width: 1200px;
    height: 450px;
    padding: 20px;
    margin-bottom:50px;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 1.6;
    overflow-y: auto; /* Enable vertical scrolling if content exceeds container height */
}
.attention p {
    margin: 0; /* Remove default paragraph margin */
}

.attention ul,ol {
    margin-top: 10px; /* Add margin above unordered list */
}

.attention ul,ol li {
    margin-bottom: 5px; /* Add margin below list items */
}


    </style>
    
</head>
<body class="bodyclass">
<header class="poppins-semibold">
     Purchase Order Vendor
</header>
<main class="content">

    <table class="first">
        <tr>
            <td>To </td>
            <td>Google Indonesia</td>
            <td>Advertiser: </td>
            <td>Unilever</td>
          </tr>
          <tr>
            <td>Media  </td>
            <td>Youtube</td>
            <td>Product </td>
            <td>Lifebuoy Anti Hairfall Shampoo</td>
          </tr>
          <tr>
            <td>Attn. </td>
            <td>Admin</td>
            <td>Period </td>
            <td>01 - 08 - 2023 to 01-12-2023</td>
          </tr>
          <tr>
            <td>Phone Number </td>
            <td>+62 0000000000</td>
            <td>Order Date </td>
            <td>01 - 08 - 2023</td>
          </tr>
    </table>

    <div class="table-container">
        <div class="scroll-wrapper">
    <table class="tg">
        <thead>
          <tr>
            <th class="tg-0pky no-border" colspan="7"></th>
            <th class="tg-9u2q" colspan="6">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="tg-0pky">Period</td>
            <td class="tg-0pky">Channel/Position</td>
            <td class="tg-0pky">Format</td>
            <td class="tg-0pky">Material</td>
            <td class="tg-0pky">Size</td>
            <td class="tg-0pky">Buy Type</td>
            <td class="tg-0pky">Goal</td>
            <td class="tg-0pky">Gross Value</td>
            <td class="tg-0pky">SCG</td>
            <td class="tg-0pky">Gross Paid</td>
            <td class="tg-0pky">Disc</td>
            <td class="tg-0pky">Disc Amount</td>
            <td class="tg-0pky">Nett</td>
          </tr>
          <tr>
            <td class="tg-0pky"></td>
            <td class="tg-0pky"></td>
            <td class="tg-0pky"></td>
            <td class="tg-0pky"></td>
            <td class="tg-0pky"></td>
            <td class="tg-0pky"></td>
            <td class="tg-0pky"></td>
            <td class="tg-0pky"></td>
            <td class="tg-0pky"></td>
            <td class="tg-0pky"></td>
            <td class="tg-0pky"></td>
            <td class="tg-0pky"></td>
            <td class="tg-0pky"></td>
          </tr>
          <tr>
            <td class="tg-0pky no-border" colspan="7"></td>
            <td class="tg-0pky"></td>
            <td class="tg-0pky"></td>
            <td class="tg-0pky"></td>
            <td class="tg-0pky"></td>
            <td class="tg-0pky"></td>
            <td class="tg-0pky"></td>
          </tr>
          <tr>
            <td class="tg-0pky no-border" colspan="7"></td>
            <td class="tg-0pky"></td>
            <td class="tg-0pky"></td>
            <td class="tg-0pky"></td>
            <td class="tg-0pky"></td>
            <td class="tg-0pky"></td>
            <td class="tg-0pky"></td>
          </tr>
          <tr>
            <td class="tg-0pky no-border" colspan="7"></td>
            <td class="tg-0pky"></td>
            <td class="tg-0pky"></td>
            <td class="tg-0pky"></td>
            <td class="tg-0pky"></td>
            <td class="tg-0pky"></td>
            <td class="tg-0pky">Grand Total:</td>
          </tr>
          
        </tbody>
        </table>
    </div>
</div>
</div>
    
    <div class="signatures">
      <div class="Signheading">
        <h2>Approval</h2>
      </div>

      <div class="sign">
        <div class="signature">    
          <canvas id="person-1-signature" width="500" height="100"></canvas>
          <p>Harry Setyono: Strategic Planner</p>
      </div>
      <div class="signature">
          <canvas id="person-2-signature" width="500" height="100"></canvas>
          <p>Pierce Carlson: Brand Manager</p>
      </div>
    </div>
      <div class="sign">
      <div class="signature">
          <canvas id="person-3-signature" width="500" height="100"></canvas>
          <p>Todd Ingram: Marketing Manager</p>
      </div>
      <div class="signature">
        <canvas id="person-4-signature" width="500" height="100"></canvas>
        <p>Todd Ingram: Marketing Manager</p>
    </div>
      </div>
      
    
</main>
<div class="attention">
    <p>
            Attention:
         
         <ol>
            <li> By accepting this Purchase Order, the Supplier is committed to the requirements specified in the Term and Condition. Please send back the hard copy of PO with signature and Company stamp.</li>

            <li> For payment purposes, please send an invoice with the following list: one set of original invoice and tax certificate, two set copies of invoice and tax certificate and PO with signature and Company stamp, and proof of airing (log proof / tearsheet / activation report / screenshot). The invoice with all supporting documents must be sent to PT. Wira Pamungkas Pariwara, at the latest two months after the airing date of the ads. If during this period we have not received the invoice as per the requirement, then we can cancel the order. We are not responsible to process the payment if the invoice with all supporting documents are not sent within 2 months after the airing. </li>

            <li> Vendor shall receive PO from PT. Wira Pamungkas Pariwara first before conduct any service and/or delivering the campaign. We are not responsible for any orders that were executed without PO or delayed PO and therefore billing payments on the order cannot be accepted. </li>

            <li> Tax Certificate is on behalf of PT. Wira Pamungkas Pariwara, with the address Gedung RDTX Place, Lt.38, Jl. Prof. Dr. Satrio Kav.3 RT.008 RW 004, Karet Kuningan Setiabudi, Jakarta Selatan, with NPWP : 01.311.813.8-073.000. </li>

            <li> The shipping address for the invoice is Gedung RDTX Place, Lt.38, Jl. Prof. Dr. Satrio Kav.3 RT.008 RW 004, Karet Kuningan Setiabudi, Jakarta Selatan. </li>

            <li> Stamp Duty and Bank charge for wire transfer paid by the supplier. </li>

            <li> Restriction for tobacco advertising on TV and radio may take place between the hours of 21:30 and 05:00 local time only. </li>

            <li> For activity related with display, banner, billboard, LED or any OOH, following proof of execution need to be submitted by vendor:</li>
        </ol>
        <ul>
            <li>Photographs on the date of start of campaign with time stamp on photograph along with the days national newspaper masthead in the photograph.</li>
            <li>Monitoring reports every fortnight with photographs to be shared. The photographs to be sent across within the next day from date stamp.</li>
        </ul>
    </p>
</div>
</body>
</html>`;

const PoVendorView = () => {
  const params = useParams();

  return (
    <>
      <Card sx={{ alignItems: 'flex-start' }}>
        <Header breadcrumbs={[{ name: 'View PO Vendor' }]} />
        <Tabs
          items={[
            {
              value: 'po-vendor',
              label: 'PO Vendor',
              link: `/app/vendors/view/${params.id}/view`,
            },
            {
              value: 'vendor-invoice-documents',
              label: 'Vendor Invoice & Documents',
              link: `/app/vendors/view/${params.id}/vendor-view-invoice`,
            },
          ]}
          active="po-vendor"
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            flexDirection: 'column',
            gap: '40px',
            marginTop: '16px',
          }}
        >
          <HeaderActions />

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
            }}
          >
            <div style={{ width: '100%', backgroundColor: '#F4F4F4' }}>
              <div
                style={{
                  width: '1270px',
                  maxWidth: '100%',
                  margin: 'auto',
                  overflowX: 'hidden',
                }}
              >
                <Box
                  id="ib-html-box"
                  sx={{ width: '100%', backgroundColor: '#F4F4F4' }}
                  dangerouslySetInnerHTML={{ __html: htmlStr }}
                />
              </div>
            </div>
          </Box>
          {/* <Divider width="100%" /> */}

          <Comments entityId={params.id} entityType="BUYING_BRIEF_GROUP" />
        </Box>
      </Card>
    </>
  );
};

export default PoVendorView;
