import { Box, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from '../../../../components/shared/buttons';
import Card from '../../../../components/shared/card';
import { useNavigate } from 'react-router-dom';
import Table from '../../../../components/shared/table';
import Chip from '../../../../components/shared/chip';
import { DeleteIcon } from '../../../../libs/svg-icons/icons';
import Input from '../../../../components/shared/form/Input/input';
import Select from '../../../../components/shared/form/Select/select';
import { formatDate } from '../../../../libs/date/format';
import EmptyTable from '../../../../components/shared/table/EmptyTable';
import { debounce } from 'lodash';
import ButtonMenu from '../../../../components/shared/menu/ButtonMenu';
import ViewDataDialog from './ViewDataDialog';
import Dialog from '../../../../components/shared/dialog';
import { useTheme } from '@emotion/react';
import { getMasterDataProjectList, listProject } from '../../../../services/project-service';
import { sortBy } from 'lodash';
import Loader from '../../../../components/shared/loader';
import { getUniqueNamesWithIds } from '../../../../utils/project';
import { capitalize } from '../../../../utils/string';
import { handleError } from '../../../../utils/errorHandling';
import { useEnqueueSnackbar } from '../../../../components/shared/toast-provider/toastHook';

const PAGE_SIZE = 10;

const ProjectsList = () => {
  const gridTableRef = useRef(null);
  const enqueueSnackbar = useEnqueueSnackbar();

  const [selectedMediaType, setSelectedMediaType] = useState('All Media');
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedBU, setSelectedBU] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');

  const [searchText, setSearchText] = useState('');
  const [showEmptyTableComponent, setShowEmptyTableComponent] = useState(false);
  const [openCancelProjectDialog, setOpenCancelProjectDailog] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const [mediaOptions, setMediaOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [buOptions, setBUOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  const theme = useTheme();

  const handleOpenCancelDialog = useCallback(rowId => {
    setSelectedRowId(rowId);
    setOpenCancelProjectDailog(true);
  }, []);

  useEffect(() => {
    setDropDownData();
  }, []);

  const setDropDownData = async optionData => {
    if (optionData) {
      const { media, brand, businessUnit, category } = optionData;

      let mediaOpt = [{ id: '', name: 'All Media' }];
      sortBy(media.data, 'name').forEach(x => mediaOpt.push(x));

      setMediaOptions(mediaOpt);
      setBrandOptions(getUniqueNamesWithIds(sortBy(brand.data, 'name')));
      setBUOptions(sortBy(businessUnit.data, 'name'));
      setCategoryOptions(sortBy(category.data, 'name'));
    }
  };

  const handleCloseCancelDialog = () => {
    setOpenCancelProjectDailog(false);
  };

  useEffect(() => {
    getMasterData();
  }, []);

  useEffect(() => {
    if (selectedBU && buOptions.length) {
      const [{ id = '' } = {}] = buOptions.filter(x => x.name === selectedBU);
      if (id) getMasterDataBrandCategory(id);
    }
  }, [buOptions, selectedBU]);

  const getMasterDataBrandCategory = async id => {
    setIsloading(true);
    const [categoryResponse, brandResponse] = await Promise.all([
      getMasterDataProjectList('category', id),
      getMasterDataProjectList('brand', id),
    ])
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setIsloading(false);
      });

    setBrandOptions(getUniqueNamesWithIds(sortBy(brandResponse?.data?.data, 'name')));
    setCategoryOptions(sortBy(categoryResponse?.data?.data, 'name'));
  };

  const getMasterData = async () => {
    setIsloading(true);
    const [mediaResponse, categoryResponse, brandResponse, buResponse] = await Promise.all([
      getMasterDataProjectList('media'),
      getMasterDataProjectList('category'),
      getMasterDataProjectList('brand'),
      getMasterDataProjectList('business-unit'),
    ])
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setIsloading(false);
      });

    setDropDownData({
      media: mediaResponse?.data || {},
      brand: brandResponse?.data || {},
      businessUnit: buResponse?.data || {},
      category: categoryResponse?.data || {},
    });
  };

  const getDataSource = useCallback(
    (selectedMediaType, selectedBrand, selectedBU, selectedCategory) => {
      const dataSource = {
        rowCount: undefined,
        getRows: async params => {
          const payload = {
            mediaTypeId: mediaOptions.find(x => x.name === selectedMediaType)?.id || '',
            brandId: brandOptions.find(x => x.name === selectedBrand)?.id || '',
            businessUnitId: buOptions.find(x => x.name === selectedBU)?.id || '',
            categoryId: categoryOptions.find(x => x.name === selectedCategory)?.id || '',
            name: searchText,
            pageNo: params.endRow / PAGE_SIZE ?? 1,
            pageSize: PAGE_SIZE,
          };

          await listProject(payload)
            .then(res => {
              const { data } = res.data;
              const { totalElements, models = [] } = data;

              const totalRowCount = totalElements;

              let lastRow = -1;
              if (totalRowCount <= params.endRow) {
                lastRow = totalRowCount;
              }

              if (
                (!totalRowCount || totalRowCount.length === 0) &&
                !searchText &&
                selectedMediaType !== mediaOptions[0]
              ) {
                setShowEmptyTableComponent(true);
              }

              let dataM = models.map(item => {
                const { buyingBriefGroupId = '', buyingBriefId = '', mediaPlanId = '' } = item;
                return {
                  ...item,
                  linkBB: { buyingBriefGroupId, buyingBriefId, mediaPlanId },
                };
              });

              params.successCallback(dataM, lastRow);
            })
            .catch(err => {
              console.log(err);
              params.successCallback(null, 0);
              let msg = handleError(err);
              enqueueSnackbar(msg, 'error');
            });
        },
      };
      return dataSource;
    },
    [searchText, selectedMediaType, selectedBU, selectedBrand, selectedCategory, mediaOptions],
  );

  const navigate = useNavigate();

  const getRowId = useMemo(() => {
    return params => {
      return params.data.id;
    };
  }, []);

  const handleViewProjectDetails = useCallback(
    event => {
      if (event.data?.id) {
        navigate(`${event.data?.id}/view-project`, {
          replace: false,
        });
      }
    },
    [navigate],
  );

  const datasource = useMemo(() => {
    return getDataSource(selectedMediaType, selectedBrand, selectedBU, selectedCategory);
  }, [getDataSource, selectedMediaType, selectedBU, selectedBrand, selectedCategory]);

  const handleMediaFilterChanged = useCallback(e => {
    setSelectedMediaType(e.target.value);
  }, []);

  const handleBrandFilterChanged = useCallback(e => {
    setSelectedBrand(e.target.value);
  }, []);

  const handleBUFilterChanged = useCallback(e => {
    setSelectedBU(e.target.value);
    setSelectedBrand('');
    setSelectedCategory('');
  }, []);

  const handleCategoryFilterChanged = useCallback(e => {
    setSelectedCategory(e.target.value);
  }, []);

  const handleSearched = e => {
    const text = e.target.value;
    debounce(() => setSearchText(text), 500, { trailing: true })();
  };

  const handleSearchCleared = () => {
    setSearchText('');
  };

  const columns = useMemo(
    () => [
      {
        id: 'name',
        headerName: 'Project',
        field: 'name',
        sortable: false,
        minWidth: 473,
        maxWidth: 473,
        cellStyle: params => ({
          cursor: 'pointer',
        }),
        onCellClicked: handleViewProjectDetails,
      },
      {
        id: 'status',
        headerName: 'Status',
        field: 'status',
        sortable: false,
        cellStyle: params => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }),
        cellRenderer: params => {
          let statusElement = null;
          switch (params.value) {
            case 'NEW':
              statusElement = (
                <Chip
                  status="warning"
                  label={capitalize(params.value)}
                  styles={{ margin: 'auto' }}
                />
              );
              break;
            case 'IN_PROGRESS':
              statusElement = (
                <Chip
                  status="warning"
                  label={capitalize(params.value)}
                  styles={{ margin: 'auto' }}
                />
              );
              break;
            case 'CANCELLED':
              statusElement = (
                <Chip status="error" label={capitalize(params.value)} styles={{ margin: 'auto' }} />
              );
              break;
            case 'COMPLETED':
              statusElement = (
                <Chip
                  status="success"
                  label={capitalize(params.value)}
                  styles={{ margin: 'auto' }}
                />
              );
              break;
            default:
              statusElement = <Chip status="warning" label="Draft" styles={{ margin: 'auto' }} />;
          }
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {statusElement}
              </Box>
            </Box>
          );
        },
        minWidth: 200,
      },
      {
        id: 'data',
        headerName: 'Project Submission',
        sortable: false,
        minWidth: 200,
        cellStyle: params => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }),
        cellRenderer: params => <ViewDataDialog params={params} />,
      },
      {
        id: 'media',
        headerName: 'Media',
        field: 'mediaPlanRow.mediaType.name',
        sortable: false,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        id: 'format',
        headerName: 'Format',
        field: 'mediaPlanRow.format.name',
        sortable: false,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        id: 'type',
        headerName: 'Type',
        field: 'mediaPlanRow.type.name',
        sortable: false,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        id: 'placement',
        headerName: 'Placement',
        field: 'mediaPlanRow.placement.name',
        sortable: false,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        id: 'partner',
        headerName: 'Partner',
        field: 'mediaPlanRow.partner.name',
        sortable: false,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        id: 'year',
        headerName: 'Year',
        field: 'year',
        sortable: false,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        id: 'month',
        headerName: 'Month',
        field: 'mediaPlanRow.month.name',
        sortable: false,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        id: 'startDate',
        headerName: 'Start Date',
        field: 'mediaPlanRow.startDate',
        sortable: false,
        minWidth: 200,
        maxWidth: 200,
        cellRenderer: params =>
          params.value ? <Box>{formatDate(new Date(params.value), 'dd/MM/yyyy')}</Box> : '-',
      },
      {
        id: 'endDate',
        headerName: 'End Date',
        field: 'mediaPlanRow.endDate',
        sortable: false,
        minWidth: 200,
        maxWidth: 200,
        cellRenderer: params =>
          params.value ? <Box>{formatDate(new Date(params.value), 'dd/MM/yyyy')}</Box> : '-',
      },
      {
        id: 'businessUnit',
        headerName: 'Business Unit',
        field: 'businessUnit',
        sortable: false,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        id: 'category',
        headerName: 'Category',
        field: 'mediaPlanRow.category.name',
        sortable: false,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        id: 'brand',
        headerName: 'Brand',
        field: 'mediaPlanRow.brand.name',
        sortable: false,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        id: 'createdOn',
        headerName: 'Created On',
        field: 'createdAt',
        sortable: false,
        minWidth: 200,
        maxWidth: 200,
        cellRenderer: params =>
          params.value ? <Box>{formatDate(new Date(params.value), 'dd/MM/yyyy')}</Box> : '-',
      },
      {
        id: 'bbNumber',
        headerName: 'BB Number',
        field: 'bbNumber',
        sortable: false,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        id: 'poClient',
        headerName: 'PO Client',
        field: 'mediaPlanRow.doNumber',
        sortable: false,
        minWidth: 200,
        maxWidth: 200,
        cellRenderer: params => (params.value ? <Box>{params.value}</Box> : '-'),
      },
      {
        id: 'linkedBB',
        headerName: 'Linked BB',
        field: 'linkBB',
        minWidth: 200,
        sortable: false,
        maxWidth: 200,
        cellStyle: params => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }),
        cellRenderer: params => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '16px',
            }}
          >
            <Button
              height="30px"
              sx={{
                minWidth: '21px',
                padding: '6px 14px',
              }}
              variant="outlined"
              label={<Typography variant="Medium-12">View</Typography>}
              onClick={() => {
                const { buyingBriefGroupId = '1', mediaPlanId = '' } = params?.value || {};
                navigate(
                  `/app/media-plans/${mediaPlanId}/buying-group/${buyingBriefGroupId}/buying-brief`,
                );
              }}
            />
          </Box>
        ),
      },
      // {
      //   id: 'actions',
      //   headerName: 'Actions',
      //   minWidth: 121,
      //   maxWidth: 121,
      //   cellStyle: params => ({
      //     display: 'flex',
      //     alignItems: 'center',
      //     justifyContent: 'center',
      //   }),
      //   cellRenderer: params => (
      //     <ButtonMenu
      //       options={['Cancel Project']}
      //       icons={[<DeleteIcon fill="var(--text-300)" />]}
      //       onMenuClick={() => handleOpenCancelDialog(params.data.id)}
      //       rowId={params.data.id}
      //     />
      //   ),
      // },
    ],
    [handleViewProjectDetails, handleOpenCancelDialog],
  );

  const defaultColDef = useMemo(
    () => ({
      resizable: false,
      editable: false,
      flex: 1,
    }),
    [],
  );

  const CancelProjectDialog = useMemo(
    () => (
      <Dialog
        open={openCancelProjectDialog}
        onClose={handleCloseCancelDialog}
        icon={
          <Box
            sx={{
              width: '52px',
              height: '52px',
              borderRadius: '50%',
              background: theme.palette.background,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 auto',
              marginBottom: '24px',
            }}
          >
            <DeleteIcon fill="var(--deep-navy)" width="32px" height="32px" />
          </Box>
        }
        headline={<Typography variant="SemiBold-22">Cancel Project</Typography>}
        description={
          <Typography variant="Regular-16" sx={{ marginBottom: '24px' }}>
            Are you sure you want to cancel this project? This action can’t be undone{' '}
          </Typography>
        }
        buttons={[
          <Button
            key="button1"
            width="176px"
            variant="outlined"
            label="Cancel"
            onClick={handleCloseCancelDialog}
          />,
          <Button
            key="button2"
            width="176px"
            variant="contained"
            label="Cancel Project"
            onClick={handleCloseCancelDialog}
          />,
        ]}
        width="420px"
        headlineAlignment="center"
      />
    ),
    [theme, openCancelProjectDialog],
  );

  const MediaPlanListTable = useMemo(
    () => (
      <Table
        ref={gridTableRef}
        columns={columns}
        datasource={datasource}
        defaultColDef={defaultColDef}
        height="calc(100vh - 312px)"
        rowSelection="single"
        rowModelType="infinite"
        getRowId={getRowId}
        maxBlocksInCache={100}
        rowBuffer={1}
        cacheBlockSize={PAGE_SIZE}
        cacheOverflowSize={1}
        maxConcurrentDatasourceRequests={1}
        infiniteInitialRowCount={10}
        suppressRowClickSelection={true}
        blockLoadDebounceMillis={500}
      />
    ),
    [columns, datasource, defaultColDef, getRowId, showEmptyTableComponent],
  );

  return (
    <>
      <Loader isLoading={isLoading} />
      <Card
        sx={{
          minHeight: 'calc(100vh - 130px)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'normal',
            width: '100%',
          }}
        >
          <Typography variant="Bold-28">PROJECTS</Typography>
          <Box sx={{ width: '12.5rem', marginLeft: '20px' }}>
            <Select
              id="client-filter"
              options={['Unilever']}
              value={'Unilever'}
              optionMapFunction={options => options?.map(x => ({ id: x, label: x, value: x }))}
              disabled
              menuHeight="400px"
              selectHeight="40px"
            />
          </Box>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '20px',
            }}
          >
            <Box sx={{ width: '24.75rem' }}>
              <Input
                id="media-plan-list-search"
                inputHeight="40px"
                type="text"
                placeholder="Search by project name..."
                searchable
                onChange={handleSearched}
                onSearchCloseClick={handleSearchCleared}
                // label={'Search by project name'}
              />
            </Box>
            <Box sx={{ width: '12.5rem' }}>
              <Select
                id="media-filter"
                inputHeight="40px"
                options={mediaOptions.map(x => x.name)}
                value={selectedMediaType}
                optionMapFunction={options => options?.map(x => ({ id: x, label: x, value: x }))}
                searchable
                menuHeight="400px"
                menuWidth="12rem"
                selectHeight="40px"
                onChange={handleMediaFilterChanged}
                // label={'Media'}
              />
            </Box>
            <Box sx={{ width: '12.5rem' }}>
              <Select
                id="bu-filter"
                inputHeight="40px"
                options={buOptions.map(x => x.name)}
                value={selectedBU}
                optionMapFunction={options => options?.map(x => ({ id: x, label: x, value: x }))}
                searchable
                menuHeight="400px"
                menuWidth="12rem"
                selectHeight="40px"
                onChange={handleBUFilterChanged}
                placeholder={'Business Unit'}
                clearSelectionButton={true}
                // label={'Business Unit'}
              />
            </Box>
            <Box sx={{ width: '12.5rem' }}>
              <Select
                id="brands-filter"
                inputHeight="40px"
                options={brandOptions.map(x => x.name)}
                value={selectedBrand}
                optionMapFunction={options =>
                  options?.map((x, i) => ({ id: `${x}-${i}`, label: x, value: x }))
                }
                searchable
                menuHeight="400px"
                menuWidth="12.5rem"
                selectHeight="40px"
                onChange={handleBrandFilterChanged}
                placeholder={'Brands'}
                clearSelectionButton={true}
                disabled={!selectedBU}
                // label={'Brands'}/
              />
            </Box>

            <Box sx={{ width: '12.5rem' }}>
              <Select
                id="category-filter"
                options={categoryOptions.map(x => x.name)}
                value={selectedCategory}
                optionMapFunction={options => options?.map(x => ({ id: x, label: x, value: x }))}
                searchable
                menuHeight="400px"
                selectHeight="40px"
                onChange={handleCategoryFilterChanged}
                placeholder={'Category'}
                clearSelectionButton={true}
                disabled={!selectedBU}
                // label={'Categroy'}
              />
            </Box>
          </Box>
          {MediaPlanListTable}
        </Box>

        {CancelProjectDialog}
      </Card>
    </>
  );
};

export default ProjectsList;
