import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import Select from '../../../../../../components/shared/form/Select';
import DatePicker from '../../../../../../components/shared/form/date-picker';
import { UploadIcon } from '../../../../../../libs/svg-icons/icons';
import Textarea from '../../../../../../components/shared/form/textarea';
import { useTheme } from '@emotion/react';
import InputField from '../../../../../../components/shared/form/Input';
import DragAndDropFilePicker from '../../../../../../components/shared/form/fileupload';

const InvoiceForm = ({ schema, schemaMethods, handleInvoiceRemoved, handleInvoiceUpload }) => {
  const theme = useTheme();
  const currencyOption = [
    { value: 'notContains', label: 'Does Not Contain' },
    { value: 'equals', label: 'Equals' },
    { value: 'notEquals', label: 'Does Not Equal' },
  ];

  const totalDetails = [
    { label: 'Subtotal exclusive taxes', value: '0.00 IDR' },
    {
      label: 'ID VAT Standard 11% of 68,000,000.00 IDR (Valid from 01-08-2023)',
      value: '0.00 IDR',
    },
  ];
  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '20px',
        }}
      >
        <Box sx={{ width: '32%' }}>
          <InputField
            type="text"
            id="invoicenumber"
            inputHeight="48px"
            placeholder="Enter invoice number"
            label="Invoice Number"
            {...schemaMethods.register('invoicenumber')}
          />
        </Box>
        <Box sx={{ width: '32%' }}>
          <DatePicker
            id="issuedate"
            label="Issue Date"
            placeholder="Select date"
            {...schemaMethods.register('issuedate')}
            onChange={() => null}
            views="MM/DD"
          />
        </Box>
        <Box sx={{ width: '32%' }}>
          <Select
            id="currency"
            label="currency"
            options={currencyOption}
            selectHeight="48px"
            placeholder="Select currency"
            {...schemaMethods.register('currency')}
          />
        </Box>
        <Box sx={{ width: '32%' }}>
          <InputField
            type="text"
            id="purchasenumber"
            inputHeight="48px"
            placeholder="Enter purchase order number"
            label="Purchase Order Number"
            {...schemaMethods.register('purchasenumber')}
          />
        </Box>

        <Box sx={{ width: '32%' }}>
          <DatePicker
            id="purchaseorderdate"
            label="Purchase Order Issue Date"
            placeholder="Select issue date"
            {...schemaMethods.register('purchaseorderdate')}
            onChange={() => null}
            views="MM/DD"
          />
        </Box>
        <Box sx={{ width: '32%' }}>
          <DatePicker
            id="startdate"
            label="Start Date"
            placeholder="Select date"
            {...schemaMethods.register('startdate')}
            onChange={() => null}
            views="MM/DD"
          />
        </Box>

        <Box sx={{ width: '32%' }}>
          <DatePicker
            id="enddate"
            label="End Date"
            placeholder="Select date"
            {...schemaMethods.register('enddate')}
            onChange={() => null}
            views="MM/DD"
          />
        </Box>
      </Box>
      <Divider sx={{ width: '100%' }} />

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '20px',
        }}
      >
        <Box sx={{ width: '32%' }}>
          <InputField
            type="text"
            id="itemid"
            inputHeight="48px"
            placeholder="Enter ID"
            label="Item ID"
            {...schemaMethods.register('itemid')}
          />
        </Box>
        <Box sx={{ width: '32%' }}>
          <InputField
            type="text"
            id="quantity"
            inputHeight="48px"
            placeholder="Enter quantity"
            label="Quantity"
            {...schemaMethods.register('quantity')}
          />
        </Box>
        <Box sx={{ width: '32%' }}>
          <InputField
            type="text"
            id="unit"
            inputHeight="48px"
            placeholder="Enter number of unit"
            label="Unit"
            {...schemaMethods.register('unit')}
          />
        </Box>
        <Box sx={{ width: '32%' }}>
          <InputField
            type="text"
            id="priceperunit"
            inputHeight="48px"
            placeholder="Enter price per unit"
            label="Price Per Unit"
            {...schemaMethods.register('priceperunit')}
          />
        </Box>

        <Box sx={{ width: '32%' }}>
          <InputField
            type="text"
            id="tax"
            inputHeight="48px"
            placeholder="Select tax percentage"
            label="Tax %"
            {...schemaMethods.register('tax')}
          />
        </Box>
        <Box sx={{ width: '32%' }}>
          <InputField
            type="text"
            id="totaltax"
            inputHeight="48px"
            placeholder="Enter total exclusive tax"
            label="Total Exclusive Tax"
            {...schemaMethods.register('totaltax')}
          />
        </Box>

        <Box sx={{ width: '32%' }}>
          <InputField
            type="text"
            id="taxnumber"
            inputHeight="48px"
            placeholder="Enter tax number"
            label="Tax Number"
            {...schemaMethods.register('taxnumber')}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '191px',
          borderRadius: '8px',
          border: '1px solid #CDD1D7',
          backgroundColor: '#F4F4F4',
          padding: '16px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: '20px',
            flexWrap: 'wrap',
          }}
        >
          {totalDetails?.map(detail => (
            <Box
              key={detail.label}
              sx={{ display: 'flex', flexDirection: 'row', gap: '80px', width: '100%' }}
            >
              <Box sx={{ display: 'flex', width: '60%' }}>
                <Typography variant="Regular-18">{detail.label}</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  width: '40%',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Typography variant="Regular-18">{detail.value}</Typography>
              </Box>
            </Box>
          ))}

          <Divider sx={{ width: '100%' }} />

          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '80px', width: '100%' }}>
            <Box sx={{ display: 'flex', width: '60%' }}>
              <Typography variant="Medium-18">Total IDR</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '40%',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Typography variant="Medium-18">0.00 IDR</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'row',
          gap: '20px',
        }}
      >
        <Box
          sx={{
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          }}
        >
          <Typography variant="Medium-16">Upload Media Plan Template</Typography>
          <DragAndDropFilePicker
            multi={false}
            label="Upload Document"
            icon={<UploadIcon fill={theme.palette.primary.main} />}
            headline="Upload Document"
            description="(Only .csv file, maximum 20MB file size)"
            onUpload={handleInvoiceUpload}
            onRemove={handleInvoiceRemoved}
            acceptedfileExtensions={['csv']}
            draggable={true}
            maxFileSize={5}
            {...schemaMethods.register('uploadinvoice')}
          />
        </Box>
        <Box sx={{ width: '50%' }}>
          <Textarea
            id="comments"
            type="text"
            rows={8}
            label="Comments"
            placeholder="Write your comment here.."
            {...schemaMethods.register('comments')}
          />
        </Box>
      </Box>
    </>
  );
};

export default InvoiceForm;
