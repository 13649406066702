import React, { useEffect, useMemo, useState } from 'react';
import { Box, Checkbox, Divider, List, ListItem, ListSubheader } from '@mui/material';
import Input from '../../form/Input/input';

const MuiSetFilter = ({
  column = null,
  onRequestFilterChange = () => null,
  filterProps = {},
  filterModel = {},
}) => {
  const [searchText, setSearchText] = useState('');
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (filterProps?.options)
      setOptions(
        filterProps?.options?.map(x => ({
          value: x,
          checked: filterModel[column.id] ? filterModel[column.id].includes(x) : true,
        })),
      );
  }, [filterProps?.options]);

  const handleSearchTextChange = e => {
    setSearchText(e.target.value);
  };

  const handleSearchCloseClick = () => {
    setSearchText('');
  };

  const handleMenuItemClick = value => {
    setOptions(prev => {
      const updatedOptions = prev.map((x, index) =>
        x.value === value ? { ...x, checked: !x.checked } : x,
      );
      if (updatedOptions?.reduce((acc, option) => acc && option?.checked, true)) {
        onRequestFilterChange(column.id, 'ALL');
      } else {
        const model = updatedOptions?.reduce((a, x) => (x.checked ? [...a, x.value] : a), []);
        onRequestFilterChange(column.id, model);
      }
      return updatedOptions;
    });
  };

  const handleSelectDeselectAllOptions = () => {
    if (options?.reduce((acc, option) => acc && option?.checked, true)) {
      setOptions(prev => prev?.map(x => ({ ...x, checked: false })));
      onRequestFilterChange(column.id, []);
    } else {
      setOptions(prev => prev?.map(x => ({ ...x, checked: true })));
      onRequestFilterChange(column.id, 'ALL');
    }
  };

  const searchedOptions = useMemo(
    () =>
      !!searchText
        ? options
            .filter(x => x?.value?.toLowerCase().includes(searchText?.toLowerCase()))
            .slice(0, 100)
        : options.slice(0, 100),
    [options, searchText],
  );

  return (
    <>
      <ListSubheader sx={{ backgroundColor: '#FFFFFF', position: 'sticky', py: 1, px: 0 }}>
        <Input
          value={searchText}
          inputHeight="36px"
          onChange={handleSearchTextChange}
          onSearchCloseClick={handleSearchCloseClick}
          placeholder="Search here"
          searchable
          sx={{ position: 'sticky' }}
          autoFocus
          onKeyDown={e => {
            if (e.key !== 'Escape') {
              e.stopPropagation();
            }
          }}
        />
      </ListSubheader>
      <ListItem
        onClick={handleSelectDeselectAllOptions}
        sx={{
          cursor: 'pointer',
          paddingTop: 0,
          paddingBottom: 0,
          marginBottom: '4px',
          '&:hover': {
            backgroundColor: '#0A275629',
            borderRadius: '8px',
          },
        }}
      >
        <Checkbox checked={options?.reduce((acc, option) => acc && option?.checked, true)} />{' '}
        {options?.reduce((acc, option) => acc && option?.checked, true)
          ? 'Unselect All'
          : 'Select All'}
      </ListItem>
      {searchedOptions.map((option, index) => (
        <ListItem
          key={option.value}
          onClick={() => handleMenuItemClick(option.value)}
          sx={{
            cursor: 'pointer',
            paddingTop: 0,
            paddingBottom: 0,
            marginBottom: '4px',
            '&:hover': {
              backgroundColor: '#0A275629',
              borderRadius: '8px',
            },
          }}
        >
          <Checkbox checked={searchedOptions[index].checked} /> {option.value}
        </ListItem>
      ))}
      <Divider />
    </>
  );
};

export default MuiSetFilter;
