import { Box, Typography } from '@mui/material';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import Button from '../../../../components/shared/buttons';
import Card from '../../../../components/shared/card';
import { useNavigate } from 'react-router-dom';
import Table from '../../../../components/shared/table';
import Chip from '../../../../components/shared/chip';
import { DeleteIcon } from '../../../../libs/svg-icons/icons';
import Input from '../../../../components/shared/form/Input/input';
import Select from '../../../../components/shared/form/Select/select';
import { formatDate } from '../../../../libs/date/format';
import EmptyTable from '../../../../components/shared/table/EmptyTable';
import { debounce } from 'lodash';
import ButtonMenu from '../../../../components/shared/menu/ButtonMenu';

const PAGE_SIZE = 20;

const POClientList = () => {
  const gridTableRef = useRef(null);

  const mediaTypes = useMemo(
    () => [
      'All Media',
      'Human',
      'Humanoid',
      'Alien',
      'Poopybutthole',
      'Mythological Creature',
      'Unknown',
    ],
    [],
  );
  const years = useMemo(() => ['All Brands', 'Lifebuoy', "Wall's", 'Cornetto', 'Dove'], []);

  const [searchText, setSearchText] = useState('');
  const [showEmptyTableComponent, setShowEmptyTableComponent] = useState(false);

  const getDataSource = useCallback(
    selectedMediaType => {
      const dataSource = {
        rowCount: undefined,
        getRows: async params => {
          let uri = `https://rickandmortyapi.com/api/character?page=${params.endRow / PAGE_SIZE ?? 1}`;

          if (!!searchText) {
            uri = uri + `&name=${searchText}`;
          }
          const response = await fetch(uri);
          const jsonData = await response.json();

          if (response.status === 200) {
            const totalRowCount = jsonData?.info?.count;

            let lastRow = -1;
            if (totalRowCount <= params.endRow) {
              lastRow = totalRowCount;
            }

            if ((!totalRowCount || totalRowCount.length === 0) && !searchText)
              setShowEmptyTableComponent(true);
            params.successCallback(jsonData?.results, lastRow);
          } else {
            params.successCallback(null, 0);
          }
        },
      };
      return dataSource;
    },
    [mediaTypes, searchText],
  );

  const navigate = useNavigate();

  const createNewMediaPlan = method => {
    navigate(`create/${method}`, {
      replace: false,
    });
  };

  const getRowId = useMemo(() => {
    return params => {
      return params.data.id;
    };
  }, []);

  const handleViewPOClientDetails = useCallback(
    event => {
      if (event.data?.id) {
        navigate(`view/${event.data?.id}`, {
          replace: false,
        });
      }
    },
    [navigate],
  );

  const datasource = useMemo(() => {
    return getDataSource();
  }, [getDataSource]);

  const handleSearched = e => {
    const text = e.target.value;

    debounce(() => setSearchText(text), 500, { trailing: true })();
  };

  const handleSearchCleared = () => {
    setSearchText('');
  };

  const columns = useMemo(
    () => [
      {
        id: 'sr.no',
        headerName: 'Sr.No.',
        field: 'id',
        minWidth: 90,
        maxWidth: 90,
        cellStyle: params => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }),
      },
      {
        id: 'name',
        headerName: 'PO Client Number',
        field: 'name',
        sortable: false,
        minWidth: 230,
        maxWidth: 230,
        cellStyle: params => ({
          cursor: 'pointer',
        }),
        onCellClicked: handleViewPOClientDetails,
        cellRenderer: params => <>PO{Math.floor(Math.random() * 1000000000)}</>,
      },
      {
        id: 'data',
        headerName: 'PO Amount',
        minWidth: 230,
        cellStyle: params => ({
          display: 'flex',
          alignItems: 'left',
          justifyContent: 'left',
        }),
        cellRenderer: params => <>{Math.floor(Math.random() * 1000005550000)} IDR</>,
      },
      {
        id: 'status',
        headerName: 'Status',
        field: 'status',
        cellStyle: params => ({
          display: 'flex',
          alignItems: 'left',
          justifyContent: 'left',
        }),
        cellRenderer: params => {
          if (params.value) {
            return <Chip status="success" label={'PO Received'} />;
          } else {
            return <Chip status="warning" label="PO Received" />;
          }
        },
        minWidth: 200,
      },

      {
        id: 'agency',
        headerName: 'Agency',
        field: 'species',
        sortable: false,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        id: 'client',
        headerName: 'Client',
        field: 'name',
        sortable: false,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        id: 'productName',
        headerName: 'Product Name',
        field: 'type',
        sortable: false,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        id: 'campaignTitle',
        headerName: 'Campaign Title',
        field: 'campaignTitle',
        sortable: false,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        id: 'type',
        headerName: 'Type',
        field: 'gender',
        sortable: false,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        id: 'media',
        headerName: 'Media',
        field: 'media',
        sortable: false,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        id: 'month',
        headerName: 'Month',
        field: 'created',
        sortable: false,
        minWidth: 200,
        maxWidth: 200,
        cellRenderer: params =>
          params.value ? (
            <Box>{new Date(params.value).toLocaleString('default', { month: 'long' })}</Box>
          ) : (
            '-'
          ),
      },
      {
        id: 'year',
        headerName: 'Year',
        field: 'created',
        sortable: false,
        minWidth: 200,
        maxWidth: 200,
        cellRenderer: params =>
          params.value ? <Box>{new Date(params.value).getFullYear()}</Box> : '-',
      },
    ],
    [handleViewPOClientDetails],
  );

  const defaultColDef = useMemo(
    () => ({
      resizable: false,
      editable: false,
      flex: 1,
    }),
    [],
  );

  const MediaPlanListTable = useMemo(
    () =>
      !showEmptyTableComponent ? (
        <Table
          ref={gridTableRef}
          columns={columns}
          datasource={datasource}
          defaultColDef={defaultColDef}
          height="calc(100vh - 312px)"
          rowSelection="single"
          rowModelType="infinite"
          getRowId={getRowId}
          maxBlocksInCache={100}
          rowBuffer={1}
          cacheBlockSize={PAGE_SIZE}
          cacheOverflowSize={1}
          maxConcurrentDatasourceRequests={1}
          infiniteInitialRowCount={10}
          suppressRowClickSelection={true}
          blockLoadDebounceMillis={500}
        />
      ) : null,
    [columns, datasource, defaultColDef, getRowId, showEmptyTableComponent],
  );

  return (
    <Card
      sx={{
        minHeight: 'calc(100vh - 130px)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Typography variant="Bold-28">PO CLIENT</Typography>
      </Box>
      {showEmptyTableComponent ? (
        <EmptyTable
          title="No Media Plans Created Yet!"
          description='Click the "Add New" button and commence your media planning journey.'
          actionItems={[
            <Button
              key="button2"
              width="120px"
              onClick={createNewMediaPlan}
              variant="contained"
              label="Add New"
            />,
          ]}
          height="calc(100vh - 244px)"
        />
      ) : (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '20px',
            }}
          >
            <Box sx={{ width: '24.75rem' }}>
              <Input
                id="media-plan-list-search"
                inputHeight="40px"
                type="text"
                placeholder="Search anything by keyword..."
                searchable
                onChange={handleSearched}
                onSearchCloseClick={handleSearchCleared}
              />
            </Box>
            <Box sx={{ width: '12.5rem' }}>
              <Select
                id="brand-filter"
                options={['Uniliver']}
                value={'Uniliver'}
                optionMapFunction={options => options?.map(x => ({ id: x, label: x, value: x }))}
                disabled
                menuHeight="400px"
                selectHeight="40px"
              />
            </Box>
            <Box sx={{ width: '12.5rem' }}>
              <Select
                id="year-filter"
                options={years}
                value={years[0]}
                optionMapFunction={options => options?.map(x => ({ id: x, label: x, value: x }))}
                searchable
                menuHeight="400px"
                selectHeight="40px"
              />
            </Box>
          </Box>
          {MediaPlanListTable}
        </Box>
      )}
    </Card>
  );
};

export default POClientList;
