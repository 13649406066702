import { createIcon } from '../createSVGIcon';

const path = (
  <g>
    <path d="M24.668 20.166H27.3346V22.8327H0.667969V20.166H3.33464V10.8327C3.33464 8.00371 4.45844 5.2906 6.45883 3.29021C8.45922 1.28982 11.1723 0.166016 14.0013 0.166016C16.8303 0.166016 19.5434 1.28982 21.5438 3.29021C23.5442 5.2906 24.668 8.00371 24.668 10.8327V20.166ZM22.0013 20.166V10.8327C22.0013 8.71095 21.1584 6.67612 19.6582 5.17583C18.1579 3.67554 16.123 2.83268 14.0013 2.83268C11.8796 2.83268 9.84474 3.67554 8.34445 5.17583C6.84416 6.67612 6.0013 8.71095 6.0013 10.8327V20.166H22.0013ZM10.0013 25.4993H18.0013V28.166H10.0013V25.4993Z" />
  </g>
);

export const NotificationIcon = createIcon(path, {
  width: 28,
  height: 29,
  viewBox: '0 0 28 29',
  fill: 'none',
});
