// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdf-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  /* margin: 20px; */
}

.controls {
  width: 100%;
  display: flex;
  gap: 10px;
  text-align: left;
  padding-left: 5px;
}

.pdf-viewer {
  overflow: auto;
  width: 100%;
  height: 530px;
}

button {
  padding: 5px;
  font-size: 16px;
}

/* stylelint-disable-next-line selector-class-pattern */
.rpv-core__page-layer::after {
  box-shadow: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/document/pdf.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;;EAEnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,SAAS;EACT,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA,uDAAuD;AACvD;EACE,2BAA2B;AAC7B","sourcesContent":[".pdf-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  /* margin: 20px; */\n}\n\n.controls {\n  width: 100%;\n  display: flex;\n  gap: 10px;\n  text-align: left;\n  padding-left: 5px;\n}\n\n.pdf-viewer {\n  overflow: auto;\n  width: 100%;\n  height: 530px;\n}\n\nbutton {\n  padding: 5px;\n  font-size: 16px;\n}\n\n/* stylelint-disable-next-line selector-class-pattern */\n.rpv-core__page-layer::after {\n  box-shadow: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
