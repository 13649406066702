import React from 'react';
import { Chip as MuiChip } from '@mui/material';
import { styled } from '@mui/system';
import { themePalette } from '../../../constants/theme/color';

const StyledChip = styled(MuiChip)(() => ({
  padding: '6px 9px',
  height: '40px',
  borderRadius: '6px',
  alignSelf: 'stretch',
  fontFamily: 'Poppins',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  color: 'black',
  width: 'auto',
  display: 'inline-flex',
  '& .MuiChip-label': {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    color: 'black',
  },
}));

const Chip = ({
  label = '',
  status = '',
  height = '',
  width = '',
  styles = null,
  onClick = () => null,
}) => {
  const getStatusColor = status => {
    switch (status) {
      case 'success':
        return themePalette.palette.success.light;
      case 'warning':
        return themePalette.palette.warning.light;
      case 'error':
        return themePalette.palette.error.light;
      case 'primary':
        return themePalette.palette.primary.light;
      default:
        return themePalette.palette.warning.light;
    }
  };

  return (
    <StyledChip
      sx={{
        fontFamily: 'Poppins',
        fontSize: '0.875rem !important',
        fontWeight: 500,
        ...styles,
      }}
      label={label}
      onClick={onClick}
      height={height}
      width={width}
      style={{ backgroundColor: getStatusColor(status) }}
    />
  );
};

export default Chip;
