import React from 'react';
import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@emotion/react';

const ClientPoDialog = ({
  open = false,
  onClose = () => null,
  headline = '',
  img = '',
  chips = null,
  buttons = null,
  label = '',
  width = '',
  height = '',
  details = [],
  backgroundColor = null,
  showDialogActions = true,
}) => {
  const theme = useTheme();

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={false}
      PaperProps={{
        style: {
          width: width,
          borderRadius: '8px',
          gap: '24px',
          backgroundColor: backgroundColor ?? theme.palette.white,
        },
      }}
    >
      <DialogTitle
        style={{
          background: 'white',
          borderRadius: '8px 8px 0 0',
        }}
      >
        {headline && onClose && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        )}

        <Typography variant="SemiBold-22">{headline}</Typography>
      </DialogTitle>
      <DialogContent
        style={{
          background: 'white',
          width: width,
          height: height,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          textAlign: 'center',
          padding: '0px 24px',
          borderRadius: showDialogActions ? 0 : '0 0 8px 8px',
          overflowX: 'hidden',
          gap: '24px',
        }}
      >
        <Box
          sx={{
            width: '1204px',
            backgroundColor: 'var(--text-600)',
            height: '350px',
          }}
        >
          {img}
        </Box>
      </DialogContent>
      {showDialogActions && (
        <DialogActions
          style={{
            display: 'flex',
            padding: '0px 24px',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            gap: '15px',
          }}
        >
          <Box>{buttons}</Box>
          <Box>{chips}</Box>
        </DialogActions>
      )}
      <Box
        sx={{
          display: 'flex',
          padding: !!details.length ? '24px' : '0px',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        {details?.map(detail => (
          <Box
            sx={{ display: 'flex', gap: '24px', alignItems: 'flex-start', flexDirection: 'row' }}
          >
            <Box
              sx={{
                minWidth: '200px',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              <Typography variant="Medium-14">{detail.label}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
              <Typography variant="Medium-14">{detail.value}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </MuiDialog>
  );
};

export default ClientPoDialog;
