import RouteHandler from '../../../components/router/handler';
import POClientDetails from './POClientDetails';
import POClientsList from './POClientList';

const POClientRoutes = [
  {
    index: true,
    path: '',
    component: <POClientsList />,
  },
  {
    path: 'view/:id',
    component: <POClientDetails />,
  },
];

const POClientRouter = () => {
  return <RouteHandler routes={POClientRoutes} />;
};

export default POClientRouter;
