import React from 'react';
import Box from '@mui/material/Box';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import styled from 'styled-components';
import Button from '../buttons';
import {
  convertURLToArrayBlob,
  downloadFileFromBlob,
  getFileType,
} from '../../../libs/document/file';
import { DownloadIcon } from '../../../libs/svg-icons/icons';

const StyledDocViewer = styled(DocViewer)`
  background-color: var(--white);

  & #html-renderer: {
    padding: 0;

    & #html-body: {
      border: none;
      margin: 0;
      padding: 0;
    }
  }
`;

const DocumentViewer = ({
  show = false,
  downloadable = true,
  link = '',
  fileName = '',
  extension = null,
  fullPage = false,
}) => {
  const handleDownloadFile = async () => {
    const fileBlob = await convertURLToArrayBlob(link);
    if (fileBlob) {
      downloadFileFromBlob(fileBlob, `${fileName}.${extension ?? getFileType(link)}`);
    }
  };
  return (
    <Box sx={{ width: '100%' }}>
      {downloadable && (
        <Box sx={{ mb: 4 }}>
          <Button
            sx={{
              minWidth: '21px',
              padding: '5px',
            }}
            variant="outlined"
            startIcon={
              <Box
                component={'span'}
                sx={{
                  width: '21px',
                  height: '21px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <DownloadIcon fill="var(--primary)" />
              </Box>
            }
            onClick={handleDownloadFile}
          />
        </Box>
      )}
      {show && (
        <StyledDocViewer
          pluginRenderers={DocViewerRenderers}
          documents={[
            {
              uri: link,
              fileType: extension ?? getFileType(link),
            },
          ]}
          initialActiveDocument={{
            uri: link,
            fileType: extension ?? getFileType(link),
          }}
          style={{ height: fullPage ? 'calc(100vh - 142px)' : '74vh' }}
          config={{
            header: {
              disableHeader: true,
              disableFileName: true,
              retainURLParams: false,
            },
          }}
        />
      )}
    </Box>
  );
};

export default DocumentViewer;
