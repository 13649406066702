import { Box, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FilterIcon } from '../../../libs/svg-icons/icons';
import { useTheme } from '@mui/material/styles';
import OverflowTooltip from '../tooltip/OverflowTooltip';
import { SortAscIcon } from '../../../libs/svg-icons/icons/SortAsc';
import { SortDescIcon } from '../../../libs/svg-icons/icons/SortDesc';

const ColumnHeader = props => {
  const theme = useTheme();
  const [ascSort, setAscSort] = useState('inactive');
  const [descSort, setDescSort] = useState('inactive');
  const [noSort, setNoSort] = useState('active');
  const [filters, setFilters] = useState({});
  const refButton = useRef(null);

  const colId = props?.column?.colId;

  const onMenuClicked = () => {
    if (colId) props.api.showColumnMenu(colId);
  };

  const onSortChanged = useCallback(() => {
    setAscSort(props.column.isSortAscending() ? 'active' : 'inactive');
    setDescSort(props.column.isSortDescending() ? 'active' : 'inactive');
    setNoSort(
      !props.column.isSortAscending() && !props.column.isSortDescending() ? 'active' : 'inactive',
    );
  }, [props.column]);

  const onFilterChanged = useCallback(() => {
    setFilters(props.api?.getFilterModel());
  }, [props.api]);

  const onSortRequested = (order, event) => {
    if (props.enableSorting) props.setSort(order, event.shiftKey);
  };

  useEffect(() => {
    props.column.addEventListener('sortChanged', onSortChanged);
    onSortChanged();
    return () => props.column.removeEventListener('sortChanged', onSortChanged);
  }, [onSortChanged, props.column]);

  useEffect(() => {
    props.column.addEventListener('filterChanged', onFilterChanged);
    onFilterChanged();
    return () => props.column.removeEventListener('filterChanged', onFilterChanged);
  }, [onFilterChanged, props.column]);

  let menu = null;
  const showMenu = useMemo(() => {
    if (!props.enableMenu) return false;
    if (Object.keys(filters).length > 0 && colId) {
      return !filters[colId]?.default;
    } else return false;
  }, [colId, filters, props.enableMenu]);

  if (props.enableMenu) {
    menu = (
      <Box
        ref={refButton}
        className="menu-icon-wrapper"
        sx={{ position: 'relative', zIndex: 10, cursor: 'pointer' }}
        onClick={() => onMenuClicked()}
      >
        <FilterIcon height={10} width={16} fill={theme.palette.text['100']} />
      </Box>
    );
  }

  let sort = null;
  if (props.enableSorting) {
    sort = (
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        {noSort === 'inactive' ? (
          ascSort === 'active' ? (
            <Box
              onClick={event => onSortRequested('desc', event)}
              onTouchEnd={event => onSortRequested('desc', event)}
              className={`customSortUpLabel asc-${ascSort}`}
            >
              <SortAscIcon height={12} width={14} fill={theme.palette.text['100']} />
            </Box>
          ) : descSort === 'active' ? (
            <Box
              onClick={event => onSortRequested('', event)}
              onTouchEnd={event => onSortRequested('', event)}
              className={`customSortRemoveLabel desc-${descSort}`}
            >
              <SortDescIcon height={12} width={14} fill={theme.palette.text['100']} />
            </Box>
          ) : null
        ) : null}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 15px 0 0',

        '& .column-name-wrapper': {
          width: `calc(100% - ${showMenu ? (noSort === 'inactive' ? '34px' : '20px') : noSort === 'inactive' ? '14px' : '0px'})`,
        },

        '&:hover .column-name-wrapper': {
          width: `calc(100% - ${props.enableMenu ? (noSort === 'inactive' ? '34px' : '20px') : noSort === 'inactive' ? '14px' : '0px'})`,
        },

        '& .menu-icon-wrapper': {
          display: showMenu ? 'block' : 'none',
        },

        '&:hover .menu-icon-wrapper': {
          display: 'block',
        },
      }}
    >
      <Box
        className="column-name-wrapper"
        sx={{ padding: '0 0 0 15px' }}
        onClick={event =>
          onSortRequested(noSort === 'active' ? 'asc' : ascSort === 'active' ? 'desc' : '', event)
        }
        onTouchEnd={event =>
          onSortRequested(noSort === 'active' ? 'asc' : ascSort === 'active' ? 'desc' : '', event)
        }
      >
        <OverflowTooltip text={props.displayName}>
          <Typography variant="Medium-14" color={theme.palette.text['100']}>
            {props.displayName}
          </Typography>
        </OverflowTooltip>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px' }}>
        {sort}
        {menu}
      </Box>
    </Box>
  );
};

export default ColumnHeader;
