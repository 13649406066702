import React from 'react';
import { Box, Typography } from '@mui/material';
import PageNotFoundIllustration from '../assets/images/404.svg';
import { useTheme } from '@mui/material/styles';
import Card from '../components/shared/card';

const PageNotFound = ({ isRoot }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        alignItems: 'flex-start',
        width: isRoot ? 'calc(100% - 193px)' : '100%',
        minHeight: 'calc(100vh - 193px)',
        height: '100%',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '30%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '30px',
          }}
        >
          <img
            src={PageNotFoundIllustration}
            alt="Page Not Found Illustration"
            width={300}
            height={300}
          />
          <Box
            sx={{
              width: '100%',
              minWidth: '386px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '1rem',
            }}
          >
            <Typography variant="Medium-18" color={theme.palette.text['100']}>
              Whoops!
            </Typography>
            <Typography variant="Regular-14" textAlign="center" color={theme.palette.text['300']}>
              It seems you've taken a wrong turn in our digital realm.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default PageNotFound;
