import { ApiURLConstants } from '../constants/api-url-constants';
import axiosInstance from './api';

const globalHeaders = {
  'Content-Type': 'application/json',
};

const API_BASE_URL = process.env.REACT_APP_COMMENTS_API_BASE_URL;
const API = axiosInstance;

export const getAllComments = async params => {
  const uri = `${API_BASE_URL}${ApiURLConstants.GET_ALL_COMMENTS(params)}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const addComment = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.ADD_COMMENTS}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const editComment = async (params, payload) => {
  const uri = `${API_BASE_URL}${ApiURLConstants.EDIT_COMMENTS(params)}`;
  const headers = { ...globalHeaders };
  const response = await API.put(uri, payload, {
    headers,
  });
  return response;
};

export const deleteComment = async params => {
  const uri = `${API_BASE_URL}${ApiURLConstants.DELETE_COMMENTS(params)}`;
  const headers = { ...globalHeaders };
  const response = await API.delete(uri, {
    headers,
  });
  return response;
};
