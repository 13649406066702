import React from 'react';
import { Button as MuiButton } from '@mui/material';
import { styled } from '@mui/system';

const StyledButton = styled(MuiButton)(({ theme }) => ({
  alignSelf: 'stretch',
  textTransform: 'none',
  gap: '8px',

  '& .MuiButton-label': {
    fontSize: '14px',
    fontFamily: 'Poppins',
  },
  '& .MuiButton-startIcon, & .MuiButton-endIcon': {
    margin: 0,
  },
}));

const Button = ({
  type = 'button',
  variant = 'outlined',
  disabled = false,
  label = '',
  startIcon = undefined,
  endIcon = undefined,
  onClick = () => null,
  width = '',
  height = '',
  sx = null,
}) => {
  return (
    <StyledButton
      type={type}
      variant={variant}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      disabled={disabled}
      sx={{ width: { width }, height: { height }, ...sx }}
    >
      {label}
    </StyledButton>
  );
};

export default Button;
export { StyledButton };
