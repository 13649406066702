import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  user: null,
  loggedInUserId: '',
  userRoles: [],
  permissions: [],
  redirect: '',
  fcmToken: '',
  businessUnit: [],
  teams: [],
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loggedIn: (state, action) => {
      state.redirect = action.payload.redirect;
      state.isLoggedIn = true;
      state.loggedInUserId = action.payload.loggedInUserId;
      state.user = action.payload.user;
      state.userRoles = action.payload.userRoles;
      state.permissions = action.payload.permissions;
      state.fcmToken = action.payload.fcmToken;
      state.businessUnit = action.payload.businessUnit;
      state.teams = action.payload.teams;
    },
    loggedOut: state => {
      state.isLoggedIn = false;
      state.user = null;
      state.loggedInUserId = '';
      state.userRoles = [];
      state.permissions = [];
      state.fcmToken = '';
      state.businessUnit = [];
      state.teams = [];
    },
    resetRedirect: state => {
      state.redirect = '';
    },
  },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
