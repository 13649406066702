import { createIcon } from '../createSVGIcon';

const path = (
  <g>
    <path d="M5.25 7.49995H15C15.1989 7.49995 15.3897 7.57896 15.5303 7.71962C15.671 7.86027 15.75 8.05103 15.75 8.24995V15.7499C15.75 15.9489 15.671 16.1396 15.5303 16.2803C15.3897 16.4209 15.1989 16.4999 15 16.4999H3C2.80109 16.4999 2.61032 16.4209 2.46967 16.2803C2.32902 16.1396 2.25 15.9489 2.25 15.7499V8.24995C2.25 8.05103 2.32902 7.86027 2.46967 7.71962C2.61032 7.57896 2.80109 7.49995 3 7.49995H3.75V6.74995C3.74957 5.56697 4.14869 4.41853 4.88264 3.49077C5.6166 2.56302 6.64238 1.91033 7.7937 1.63852C8.94502 1.3667 10.1544 1.49169 11.2258 1.99323C12.2972 2.49476 13.1678 3.34344 13.6965 4.4017L12.3547 5.0722C11.9771 4.31621 11.3552 3.70993 10.5899 3.35166C9.82452 2.99338 8.9606 2.90411 8.13816 3.09833C7.31572 3.29254 6.583 3.75885 6.05877 4.42165C5.53454 5.08445 5.24955 5.90489 5.25 6.74995V7.49995ZM3.75 8.99995V14.9999H14.25V8.99995H3.75ZM7.5 11.2499H10.5V12.7499H7.5V11.2499Z" />
  </g>
);

export const UnLockIcon = createIcon(path, {
  width: 18,
  height: 18,
  viewBox: '0 0 18 18',
  fill: 'none',
});
