import { createIcon } from '../createSVGIcon';

const path = (
  <g>
    <path d="M8.25 8.25V3.75H9.75V8.25H14.25V9.75H9.75V14.25H8.25V9.75H3.75V8.25H8.25Z" />
  </g>
);

export const AddIcon = createIcon(path, {
  width: 20,
  height: 20,
  viewBox: '0 0 20 20',
  fill: 'none',
});
