import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { isLoggedInSelector } from '../../redux/slice';

const PublicRoute = ({ children }) => {
  const isLoggedIn = useAppSelector(isLoggedInSelector);

  if (isLoggedIn) {
    return <Navigate to="/app" replace />;
  }

  return <div>{children}</div>;
};

export default PublicRoute;
