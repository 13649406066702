import { createIcon } from '../createSVGIcon';

const path = (
  <g>
    <path d="M4.97656 5.99962L0.851562 1.87462L2.0299 0.696289L7.33323 5.99962L2.0299 11.303L0.851562 10.1246L4.97656 5.99962Z" />
  </g>
);

export const ChevronRightIcon = createIcon(path, {
  width: 8,
  height: 12,
  viewBox: '0 0 8 12',
  fill: 'none',
});
