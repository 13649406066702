import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Card from '../../../../../../components/shared/card';
import Header from '../../../../../../components/shared/header';
import Tabs from '../../../../../../components/shared/tabs';
import { Box, Divider, Typography } from '@mui/material';
import Button from '../../../../../../components/shared/buttons';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Comments from '../../../../../../components/shared/comments';
import NonClickableTab from '../../../../../../components/shared/tabs/NonClickableTabs';
import InvoiceForm from './InvoiceForm';
import UploadFileForm from './UploadFileForm';
import Dialog from '../../../../../../components/shared/dialog';
import { ErrorIcon } from '../../../../../../libs/svg-icons/icons';
import { useTheme } from '@emotion/react';
import ViewInvoiceDocumentTable from './ViewInvoiceDocumentTable';

const schema = yup.object().shape({
  invoicenumber: yup.string().required('Invoice Number is Required'),
  issuedate: yup.string().required('Issue Date is Required'),
  currency: yup.string().required('Currency is Required'),
  purchasenumber: yup.string().required('Purchase Number is Required'),
  purchaseorderdate: yup.string().required('Purchase Order Date Required'),
  startdate: yup.string().required('Start Date Required'),
  enddate: yup.string().required('End Date Required'),
  itemid: yup.string().required('Item Id Required'),
  quantity: yup.string().required('Quantity Required'),
  unit: yup.string().required('Unit Required'),
  priceperunit: yup.string().required('Price Per Unit Required'),
  tax: yup.string().required('Tax Required'),
  totaltax: yup.string().required('Total Tax Required'),
  taxnumber: yup.string().required('Tax Number Required'),
});

const uploadFileSchema = yup.object().shape({
  uploadtax: yup.mixed().required('Upload Tax is Required'),
  uploadlogproof: yup.mixed().required('Upload Tax is Required'),
  uploaddocuments: yup.mixed().required('Upload Tax is Required'),
});

const VendorViewInvoiceDocuments = () => {
  const params = useParams();
  const theme = useTheme();

  const [openUploadInvoiceDialog, setOpenUploadInvoiceDialog] = useState(false);
  const [editInvoiceClicked, setEditInvoiceClicked] = useState(false);

  const handleEditInvoiceClick = () => {
    console.log('Clicked');
    setEditInvoiceClicked(true);
  };

  const handleOpenUploadInvoiceDialog = () => {
    setOpenUploadInvoiceDialog(true);
  };

  const handleCloseUploadInvoiceDialog = () => {
    setOpenUploadInvoiceDialog(false);
  };

  const schemaMethods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const uploadFileSchemaMethods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(uploadFileSchema),
    defaultValues: {},
  });

  const handleInvoiceUpload = file => {};

  const handleInvoiceRemoved = () => {
    schemaMethods.setValue('uploadinvoice', undefined, {
      shouldValidate: true,
    });
  };

  const handleUploadTax = file => {};

  const handleRemoveduploadtax = () => {
    schemaMethods.setValue('uploadtax', undefined, {
      shouldValidate: true,
    });
  };

  const handleUploadLogProof = file => {};

  const handleRemovedLogProof = () => {
    schemaMethods.setValue('uploadlogproof', undefined, {
      shouldValidate: true,
    });
  };

  const handleUploadDocuments = file => {};

  const handleRemovedUploadDocuments = () => {
    schemaMethods.setValue('uploaddocuments', undefined, {
      shouldValidate: true,
    });
  };

  const [value, setValue] = useState(0);
  const [nextClicked, setNextClicked] = useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNextClick = () => {
    if (nextClicked === 2 && schemaMethods?.formState?.isValid) {
      setOpenUploadInvoiceDialog(true);
    } else {
      setNextClicked(2);
    }
  };

  const isNextButtonDisabled = useMemo(() => {
    if (nextClicked === 1) {
      return !schemaMethods?.formState?.isValid;
    } else {
      return !uploadFileSchemaMethods?.formState?.isValid;
    }
  }, [nextClicked, schemaMethods?.formState?.isValid, uploadFileSchemaMethods?.formState?.isValid]);

  const tabs = [{ label: 'Invoice' }, { label: 'Supporting Documents' }];

  return (
    <Card sx={{ alignItems: 'flex-start' }}>
      <Header breadcrumbs={[{ name: '2023_August_Lifebuoy_Sun_Digital_KOL_BB1234567890' }]} />
      <Tabs
        items={[
          {
            value: 'po-vendor',
            label: 'PO Vendor',
            link: `/app/vendors/view/${params.id}/view`,
          },
          {
            value: 'vendor-invoice-documents',
            label: 'Vendor Invoice & Documents',
            link: `/app/vendors/view/${params.id}/vendor-view-invoice`,
          },
        ]}
        active="vendor-invoice-documents"
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '100%',
          flexDirection: 'column',
          gap: '40px',
        }}
      >
        {!editInvoiceClicked ? (
          <>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexDirection: 'row',
              }}
            >
              <Box sx={{ width: '50%' }}>
                <NonClickableTab tabs={tabs} value={value} onChange={handleChange} />
              </Box>
              <Box
                sx={{
                  width: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  label={nextClicked === 2 ? 'Upload Invoice & Documents' : 'Next'}
                  variant="contained"
                  disabled={isNextButtonDisabled}
                  onClick={handleNextClick}
                />
              </Box>
            </Box>

            {nextClicked === 1 ? (
              <FormProvider {...schemaMethods}>
                <InvoiceForm
                  schema={schema}
                  schemaMethods={schemaMethods}
                  handleInvoiceUpload={handleInvoiceUpload}
                  handleInvoiceRemoved={handleInvoiceRemoved}
                />
              </FormProvider>
            ) : (
              <FormProvider {...uploadFileSchemaMethods}>
                <UploadFileForm
                  uploadFileSchemaMethods={uploadFileSchemaMethods}
                  handleUploadTax={handleUploadTax}
                  handleRemoveduploadtax={handleRemoveduploadtax}
                  handleUploadLogProof={handleUploadLogProof}
                  handleRemovedLogProof={handleRemovedLogProof}
                  handleUploadDocuments={handleUploadDocuments}
                  handleRemovedUploadDocuments={handleRemovedUploadDocuments}
                />
              </FormProvider>
            )}

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexDirection: 'row',
              }}
            >
              <Box sx={{ width: '50%' }}></Box>
              <Box
                sx={{
                  width: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  label={nextClicked === 2 ? 'Upload Invoice & Documents' : 'Next'}
                  variant="contained"
                  disabled={isNextButtonDisabled}
                  onClick={handleNextClick}
                />
                <Dialog
                  open={openUploadInvoiceDialog}
                  onClose={handleCloseUploadInvoiceDialog}
                  width="650px"
                  icon={
                    <Box
                      sx={{
                        width: '52px',
                        height: '52px',
                        borderRadius: '50%',
                        background: theme.palette.background,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '0 auto',
                        marginBottom: '24px',
                      }}
                    >
                      <ErrorIcon fill="var(--deep-navy)" width="32px" height="32px" />
                    </Box>
                  }
                  headline={<Typography variant="SemiBold-22">Invoice Amount Exceeding</Typography>}
                  description={
                    <>
                      <Typography variant="Regular-16">
                        Total of the previously uploaded invoice(s) and this invoice amount is
                        exceeding the total PO amount. Please recheck and modify the invoice in
                        order to continue.
                      </Typography>

                      <Typography
                        variant="Regular-16"
                        sx={{ marginTop: '10px', marginBottom: '24px' }}
                      >
                        Use the Comments feature at the end of the page to send your comments to the
                        concerned team(s).
                      </Typography>
                    </>
                  }
                  buttons={[
                    <Button
                      key="button1"
                      width="300px"
                      variant="outlined"
                      label="Cancel"
                      onClick={handleCloseUploadInvoiceDialog}
                    />,
                    <Button
                      type="submit"
                      key="button2"
                      width="300px"
                      variant="contained"
                      label="Edit Invoice"
                      onClick={handleEditInvoiceClick}
                    />,
                  ]}
                  headlineAlignment="center"
                />
              </Box>
            </Box>
          </>
        ) : (
          <Box sx={{ width: '100%' }}>
            <ViewInvoiceDocumentTable />
          </Box>
        )}

        <Divider sx={{ width: '100%' }} />
        <Comments entityId={params.id} entityType="BUYING_BRIEF_GROUP" />
      </Box>
    </Card>
  );
};

export default VendorViewInvoiceDocuments;
