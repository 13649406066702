import { Tabs as MuiTabs, Tab } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Tabs = ({ items = [], active = '' }) => {
  const navigate = useNavigate();
  const handleTabClick = item => {
    if (item.link) {
      navigate(item.link);
    }
  };
  return (
    <MuiTabs
      value={active}
      sx={{
        '& .MuiTabs-flexContainer': {
          gap: '20px',

          '& .MuiButtonBase-root': {
            padding: '6.5px 10px',
            fontFamily: 'Poppins',
            fontSize: '18px',
            fontWeight: 500,
            lineHeight: '27px',
            letterSpacing: '0em',
          },
        },
      }}
    >
      {items?.map(item => (
        <Tab
          key={item.value}
          value={item.value}
          label={item.label}
          onClick={() => handleTabClick(item)}
          style={{ textTransform: 'none' }}
        />
      ))}
    </MuiTabs>
  );
};

export default Tabs;
