import React, { useState, useEffect } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { AddIcon, UploadIcon } from '../../../../../../libs/svg-icons/icons';
import DragAndDropFilePicker from '../../../../../../components/shared/form/fileupload';
import Button from '../../../../../../components/shared/buttons';
import { useTheme } from '@emotion/react';

const UploadFileForm = ({
  uploadFileSchemaMethods,
  handleUploadTax,
  handleRemoveduploadtax,
  handleUploadLogProof,
  handleRemovedLogProof,
  handleUploadDocuments,
  handleRemovedUploadDocuments,
  handleFileUploadStatus,
  handleFileUploadComplete,
  setAllFilesUploaded,
}) => {
  const theme = useTheme();

  const handleFileUpload = () => {
    if (
      uploadFileSchemaMethods.getValues('uploadtax') &&
      uploadFileSchemaMethods.getValues('uploadlogproof') &&
      uploadFileSchemaMethods.getValues('uploaddocuments')
    ) {
      setAllFilesUploaded(true);
    }
  };

  const handleFileRemove = () => {
    handleRemoveduploadtax();
    handleRemovedLogProof();
    handleRemovedUploadDocuments();
    setAllFilesUploaded(false);
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '40px',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexDirection: 'row',
            gap: '20px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '40px',
              marginTop: '100px',
            }}
          >
            <Button
              sx={{
                minWidth: '21px',
                padding: '5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              variant="outlined"
              startIcon={
                <Box
                  component={'span'}
                  sx={{
                    width: '21px',
                    height: '21px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <AddIcon fill="var(--primary)" />
                </Box>
              }
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              flexDirection: 'column',
              gap: '4px',
              width: '35%',
            }}
          >
            <Typography variant="Medium-16">Upload Tax</Typography>
            <Box
              sx={{
                width: '396px',
              }}
            >
              <DragAndDropFilePicker
                multi={false}
                icon={<UploadIcon fill={theme.palette.primary.main} />}
                headline="Upload Document"
                description="(Maximum 20MB file size)"
                onUpload={handleFileUpload}
                onRemove={handleFileRemove}
                acceptedfileExtensions={['pdf']}
                draggable={true}
                maxFileSize={5}
                {...uploadFileSchemaMethods.register('uploadtax')}
              />
            </Box>
          </Box>
        </Box>
        <Divider sx={{ width: '100%' }} />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexDirection: 'row',
            gap: '20px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '100px',
            }}
          >
            <Button
              sx={{
                minWidth: '21px',
                padding: '5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              variant="outlined"
              startIcon={
                <Box
                  component={'span'}
                  sx={{
                    width: '21px',
                    height: '21px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <AddIcon fill="var(--primary)" />
                </Box>
              }
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              flexDirection: 'column',
              gap: '4px',
              width: '35%',
            }}
          >
            <Typography variant="Medium-16">Upload Log Proof/Proof of Airing</Typography>
            <Box
              sx={{
                width: '396px',
              }}
            >
              <DragAndDropFilePicker
                multi={false}
                icon={<UploadIcon fill={theme.palette.primary.main} />}
                headline="Upload Document"
                description="(Maximum 20MB file size)"
                onUpload={handleFileUpload}
                onRemove={handleFileRemove}
                acceptedfileExtensions={['pdf']}
                draggable={true}
                maxFileSize={5}
                {...uploadFileSchemaMethods.register('uploadlogproof')}
              />
            </Box>
          </Box>
        </Box>
        <Divider sx={{ width: '100%' }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexDirection: 'row',
            gap: '20px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '100px',
            }}
          >
            <Button
              sx={{
                minWidth: '21px',
                padding: '5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              variant="outlined"
              startIcon={
                <Box
                  component={'span'}
                  sx={{
                    width: '21px',
                    height: '21px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <AddIcon fill="var(--primary)" />
                </Box>
              }
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              flexDirection: 'column',
              gap: '4px',
              width: '35%',
            }}
          >
            <Typography variant="Medium-16">Upload Other Documents (optional)</Typography>
            <Box
              sx={{
                width: '396px',
              }}
            >
              <DragAndDropFilePicker
                multi={false}
                icon={<UploadIcon fill={theme.palette.primary.main} />}
                headline="Upload Document"
                description="(Only .csv file, maximum 20MB file size)"
                onUpload={handleFileUpload}
                onRemove={handleFileRemove}
                acceptedfileExtensions={['pdf']}
                draggable={true}
                maxFileSize={5}
                {...uploadFileSchemaMethods.register('uploaddocuments')}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UploadFileForm;
