import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { authReducer, breadcrumbsReducer } from './slice';
import { createMediaPlanReducer } from './slice/createMediaPlan';

const persistConfig = {
  key: 'root',
  storage,
};

const reducers = combineReducers({
  auth: authReducer,
  breadcrumbs: breadcrumbsReducer,
  createMediaPlan: createMediaPlanReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);
export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env['REACT_APP_ENV'] === 'DEV',
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      immutableCheck: true, // makes sure store is not mutated directly
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'], // ignore serializable check for redux-persist
      },
    });
  },
});

export const persistor = persistStore(store);
export const dispatcher = store.dispatch;
