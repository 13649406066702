import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../../../../components/shared/card';
import Header from '../../../../../components/shared/header';
import Tabs from '../../../../../components/shared/tabs';
import { defaultColDef, columns as columnData } from './ColumnData';
import Table from '../../../../../components/shared/table';
import { Box } from '@mui/material';
import Input from '../../../../../components/shared/form/Input/input';
import MediaPlanDetails from '../common/MediaPlanDetails';
import { getBuyingBriefGroupList, getMediaPlan } from '../../../../../services/media-plan-service';
import { useEnqueueSnackbar } from '../../../../../components/shared/toast-provider/toastHook';
import { AppConstants } from '../../../../../constants/app-constants';
import PageNotFound from '../../../../404';
import { PermissionsConstants } from '../../../../../constants/permissions-constants';
import { useHasAllPermission } from '../../../../../hooks/permissions';
import { isEmpty, debounce } from 'lodash';
import Loader from '../../../../../components/shared/loader';
import BackdropOverlay from '../../../../../components/shared/backdropOverlay';
import { useAppSelector } from '../../../../../redux/hooks';

const PAGE_SIZE = 10;

const BuyingGroups = () => {
  const params = useParams();
  const navigate = useNavigate();
  const enqueueSnackbar = useEnqueueSnackbar();
  const hasAllPermissions = useHasAllPermission();

  const gridTableRef = useRef(null);

  const [mediaPlanId, setMediaPlanId] = useState(null);
  const [mediaPlanDetails, setMediaPlanDetails] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const userBusinessUnit = useAppSelector(state => state.auth.businessUnit);

  const backdropOpen = useMemo(() => {
    const { name = '' } = userBusinessUnit || {};
    const { businessUnit = null } = mediaPlanDetails || {};
    return !isLoading && name !== businessUnit;
  }, [mediaPlanDetails]);

  const handleSetMediaPlanData = mediaPlanData => {
    if (mediaPlanData) {
      setMediaPlanId(mediaPlanData.id);
      setMediaPlanDetails({
        id: mediaPlanData.id,
        typeId: mediaPlanData.mediaPlanType.id,
        name: mediaPlanData.name,
        brand: mediaPlanData.brand,
        year: mediaPlanData.year,
        businessUnit: mediaPlanData.businessUnit,
        planner: mediaPlanData.planner,
        category: mediaPlanData.category,
        startDate: mediaPlanData.startDate,
        endDate: mediaPlanData.endDate,
        totalBudget: mediaPlanData.totalBudget,
        calculatedBudget: mediaPlanData.givenBudget,
        status: mediaPlanData.activeVersion?.status,
        approvalId: mediaPlanData.activeVersion?.approvalStatus?.id,
        activeVersionId: mediaPlanData.activeVersion.id,
        approvalStatus: mediaPlanData.activeVersion?.managerApprovalstatus,
        approvalManagerId: mediaPlanData.activeVersion?.approvalStatus?.managerId,
        activeVersionNumber: mediaPlanData.activeVersion?.versionNumber,
      });
    }
  };

  const handleUpdateMediaPlanDetails = props => {
    setMediaPlanDetails(prev => ({
      ...prev,
      ...props,
    }));
  };

  const getDataSource = useCallback(() => {
    const dataSource = {
      rowCount: undefined,
      getRows: async params => {
        try {
          const uriParams = {
            versionId: mediaPlanDetails?.activeVersionId,
            pageNo: params.endRow / PAGE_SIZE ?? 1,
            pageSize: PAGE_SIZE,
          };
          if (!isEmpty(searchText)) {
            uriParams['searchText'] = searchText;
          } else {
            uriParams['searchText'] = undefined;
          }

          await getBuyingBriefGroupList(uriParams).then(response => {
            const jsonData = response.data;
            const { data = {} } = jsonData;
            const { models = [], totalElements = 0 } = data;
            if (response.status === 200) {
              const totalRowCount = totalElements;

              let lastRow = -1;
              if (totalRowCount <= params.endRow) {
                lastRow = totalRowCount;
              }

              params.successCallback(models, lastRow);
            } else {
              params.successCallback(null, 0);
            }
          });
        } catch (errorResponse) {
          const { data = {} } = errorResponse?.response || {};
          const { message = AppConstants.SOMETHING_WENT_WRONG } = data;

          enqueueSnackbar(message, 'error');

          params.successCallback(null, 0);
        }
      },
    };
    return dataSource;
  }, [enqueueSnackbar, mediaPlanDetails?.activeVersionId, searchText]);

  const fetchData = () => {
    setIsLoading(true);
    const id = params.id;
    getMediaPlan(id)
      .then(async response => {
        const { data: mediaPlanData } = response.data;
        handleSetMediaPlanData(mediaPlanData);
      })
      .catch(error => {
        const { message = AppConstants.SOMETHING_WENT_WRONG } = error.response.data;
        enqueueSnackbar(message, 'error');
        setMediaPlanId(0);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleBuyingBriefGroupIdClicked = useCallback(
    event => {
      if (event.data?.id) {
        navigate(`${event.data?.id}`, {
          replace: false,
        });
      }
    },
    [navigate],
  );

  const columns = useMemo(() => columnData(handleBuyingBriefGroupIdClicked), []);

  const datasource = useMemo(() => {
    if (mediaPlanDetails?.activeVersionId) return getDataSource();
  }, [getDataSource, mediaPlanDetails?.activeVersionId]);

  const getRowId = useMemo(() => {
    return params => {
      return params.data.buyingBriefGroupID;
    };
  }, []);

  const handleSearched = e => {
    const text = e.target.value;

    debounce(() => setSearchText(text), 500, { trailing: true })();
  };

  const handleSearchCleared = () => {
    setSearchText('');
  };

  const BuyingGroupsTable = useMemo(
    () =>
      mediaPlanDetails?.activeVersionId ? (
        <Table
          ref={gridTableRef}
          datasource={datasource}
          getRowId={getRowId}
          columns={columns}
          defaultColDef={defaultColDef}
          height="calc(100vh - 450px)"
          rowSelection="single"
          rowModelType="infinite"
          maxBlocksInCache={100}
          rowBuffer={1}
          cacheBlockSize={PAGE_SIZE}
          cacheOverflowSize={1}
          maxConcurrentDatasourceRequests={1}
          infiniteInitialRowCount={10}
          suppressRowClickSelection={true}
          blockLoadDebounceMillis={500}
        />
      ) : null,
    [columns, datasource, getRowId, mediaPlanDetails?.activeVersionId],
  );

  const MediaPlanInfo = useMemo(
    () =>
      mediaPlanDetails ? (
        <MediaPlanDetails
          mediaPlanDetails={mediaPlanDetails}
          handleUpdateMediaPlanDetails={handleUpdateMediaPlanDetails}
          disableGivenBudget={!hasAllPermissions([PermissionsConstants.UPDATE_GIVEN_BUDGET])}
        />
      ) : null,
    [hasAllPermissions, mediaPlanDetails],
  );

  if (mediaPlanId === 0 || !hasAllPermissions([PermissionsConstants.READ_MEDIA_PLAN]))
    return <PageNotFound />;

  return (
    <>
      {!isLoading && mediaPlanDetails && (
        <BackdropOverlay
          open={backdropOpen}
          buttonLabel="Redirect To Media Plan List"
          clickAction={() => {
            navigate(`/app/media-plans`);
          }}
        />
      )}
      <Loader isLoading={isLoading} />
      <Card sx={{ alignItems: 'flex-start' }}>
        <Header breadcrumbs={[{ name: 'View Media Plan' }]} />
        <Tabs
          items={[
            {
              value: 'media-plan',
              label: 'Media Plan',
              link: `/app/media-plans/${params.id}/media-plan`,
            },
            {
              value: 'summary',
              label: 'Summary',
              link: `/app/media-plans/${params.id}/summary`,
            },
            {
              value: 'buying-group',
              label: 'Buying Brief Group',
              link: `/app/media-plans/${params.id}/buying-group`,
            },
          ]}
          active="buying-group"
        />
        {MediaPlanInfo}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '20px',
            }}
          >
            <Box sx={{ width: '24.75rem' }}>
              <Input
                id="buying-group-search"
                inputHeight="40px"
                type="text"
                placeholder="Search buying group by id number"
                searchable
                onChange={handleSearched}
                onSearchCloseClick={handleSearchCleared}
              />
            </Box>
          </Box>
          {BuyingGroupsTable}
        </Box>
      </Card>
    </>
  );
};

export default BuyingGroups;
