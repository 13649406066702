import React, { Suspense, useEffect } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AppRouter from './pages/app';
import { persistor } from './redux/store';
import AuthRouter from './pages/auth';
import ProtectedRoute from './components/router/ProtectedRoute';
import PublicRoute from './components/router/PublicRoute';
import DocumentViewerPage from './pages/document-viewer';
import PageNotFound from './pages/404';
import { Box } from '@mui/material';
import { onMessage } from 'firebase/messaging';
import { throwNotification } from './utils/notification';
import { requestPermission, messaging } from '../src/firebase/firebase';
import { useAppSelector } from './redux/hooks';
import { saveFCMToken } from './services/notification-service';
import { useEnqueueSnackbar } from './components/shared/toast-provider/toastHook';

function App() {
  const enqueueSnackbar = useEnqueueSnackbar();
  const userIsLoggedIn = useAppSelector(state => state.auth.isLoggedIn);

  function setupForegroundMessageHandler() {
    onMessage(messaging, payload => {
      throwNotification(payload);
    });
  }

  const sendFCMToken = async token => {
    try {
      const response = await saveFCMToken(token);
      if (response.status === 200) {
        // console.log('FCM token successfully sent to server.');
      } else {
        console.error('Failed to send FCM token to server.', response);
      }
    } catch (error) {
      enqueueSnackbar('Error while saving FCM token to server: Firebase', 'error');
      console.error('Error calling FCM token API:', error);
    }
  };

  useEffect(() => {
    if (userIsLoggedIn) {
      requestPermission()
        .then(token => {
          localStorage.setItem('fcmToken', token);
          // if (localStorage.getItem('fcmToken') !== token) {
          sendFCMToken(token);
          // }
          setupForegroundMessageHandler();
        })
        .catch(e => {
          console.error('Error requesting notification permission: ', e);
        });
    }
  }, [userIsLoggedIn]);

  return (
    <PersistGate persistor={persistor}>
      <Suspense fallback={<p>Loading</p>}>
        <Routes>
          {/* Redirect to /app from / */}
          <Route index element={<Navigate to="/app" />} />
          <Route
            path="/auth/*"
            element={
              <PublicRoute>
                <AuthRouter />
              </PublicRoute>
            }
          />
          <Route
            path="/app/*"
            element={
              <ProtectedRoute>
                <AppRouter />
              </ProtectedRoute>
            }
          />
          <Route
            path="document-viewer/"
            element={
              <ProtectedRoute>
                <DocumentViewerPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="*"
            element={
              <Box
                sx={{
                  width: '100vw',
                  height: '100vh',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <PageNotFound isRoot />
              </Box>
            }
          />
        </Routes>
      </Suspense>
    </PersistGate>
  );
}

export default App;
