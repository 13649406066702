import { createIcon } from '../createSVGIcon';

const path = (
  <g>
    <path d="M14.25 7.5H15C15.1989 7.5 15.3897 7.57902 15.5303 7.71967C15.671 7.86032 15.75 8.05109 15.75 8.25V15.75C15.75 15.9489 15.671 16.1397 15.5303 16.2803C15.3897 16.421 15.1989 16.5 15 16.5H3C2.80109 16.5 2.61032 16.421 2.46967 16.2803C2.32902 16.1397 2.25 15.9489 2.25 15.75V8.25C2.25 8.05109 2.32902 7.86032 2.46967 7.71967C2.61032 7.57902 2.80109 7.5 3 7.5H3.75V6.75C3.75 6.06056 3.8858 5.37787 4.14963 4.74091C4.41347 4.10395 4.80018 3.5252 5.28769 3.03769C5.7752 2.55018 6.35395 2.16347 6.99091 1.89963C7.62787 1.6358 8.31056 1.5 9 1.5C9.68944 1.5 10.3721 1.6358 11.0091 1.89963C11.646 2.16347 12.2248 2.55018 12.7123 3.03769C13.1998 3.5252 13.5865 4.10395 13.8504 4.74091C14.1142 5.37787 14.25 6.06056 14.25 6.75V7.5ZM3.75 9V15H14.25V9H3.75ZM8.25 10.5H9.75V13.5H8.25V10.5ZM12.75 7.5V6.75C12.75 5.75544 12.3549 4.80161 11.6517 4.09835C10.9484 3.39509 9.99456 3 9 3C8.00544 3 7.05161 3.39509 6.34835 4.09835C5.64509 4.80161 5.25 5.75544 5.25 6.75V7.5H12.75Z" />
  </g>
);

export const LockIcon = createIcon(path, {
  width: 20,
  height: 20,
  viewBox: '0 0 20 20',
  fill: 'none',
});
