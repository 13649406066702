import RouteHandler from '../../../components/router/handler';
import PoVendor from './VendorDetails/PoVendor';
import VendorInvoiceDocuments from './VendorDetails/VendorInvoiceDocuments';
import VendorViewInvoiceDocuments from './VendorView/VendorDetails/VendorViewInvoiceDocument';
import VendorsList from './VendorstList';
import POVendorView from './VendorView/VendorDetails/PoVendorView';

const VendorsRoutes = [
  {
    index: true,
    path: '',
    component: <VendorsList />,
  },
  {
    path: 'view/:id',
    component: <PoVendor />,
  },
  {
    path: 'view/:id/vendor-invoice',
    component: <VendorInvoiceDocuments />,
  },
  {
    path: 'view/:id/view',
    component: <POVendorView />,
  },
  {
    path: 'view/:id/vendor-view-invoice',
    component: <VendorViewInvoiceDocuments />,
  },
];

const VendorsRouter = () => {
  return <RouteHandler routes={VendorsRoutes} />;
};

export default VendorsRouter;
