import { createIcon } from '../createSVGIcon';

const path = (
  <g>
    <path d="M10.8279 0.685974L19.3139 9.17197L17.8989 10.586L17.1919 9.87897L12.9499 14.121L12.2429 17.657L10.8279 19.071L6.58592 14.828L1.63592 19.778L0.221924 18.364L5.17192 13.414L0.928924 9.17197L2.34292 7.75697L5.87992 7.04997L10.1219 2.80797L9.41492 2.10097L10.8289 0.685974H10.8279ZM11.5359 4.22197L6.86492 8.89197L4.04292 9.45697L10.5429 15.957L11.1069 13.135L15.7779 8.46497L11.5359 4.22197Z" />
  </g>
);

export const PinIcon = createIcon(path, {
  width: 20,
  height: 20,
  viewBox: '0 0 20 20',
  fill: 'none',
});
