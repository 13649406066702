import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const CustomTab = styled(Tab)(({ theme, isActive }) => ({
  width: 'auto',
  height: '48px',
  borderRadius: '6px',
  border: '0px',
  padding: '12px 16px',
  borderBottom: 'none',

  indicator: {
    display: 'none',
  },
  backgroundColor: isActive ? '#0A275629' : 'inherit',
  color: isActive ? '#16181A' : '#6D7581',
  fontWeight: isActive ? 500 : 500,
  fontSize: isActive ? '14px' : '14px',
  fontFamily: 'Poppins',
  lineHeight: isActive ? '23.8px' : 'normal',
  textTransform: 'capitalize',
}));

const CustomTabs = ({ tabs, value, onChange }) => {
  return (
    <Tabs
      value={value}
      onChange={onChange}
      TabIndicatorProps={{
        style: { display: 'none' },
      }}
      sx={{
        '& .MuiTabs-flexContainer': {
          gap: '20px',
        },
      }}
      textColor="#16181A"
    >
      {tabs.map((tab, index) => (
        <CustomTab key={index} label={tab.label} value={tab.value} isActive={value === tab.value} />
      ))}
    </Tabs>
  );
};

export default CustomTabs;
