import { format } from 'date-fns';

const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';
export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/**
 * @param date - number or date type param to format
 * @param dateFormat - date format to convert the date into - eg. YYYY-MM-DD
 * @returns date as a string in the
 */
export function formatDate(date, dateFormat = DEFAULT_DATE_FORMAT) {
  return format(date, dateFormat);
}

export function getMonthName(monthIndex) {
  if (monthIndex < 0 || monthIndex > 11) {
    return 'Invalid month index';
  }

  return MONTH_NAMES[monthIndex];
}

export function getLastDateOfMonth(dateString) {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = date.getMonth();

  const firstDayNextMonth = new Date(year, month + 1, 1);

  const lastDateOfMonth = new Date(firstDayNextMonth - 1);

  return lastDateOfMonth;
}
