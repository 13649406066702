import { Box, Breadcrumbs, Typography } from '@mui/material';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { ChevronLeftIcon } from '../../../libs/svg-icons/icons';

const Header = ({ breadcrumbs = [], backHref = '', rightActions = null }) => {
  const navigate = useNavigate();

  const theme = useTheme();
  const handleBackClicked = () => {
    if (!!backHref) navigate(backHref);
    else navigate('../');
  };

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '40px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '1.5rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '0.5rem',
        }}
      >
        <Box
          sx={{
            height: '24px',
            width: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={handleBackClicked}
        >
          <ChevronLeftIcon fill={theme.palette.text['100']} />
        </Box>
        <Breadcrumbs aria-label="breadcrumb">
          {breadcrumbs?.map((detail, index) =>
            index === breadcrumbs.length - 1 ? (
              <Typography key={detail.name} variant="SemiBold-18" color={theme.palette.text['100']}>
                {detail.name}
              </Typography>
            ) : (
              <Link
                key={detail.name}
                color="inherit"
                to={detail.href}
                style={{ textDecoration: 'none' }}
              >
                <Typography variant="SemiBold-18" color={theme.palette.text['300']}>
                  {detail.name}
                </Typography>
              </Link>
            ),
          )}
        </Breadcrumbs>
      </Box>
      {rightActions && (
        <Box
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: '1.5rem',
          }}
        >
          {rightActions}
        </Box>
      )}
    </Box>
  );
};

export default Header;
