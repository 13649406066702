import { createTheme } from '@mui/material/styles';
import { themePalette } from './color';
import { themeButtons } from './button';
import { themeTypography } from './typography';
import { themeInputs } from './input';
import { themeTextarea } from './textarea';

const theme = createTheme(themePalette, themeButtons, themeTypography, themeInputs, themeTextarea);

export default theme;
