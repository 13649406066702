import { Box, Typography, Skeleton, CircularProgress } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import UserDetails from '../user';
import { DeleteIcon, EditIcon } from '../../../libs/svg-icons/icons';
import Button from '../buttons';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import InputField from '../form/Input';
import { useAppSelector } from '../../../redux/hooks';
import {
  addComment,
  deleteComment,
  editComment,
  getAllComments,
} from '../../../services/comments-service';
import { AppConstants } from '../../../constants/app-constants';
import { useEnqueueSnackbar } from '../toast-provider/toastHook';
import { capitalize } from '../../../utils/string';
import Dialog from '../dialog';
import { formatDate } from '../../../libs/date/format';

const schema = yup.object().shape({
  editingRemarkId: yup.string(),
  remark: yup
    .string()
    .required('Remark is required')
    .typeError('Remark is required')
    .max(200, 'Remark must not exceed 200 characters')
    .test({
      name: 'is-valid-remark',
      test: value => value.trim().length > 0,
      message: 'Remark is invalid',
    }),
});

const PAGE_SIZE = 3;

const Comments = ({ entityId = null, entityType = null }) => {
  const theme = useTheme();
  const enqueueSnackbar = useEnqueueSnackbar();
  const user = useAppSelector(state => state.auth.user);
  const loggedInUserId = useAppSelector(state => state.auth.loggedInUserId);

  const [comments, setComments] = useState([]);
  const [deletingCommentId, setDeletingCommentId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoader, setIsLoader] = useState(false);

  const fetchComments = useCallback(() => {
    setIsLoading(true);
    getAllComments({
      entityId,
      entityType,
    })
      .then(response => {
        const { data: commentsData } = response.data;
        setComments(commentsData.models);
      })
      .catch(error => {
        const { detail = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
        enqueueSnackbar(detail, 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [enqueueSnackbar, entityId, entityType]);

  useEffect(() => {
    if (entityId && entityType) {
      fetchComments();
    }
  }, [entityType, entityId]);

  const [currentPage, setCurrentPage] = useState(1);

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const handleLoadMore = () => {
    setCurrentPage(prev => prev + 1);
  };

  const handleEditRemark = comment => {
    methods.setValue('editingRemarkId', comment.id);
    methods.setValue('remark', comment.content, {
      shouldValidate: true,
    });
  };

  const handleCancelRemark = () => {
    methods.reset({
      editingRemarkId: undefined,
      remark: '',
    });
  };

  const handleOpenDeleteCommentModal = comment => {
    setDeletingCommentId(comment.id);
  };

  const handleCloseDeleteCommentModal = () => {
    setDeletingCommentId(null);
  };

  const handleDeleteComment = () => {
    handleCancelRemark();

    if (deletingCommentId) {
      deleteComment({
        commentId: deletingCommentId,
      })
        .then(() => fetchComments())
        .catch(error => {
          const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
          enqueueSnackbar(message, 'error');
        })
        .finally(() => handleCloseDeleteCommentModal());
    }
  };

  const handleEditAddRemarks = data => {
    if ((loggedInUserId, entityId, entityType)) {
      setIsLoader(true);
      if (methods.watch('editingRemarkId')) {
        const payload = {
          content: data.remark,
          entityId: entityId,
          entityType: entityType,
        };
        editComment(
          {
            commentId: methods.watch('editingRemarkId'),
          },
          payload,
        )
          .then(() => fetchComments())
          .then(() => {
            methods.reset({
              editingRemarkId: undefined,
              remark: '',
            });
          })
          .catch(error => {
            const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
            enqueueSnackbar(message, 'error');
          })
          .finally(() => setIsLoader(false));
      } else {
        const payload = {
          content: data.remark,
          commenterId: loggedInUserId,
          entityId: entityId,
          entityType: entityType,
          commentStatus: 'ACTIVE',
          isEdited: false,
          modifiedBy: null,
        };
        addComment(payload)
          .then(() => fetchComments())
          .then(() => {
            methods.reset({
              editingRemarkId: undefined,
              remark: '',
            });
          })
          .catch(error => {
            const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
            enqueueSnackbar(message, 'error');
          })
          .finally(() => setIsLoader(false));
      }
    }
  };

  return (
    <>
      {/* <Loader isLoading={isLoader} /> */}
      <Box
        sx={{
          marginTop: '16px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          gap: '24px',
        }}
      >
        <Box
          sx={{
            marginTop: '16px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            gap: '20px',
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
                gap: '20px',
              }}
            >
              <Box
                sx={{
                  width: '375px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '10px',
                }}
              >
                <Skeleton variant="rectangular" width="12%" height="38px" />
                <Skeleton variant="rectangular" width="40%" height="38px" />
                <Skeleton variant="rectangular" width="48%" height="38px" />
              </Box>
              <Box sx={{ width: '100%' }}>
                <Skeleton variant="rectangular" width="90%" height="38px" />
              </Box>
            </Box>
          ) : comments.length > 0 ? (
            comments?.slice(0, currentPage * PAGE_SIZE).map(comment => (
              <Box
                key={comment.id}
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  gap: '20px',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      gap: '10px',
                      width: 'calc(100% - 76px)',
                    }}
                  >
                    <UserDetails
                      fullName={capitalize(comment.commenterName)}
                      fullNameColor={theme.palette.text['100']}
                      initials={capitalize(comment.commenterName)
                        .split(' ')
                        .map(x => x[0])}
                      initialsBackgroundColor={theme.palette.primary.main}
                      initialsColor={theme.palette.white}
                      sx={{
                        width: 'fit-content',
                      }}
                      showFullName={true}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: '10px',
                        marginTop: '5px',
                      }}
                    >
                      <Typography
                        variant="Medium-14"
                        lineHeight="117%"
                        color={theme.palette.text['400']}
                      >
                        {formatDate(comment.updatedAt, 'dd/MM/yyyy')}
                      </Typography>
                      <Box
                        sx={{
                          width: '4px',
                          height: '4px',
                          background: theme.palette.primary.main,
                          borderRadius: '100%',
                        }}
                      />
                      <Typography
                        variant="Medium-14"
                        lineHeight="117%"
                        color={theme.palette.text['400']}
                      >
                        {formatDate(comment.updatedAt, 'hh:mm a')}
                      </Typography>
                    </Box>
                  </Box>
                  {loggedInUserId === comment.commenterId ? (
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
                      <Button
                        key="edit"
                        width="30px"
                        height="30px"
                        sx={{
                          minWidth: '21px',
                          padding: '5px',
                        }}
                        variant="outlined"
                        onClick={() => handleEditRemark(comment)}
                        startIcon={
                          <Box
                            component={'span'}
                            sx={{
                              width: '14px',
                              height: '16px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <EditIcon width={14} height={16} fill={theme.palette.primary.main} />
                          </Box>
                        }
                      />
                      <Button
                        key="delete"
                        width="30px"
                        height="30px"
                        onClick={() => handleOpenDeleteCommentModal(comment)}
                        sx={{
                          minWidth: '21px',
                          padding: '5px',
                        }}
                        variant="outlined"
                        startIcon={
                          <Box
                            component={'span'}
                            sx={{
                              width: '18px',
                              height: '18px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <DeleteIcon fill={theme.palette.primary.main} />
                          </Box>
                        }
                      />
                      <Dialog
                        open={deletingCommentId && deletingCommentId > -1}
                        onClose={handleCloseDeleteCommentModal}
                        icon={
                          <Box
                            sx={{
                              width: '52px',
                              height: '52px',
                              borderRadius: '50%',
                              background: theme.palette.background,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              margin: '0 auto',
                            }}
                          >
                            <DeleteIcon fill="var(--deep-navy)" width="32px" height="32px" />
                          </Box>
                        }
                        headline={<Typography variant="SemiBold-22">Delete Remark</Typography>}
                        description={
                          <Typography variant="Regular-16">
                            Are you sure you want to delete your remark? This action can’t be undone
                          </Typography>
                        }
                        buttons={[
                          <Button
                            key="button1"
                            width="176px"
                            variant="outlined"
                            label="Cancel"
                            onClick={handleCloseDeleteCommentModal}
                          />,
                          <Button
                            type="submit"
                            key="button2"
                            width="176px"
                            variant="contained"
                            label="Delete"
                            onClick={handleDeleteComment}
                          />,
                        ]}
                        width="420px"
                        headlineAlignment="center"
                      />
                    </Box>
                  ) : null}
                </Box>
                <Box sx={{ width: '100%' }}>
                  <Typography
                    variant="Regular-14"
                    sx={{ wordWrap: 'break-word', wordBreak: 'break-all' }}
                    color={theme.palette.text['200']}
                  >
                    {comment.content}
                  </Typography>
                </Box>
              </Box>
            ))
          ) : null}
          {currentPage * PAGE_SIZE < comments.length ? (
            <Box sx={{ cursor: 'pointer' }} onClick={handleLoadMore}>
              <Typography variant="Medium-16" color={theme.palette.primary.light}>
                Load More...
              </Typography>
            </Box>
          ) : null}
        </Box>
        {isLoading ? null : (
          <Box sx={{ width: '100%' }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(handleEditAddRemarks)}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '30px',
                  }}
                >
                  <Box sx={{ width: '100%' }}>
                    <InputField
                      id="remarks"
                      inputHeight="48px"
                      type="text"
                      label={`Add Remarks as ${user.name}`}
                      placeholder="Write your remarks here"
                      {...methods.register('remark')}
                    />
                  </Box>
                  <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '20px' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      label={
                        isLoader ? (
                          <CircularProgress
                            color="inherit"
                            style={{ height: '20px', width: '20px' }}
                          />
                        ) : (
                          'Submit'
                        )
                      }
                      disabled={!methods.formState.isValid || isLoader}
                      sx={{ width: '91px', height: '40px' }}
                    />
                    {methods.watch('remark') && methods.watch('remark').trim().length > 0 && (
                      <Button
                        type="button"
                        variant="outlined"
                        label="Cancel"
                        onClick={handleCancelRemark}
                        sx={{ width: '91px', height: '40px' }}
                      />
                    )}
                  </Box>
                </Box>
              </form>
            </FormProvider>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Comments;
