import { Box, Typography } from '@mui/material';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import Input from '../form/Input';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Table from '../table';
import Button from '../buttons';

const taskSchema = yup.object().shape({
  task: yup.string().required('Required'),
});

const SelectTaskToReset = () => {
  const selectTaskToResetRef = useRef(null);
  const [isChecked, setIsChecked] = useState(false);
  const [gridApi, setGridApi] = useState(null);

  const taskMethods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(taskSchema),
    defaultValues: {
      task: '',
    },
  });

  const columns = useMemo(
    () => [
      {
        colId: 'checkbox',
        field: '',
        checkboxSelection: true,
        cellStyle: paraps => ({
          padding: '18px 16px',
        }),
        minWidth: 50,
        width: 50,
        maxWidth: 50,
      },
      {
        colId: 'taskid',
        field: 'taskid',
        headerName: 'Task ID',
        minWidth: 110,
        width: 110,
        cellStyle: paraps => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }),
      },
      {
        colId: 'taskname',
        field: 'taskname',
        headerName: 'Task Name',
        minWidth: 486,
        width: 486,
        cellStyle: paraps => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }),
      },
    ],
    [],
  );

  const data = useMemo(
    () => [
      {
        id: 1,
        taskid: 'LBAUG 1',
        taskname: 'Media Plan Submission',
      },
      {
        id: 2,
        taskid: 'LBAUG 1',
        taskname: 'Media Plan Submission',
      },
      {
        id: 3,
        taskid: 'LBAUG 1',
        taskname: 'Media Plan Submission',
      },
      {
        id: 4,
        taskid: 'LBAUG 1',
        taskname: 'Media Plan Submission',
      },
      {
        id: 5,
        taskid: 'LBAUG 1',
        taskname: 'Media Plan Submission',
      },
      {
        id: 6,
        taskid: 'LBAUG 1',
        taskname: 'Media Plan Submission',
      },
      {
        id: 7,
        taskid: 'LBAUG 1',
        taskname: 'Media Plan Submission',
      },
      {
        id: 8,
        taskid: 'LBAUG 1',
        taskname: 'Media Plan Submission',
      },
      {
        id: 9,
        taskid: 'LBAUG 1',
        taskname: 'Media Plan Submission',
      },
      {
        id: 10,
        taskid: 'LBAUG 1',
        taskname: 'Media Plan Submission',
      },
      {
        id: 11,
        taskid: 'LBAUG 1',
        taskname: 'Media Plan Submission',
      },
      {
        id: 12,
        taskid: 'LBAUG 1',
        taskname: 'Media Plan Submission',
      },
      {
        id: 13,
        taskid: 'LBAUG 1',
        taskname: 'Media Plan Submission',
      },
      {
        id: 14,
        taskid: 'LBAUG 1',
        taskname: 'Media Plan Submission',
      },
      {
        id: 15,
        taskid: 'LBAUG 1',
        taskname: 'Media Plan Submission',
      },
      {
        id: 16,
        taskid: 'LBAUG 1',
        taskname: 'Media Plan Submission',
      },
      {
        id: 17,
        taskid: 'LBAUG 1',
        taskname: 'Media Plan Submission',
      },
      {
        id: 18,
        taskid: 'LBAUG 1',
        taskname: 'Media Plan Submission',
      },
      {
        id: 19,
        taskid: 'LBAUG 1',
        taskname: 'Media Plan Submission',
      },
      {
        id: 20,
        taskid: 'LBAUG 1',
        taskname: 'Media Plan Submission',
      },
    ],
    [],
  );
  const defaultColDef = useMemo(
    () => ({
      resizable: false,
      editable: false,
      flex: 1,
    }),
    [],
  );

  const onGridReady = useCallback(params => {
    setGridApi(params.api);
    params.api.addEventListener('selectionChanged', () => {
      const selectedNodes = params.api.getSelectedNodes();
      setIsChecked(selectedNodes.length > 0);
    });
  }, []);
  const TaskTable = useMemo(
    () => (
      <Table
        ref={selectTaskToResetRef}
        columns={columns}
        defaultColDef={defaultColDef}
        data={data}
        rowSelection={'multiple'}
        onGridReady={onGridReady}
        height={`calc(5 * 2.75rem + 163px)`}
      />
    ),
    [columns, data, defaultColDef],
  );
  return (
    <Box
      sx={{
        display: 'flex',
        marginTop: '0px',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: '24px',
      }}
    >
      <Typography variant="Regular-16" sx={{ color: '#3A3E45', marginTop: '10px' }}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      </Typography>

      <FormProvider {...taskMethods}>
        <Box sx={{ width: '396px' }}>
          <Input
            id="task"
            inputHeight="48px"
            type="text"
            placeholder="Search any task by keyword or task ID"
            searchable
          />
        </Box>
        <Box sx={{ width: '100%' }}>{TaskTable}</Box>
      </FormProvider>
      <Box
        sx={{ width: '100%', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}
      >
        <Button label="Reset Task" variant="contained" disabled={!isChecked} />
      </Box>
    </Box>
  );
};

export default SelectTaskToReset;
