import RouteHandler from '../../../components/router/handler';
import { PermissionsConstants } from '../../../constants/permissions-constants';
import ProjectDetails from './ProjectDetails';
import TaskDetails from './ProjectDetails/TaskDetails';
import SubtaskDetails from './ProjectDetails/TaskDetails/SubtaskDetails';
import ProjectsList from './ProjectsList';

const ProjectsRoutes = [
  {
    index: true,
    path: '',
    component: <ProjectsList />,
    // permissions: [PermissionsConstants.READ_PROJECT],
  },
  {
    path: ':id/view-project',
    component: <ProjectDetails />,
    // permissions: [PermissionsConstants.READ_PROJECT],
  },
  {
    path: ':id/view-project/:taskId',
    component: <TaskDetails />,
    // permissions: [PermissionsConstants.READ_PROJECT_TASK],
  },
  {
    path: ':id/view-project/:taskId/:subtaskId',
    component: <SubtaskDetails />,
    // permissions: [PermissionsConstants.READ_PROJECT_SUBTASK],
  },
];

const ProjectsRouter = () => {
  return <RouteHandler routes={ProjectsRoutes} />;
};

export default ProjectsRouter;
