import React from 'react';
import { Typography, Box, Skeleton } from '@mui/material';
import UserDetails from '../user';
import { themePalette } from '../../../constants/theme/color';

const RequestDialogCard = ({ headline, description, ManagerName, style }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Box
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          gap: '8px',
          width: '620px',
        }}
      >
        <Typography variant="Medium-18" alignItems="flex-start" justifyContent="flex-start">
          {headline}
        </Typography>

        <Typography
          style={style}
          variant="Regular-14"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          {description}
        </Typography>

        <Box
          sx={{
            height: '58px',
            width: '100%',
            flexGrow: 1,
            border: '1px solid #CDD1D7',
            padding: '13px 16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          {ManagerName ? (
            <UserDetails
              sx={{ width: '100%' }}
              fullName={ManagerName}
              fullNameColor={themePalette.palette.text['100']}
              initials={ManagerName?.split(' ').map(x => x[0])}
              initialsBackgroundColor={themePalette.palette.primary.main}
              initialsColor={themePalette.palette.white}
              initialsVariant="SemiBold-12"
              initailsContainer="32px"
              fullNameVariant="Medium-14"
            />
          ) : (
            <Skeleton variant="rectangular" width="80%" height="38px" sx={{ margin: '0 15px' }} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default RequestDialogCard;
