import React, { useEffect, useMemo, useState } from 'react';
import Card from '../../../../components/shared/card';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { UploadIcon } from '../../../../libs/svg-icons/icons';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import DragAndDropFilePicker from '../../../../components/shared/form/fileupload';
import Button from '../../../../components/shared/buttons';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../../components/shared/header';
import InputField from '../../../../components/shared/form/Input';
import SelectField from '../../../../components/shared/form/Select';
import DatePicker from '../../../../components/shared/form/date-picker';
import { read, utils } from 'xlsx';
import MediaPlanDetailsHeader from '../MediaPlanDetailsHeader.json';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { createMediaPlanActions } from '../../../../redux/slice/createMediaPlan';
import dayjs from 'dayjs';
import {
  createMediaPlan,
  getCreateMediaPlanMasterData,
} from '../../../../services/media-plan-service';
import { useEnqueueSnackbar } from '../../../../components/shared/toast-provider/toastHook';
import Loader from '../../../../components/shared/loader';
import { AppConstants } from '../../../../constants/app-constants';
import { PermissionsConstants } from '../../../../constants/permissions-constants';
import { useHasAllPermission } from '../../../../hooks/permissions';
import { formatDate } from '../../../../libs/date/format';

const schema = yup.object().shape({
  method: yup.string(),
  client: yup.string().required('Client is required'),
  brand: yup.string().required('Brand is required'),
  type: yup.string().required('Type is required'),
  category: yup.string().required('Category is required'),
  businessUnit: yup.string().required('Business unit is required'),
  mediaPlanName: yup.string().required('Media Plan Name is required'),
  startDate: yup.object().required('Start Date is required'),
  endDate: yup.object().required('End Date is required'),
  mediaPlanTemplate: yup.mixed().test({
    name: 'is-required',
    message: 'Media Plan Template is required.',
    test: (val, ctx) => (ctx.parent.method === 'upload' ? !!val : true),
  }),
});

export const getHeaderNameFromParams = method => {
  if (method === 'template') return 'Via Template';
  if (method === 'upload') return 'Via Upload Media Plan';
};

const MediaPlanCreate = () => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const enqueueSnackbar = useEnqueueSnackbar();
  const hasAllPermissions = useHasAllPermission();

  const brands = useMemo(() => ['Lifebuoy', `Wall's`, 'Cornetto', 'Dove'], []);
  const years = useMemo(() => ['2023', '2022', '2021', '2020', '2019', '2018'], []);
  const types = useMemo(() => ['Brand', 'Commerce', 'Search'], []);
  const category = useMemo(() => ['Beauty & Wellbeing', 'Beauty', 'Wellbeing'], []);
  const businessUnit = useMemo(() => ['Hair Care', 'Skin Care'], []);

  const [masterData, setMasterData] = useState([]);
  const [mediaPlanTypes, setMediaPlanTypes] = useState([]);
  const [bussiness_unit, setBussinesUnit] = useState({
    businessUnit: '',
    businessUnitId: '',
  });

  const createMediaPLanPayload = useAppSelector(store => store.createMediaPlan);

  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      method: params.method,
      client: 'Unilever',
      startDate: null,
      endDate: null,
    },
  });

  const watchValues = methods.watch();

  const handleMediaPlanTemplateUpload = file => {};

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      await getMediaPlanUserMasterData();
    };

    init();
  }, []);

  useEffect(() => {
    const { formData = null, mediaPlanTemplate } = createMediaPLanPayload;
    if (formData) {
      const { brand = null, type, category, startDate = null, endDate } = formData;
      if (brand && startDate) {
        methods.setValue('brand', brand, { shouldValidate: true });
        methods.setValue('businessUnit', bussiness_unit.businessUnit, { shouldValidate: true });
        methods.setValue('category', category, { shouldValidate: true });
        methods.setValue('type', type, { shouldValidate: true });
        methods.setValue('startDate', dayjs(startDate), { shouldValidate: true });
        methods.setValue('endDate', dayjs(endDate), { shouldValidate: true });
        // methods.setValue('mediaPlanTemplate', mediaPlanTemplate, {
        //   shouldValidate: true,
        // });
      }
    }
  }, [createMediaPLanPayload]);

  const handleMediaPlanTemplateRemoved = () => {
    methods.setValue('mediaPlanTemplate', undefined, {
      shouldValidate: true,
    });
  };

  const createMediaPlanCall = async payload => {
    await createMediaPlan(payload)
      .then(response => {
        enqueueSnackbar('Media plan created successfully.', 'success');
        setIsLoading(false);
        navigate('/app/media-plans');
      })
      .catch(error => {
        const { response = null } = error;
        if (response) {
          const { message = AppConstants.SOMETHING_WENT_WRONG } = response?.data || {};
          enqueueSnackbar(message, 'error');
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (
      watchValues.client &&
      watchValues.type &&
      watchValues.businessUnit &&
      watchValues.category &&
      watchValues.brand &&
      watchValues.startDate
    ) {
      const year = new Date(watchValues.startDate).getFullYear().toString();
      const mediaPlanName = `${watchValues.client} Media Plan_${year}_${watchValues.type}_${watchValues.category}_${watchValues.businessUnit}_${watchValues.brand}`;
      methods.setValue('mediaPlanName', mediaPlanName, {
        shouldValidate: true,
      });
    }
  }, [
    watchValues.client,
    watchValues.brand,
    watchValues.type,
    watchValues.category,
    watchValues.businessUnit,
    watchValues.startDate,
  ]);

  const getMaxDate = date => {
    date = new Date(date);
    const year = date.getFullYear();
    const nextYearDate = new Date(year + 1, 0, 1);
    const lastDayOfYear = new Date(nextYearDate - 1);

    return dayjs(lastDayOfYear);
  };

  const handleStartDateChanged = e => {
    const endDate = methods.getValues('endDate');
    if (endDate) {
      if (e.$M === endDate.$M && e.$y === endDate.$y) {
        return;
      } else {
        methods.setValue('endDate', null);
      }
    }
  };

  const onSubmit = data => {
    if (hasAllPermissions([PermissionsConstants?.CREATE_MEDIA_PLAN])) {
      let _data = data;
      const payload = {
        clientId: 1,
        brandId: getBrands(data?.brand),
        categoryId: getCategory(data?.category),
        bussinesUnitId: bussiness_unit.businessUnitId,
        mediaPlanTypeId: getMediaPlanId(data?.type),
        name: data?.mediaPlanName,
        year: new Date(data?.startDate || '').getFullYear(),
        startDate: `${formatDate(new Date(data?.startDate), 'yyyy-MM-dd')}T00:00:00Z`,
        endDate: `${formatDate(new Date(data?.endDate), 'yyyy-MM-dd')}T00:00:00Z`,
        mediaPlanTemplate: data?.mediaPlanTemplate,
      };

      if (payload?.mediaPlanTemplate) {
        const reader = new FileReader();
        reader.onload = function (event) {
          const data = event.target.result;
          const workbook = read(data, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const headers = [];
          const range = utils.decode_range(sheet['!ref']);

          for (let C = range.s.c; C <= range.e.c; ++C) {
            const cell = sheet[utils.encode_cell({ r: range.s.r, c: C })];
            headers.push(cell ? cell.v : undefined);
          }
          if (headers.length > 0) {
            const availableHeadersFromFile = headers.map(x => x.trim());
            let tempMap = [];
            const unmappedHeader = availableHeadersFromFile.reduce((a, x) => {
              const mappedColumn = MediaPlanDetailsHeader.find(h => h.label === x);
              if (x && mappedColumn) {
                tempMap.push({
                  'label': x,
                  'isMapped': true,
                  'mappedWith': mappedColumn.value,
                });
              }
              // if (x && MediaPlanDetailsHeader.find(h => h.label === x)) return a;
              return [...a, x];
            }, []);

            const unmatchedHeader = MediaPlanDetailsHeader.reduce((a, x) => {
              // if (x?.label && availableHeadersFromFile.find(h => h === x.label)) return a;
              return [...a, x.value];
            }, []);

            let temp = {
              ...payload,
              formData: {
                brand: _data?.brand,
                category: _data?.category,
                type: _data?.type,
                startDate: _data?.startDate,
                endDate: _data?.endDate,
              },
              unmappedHeader: unmappedHeader,
              unmatchedHeader: unmatchedHeader,
              mappedColumn: createMediaPLanPayload.mappedColumn
                ? createMediaPLanPayload.mappedColumn
                : tempMap,
            };

            dispatch(createMediaPlanActions.create(temp));

            if (unmappedHeader && unmappedHeader.length > 0) {
              navigate('map-columns');
            } else {
              enqueueSnackbar(AppConstants.SOMETHING_WENT_WRONG, 'error');
            }
          }
        };
        reader.readAsBinaryString(payload?.mediaPlanTemplate);
      } else {
        setIsLoading(true);
        createMediaPlanCall(payload);
      }
    }
  };

  const getMediaPlanUserMasterData = async () => {
    await getCreateMediaPlanMasterData()
      .then(response => {
        const jsonData = response.data;
        if (jsonData) {
          const {
            businessUnit,
            businessUnitId,
            mediaPlanType = [],
            child = [],
          } = jsonData?.data || {};
          setMediaPlanTypes(mediaPlanType);
          setMasterData(child);
          setBussinesUnit({
            businessUnit,
            businessUnitId,
          });
          methods.setValue('businessUnit', businessUnit, {
            shouldValidate: true,
          });
        }
        setIsLoading(false);
      })
      .catch(error => {
        enqueueSnackbar(AppConstants.SOMETHING_WENT_WRONG, 'error');
        setIsLoading(false);
      });
  };

  const getCategory = (data = null) => {
    let cats = [];
    let catId = '';
    masterData.forEach(ele => {
      if (ele.type === 'category') {
        if (data && ele.name === data) {
          catId = ele.id;
          return catId;
        }
        cats.push(ele);
      }
    });

    return data ? catId : cats;
  };

  const getBrands = (data = null) => {
    let brandsOpt = [];
    let brandId = '';
    masterData.forEach(ele => {
      if (ele.type === 'category' && ele.name === watchValues.category) {
        ele.child.forEach(brd => {
          if (brd.type === 'brand') {
            if (data && brd.name === data) {
              brandId = brd.id;
              return brandId;
            }
            brandsOpt.push(brd);
          }
        });
      }
    });

    return data ? brandId : brandsOpt;
  };

  const getMediaPlanId = data => {
    let id = '';
    mediaPlanTypes.forEach(ele => {
      if (ele.name === data) {
        id = ele.id;
        return id;
      }
    });
    return id;
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Card
        sx={{
          minHeight: 'calc(100vh - 130px)',
          width: '100%',
          alignItems: 'revert-layer',
          gap: '40px',
          justifyContent: 'flex-start',
        }}
      >
        <Header
          breadcrumbs={[{ name: `Create Media Plan ${getHeaderNameFromParams(params.method)}` }]}
          backHref="/app/media-plans"
        />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: 'flex',
                maxWidth: '812px',
                flexDirection: 'column',
                gap: '24px',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '20px',
                }}
              >
                <Box sx={{ width: '50%' }}>
                  <InputField
                    id="client-input"
                    type="text"
                    label="Client"
                    placeholder="Placeholder Text"
                    disabled
                    {...methods.register('client')}
                  />
                </Box>

                <Box sx={{ width: '50%' }}>
                  <SelectField
                    id="type-filter"
                    options={mediaPlanTypes}
                    label="Type"
                    placeholder="Select type"
                    optionMapFunction={options =>
                      options?.map(x => ({ id: x.id, label: x.name, value: x.name }))
                    }
                    {...methods.register('type')}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '20px',
                }}
              >
                <Box sx={{ width: '50%' }}>
                  <SelectField
                    id="businessUnit-filter"
                    options={[bussiness_unit]}
                    label="Business Unit"
                    placeholder="Select business unit"
                    errors=" Business Unit is required"
                    disabled={true}
                    optionMapFunction={options =>
                      options?.map(x => ({
                        id: x.businessUnitId,
                        label: x.businessUnit,
                        value: x.businessUnit,
                      }))
                    }
                    {...methods.register('businessUnit')}
                  />
                </Box>

                <Box sx={{ width: '50%' }}>
                  <SelectField
                    id="category-filter"
                    options={getCategory() || []}
                    label="Category"
                    placeholder="Select category"
                    errors="Category is required"
                    disabled={!watchValues.type}
                    optionMapFunction={options =>
                      options?.map(x => ({ id: x.id, label: x.name, value: x.name }))
                    }
                    {...methods.register('category')}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '20px',
                }}
              >
                <Box sx={{ width: '50%' }}>
                  <SelectField
                    id="brand-filter"
                    options={getBrands()}
                    label=" Brand"
                    placeholder="Select brand"
                    errors="Brand  is required"
                    disabled={!watchValues.category}
                    optionMapFunction={options =>
                      options?.map(x => ({ id: x.id, label: x.name, value: x.name }))
                    }
                    {...methods.register('brand')}
                  />
                </Box>
                <Box sx={{ width: '50%' }}>
                  <DatePicker
                    id="start-date-picker"
                    label="Start Date"
                    placeholder="Enter start date"
                    errors="Start Date is required"
                    views={['year']}
                    {...methods.register('startDate')}
                    onChange={handleStartDateChanged}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '20px',
                }}
              >
                <Box sx={{ width: '50%' }}>
                  <DatePicker
                    id="start-date-picker"
                    label="End Date"
                    placeholder="Enter start date"
                    errors="Start Date is required"
                    views="MM/DD"
                    minDate={watchValues.startDate}
                    maxDate={watchValues.startDate ? getMaxDate(watchValues.startDate) : null}
                    disabled={!watchValues.startDate}
                    {...methods.register('endDate')}
                    onChange={() => null}
                  />
                </Box>
                <Box sx={{ width: '50%' }}></Box>
              </Box>

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                }}
              >
                <Box sx={{ width: '100%' }}>
                  <InputField
                    id="mediaplan-input"
                    type="text"
                    label="Media Plan Name"
                    disabled
                    {...methods.register('mediaPlanName')}
                  />
                </Box>
              </Box>
              {params.method === 'upload' && (
                <Box sx={{ width: '396px', display: 'flex', gap: '4px', flexDirection: 'column' }}>
                  <Typography variant="Medium-16">Upload Media Plan Template</Typography>
                  <DragAndDropFilePicker
                    multi={false}
                    icon={<UploadIcon fill={theme.palette.primary.main} />}
                    headline="Upload Media Plan Template"
                    description="(Only .xlsx and .xlsm file, maximum 20MB file size)"
                    onUpload={handleMediaPlanTemplateUpload}
                    onRemove={handleMediaPlanTemplateRemoved}
                    acceptedfileExtensions={['xlsx', 'xlsm']}
                    {...methods.register('mediaPlanTemplate')}
                  />
                </Box>
              )}

              <Box>
                <Button
                  type="submit"
                  variant="contained"
                  label="Create Media Plan"
                  disabled={!methods.formState.isValid}
                  sx={{ width: '396px', height: '50px' }}
                />
              </Box>
            </Box>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default MediaPlanCreate;
