import React, { useEffect, useState } from 'react';
import Button from '../buttons';
import Dialog from '../dialog';
import { Box, Divider, Typography } from '@mui/material';
import { InvoiceIcon } from '../../../libs/svg-icons/icons';
import { useTheme } from '@emotion/react';
import { formatDate } from '../../../libs/date/format';
import { formatCurrency } from '../../../utils/string';

const ViewMaterial = ({ materialData = {} }) => {
  const [openViewInvoiceDialog, setOpenViewInvoiceDailog] = useState(false);
  const theme = useTheme();
  const [data, setData] = useState([]);

  const handleOpenViewInvoiceDailog = () => {
    setOpenViewInvoiceDailog(true);
  };

  const handleCloseViewInvoiceDailog = () => {
    setOpenViewInvoiceDailog(false);
  };

  const detailBoxStyle = {
    display: 'flex',
    width: '20%',
    gap: '4px',
    alignItems: 'flex-start',
    flexDirection: 'column',
  };

  const getDateFromStr = str => {
    const givenDate = `${str}`;

    const year = givenDate.substring(0, 4);
    const month = givenDate.substring(4, 6);
    const day = givenDate.substring(6, 8);

    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    if (materialData) {
      let temp = [];
      let materials = Object.keys(materialData);
      if (materials.length) {
        materials.forEach(ele => {
          const [dd] = materialData[ele]?.seperateBudget || [];
          const { budget = '', grp = '-', rfvalue = '-', rf = '-', groupGRP = '-' } = dd || {};
          // [1, 2, 3, 4, 5, 6, 7].forEach(e => {
          temp.push({
            ...materialData[ele],
            startDate: materialData[ele].startDate
              ? getDateFromStr(materialData[ele].startDate)
              : '-',
            endDate: materialData[ele].endDate ? getDateFromStr(materialData[ele].endDate) : '-',
            budget: budget ? formatCurrency(budget) : '-',
            grp,
            rf,
            rfvalue,
            groupGRP,
          });
          // });
        });
      }
      setData(temp);
    }
  }, [materialData]);

  return (
    <>
      <Button
        label="View Material"
        styles={{ margin: 'auto' }}
        variant="outlined"
        height="30px"
        onClick={handleOpenViewInvoiceDailog}
      />

      <Dialog
        open={openViewInvoiceDialog}
        onClose={handleCloseViewInvoiceDailog}
        headline={<Typography variant="SemiBold-22">Materials</Typography>}
        description={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              width: '100%',
              flexDirection: 'column',
              gap: '10px',
              minHeight: '200px',
            }}
          >
            {data.length === 0 && (
              <>
                <Typography variant="Regular-14" style={{ margin: 'auto' }}>
                  There are no materials
                </Typography>
              </>
            )}
            {data.map((item, index) => {
              const flag = index % 2 === 0;
              return (
                <Box
                  sx={{
                    flexWrap: 'wrap',
                    gap: '4px',
                    width: '100%',
                    border: '1px solid #CDD1D7',
                    padding: '12px',
                    borderRadius: '8px',
                    background: flag ? '' : '#F4F4F4',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '20px',
                      flexDirection: 'row',
                      width: '100%',
                      flex: '1 1 30%',
                    }}
                  >
                    <Box sx={detailBoxStyle}>
                      <Typography variant="Medium-16">Material Name</Typography>
                      <Typography variant="Regular-14">{item?.material || '-'}</Typography>
                    </Box>

                    <Box sx={detailBoxStyle}>
                      <Typography variant="Medium-16">Material ID</Typography>
                      <Typography variant="Regular-14">{item?.materialId || '-'}</Typography>
                    </Box>

                    <Box sx={detailBoxStyle}>
                      <Typography variant="Medium-16">Size</Typography>
                      <Typography variant="Regular-14">{item?.size || '-'}</Typography>
                    </Box>

                    <Box sx={detailBoxStyle}>
                      <Typography variant="Medium-16">Start Date</Typography>
                      <Typography variant="Regular-14">{item?.startDate || '-'}</Typography>
                    </Box>

                    <Box sx={detailBoxStyle}>
                      <Typography variant="Medium-16">End Date</Typography>
                      <Typography variant="Regular-14">{item?.endDate || '-'}</Typography>
                    </Box>
                  </Box>

                  <div
                    style={{
                      width: '100%',
                      textAlign: 'left',
                      padding: '6px',
                      borderRadius: '8px',
                      backgroundColor: flag ? '#F4F4F4' : '#fff',
                      gap: '10px',
                      margin: '16px 0px',
                    }}
                  >
                    <Typography variant="Medium-16">Seperate Budget</Typography>
                  </div>

                  <Box
                    sx={{
                      display: 'flex',
                      gap: '20px',
                      flexDirection: 'row',
                      width: '100%',
                      flex: '1 1 30%',
                    }}
                  >
                    <Box sx={detailBoxStyle}>
                      <Typography variant="Medium-16">Budget</Typography>
                      <Typography variant="Regular-14">{item?.budget || '-'}</Typography>
                    </Box>

                    <Box sx={detailBoxStyle}>
                      <Typography variant="Medium-16">GRP</Typography>
                      <Typography variant="Regular-14">{item?.grp || '-'}</Typography>
                    </Box>

                    <Box sx={detailBoxStyle}>
                      <Typography variant="Medium-16">GroupGRP</Typography>
                      <Typography variant="Regular-14">{item?.groupGRP || '-'}</Typography>
                    </Box>

                    <Box sx={detailBoxStyle}>
                      <Typography variant="Medium-16">RFValue</Typography>
                      <Typography variant="Regular-14">{item?.rfvalue || '-'}</Typography>
                    </Box>

                    <Box sx={detailBoxStyle}>
                      <Typography variant="Medium-16">RF</Typography>
                      <Typography variant="Regular-14">{item?.rf || '-'}</Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        }
        width="1120px"
        showDialogActions={false}
        fullBodyScroll={true}
      />
    </>
  );
};

export default ViewMaterial;
