import { alpha, createTheme } from '@mui/material';
import { themePalette } from './color';

export const themeButtons = createTheme(themePalette, {
  components: {
    MuiButton: {
      disableProps: {
        disableElevation: true,
        variant: 'contained',
        color: 'primary',
      },
      styleOverrides: {
        root: {
          borderRadius: '4px',
          textTransform: 'none',
          fontFamily: 'Poppins',
          fontSize: '0.875rem',

          height: '40px',
          '&.Mui-disabled': {
            backgroundColor: alpha(themePalette.palette.primary.main, 0.5),
            color: themePalette.palette.text['600'],
            '&.MuiButton-outlined': {
              backgroundColor: themePalette.palette.white,
              color: alpha(themePalette.palette.primary.main, 0.5),
            },
          },
        },
      },
    },

    variants: [
      {
        props: { variant: 'contained' },
        style: {
          color: 'white',
          backgroundColor: themePalette.palette.primary,
        },
      },
      {
        props: { variant: 'outlined' },
        style: {
          color: themePalette.palette.primary,
          backgroundColor: 'white',
        },
      },
      {
        props: { variant: 'iconOnly' },
        style: {
          minWidth: 0,
          padding: '6px',
          borderRadius: '50%',
          backgroundColor: 'transparent',
          '& .MuiButton-label': {
            visibility: 'hidden',
          },
          '& .MuiButton-startIcon, & .MuiButton-endIcon': {
            margin: 0,
          },
        },
      },
    ],
  },
});
