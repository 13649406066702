import { Box, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from '../../../../components/shared/buttons';
import Card from '../../../../components/shared/card';
import { useNavigate } from 'react-router-dom';
import Table from '../../../../components/shared/table';
import UserDetails from '../../../../components/shared/user';
import { useTheme } from '@mui/material/styles';
import Chip from '../../../../components/shared/chip';
import {
  AddIcon,
  UploadMediaPlan,
  TemplateIcon,
  DeleteIcon,
} from '../../../../libs/svg-icons/icons';
import Input from '../../../../components/shared/form/Input/input';
import Select from '../../../../components/shared/form/Select/select';
import { formatDate } from '../../../../libs/date/format';
import EmptyTable from '../../../../components/shared/table/EmptyTable';
import CLickableCard from '../../../../components/shared/clickable-card';
import Dialog from '../../../../components/shared/dialog';
import { debounce, sortBy } from 'lodash';
import { getAllBrand, listMediaPlan } from '../../../../services/media-plan-service';
import OverflowTooltip from '../../../../components/shared/tooltip/OverflowTooltip';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { createMediaPlanActions } from '../../../../redux/slice/createMediaPlan';
import { useAuth } from 'react-oidc-context';
import { capitalize, formatCurrency } from '../../../../utils/string';
import { useEnqueueSnackbar } from '../../../../components/shared/toast-provider/toastHook';
import Loader from '../../../../components/shared/loader';
import { AppConstants } from '../../../../constants/app-constants';
import { useHasAllPermission } from '../../../../hooks/permissions';
import { PermissionsConstants } from '../../../../constants/permissions-constants';
import { getUniqueNamesWithIds } from '../../../../utils/project';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import DatePicker from '../../../../components/shared/form/date-picker';
import dayjs from 'dayjs';
import { notificationActions } from '../../../../redux/slice';
import { getNotificationCount } from '../../../../services/notification-service';

const PAGE_SIZE = 10;

const schema = yup.object().shape({
  enddate: yup.string().required('End Date Required'),
});

const MediaPlansList = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const auth = useAuth();

  const gridTableRef = useRef(null);
  const userBusinessUnit = useAppSelector(state => state.auth.businessUnit) || [];

  const years = useMemo(
    () => [
      'All Years',
      '2024',
      '2023',
      '2022',
      '2021',
      '2020',
      '2019',
      '2018',
      '2017',
      '2016',
      '2015',
    ],
    [],
  );

  const [brandOptions, setBrandOptions] = useState([
    {
      id: '0',
      name: 'All Brands',
    },
  ]);
  const [buOptions, setBuOptions] = useState(userBusinessUnit);
  const [selectedYear, setSelectedYear] = useState(years[0]);
  const [selectedBrand, setSelectedBrand] = useState(brandOptions[0]?.id);
  const [selectedBU, setSelectedBU] = useState(buOptions[0]?.id);
  const [searchText, setSearchText] = useState('');
  const [showEmptyTableComponent, setShowEmptyTableComponent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const enqueueSnackbar = useEnqueueSnackbar();
  const hasAllPermissions = useHasAllPermission();

  const fetchNotificationCount = async () => {
    await getNotificationCount()
      .then(response => {
        const { data = {} } = response.data || {};
        const { unreadCount = '0' } = data;
        dispatch(notificationActions.setNotificationCount({ notificationCount: unreadCount }));
      })
      .catch(error => {
        console.error(error);
        dispatch(notificationActions.setNotificationCount({ notificationCount: '!' }));
      });
  };

  useEffect(() => {
    auth.signinSilent();
    fetchNotificationCount();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getAllBrand(selectedBU)
      .then(response => {
        const { data: options } = response?.data ?? {};
        setSelectedBrand(brandOptions[0].id);
        if (options) {
          let t = getUniqueNamesWithIds(sortBy(options, 'name'));
          setBrandOptions(prev => [
            prev[0],
            ...t,
            // ...options.map(x => ({ ...x, id: x?.id?.toString() })),
          ]);
        }
      })
      .catch(error => enqueueSnackbar(AppConstants.SOMETHING_WENT_WRONG, 'error'))
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedBU]);

  const getDataSource = useCallback(
    (selectedBrand, selectedYear) => {
      const dataSource = {
        rowCount: undefined,
        getRows: async params => {
          try {
            let uri = `?pageNo=${params.endRow / PAGE_SIZE ?? 1}&pageSize=10`;
            if (!!searchText) {
              uri = uri + `&name=${searchText}`;
            }
            if (selectedBU) {
              uri = uri + `&businessUnitId=${selectedBU || ''}`;
            }
            if (selectedBrand !== brandOptions[0].id) {
              uri = uri + `&brandId=${selectedBrand || ''}`;
            }
            if (selectedYear !== years[0]) {
              uri = uri + `&year=${selectedYear === years[0] ? '' : selectedYear}`;
            }
            await listMediaPlan(uri).then(response => {
              const jsonData = response.data;
              const { data = {} } = jsonData;
              const { models = [], totalElements = 0 } = data;
              if (response.status === 200) {
                const totalRowCount = totalElements;

                let lastRow = -1;
                if (totalRowCount <= params.endRow) {
                  lastRow = totalRowCount;
                }

                if (
                  totalElements === 0 &&
                  !searchText &&
                  selectedBrand === brandOptions[0].id &&
                  selectedYear === years[0]
                )
                  setShowEmptyTableComponent(true);
                params.successCallback(models, lastRow);
              } else {
                params.successCallback(null, 0);
              }
            });
          } catch (errorResponse) {
            const { data = {} } = errorResponse?.response || {};
            const { status = 400, message = AppConstants.SOMETHING_WENT_WRONG } = data;

            enqueueSnackbar(message, 'error');
            if (status === 403) {
              localStorage.clear();
              auth.signoutRedirect();
            }

            setShowEmptyTableComponent(true);
            params.successCallback(null, 0);
          }
        },
      };
      return dataSource;
    },
    [searchText, brandOptions, years, selectedBU],
  );

  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const createNewMediaPlan = method => {
    if (hasAllPermissions([PermissionsConstants?.CREATE_MEDIA_PLAN])) {
      dispatch(createMediaPlanActions.clear());
      navigate(`create/${method}`, {
        replace: false,
      });
    }
  };

  const createMediaPlanCardDetails = [
    {
      id: 1,
      icon: <TemplateIcon fill={theme.palette.primary.main} />,
      title: 'Via Template',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit consectetur adipiscing elit.',
      onClick: () => createNewMediaPlan('template'),
    },
    {
      id: 2,
      icon: <UploadMediaPlan fill={theme.palette.primary.main} />,
      title: 'Upload Media Plan',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      onClick: () => createNewMediaPlan('upload'),
    },
  ];

  const getRowId = useMemo(() => {
    return params => {
      return params.data.id;
    };
  }, []);

  const handleViewMediaPlanDetails = useCallback(
    event => {
      if (event.data?.id) {
        navigate(`${event.data?.id}/media-plan`, {
          replace: false,
        });
      }
    },
    [navigate],
  );

  const datasource = useMemo(() => {
    if (!isLoading) return getDataSource(selectedBrand, selectedYear);
  }, [getDataSource, isLoading, selectedBrand, selectedYear]);

  const handleBrandFilterChanged = useCallback(e => {
    setSelectedBrand(e.target.value);
  }, []);

  const handleBUFilterChanged = useCallback(e => {
    setSelectedBU(e.target.value);
  }, []);

  const handleYearFilterChanged = useCallback(e => {
    setSelectedYear(e.target.value);
  }, []);

  const handleSearched = e => {
    const text = e.target.value;

    debounce(() => setSearchText(text), 500, { trailing: true })();
  };

  const handleSearchCleared = () => {
    setSearchText('');
  };

  const columns = useMemo(
    () => [
      {
        colId: 'name',
        headerName: 'Media Plan Name',
        field: 'name',
        sortable: false,
        minWidth: 210,
        maxWidth: 210,
        cellStyle: params => ({
          cursor: 'pointer',
        }),
        onCellClicked: handleViewMediaPlanDetails,
      },
      {
        colId: 'brand',
        headerName: 'Brand',
        field: 'species',
        sortable: false,
        minWidth: 137,
        cellRenderer: params =>
          params?.node?.data?.brand?.name ? <Box>{params?.node?.data?.brand?.name} </Box> : '-',
      },
      {
        colId: 'type',
        headerName: 'Type',
        field: 'mediaPlanType.name',
        sortable: false,
        minWidth: 120,
        maxWidth: 120,
        valueFormatter: params => params.value ?? '-',
      },
      {
        colId: 'businessUnit',
        headerName: 'Business Unit',
        field: 'businessUnit',
        sortable: false,
        minWidth: 173,
        valueGetter: params =>
          params?.node?.data?.businessUnit ?? <Box>{params?.node?.data?.businessUnit}</Box>,
      },
      {
        colId: 'category',
        headerName: 'Category',
        field: 'category',
        sortable: false,
        minWidth: 110,
        valueGetter: params =>
          params?.node?.data?.category?.name ? (
            <Box>{params?.node?.data?.category?.name} </Box>
          ) : (
            '-'
          ),
      },
      {
        colId: 'createdOn',
        headerName: 'Created On',
        field: 'createdAt',
        sortable: false,
        minWidth: 124,
        cellRenderer: params => {
          const str = `${formatDate(new Date(params?.node?.data?.createdAt), 'dd/MM/yyyy')}`;
          return <OverflowTooltip text={str}>{str}</OverflowTooltip>;
        },
      },
      {
        colId: 'planer',
        headerName: 'Planner',
        field: 'name',
        sortable: false,
        minWidth: 210,
        cellRenderer: params => (
          <UserDetails
            fullName={params?.data?.planner}
            fullNameColor={theme.palette.text['100']}
            initials={params?.data?.planner.split(' ').map(x => x[0])}
            initialsBackgroundColor={theme.palette.primary.main}
            initialsColor={theme.palette.white}
            initialsVariant="SemiBold-12"
            initailsContainer="32px"
            fullNameVariant="Medium-14"
          />
        ),
      },
      {
        colId: 'startDate',
        headerName: 'Start  - End Date',
        field: 'startDate',
        sortable: false,
        minWidth: 250,
        cellRenderer: params => {
          const str = `${formatDate(new Date(params?.node?.data?.startDate), 'dd/MM/yyyy')} -${' '}
        ${formatDate(new Date(params?.node?.data?.endDate), 'dd/MM/yyyy')}`;
          return <OverflowTooltip text={str}>{str}</OverflowTooltip>;
        },
      },
      {
        colId: 'totalBudget',
        headerName: 'Total Budget',
        field: 'totalBudget',
        sortable: false,
        minWidth: 210,

        valueGetter: params =>
          params?.node?.data?.totalBudget ?? <Box>{params?.node?.data?.totalBudget}</Box>,
        valueFormatter: params => (params?.value ? formatCurrency(params?.value) : '-'),
        // cellRenderer: params => {
        //   return formatCurrency(params?.value);
        // },
      },
      {
        colId: 'status',
        headerName: 'Status',
        field: 'activeVersion.status',
        sortable: false,
        cellStyle: params => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }),
        valueFormatter: params => capitalize(params.value),
        cellRenderer: params => {
          let status = 'warning';
          switch (params.value) {
            case 'FINALIZED':
              status = 'success';
              break;
            default:
              break;
          }
          return <Chip status={status} label={params.valueFormatted} styles={{ margin: 'auto' }} />;
        },
        minWidth: 200,
      },
    ],
    [
      handleViewMediaPlanDetails,
      theme.palette.primary.main,
      theme.palette.text,
      theme.palette.white,
    ],
  );

  const defaultColDef = useMemo(
    () => ({
      resizable: false,
      editable: false,
      flex: 1,
      sortable: false,
    }),
    [],
  );

  const MediaPlanListTable = useMemo(
    () =>
      !showEmptyTableComponent ? (
        <Table
          ref={gridTableRef}
          columns={columns}
          datasource={datasource}
          defaultColDef={defaultColDef}
          height="calc(100vh - 312px)"
          rowSelection="single"
          rowModelType="infinite"
          getRowId={getRowId}
          maxBlocksInCache={100}
          rowBuffer={1}
          cacheBlockSize={PAGE_SIZE}
          cacheOverflowSize={1}
          maxConcurrentDatasourceRequests={1}
          infiniteInitialRowCount={10}
          suppressRowClickSelection={true}
          blockLoadDebounceMillis={500}
        />
      ) : null,
    [columns, datasource, defaultColDef, getRowId, showEmptyTableComponent],
  );

  const schemaMethods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      enddate: '',
    },
  });

  return (
    <>
      <Loader isLoading={isLoading} />
      <Card
        sx={{
          minHeight: 'calc(100vh - 130px)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography variant="Bold-28">MEDIA PLANS</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '1.25rem',
            }}
          >
            <Button
              key="button2"
              width="130px"
              onClick={handleOpenDialog}
              variant="contained"
              disabled={!hasAllPermissions([PermissionsConstants?.CREATE_MEDIA_PLAN])}
              label="Add New"
              startIcon={<AddIcon fill="#FFFFFF" />}
            />
            {hasAllPermissions([PermissionsConstants?.CREATE_MEDIA_PLAN]) && (
              <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                headline={
                  <Typography
                    variant="SemiBold-22"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    Select type of Media Plan Creation
                  </Typography>
                }
                cards={createMediaPlanCardDetails.map(item => (
                  <CLickableCard
                    key={item.id}
                    icon={item.icon}
                    headline={item.title}
                    description={<Typography variant="Regular-12">{item.description}</Typography>}
                    onClick={item.onClick}
                  />
                ))}
                buttons={
                  <Button
                    variant="outlined"
                    label="Download Template as Excel"
                    width="100%"
                    onClick={() => {
                      window.open(
                        'https://storage.cloud.google.com/mindshare-x-marvelai-gcsbucket-public/media-plan-template-formatted.xlsm',
                      );
                    }}
                  />
                }
                width="668px"
                backgroundColor="white"
              />
            )}
          </Box>
        </Box>
        {showEmptyTableComponent ? (
          <EmptyTable
            title="No Media Plans Created Yet!"
            description='Click the "Add New" button and commence your media planning journey.'
            actionItems={[
              <Button
                key="button2"
                width="120px"
                onClick={handleOpenDialog}
                disabled={!hasAllPermissions([PermissionsConstants?.CREATE_MEDIA_PLAN])}
                variant="contained"
                label="Add New"
              />,
            ]}
            height="100%"
          />
        ) : (
          <FormProvider {...schemaMethods}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'column',
                gap: '24px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: '20px',
                }}
              >
                <Box sx={{ width: '24.75rem', height: '40px' }}>
                  <Input
                    id="media-plan-list-search"
                    inputHeight="40px"
                    type="text"
                    placeholder="Search by name"
                    searchable
                    onChange={handleSearched}
                    onSearchCloseClick={handleSearchCleared}
                  />
                </Box>
                <Box sx={{ width: '12.5rem', height: '40px' }}>
                  <Select
                    id="bu-filter"
                    inputHeight="40px"
                    options={buOptions}
                    value={selectedBU}
                    optionMapFunction={options =>
                      options?.map(x => ({ id: x.id, label: x.name, value: x.id }))
                    }
                    searchable
                    menuHeight="400px"
                    menuWidth="12rem"
                    selectHeight="40px"
                    onChange={handleBUFilterChanged}
                  />
                </Box>
                <Box sx={{ width: '12.5rem', height: '40px' }}>
                  <Select
                    id="brand-filter"
                    inputHeight="40px"
                    options={brandOptions}
                    value={selectedBrand}
                    optionMapFunction={options =>
                      options?.map(x => ({ id: x.id, label: x.name, value: x.id }))
                    }
                    searchable
                    menuHeight="400px"
                    menuWidth="12rem"
                    selectHeight="40px"
                    onChange={handleBrandFilterChanged}
                    disabled={!selectedBU}
                  />
                </Box>
                <Box sx={{ width: 'fit-content', height: '40px' }}>
                  {/* <Select
                  id="year-filter"
                  options={years}
                  value={selectedYear}
                  optionMapFunction={options => options?.map(x => ({ id: x, label: x, value: x }))}
                  searchable
                  searchInputPlaceholder="Search Year"
                  menuHeight="400px"
                  selectHeight="40px"
                  onChange={handleYearFilterChanged}
                /> */}

                  <Box sx={{ marginTop: '-4px', display: 'flex' }}>
                    <Box sx={{ width: '12.5rem' }}>
                      <DatePicker
                        id="enddate"
                        label=""
                        placeholder="Select date"
                        {...schemaMethods.register('enddate')}
                        onChange={e => {
                          setSelectedYear(e?.year() || '');
                        }}
                        views={['year']}
                        inputHeight={'40px'}
                        // minDate={dayjs(new Date('1-1-2000'))}
                        isYearPicker={true}
                      />
                    </Box>
                    {selectedYear && schemaMethods.getValues('enddate') && (
                      <div
                        style={{
                          marginLeft: '5px',
                          width: '160px',
                          fontFamily: 'Poppins',
                          color: 'rgb(10, 39, 86)',
                          cursor: 'pointer',
                          padding: '10px 0px',
                        }}
                        onClick={() => {
                          setSelectedYear(years[0]);
                          schemaMethods.setValue('enddate', '');
                        }}
                      >
                        Clear Year
                      </div>
                    )}
                  </Box>
                </Box>
              </Box>
              {MediaPlanListTable}
            </Box>
          </FormProvider>
        )}
      </Card>
    </>
  );
};

export default MediaPlansList;
