import { createIcon } from '../createSVGIcon';

const path = (
  <g>
    <path d="M8.60663 25.3333L2.66663 30V5.33333C2.66663 4.97971 2.8071 4.64057 3.05715 4.39052C3.3072 4.14048 3.64634 4 3.99996 4H28C28.3536 4 28.6927 4.14048 28.9428 4.39052C29.1928 4.64057 29.3333 4.97971 29.3333 5.33333V24C29.3333 24.3536 29.1928 24.6928 28.9428 24.9428C28.6927 25.1929 28.3536 25.3333 28 25.3333H8.60663ZM7.68396 22.6667H26.6666V6.66667H5.33329V24.5133L7.68396 22.6667ZM15.0573 16.1613L20.7146 10.5053L22.6 12.3907L15.0573 19.9333L9.87063 14.7467L11.7573 12.8613L15.0573 16.1613Z" />
  </g>
);

export const SendPoClientRequestIcon = createIcon(path, {
  width: 32,
  height: 32,
  viewBox: '0 0 32 32',
  fill: 'none',
});
