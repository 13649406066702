import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Button from '../../../../../../../components/shared/buttons';
import InputField from '../../../../../../../components/shared/form/Input';
import { CheckBox } from '@mui/icons-material';
import OverflowTooltip from '../../../../../../../components/shared/tooltip/OverflowTooltip';
import ReusableCheckbox from '../../../../../../../components/shared/checkbox';
import Input from '../../../../../../../components/shared/form/Input/input';

const CheckboxSubmission = ({
  data = [],
  createUpdateSubtaskRequest = () => {},
  showReadyOnly = false,
  url = '',
  freezeAll = false,
}) => {
  const theme = useTheme();
  const [formData, setFormData] = useState([]);
  const [_URL, setURL] = useState(url);
  const [URLError, setURLError] = useState('');

  const isSaveDisabled = useMemo(() => {
    let temp = true;
    if (data?.length) {
      data.forEach(x => {
        if (x.isEditable) {
          temp = false;
        }
      });
    }
    if (showReadyOnly === true) {
      return true;
    } else {
      return temp;
    }
  }, [data]);

  useEffect(() => {
    const temp = data?.map((ele, i) => {
      return {
        id: ele.id,
        label: ele?.name,
        value: ele?.columnValue === null ? false : ele?.columnValue === 'false' ? false : true,
        disabled: showReadyOnly === true ? showReadyOnly : !ele?.isEditable,
        error: ele?.error || '',
        fieldType: ele?.fieldType || '',
        isEditable: ele?.isEditable || false,
        isCheckbox: ele?.isCheckbox || true,
        isEdited: ele?.isEdited,
      };
    });
    setFormData(temp);
  }, [data]);

  const getPayload = formData => {
    const t = [];
    formData?.forEach(x => {
      const { id, isEdited, isEditable, value } = x;
      const temp = {
        id,
        isEdited,
        columnValue: value,
      };
      if (isEditable) {
        t.push(temp);
      }
    });
    return t;
  };

  const onSubmit = () => {
    createUpdateSubtaskRequest(getPayload(formData), false, _URL);
  };

  const checkBoxClicked = index => {
    let temp = [...formData];
    temp[index].value = !formData[index].value;
    setFormData(temp);
  };

  const FormDetails = useMemo(
    () => (
      <FormGroup sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '24px',
          }}
        >
          {formData?.map((x, index) => (
            <Box sx={{ width: 'calc(33.33% - 16.33px)', maxWidth: 'calc(33.33% - 16.33px)' }}>
              <ReusableCheckbox
                label={<OverflowTooltip text={x.label}>{x.label}</OverflowTooltip>}
                checked={x?.value}
                onChange={() => {
                  checkBoxClicked(index);
                }}
                disabled={x.disabled || freezeAll}
                error={x.error}
              />
            </Box>
          ))}
        </Box>
      </FormGroup>
    ),
    [formData],
  );

  const isValidURL = url => {
    try {
      new URL(url);
      setURLError('');
      return true;
    } catch (e) {
      setURLError('Please enter valid URL');
      return false;
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        padding: '24px 0',
        borderTop: `1px solid ${theme.palette.text['500']}`,
        borderBottom: `1px solid ${theme.palette.text['500']}`,
      }}
    >
      <Box
        sx={{
          borderRadius: '8px',
          padding: '0',
          background: 'transparent',
        }}
      >
        <Box sx={{ width: '100%', maxWidth: '940px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              gap: '1rem',
              marginBottom: '1rem',
            }}
          >
            {/* <Typography variant="SemiBold-18">Lorem Ipsum dummy text</Typography> */}
            <Typography variant="Regular-14">
              For Task to be considered complete, the following checklist must be satisfied:
            </Typography>
          </Box>

          <Box sx={{ width: '100%', marginBottom: '20px' }}>
            <Input
              id="media-plan-list-search"
              inputHeight="40px"
              type="text"
              placeholder="URL"
              label="URL"
              value={_URL}
              onChange={e => {
                const val = e.target.value.trim();
                if (val) {
                  isValidURL(val);
                } else {
                  setURLError('');
                }
                setURL(val);
              }}
              error={URLError}
              helperText={URLError || 'Example URL: https://example.com'}
              disabled={showReadyOnly || freezeAll}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              gap: '30px',
            }}
          >
            {FormDetails}
            <Box>
              <Button
                type="submit"
                variant="contained"
                label={'Submit'}
                disabled={URLError || isSaveDisabled || freezeAll}
                sx={{ height: '50px' }}
                onClick={onSubmit}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CheckboxSubmission;
