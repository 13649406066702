import * as React from 'react';
import TablePagination from '@mui/material/TablePagination';

export default function Pagination({
  rowsPerPage,
  page,
  setRowsPerPage = null,
  setPage = null,
  totalItems = 0,
  rowsPerPageOptions = [2, 5, 10],
}) {
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={totalItems}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
}
