/* eslint-disable jsx-a11y/alt-text */
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Header from '../../../../../../components/shared/header';
import Table from '../../../../../../components/shared/table';
import { defaultColDef, columns as columnData } from './ColumnData';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../../../../../components/shared/card';
import Tabs from '../../../../../../components/shared/tabs';
import Button from '../../../../../../components/shared/buttons';
import { DownloadIcon } from '../../../../../../libs/svg-icons/icons';
import Chip from '../../../../../../components/shared/chip';
import Comments from '../../../../../../components/shared/comments';
import Accordion from '../../../../../../components/shared/accordion';
import PoClientTable from './PoClientTable';

import {
  changePoExceptionStatus,
  createBuyingBrief,
  createBBGOnMinder,
  generateInternalBrief,
  getBuyingBriefGroup,
  getCreateMediaPlanMasterData,
  getMediaPlanMasterData,
  getPOClientExceptionRequest,
  updateBBGOnMinder,
} from '../../../../../../services/media-plan-service';
import { useEnqueueSnackbar } from '../../../../../../components/shared/toast-provider/toastHook';
import { AppConstants } from '../../../../../../constants/app-constants';
import emptyMediaPlanDetailsData from '../../../MediaPlanDetailsData.json';
import {
  flattenMasterDataHierarchy,
  getExternalIdList,
  getIdList,
  lookupValue,
} from '../../../../../../utils/array';
import { capitalize, formatCurrency } from '../../../../../../utils/string';
import Loader from '../../../../../../components/shared/loader';
import { useHasAllPermission } from '../../../../../../hooks/permissions';
import { PermissionsConstants } from '../../../../../../constants/permissions-constants';
import PageNotFound from '../../../../../404';
import { formatDate } from '../../../../../../libs/date/format';
import PoClientButtonDialog from './PoClientButtonDialog';
import ViewPOClientDialog from '../../../../../../components/shared/dialog/ViewPOClientDialog';
import ApproveRejectAction from '../../../../../../components/shared/approve-reject';
import { useAppSelector } from '../../../../../../redux/hooks';
import OverflowTooltip from '../../../../../../components/shared/tooltip/OverflowTooltip';
import { downloadFromURL } from '../../../../../../libs/document/file';
import { useErrorBoundary, withErrorBoundary } from 'react-use-error-boundary';
import ErrorInHeader from '../../../../../../components/shared/errorInHeader';
import { HttpStatusCode } from 'axios';
import BackdropOverlay from '../../../../../../components/shared/backdropOverlay';

const MinderPoClientStatusMap = {
  NOT_CREATED_ON_MINDER: 'Not Created on Minder',
  CREATED_AND_MATCHED: 'Created & Matched',
  CREATED_AND_NOT_MATCHED: 'Created but not Matched',
};

const isHide = ['PROD'].includes(process.env.REACT_APP_ENV);

const BuyingGroup = () => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const enqueueSnackbar = useEnqueueSnackbar();
  const hasAllPermissions = useHasAllPermission();

  const gridTableRef = useRef(null);

  const [rowData, setRowData] = useState([]);
  const [buyingGroupId, setBuyingGroupId] = useState(null);
  const [buyingGroupDetails, setBuyingGroupDetails] = useState(null);
  const [openViewPoClientDialog, setOpenViewPoClientDialog] = useState(false);
  const [masterData, setMasterData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showApprovalRejectComponent, setShowApprovalRejectComponent] = useState(false);

  const [exceptionTeamIds, setExceptionTeamIds] = useState([]);

  const [exceptionDetails, setExceptionDetails] = useState([]);
  const [exceptionRequestDate, setExceptionRequestDate] = useState();
  const [clientConfirmationUrl, setClientConfirmationUrl] = useState(null);
  const [teamDetails, setTeamDetails] = useState([]);
  const [approvalStatus, setApprovalStatus] = useState('');
  const [requestStatus, setRequestStatus] = useState(
    AppConstants.PO_CLIENT_EXCEPTION_NOT_INITIATED,
  );
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [error, resetError] = useErrorBoundary((error, errorInfo) => console.log(error, errorInfo));
  const [totalPlanBudget, setTotalPlanBudget] = useState(0);

  const [headerError, setHeaderError] = useState(null);

  const [backdropOpen, setBackDropOpen] = useState(false);

  useEffect(() => {
    resetError();
  }, [error, resetError]);

  const loggedInUserId = useAppSelector(state => state.auth.loggedInUserId);

  const handleSetBuyingGroupData = buyingGroupData => {
    if (buyingGroupData) {
      const {
        internalBriefId = null,
        internalBriefStatus = '',
        discrepancyStatus = AppConstants.NOT_CREATED_ON_MINDER,
        webappBbgNumber = '',
        webappClientPO = null,
        minderClientPO = null,
        clientPoRequest = null,
        campaign = null,
        variantFinanceName = null,
        downloadPath = '',
        minderBbgNumber = null,
        managerApprovalstatus = '',
        brand,
      } = buyingGroupData;
      setBuyingGroupId(buyingGroupData.id);
      setBuyingGroupDetails({
        id: buyingGroupData.id,
        isEditing: false,
        internalBriefId,
        internalBriefStatus,
        discrepancyStatus,
        webappBbgNumber,
        webappClientPO,
        minderClientPO,
        clientPoRequest,
        downloadPath,
        campaign,
        variantFinanceName,
        minderBbgNumber,
        managerApprovalstatus,
        brandData: brand,
      });
      if (buyingGroupData?.mediaPlanRows && buyingGroupData?.mediaPlanRows.length > 0) {
        setRowData(
          buyingGroupData?.mediaPlanRows.map((x, i) => ({
            ...emptyMediaPlanDetailsData[0],
            ...x,
            id: i,
            rowId: x.id,
          })),
        );
      }
      if (clientPoRequest) {
        const {
          agencyHeadId,
          agencyHeadRequestStatus,
          buyingTeamHeadId,
          buyingTeamRequestStatus,
          financeHeadId,
          financeHeadRequestStatus,
        } = clientPoRequest;
        setExceptionTeamIds([
          { id: agencyHeadId, status: agencyHeadRequestStatus, payloadId: 'agencyHeadId' },
          { id: buyingTeamHeadId, status: buyingTeamRequestStatus, payloadId: 'buyingTeamHeadId' },
          { id: financeHeadId, status: financeHeadRequestStatus, payloadId: 'financeHeadId' },
        ]);
      }
    }
  };

  const showApproveRejectButtonForException = useMemo(() => {
    let flag = null;
    exceptionTeamIds.forEach(ele => {
      if (ele.id === loggedInUserId && ele.status === 'PENDING' && !flag) {
        flag = ele;
      }
    });
    return flag;
  }, [loggedInUserId, exceptionTeamIds]);

  const fetchData = () => {
    setIsLoading(true);
    const param = { id: params.bgid, mediaPlanId: params.id };
    getBuyingBriefGroup(param)
      .then(async response => {
        const { data: buyingGroupData } = response.data;
        handleSetBuyingGroupData(buyingGroupData);
        const totalBudget = buyingGroupData.mediaPlanRows.reduce(
          (sum, row) => sum + row.planBudget,
          0,
        );
        setTotalPlanBudget(totalBudget);
        return {
          clientPoRequest: buyingGroupData?.clientPoRequest,
        };
      })
      .then(({ clientPoRequest }) => {
        if (clientPoRequest) {
          getPOClientExceptionRequest({
            bbgId: params.bgid,
          })
            .then(response => {
              const { data } = response.data;
              const approvalStatus = data.approvalStatus ?? AppConstants.PO_CLIENT_APPROVAL_PENDING;
              const requestStatus =
                data.requestStatus ?? AppConstants.PO_CLIENT_EXCEPTION_NOT_INITIATED;
              setApprovalStatus(approvalStatus);
              setRequestStatus(requestStatus);

              setExceptionDetails([
                { label: 'Agency', value: data?.agency ?? '-' },
                { label: 'Client', value: data?.client ?? '-' },
                { label: 'Brand', value: data?.brand ?? '-' },
                {
                  label: 'Campaign Start Date',
                  value: data?.campaignStartDate
                    ? formatDate(data?.campaignStartDate, 'dd/MM/yyyy')
                    : '-',
                },
                { label: 'Media Type', value: data?.mediaType ?? '-' },
                {
                  label: 'Total Amount',
                  value: data?.totalAmount ? `${formatCurrency(data.totalAmount)} IDR` : '-',
                },
                {
                  label: 'Estimated Date of getting the PO',
                  value: data?.estimatedDate ? formatDate(data?.estimatedDate, 'dd/MM/yyyy') : '-',
                },
                {
                  label: 'Reason for not getting the Client PO',
                  value: data?.reason ?? '-',
                  direction: 'column',
                  variant: 'Normal-14',
                },
              ]);

              setExceptionRequestDate(data.createdAt);

              setClientConfirmationUrl(data?.clientConfirmationUrl ?? null);

              setTeamDetails([
                {
                  status: data?.buyingTeamRequestStatus ?? '-',
                  value: data?.buyingTeamHead ?? '-',
                },
                { status: data?.financeHeadRequestStatus ?? '-', value: data?.financeHead ?? '-' },
                { status: data?.agencyHeadRequestStatus ?? '-', value: data?.agencyHead ?? '-' },
              ]);
            })
            .catch(error => {
              const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
              if (error?.response?.status === HttpStatusCode.Forbidden) {
                setBackDropOpen(true);
              } else {
                enqueueSnackbar(message, 'error');
              }
            });
        }
      })
      .catch(error => {
        console.log(error.response);
        const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
        if (error?.response?.status === HttpStatusCode.Forbidden) {
          setBackDropOpen(true);
        } else {
          enqueueSnackbar(message, 'error');
        }
        setBuyingGroupId(0);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (buyingGroupDetails?.brandData) {
      setMasterData([]);
      getMediaPlanMasterData(buyingGroupDetails?.brandData?.id)
        .then(response => {
          const masterDataJSON = response.data;
          if (masterDataJSON?.data && masterDataJSON?.data[0]?.child)
            setMasterData(prev => {
              const newMasterData = prev
                ? [...prev, ...flattenMasterDataHierarchy(masterDataJSON.data[0].child)]
                : flattenMasterDataHierarchy(masterDataJSON.data[0].child);
              return newMasterData;
            });
        })
        .then(() => {
          getCreateMediaPlanMasterData(buyingGroupDetails?.brandData?.businessUnitId)
            .then(response => {
              const masterDataJSON = response.data;
              if (masterDataJSON?.data?.child) {
                setMasterData(prev => {
                  const newMasterData = prev
                    ? [...prev, ...flattenMasterDataHierarchy(masterDataJSON.data.child)]
                    : flattenMasterDataHierarchy(masterDataJSON.data.child);
                  return newMasterData;
                });
              }
            })
            .catch(error => {
              const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
              enqueueSnackbar(message, 'error');
            });
        })
        .catch(error => {
          const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
          enqueueSnackbar(message, 'error');
        });
    }
  }, [buyingGroupDetails]);

  const handleCloseViewPoClientDialog = () => {
    setOpenViewPoClientDialog(false);
  };

  const getRowId = useMemo(() => {
    return params => {
      return params?.data?.id;
    };
  }, []);

  const handleGridReady = useCallback(
    e => {
      const existingColumnDefs = e.api.getGridOption('columnDefs');
      e?.api.ensureNodeVisible(-1);
      let updatedColumnDefs = existingColumnDefs.map(col => {
        const fieldId = col.colId;
        switch (fieldId) {
          case 'status':
            return {
              ...col,
              filterParams: {
                values: ['DRAFT', 'LIVE', 'PARTIAL_ACTUAL', 'FULLY_ACTUAL', 'CANCELLED'],
                valueFormatter: value => capitalize(value),
              },
            };
          case 'category':
            return {
              ...col,
              valueFormatter: params =>
                lookupValue(
                  masterData.filter(x => x.type === 'category'),
                  'id',
                  params.value,
                )?.name,
            };
          case 'brand':
            return {
              ...col,
              valueFormatter: params =>
                lookupValue(
                  masterData.filter(x => x.type === 'brand'),
                  'id',
                  params.value,
                )?.name,
            };
          case 'brandFinance':
            return {
              ...col,
              valueFormatter: params =>
                lookupValue(
                  masterData?.filter(x => x.type === 'brandFinance'),
                  'id',
                  params.value,
                )?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'brandFinance')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'campaign':
            return {
              ...col,
              valueFormatter: params =>
                lookupValue(
                  masterData.filter(x => x.type === 'campaign'),
                  'id',
                  params.value,
                )?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'campaign')),
                valueFormatter: value =>
                  lookupValue(
                    masterData.filter(x => x.type === 'campaign'),
                    'id',
                    value,
                  )?.name,
              },
            };
          case 'variantFinanceCode':
            return {
              ...col,
              filterParams: {
                values: getExternalIdList(
                  masterData?.filter(x => x.type === 'Variant(Finance)Name'),
                ),
              },
            };
          case 'variantFinance':
            return {
              ...col,
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'Variant(Finance)Name')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'materialCode':
            return {
              ...col,
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
            };
          case 'material':
            return {
              ...col,
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
            };
          case 'minderTA':
            return {
              ...col,
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'targetID')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'clusterTA':
            return {
              ...col,
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'Cluster TA')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'media':
            return {
              ...col,
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'media')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'format':
            return {
              ...col,
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'format')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'expenditureType':
            return {
              ...col,
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'Expenditure Type')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'activityInput':
            return {
              ...col,
              valueFormatter: params =>
                `${lookupValue(masterData, 'id', params.value)?.name} ${
                  lookupValue(
                    masterData?.filter(x => x.type === 'campaign'),
                    'id',
                    params.data?.campaign?.id,
                  )?.name
                }`,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'Activity Input')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'placement':
            return {
              ...col,
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'placement')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'type':
            return {
              ...col,
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'type')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'partner':
            return {
              ...col,
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'partner')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'duration':
            return {
              ...col,
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'Duration')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'frequency':
            return {
              ...col,
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'Freq')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'month':
            return {
              ...col,
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'Month')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'quarter':
            return {
              ...col,
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'Quarter')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'half':
            return {
              ...col,
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'Half')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          default:
            return col;
        }
      });

      e?.api.setGridOption('columnDefs', updatedColumnDefs);
    },
    [masterData],
  );

  const handleCheckIsRowSelectable = useCallback(params => {
    const isRowCancelled = params.data?.mediaPlanRowsStatus === 'CANCELLED';
    if (isRowCancelled) return false;
    return true;
  }, []);

  const handleCheckboxChange = useCallback(selectedRows => {
    setSelectedCheckboxes(selectedRows.map(row => row.id));
  }, []);

  const handleGenerateInternalBrief = useCallback(() => {
    if (hasAllPermissions([PermissionsConstants.CREATE_INTERNAL_BRIEF])) {
      setIsLoading(true);
      const payload = {
        buyingBriefGroupId: params?.bgid,
      };
      generateInternalBrief(payload)
        .then(response => {
          const { data } = response.data;
          if (data?.id) {
            enqueueSnackbar('Internal brief generated successfully!', 'success');
            navigate(
              `/app/media-plans/${params.id}/buying-group/${params.bgid}/internal-brief/${data.id}`,
            );
          }
        })
        .catch(error => {
          const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
          enqueueSnackbar(message, 'error');
        });
    }
  }, [enqueueSnackbar, hasAllPermissions, navigate, params.bgid, params.id]);

  const handleViewInternalBrief = useCallback(() => {
    if (hasAllPermissions([PermissionsConstants.READ_INTERNAL_BRIEF])) {
      navigate(
        `/app/media-plans/${params.id}/buying-group/${params.bgid}/internal-brief/${buyingGroupDetails?.internalBriefId}`,
      );
    }
  }, [buyingGroupDetails?.internalBriefId, hasAllPermissions, navigate, params.bgid, params.id]);

  const handleCreateBuyingBrief = useCallback(() => {
    const mediaPlanRowIds = [];

    setIsLoading(true);

    gridTableRef.current?.api.forEachNode(x => {
      if (selectedCheckboxes.includes(x.id)) mediaPlanRowIds.push(x?.data?.rowId);
    });
    const payload = {
      mediaPlanId: params?.id,
      bbgId: params?.bgid,
      mediaPlanRowIds,
    };

    createBuyingBrief(payload)
      .then(() => {
        enqueueSnackbar('Buying Brief created successfully!', 'success');
        navigate(`/app/media-plans/${params.id}/buying-group/${params.bgid}/buying-brief`);
      })
      .catch(error => {
        const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
        enqueueSnackbar(message, 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [enqueueSnackbar, navigate, params.bgid, params.id, selectedCheckboxes]);

  const handleClearAll = () => {
    gridTableRef.current?.api?.forEachNode(node => {
      node.setSelected(false);
    });
  };

  const TableUtils = useMemo(() => {
    let totalRows = 0;
    gridTableRef.current?.api.forEachNode(x => {
      totalRows = totalRows + 1;
    });
    if (selectedCheckboxes && selectedCheckboxes.length > 0) {
      return (
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            padding: '13px 16px',
            border: '1px solid #CDD1D7',
            backgroundColor: theme.palette.text[600],
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '5px',
              flexDirection: 'row',
            }}
          >
            <Typography variant="SemiBold-18">
              <span
                style={{ color: '#0080FF' }}
              >{`${String(selectedCheckboxes ? selectedCheckboxes.length : 0).padStart(2, '0')}`}</span>
            </Typography>
            <Typography variant="SemiBold-18">
              <span style={{ color: '#16181A' }}>/</span>{' '}
            </Typography>
            <Typography variant="SemiBold-18">
              <span style={{ color: '#16181A' }}>{`${String(totalRows).padStart(2, '0')}`}</span>{' '}
            </Typography>
          </Box>

          {hasAllPermissions([PermissionsConstants.CREATE_BUYING_BRIEF_GROUP]) &&
            buyingGroupDetails?.managerApprovalstatus === 'MANAGER_APPROVED' &&
            ['ACTIVE', 'REVISED'].includes(buyingGroupDetails?.minderClientPO?.status) &&
            buyingGroupDetails.discrepancyStatus === AppConstants.CREATED_AND_MATCHED && (
              <Button
                variant="contained"
                onClick={handleCreateBuyingBrief}
                label="Create Buying Brief"
                height="30px"
                disabled={isLoading || isHide}
              />
            )}
          <Button variant="outlined" label="Clear All" height="30px" onClick={handleClearAll} />
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '16px',
            }}
          >
            {!isLoading && buyingGroupDetails?.internalBriefId && (
              <Button
                height="40px"
                sx={{
                  minWidth: '21px',
                  padding: '8px 20px',
                }}
                variant="outlined"
                label="View IB"
                disabled={!hasAllPermissions([PermissionsConstants.READ_INTERNAL_BRIEF])}
                onClick={handleViewInternalBrief}
              />
            )}
            {!isLoading &&
              ((buyingGroupDetails?.internalBriefStatus ===
                AppConstants.IB_STATUS_CLIENT_CANCELLED &&
                buyingGroupDetails?.managerApprovalstatus === 'MANAGER_APPROVED') ||
                !buyingGroupDetails?.internalBriefId) && (
                <Button
                  height="40px"
                  sx={{
                    minWidth: '21px',
                    padding: '8px 20px',
                  }}
                  variant="contained"
                  label="Generate IB"
                  disabled={!hasAllPermissions([PermissionsConstants.CREATE_INTERNAL_BRIEF])}
                  onClick={handleGenerateInternalBrief}
                />
              )}
          </Box>
        </Box>
      );
    }
  }, [
    buyingGroupDetails?.internalBriefId,
    handleCreateBuyingBrief,
    handleGenerateInternalBrief,
    handleViewInternalBrief,
    hasAllPermissions,
    isLoading,
    selectedCheckboxes,
    theme.palette.text,
  ]);

  const BuyingGroupsTable = useMemo(
    () => (
      <Table
        ref={gridTableRef}
        getRowId={getRowId}
        columns={columnData}
        data={rowData}
        defaultColDef={defaultColDef}
        height={`max(calc((${Math.min(rowData?.length ? rowData.length : 5, 5) * 3.75}rem) + 98px), calc(100vh - 550px))`}
        onGridReady={handleGridReady}
        isRowSelectable={handleCheckIsRowSelectable}
        onRowSelected={handleCheckboxChange}
        rowSelection={'multiple'}
      />
    ),
    [getRowId, handleCheckIsRowSelectable, handleCheckboxChange, handleGridReady, rowData],
  );
  const PoTable = useMemo(
    () => <PoClientTable buyingGroupDetails={buyingGroupDetails} />,
    [buyingGroupDetails],
  );

  const handleOpenViewPoClient = () => {
    setOpenViewPoClientDialog(true);
  };

  const handleApprove = () => {
    handleChangeStatusWithApproveReject('APPROVED');
  };

  const handleReject = () => {
    handleChangeStatusWithApproveReject('REJECTED');
  };

  const handleChangeStatusWithApproveReject = status => {
    const { id, payloadId } = showApproveRejectButtonForException;
    const payload = {
      'bbgId': params?.bgid,
      [payloadId]: id,
      'status': status,
    };

    handleChangePoClientExceptionStatus(buyingGroupDetails?.clientPoRequest?.id, payload);
  };

  const handleChangePoClientExceptionStatus = async (exceptionId, payload) => {
    setIsLoading(true);
    await changePoExceptionStatus(exceptionId, payload)
      .then(response => {
        const { data = null } = response?.data || {};
        if (data) {
          fetchData();
        }
      })
      .catch(error => {
        enqueueSnackbar(AppConstants.SOMETHING_WENT_WRONG, 'error');
      })
      .finally(() => setIsLoading(false));
  };

  const handleCreateOnMinder = () => {
    if (hasAllPermissions([PermissionsConstants.CREATE_MINDER_IB])) {
      setIsLoading(true);
      const payload = {
        'buyingBriefGroupId': params?.bgid,
      };
      createBBGOnMinder(payload)
        .then(response => {
          const { data } = response?.data ?? {};
          enqueueSnackbar(data?.message, 'success');
        })
        .then(() => fetchData())
        .catch(error => {
          const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
          enqueueSnackbar(message, 'error');
          setHeaderError(message);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleUpdateOnMinder = () => {
    if (hasAllPermissions([PermissionsConstants.CREATE_MINDER_IB])) {
      setIsLoading(true);
      const payload = {
        'buyingBriefGroupId': params?.bgid,
      };
      updateBBGOnMinder(payload)
        .then(response => {
          const { message = '' } = response?.data?.data ?? {};
          enqueueSnackbar(message, 'success');
        })
        .then(() => fetchData())
        .catch(error => {
          const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
          enqueueSnackbar(message, 'error');
          setHeaderError(message);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const tabItems = useMemo(() => {
    let temp = [
      {
        value: 'buying-brief-group',
        label: 'Buying Brief Group',
        link: `/app/media-plans/${params.id}/buying-group/${params.bgid}/`,
      },
    ];

    if (!isHide) {
      temp.push({
        value: 'buying-brief',
        label: 'Buying Brief',
        link: `/app/media-plans/${params.id}/buying-group/${params.bgid}/buying-brief`,
      });
    }

    return temp;
  }, []);

  if (
    !backdropOpen &&
    (buyingGroupId === 0 || !hasAllPermissions([PermissionsConstants.READ_BUYING_BRIEF_GROUP]))
  )
    return <PageNotFound />;

  return (
    <>
      <Loader isLoading={isLoading} />

      {backdropOpen && (
        <BackdropOverlay
          open={backdropOpen}
          buttonLabel="Redirect To Media Plan List"
          clickAction={() => {
            navigate(`/app/media-plans`);
          }}
        />
      )}

      <Card sx={{ alignItems: 'flex-start' }}>
        {buyingGroupDetails?.clientPoRequest?.requestStatus ===
          AppConstants.PO_CLIENT_EXCEPTION_REQUESTED &&
          showApproveRejectButtonForException && (
            <ApproveRejectAction onApprove={handleApprove} onReject={handleReject} />
          )}

        {headerError && (
          <ErrorInHeader
            onClose={() => {
              setHeaderError(null);
            }}
            content={<Typography variant="Medium-14">{headerError}</Typography>}
          />
        )}

        <Header
          breadcrumbs={[
            { name: 'View Media Plan', href: `/app/media-plans/${params.id}/media-plan` },
            { name: 'Buying Group', href: `/app/media-plans/${params.id}/buying-group` },
            { name: params?.bgid },
          ]}
          backHref={`/app/media-plans/${params.id}/buying-group`}
          rightActions={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '1.25rem',
              }}
            >
              <ViewPOClientDialog
                open={openViewPoClientDialog}
                onClose={handleCloseViewPoClientDialog}
                width="1252px"
                documentLink={buyingGroupDetails?.webappClientPO?.clientPoUrl || ''}
                img={
                  <img
                    width="100%"
                    height="800px"
                    src="https://www.img2go.com/assets/img/img2go_screenshot.png"
                    object-fit="cover"
                  />
                }
                buttons={docLink => (
                  <Button
                    width="40px"
                    height="40px"
                    sx={{
                      minWidth: '21px',
                      padding: '5px',
                    }}
                    variant="outlined"
                    startIcon={
                      <Box
                        component={'span'}
                        sx={{
                          width: '21px',
                          height: '21px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <DownloadIcon fill={theme.palette.primary.main} />
                      </Box>
                    }
                    onClick={() => {
                      downloadFromURL(docLink, 'PO client document');
                    }}
                  />
                )}
                chips={<Chip label="Status" status="success" width="85px" height="40px" />}
              />

              <PoClientButtonDialog
                toggleApprovalComponent={() =>
                  setShowApprovalRejectComponent(!showApprovalRejectComponent)
                }
                fetchBuyingBriefGroup={fetchData}
                buyingGroupDetails={buyingGroupDetails}
                handleChangePoClientExceptionStatus={handleChangePoClientExceptionStatus}
                canCancelApproval={!exceptionTeamIds.map(ele => ele.id).includes(loggedInUserId)}
                teamDetails={teamDetails}
                exceptionDetails={exceptionDetails}
                approvalStatus={approvalStatus}
                requestStatus={requestStatus}
                clientConfirmationUrl={clientConfirmationUrl}
                exceptionRequestDate={exceptionRequestDate}
                totalPlanBudget={totalPlanBudget}
              />
            </Box>
          }
        />
        <Tabs items={tabItems} active="buying-brief-group" />
        <Box sx={{ width: '100%' }}>
          <Accordion
            defaultExpanded={true}
            summary={<Typography variant="Medium-14">PO Details</Typography>}
            details={
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '24px',
                  rowGap: '16px',
                }}
              >
                {buyingGroupDetails?.webappClientPO ? (
                  <Box
                    sx={{
                      width: 'calc(30% - 12px)',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '20px',
                    }}
                  >
                    {buyingGroupDetails?.discrepancyStatus ===
                    AppConstants.NOT_CREATED_ON_MINDER ? (
                      <Button
                        label="Create on Minder"
                        variant="contained"
                        disabled={
                          !hasAllPermissions([PermissionsConstants.CREATE_MINDER_IB]) || isHide
                        }
                        onClick={handleCreateOnMinder}
                      />
                    ) : (
                      <Button
                        disabled={
                          buyingGroupDetails?.discrepancyStatus ===
                            AppConstants.CREATED_AND_MATCHED ||
                          buyingGroupDetails?.minderClientPO?.isUpdatedOnMinder ||
                          isHide
                        }
                        label="Update on Minder"
                        variant="outlined"
                        onClick={handleUpdateOnMinder}
                      />
                    )}

                    {buyingGroupDetails?.webappClientPO?.clientPoUrl ? (
                      <Box
                        sx={{
                          width: 'calc(50% - 24px)',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '20px',
                        }}
                      >
                        <Button
                          key="View PO Client"
                          variant="outlined"
                          label="View PO Client"
                          onClick={handleOpenViewPoClient}
                        />
                      </Box>
                    ) : null}
                  </Box>
                ) : (
                  <Box />
                )}
                <Box
                  sx={{
                    width: 'calc(70% - 12px)',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '20px',
                  }}
                >
                  <Box
                    sx={{
                      width: 'calc(50% - 10px)',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: '20px',
                      backgroundColor: theme.palette.background,
                      borderRadius: '6px',
                      border: `1px solid ${theme.palette.text['500']}`,
                      padding: '12px',
                    }}
                  >
                    <Box sx={{ width: 'calc(50% - 20px)' }}>
                      <Typography variant="Medium-14" color={theme.palette.text['300']}>
                        BBG Number from Minder
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: 'calc(50% - 12px)',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                      }}
                    >
                      {buyingGroupDetails?.minderBbgNumber ? (
                        <OverflowTooltip text={buyingGroupDetails?.minderBbgNumber ?? '-'}>
                          {buyingGroupDetails?.minderBbgNumber}
                        </OverflowTooltip>
                      ) : (
                        '-'
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: 'calc(50% - 10px)',
                      display: 'flex',
                      height: '51px',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: '20px',
                      backgroundColor: theme.palette.background,
                      borderRadius: '6px',
                      border: `1px solid ${theme.palette.text['500']}`,
                      padding: '9px 12px',
                    }}
                  >
                    <Box sx={{ width: 'calc(50% - 20px)' }}>
                      <Typography variant="Medium-14" color={theme.palette.text['300']}>
                        Discrepancy Status
                      </Typography>
                    </Box>
                    {buyingGroupDetails?.discrepancyStatus && (
                      <Box
                        sx={{
                          width: 'calc(50% - 20px)',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'flex-end',
                        }}
                      >
                        <Chip
                          sx={{ height: '30px' }}
                          status={
                            buyingGroupDetails?.discrepancyStatus ===
                            AppConstants.CREATED_AND_MATCHED
                              ? 'success'
                              : 'warning'
                          }
                          label={MinderPoClientStatusMap[buyingGroupDetails?.discrepancyStatus]}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box sx={{ width: '100%' }}>{PoTable}</Box>
              </Box>
            }
          />
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            mt: '20px',
            flexDirection: 'column',
            gap: selectedCheckboxes && selectedCheckboxes.length > 0 ? '6px' : '24px',
          }}
        >
          {TableUtils}
          {BuyingGroupsTable}
        </Box>
        <Comments entityId={params?.bgid} entityType="BUYING_BRIEF_GROUP" />
      </Card>
    </>
  );
};

export default withErrorBoundary(BuyingGroup);
