import React, { useMemo, useRef, useState } from 'react';
import Table from '../../../../../../components/shared/table';
import { columns, data } from './ColumnData';
import { Box, Typography } from '@mui/material';
import { DeleteIcon, DownloadIcon, ErrorIcon } from '../../../../../../libs/svg-icons/icons';
import Button from '../../../../../../components/shared/buttons';
import { useTheme } from '@emotion/react';
import Dialog from '../../../../../../components/shared/dialog';
import { FormProvider, useForm } from 'react-hook-form';
import Textarea from '../../../../../../components/shared/form/textarea';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Chip from '../../../../../../components/shared/chip';
import CustomCheckbox from '../../../../../../components/shared/checkbox';

const schema = yup.object().shape({
  comments: yup.string().required('Comment is Required'),
});

const ViewInvoiceDocumentTable = () => {
  const viewInvoicedocumenttable = useRef();
  const theme = useTheme();
  const [openSendForApprovalDialog, setOpenSendForApprovalDialog] = useState(false);
  const [approvalPending, setApprovalPending] = useState(false);
  const [chipStatus, setChipStatus] = useState({ label: 'Approval Pending', status: 'warning' });
  const [showReason, setShowReason] = useState(false);
  const [openViewReasonDialog, setopenViewReasonDialog] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const handleOpenSendForApprovalDialog = () => {
    setOpenSendForApprovalDialog(true);
  };

  const handleCloseSendForApprovalDialog = () => {
    setOpenSendForApprovalDialog(false);
  };

  const handleSubmit = () => {
    setApprovalPending(true);
    setOpenSendForApprovalDialog(false);
  };

  const handleOpenViewReason = () => {
    setopenViewReasonDialog(true);
  };

  const handleCloseViewReson = () => {
    setopenViewReasonDialog(false);
  };

  const handleChipClick = () => {
    if (chipStatus.label === 'Approval Pending') {
      setChipStatus({ label: 'Approved', status: 'success' });
    } else if (chipStatus.label === 'Approved') {
      setChipStatus({ label: 'Rejected by AP Team', status: 'error' });
      setShowReason(true);
    }
  };

  const defaultColDef = useMemo(
    () => ({
      resizable: false,
      editable: false,
      flex: 1,
    }),
    [],
  );

  const schemaMethods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const VendorInvoiceDocumentsTable = useMemo(
    () => (
      <Table
        ref={viewInvoicedocumenttable}
        data={data}
        columns={columns}
        defaultColDef={defaultColDef}
        height={`calc(5 * 2.75rem - 62px)`}
      />
    ),
    [defaultColDef],
  );

  const handleCheckboxChange = event => {
    setCheckboxChecked(event.target.checked);
    setSubmitButtonDisabled(!event.target.checked);
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: '40px',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          gap: '20px',
          flexDirection: 'row',
        }}
      >
        <Button
          width="40px"
          height="40px"
          sx={{
            minWidth: '21px',
            padding: '5px',
          }}
          variant="outlined"
          startIcon={
            <Box
              component={'span'}
              sx={{
                width: '21px',
                height: '21px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <DownloadIcon fill={theme.palette.primary.main} />
            </Box>
          }
        />

        <Button
          width="40px"
          height="40px"
          sx={{
            minWidth: '21px',
            padding: '5px',
          }}
          variant="outlined"
          startIcon={
            <Box
              component={'span'}
              sx={{
                width: '21px',
                height: '21px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <DeleteIcon fill={theme.palette.primary.main} />
            </Box>
          }
        />

        {!approvalPending && (
          <Button
            label="Sent for Approval"
            variant="contained"
            onClick={handleOpenSendForApprovalDialog}
          />
        )}

        {approvalPending && (
          <>
            {showReason && (
              <Button label="View Reason" variant="outlined" onClick={handleOpenViewReason} />
            )}
            <Dialog
              open={openViewReasonDialog}
              onClose={handleCloseViewReson}
              headline={
                <Typography variant="SemiBold-22">Reason for Rejecting the Invoice</Typography>
              }
              description={
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '24px',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="Regular-14" sx={{ textAlign: 'left' }}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla faucibus
                      ultrices dui sit amet aliquam. Proin at nibh euismod, lobortis nisl at, tempor
                      enim
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Typography variant="Regular-14" sx={{ color: '#EF5B2B' }}>
                      Rejected by Harry Setyono from AP Team on 07-08-2023 at 10:00 AM{' '}
                    </Typography>
                  </Box>
                </Box>
              }
              width="636px"
              showDialogActions={false}
            />
            <Chip label={chipStatus.label} status={chipStatus.status} onClick={handleChipClick} />
          </>
        )}

        <Dialog
          open={openSendForApprovalDialog}
          onClose={handleCloseSendForApprovalDialog}
          icon={
            <Box
              sx={{
                width: '52px',
                height: '52px',
                borderRadius: '50%',
                background: theme.palette.background,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto',
                marginBottom: '24px',
              }}
            >
              <ErrorIcon fill="var(--deep-navy)" width="32px" height="32px" />
            </Box>
          }
          headline={
            <Typography variant="SemiBold-22">
              Total Invoice Amount is Less than the PO Amount
            </Typography>
          }
          description={
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                flexDirection: 'column',
              }}
            >
              <Box sx={{ marginTop: '10px', marginBottom: '24px' }}>
                <Typography variant="Regular-16">
                  Are you sure you want to submit all the invoices? Once you submit the invoices for
                  approval, you will no longer be able to modify or add any more invoices to this
                  purchase order.
                </Typography>
              </Box>

              <FormProvider {...schemaMethods}>
                <Box
                  sx={{
                    width: '602px',
                    marginBottom: '24px',
                  }}
                >
                  <Textarea
                    id="comments"
                    type="text"
                    minRows={5}
                    label="Comments (optional)"
                    placeholder="Write your reason here.."
                    {...schemaMethods.register('comments')}
                  />
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexDirection: 'row',
                    padding: '0px 13px 10px 13px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      marginTop: '5px',
                    }}
                  >
                    <CustomCheckbox checked={checkboxChecked} onChange={handleCheckboxChange} />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Typography variant="Regular-16" sx={{ textAlign: 'left' }}>
                      Yes, I understand and want to continue
                    </Typography>
                  </Box>
                </Box>
              </FormProvider>
            </Box>
          }
          width="650px"
          buttons={[
            <Button
              key="button1"
              width="300px"
              variant="outlined"
              label="Cancel"
              onClick={handleCloseSendForApprovalDialog}
            />,
            <Button
              type="submit"
              key="button2"
              width="300px"
              variant="contained"
              label="Submit"
              onClick={handleSubmit}
              disabled={submitButtonDisabled}
            />,
          ]}
          headlineAlignment="center"
          fullBodyScroll={true}
        />
      </Box>
      <Box sx={{ width: '100%' }}>{VendorInvoiceDocumentsTable}</Box>
    </Box>
  );
};

export default ViewInvoiceDocumentTable;
