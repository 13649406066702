import { createIcon } from '../createSVGIcon';

const path = (
  <g>
    <path d="M4 2V0H14V2H17.007C17.555 2 18 2.445 18 2.993V19.007C17.9997 19.2703 17.895 19.5227 17.7089 19.7089C17.5227 19.895 17.2703 19.9997 17.007 20H0.993C0.729721 19.9997 0.477302 19.895 0.291135 19.7089C0.104969 19.5227 0.000264735 19.2703 0 19.007V2.993C0 2.445 0.445 2 0.993 2H4ZM4 4H2V18H16V4H14V6H4V4ZM6 2V4H12V2H6Z" />
  </g>
);

export const ClipboardIcon = createIcon(path, {
  width: 18,
  height: 20,
  viewBox: '0 0 18 20',
  fill: 'none',
});
