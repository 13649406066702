import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { NavLink } from 'react-router-dom';
import { LogoutIcon, NotificationIcon, SettingsIcon } from '../../../libs/svg-icons/icons';
import LogoutModal from '../LogoutModal';
import UserDetails from '../../shared/user';
import { useAppSelector } from '../../../redux/hooks';

const drawerWidth = 190;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  height: '100px',
  backgroundColor: theme.palette.background,
  boxShadow: 'none',
  padding: '15px 25px',
  zIndex: theme.zIndex.drawer - 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open
    ? {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }
    : {
        width: `calc(100% - ${theme.spacing(9)})`,
        [theme.breakpoints.up('sm')]: {
          width: `calc(100% - ${theme.spacing(11)})`,
        },
      }),
  '& .MuiToolbar-root': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '8px',
  },
}));

const AppNavbar = ({
  open = false,
  showLogoutModal = () => null,
  handleShowLogoutModal = () => null,
  handleCloseLogoutModal = () => null,
}) => {
  const theme = useTheme();
  const userInfo = useAppSelector(state => state.auth);
  const { name = 'user name', email = 'user email' } = userInfo?.user || {};

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const menuId = 'user-account-menu';

  const handleCloseMenuAndShowLogoutModal = () => {
    handleMenuClose();
    handleShowLogoutModal();
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: theme.palette.white,
          width: '300px',
          borderRadius: '6px',
        },
      }}
    >
      <Box
        component="li"
        sx={{
          padding: '24px',
        }}
      >
        <UserDetails
          fullName="Harry Setyono"
          fullNameColor={theme.palette.text['100']}
          email="harry@groupm.com"
          emailColor={theme.palette.text['300']}
          initials="HS"
          initialsBackgroundColor={theme.palette.primary.main}
          initialsColor={theme.palette.white}
        />
      </Box>
      <Divider component="li" />
      <MenuItem
        onClick={handleMenuClose}
        sx={{
          gap: '16px',
          padding: '24px',
        }}
      >
        <SettingsIcon width="26px" height="26px" fill="var(--text-200)" />
        <Typography variant="Medium-16" color={theme.palette.text['200']}>
          Settings
        </Typography>
      </MenuItem>
      <MenuItem
        onClick={handleCloseMenuAndShowLogoutModal}
        sx={{
          gap: '16px',
          padding: '24px',
        }}
      >
        <LogoutIcon width="26px" height="26px" fill="var(--text-200)" />
        <Typography variant="Medium-16" color={theme.palette.text['200']}>
          Logout
        </Typography>
      </MenuItem>
      <LogoutModal open={showLogoutModal} handleClose={handleCloseLogoutModal} />
    </Menu>
  );

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar sx={{ gap: '24px' }}>
        <Box sx={{ flexGrow: 1 }} />
        <NavLink
          to="/app/notifications"
          style={{
            textDecoration: 'none',
            color: theme.palette.primary.main,
          }}
        >
          <IconButton
            size="large"
            aria-label="show 2 new notifications"
            sx={{
              width: 32,
              height: 32,
              p: 0.25,
            }}
          >
            <Box
              sx={{
                backgroundColor: theme.palette.error.main,
                width: 18,
                height: 18,
                borderRadius: 25,
                position: 'absolute',
                top: -9,
                right: '0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="Medium-12" color={theme.palette.text['600']}>
                2
              </Typography>
            </Box>
            <NotificationIcon fill="var(--text-600)" />
          </IconButton>
        </NavLink>
        <IconButton
          size="large"
          edge="end"
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          color="inherit"
          sx={{ maxWidth: '200px' }}
        >
          <UserDetails
            fullName={name}
            fullNameColor={theme.palette.white}
            email={email}
            emailColor={theme.palette.text['300']}
            tooltipPlacement="bottom"
            sx={{ width: '100%' }}
          />
        </IconButton>
        {/* {renderMenu} */}
      </Toolbar>
    </AppBar>
  );
};

export default AppNavbar;
