import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDewv1NbxwCtrdnJ_qVos41UC2uzjxbnPA',
  authDomain: 'marvelai-notification.firebaseapp.com',
  projectId: 'marvelai-notification',
  storageBucket: 'marvelai-notification.firebasestorage.app',
  messagingSenderId: '393112527256',
  appId: '1:393112527256:web:babd5e13c5e07f52280bed',
};
const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);

export async function requestPermission() {
  const permission = await Notification.requestPermission();

  if (permission === 'granted') {
    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_VAPID_KEY,
    });

    return token;
  } else if (permission === 'denied') {
    alert('You denied the notification');
    throw new Error('Notification permission denied');
  }
}
