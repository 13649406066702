export const AppConstants = {
  MAX_LENGTH_FOR_NAME: 100,

  MEDIA_PLAN_STATUS_NOT_INITIATED: 'NOT_INITIATED',
  MEDIA_PLAN_STATUS_MANAGER_APPROVAL_PENDING: 'MANAGER_APPROVAL_PENDING',
  MEDIA_PLAN_STATUS_MANAGER_APPROVED: 'MANAGER_APPROVED',
  MEDIA_PLAN_STATUS_MANAGER_REJECTED: 'MANAGER_REJECTED',
  MEDIA_PLAN_STATUS_CANCELLED: 'CANCELLED',

  MEDIA_PLAN_VALIDATION_NOT_INITIATED: 'NOT_INITIATED',
  MEDIA_PLAN_VALIDATION_NOT_VALIDATED: 'NOT_VALIDATED',
  MEDIA_PLAN_VALIDATION_IN_PROGRESS: 'IN_PROGRESS',
  MEDIA_PLAN_VALIDATION_VALIDATED: 'VALIDATED',
  MEDIA_PLAN_VALIDATION_CANCELLED: 'CANCELLED',

  SOMETHING_WENT_WRONG: 'Something went wrong! Please try again later.',

  IB_STATUS_NOT_SAVED: 'NOT_SAVED',
  IB_STATUS_SAVED: 'SAVED',
  IB_STATUS_CLIENT_UPLOADED: 'CLIENT_UPLOADED',
  IB_STATUS_CLIENT_CANCELLED: 'CANCELLED',
  // IB_STATUS_MANAGER_APPROVAL_PENDING: 'MANAGER_APPROVAL_PENDING',
  // IB_STATUS_MANAGER_APPROVED: 'MANAGER_APPROVED',
  // IB_STATUS_MANAGER_REJECTED: 'MANAGER_REJECTED',
  // IB_STATUS_UNLOCKED: 'UNLOCKED',

  PO_CLIENT_APPROVAL_PENDING: 'APPROVAL_PENDING',
  PO_CLIENT_EXCEPTION_NOT_INITIATED: 'NOT_INITIATED',
  PO_CLIENT_EXCEPTION_REQUESTED: 'PO_CLIENT_EXCEPTION_REQUESTED',
  PO_CLIENT_EXCEPTION_REQUEST_APPROVED: 'PO_CLIENT_EXCEPTION_REQUEST_APPROVED',
  PO_CLIENT_EXCEPTION_REJECTED: 'PO_CLIENT_EXCEPTION_REJECTED',

  ACTIVE: 'ACTIVE',

  // po client status

  NOT_CREATED_ON_MINDER: 'NOT_CREATED_ON_MINDER',
  CREATED_AND_NOT_MATCHED: 'CREATED_AND_NOT_MATCHED',
  CREATED_AND_MATCHED: 'CREATED_AND_MATCHED',

  MASTER_DATA_MB_SEARCH_ID: 11264,
  MASTER_DATA_MB_INTERNET_ID: 11263,
  MASTER_DATA_CONSTANT_TARGET_ID: 11087,

  //Project
  ENTITY_TYPE_PROJECT: 'PROJECT',
  ENTITY_TYPE_TASK: 'TASK',
  ENTITY_TYPE_SUBTASK: 'SUBTASK',
  EDIT_REQUEST_APPROVED: 'APPROVED',
  EDIT_REQUEST_REJECTED: 'REJECTED',

  FIELDTYPE_MULTI_SELECT: 'MULTI_SELECT',
  FIELDTYPE_SINGLE_SELECT: 'SINGLE_SELECT',
  FIELDTYPE_TEXT: 'TEXT',
  FIELDTYPE_DATE: 'DATE',

  GCP_STATUS_VALIDATED: 'VALIDATED',
  GCP_STATUS_NOT_VALIDATED: 'NOT_VALIDATED',
  GCP_STATUS_IN_PROGRESS: 'IN_PROGRESS',
  PROJECT_STATUS_CANCEL: 'CANCELLED',
  PROJECT_STATUS_COMPLETED: 'COMPLETED',

  SUBTASK_APPROVAL_MSG: 'Request for Subtask Approval',
  SUBTASK_EXCEPTION_APPROVAL_MSG: 'Subtask Approval Request with Exceptions',

  //HTTP STATUS
  HTTP_FORBIDDEN: 403,

  //error
  FORBIDDEN_ERROR:
    'Permission Denied: You do not have the necessary authorization to perform this action.',
  PAGE_ACCESS_ERROR: 'You do not have the necessary permissions to access this page.',
  GCP_VALIDATION_ERROR_ON_STATUS:
    'Data validation is incomplete. Please ensure the data is sent for validation first',
};
