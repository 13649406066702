import { Box, Typography } from '@mui/material';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme, alpha } from '@mui/material/styles';
import Chip from '../../../../../components/shared/chip';
import Button from '../../../../../components/shared/buttons';
import { CrossIcon, DeleteIcon, ErrorIcon, TickIcon } from '../../../../../libs/svg-icons/icons';
import Drawer from '../../../../../components/shared/drawer';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Dialog from '../../../../../components/shared/dialog';
import SelectField from '../../../../../components/shared/form/Select';
import RequestDetailsDialog from '../../../../../components/shared/dialog/RequestDetailsDialog';
import RequestDialogCard from '../../../../../components/shared/dialog/RequestDailogCard';
import {
  deleteMediaPlan,
  getApprovalStatus,
  getManagerList,
} from '../../../../../services/media-plan-service';
import { capitalize } from '../../../../../utils/string';
import { AppConstants } from '../../../../../constants/app-constants';
import { useEnqueueSnackbar } from '../../../../../components/shared/toast-provider/toastHook';
import Loader from '../../../../../components/shared/loader';
import { useNavigate } from 'react-router-dom';
import { PermissionsConstants } from '../../../../../constants/permissions-constants';
import { useHasAllPermission } from '../../../../../hooks/permissions';
import { formatDate } from '../../../../../libs/date/format';
import { useAppSelector } from '../../../../../redux/hooks';
import { columns } from './ColumnData';

const sendApprovalToManagerSchema = yup.object().shape({
  lineManager: yup.string().required('Line manager is required'),
});

const HeaderActions = ({
  tableRef = null,
  mediaPlanDetails,
  handleMediaPlanApprovalStatusChanged,
  handleRequestMediaPlanValidation,
  handleRequestMediaPlanValidationCancel,
  mediaPlanValidationErrors,
  rowData,
  isBBGCreated = false,
  businessUnitId = '',
}) => {
  const theme = useTheme();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isValidating, setValidating] = useState(false);
  const [errorDetails, setErrorDetails] = useState([]);
  const [openSendApprovalToManager, setOpenSendApprovalToManager] = useState(false);
  const [openDeleteMediaPlan, setOpenDeleteMediaPlan] = useState(false);
  const [openApprovedDetailsDialog, setOpenApprovedDetailsDialog] = useState(false);
  const [lineManagerValues, setLineManagerValues] = useState([]);
  const [approvalDetails, setApprovalDetails] = useState(null);
  const [isValidationCancel, setCancelValidation] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const enqueueSnackbar = useEnqueueSnackbar();
  const navigate = useNavigate();
  const hasAllPermissions = useHasAllPermission();

  const loggedInUserId = useAppSelector(state => state.auth.loggedInUserId);

  const fetchLineManagers = useCallback(async () => {
    if (businessUnitId)
      try {
        const response = await getManagerList({ businessUnitId });
        const managerList = response.data.data.models;
        if (managerList.length === 0) {
          setLineManagerValues([{ label: 'No data available', value: '' }]);
        } else {
          const options = managerList
            ?.filter(manager => manager.id !== loggedInUserId)
            ?.map(manager => ({
              label: capitalize(manager.name),
              value: manager.id,
            }));
          setLineManagerValues(options);
          // setSelectedManagerId(options[0].value);
        }
      } catch (error) {
        const { response } = error || {};
        const { message = AppConstants.SOMETHING_WENT_WRONG } = response?.data || {};

        enqueueSnackbar(message, 'error');
      }
  }, [businessUnitId]);

  const checkIfAllRowHaveExternalId = () => {
    let res = true;
    const nodes = [];
    if (!mediaPlanDetails?.isEditing && rowData) {
      tableRef?.current?.api.forEachNode(node => nodes.push(node));
    }
    if (nodes && nodes.length > 0) {
      nodes.forEach(node => (res = res && !!node.data?.externalId));
    } else {
      return false;
    }
    return res;
  };

  const deleteMediaPlanCall = async () => {
    setIsLoading(true);
    setOpenDeleteMediaPlan(false);
    handleCloseDeleteMediaPlan();
    await deleteMediaPlan(mediaPlanDetails.id)
      .then(response => {
        enqueueSnackbar('Media plan deleted successfully.');
        navigate('/app/media-plans');
      })
      .catch(error => {
        const { response } = error || {};
        const { message = AppConstants.SOMETHING_WENT_WRONG } = response?.data || {};
        enqueueSnackbar(message, 'error');
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchLineManagers();
  }, [fetchLineManagers]);

  useEffect(() => {
    if (openSendApprovalToManager) sendApprovalToManagerMethods.reset();
  }, [openSendApprovalToManager]);

  useEffect(() => {
    if (openApprovedDetailsDialog && mediaPlanDetails?.activeVersionId) {
      getApprovalStatus({ versionId: mediaPlanDetails.activeVersionId })
        .then(response => {
          const mediaPlanApprovalStatus = response.data;
          setApprovalDetails(mediaPlanApprovalStatus.data);
        })
        .catch(error => {
          const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
          enqueueSnackbar(message, 'error');
        });
    }
  }, [mediaPlanDetails?.activeVersionId, openApprovedDetailsDialog]);

  useEffect(() => {
    setErrorDetails(
      mediaPlanValidationErrors?.map(error => ({
        externalId: error.externalId,
        field:
          error.column === 'givenBudget'
            ? 'Given Budget'
            : columns().find(x => x.colId === error.column)?.headerName,
        type: error.errorType,
        message: error.message,
      })) ?? null,
    );
    if (
      mediaPlanValidationErrors &&
      [AppConstants.MEDIA_PLAN_VALIDATION_NOT_VALIDATED].includes(
        mediaPlanDetails?.validationStatus,
      )
    ) {
      setValidating(true);
    }
  }, [mediaPlanDetails?.validationStatus, mediaPlanValidationErrors, tableRef]);

  useEffect(() => {
    setTimeout(() => {
      if (tableRef.current && tableRef.current.api) {
        const requiredNodesDataWithError = [];
        tableRef.current.api?.forEachNode(node => {
          const requiredErrorsInNode = mediaPlanValidationErrors?.filter(
            x =>
              x.externalId === node.data?.externalId &&
              x.errorLevel === 'column' &&
              x.errorType === 'error',
          );
          const requiredWarningInNode = mediaPlanValidationErrors?.filter(
            x =>
              x.externalId === node.data?.externalId &&
              x.errorLevel === 'column' &&
              x.errorType === 'warnings',
          );

          if (
            (requiredErrorsInNode && requiredErrorsInNode.length > 0) ||
            (requiredWarningInNode && requiredWarningInNode.length > 0)
          ) {
            const requiredNodeDataWithError = {
              ...node.data,
              errorFields: requiredErrorsInNode?.reduce(
                (acc, error) => ({
                  ...acc,
                  [error.column]: error.message,
                }),
                {},
              ),
              warningFields: requiredWarningInNode?.reduce(
                (acc, error) => ({
                  ...acc,
                  [error.column]: error.message,
                }),
                {},
              ),
            };
            requiredNodesDataWithError.push(requiredNodeDataWithError);
          }
        });

        tableRef.current.api.applyTransaction({
          update: requiredNodesDataWithError,
        });
        tableRef.current.api.refreshCells({ force: true });
      }
    }, 3000);
  }, [mediaPlanValidationErrors, tableRef]);

  const isSendForApprovalButtonDisabled = useMemo(() => {
    return (
      mediaPlanDetails?.status !== 'FINALIZED' ||
      !hasAllPermissions([PermissionsConstants.SEND_FOR_APPROVAL_MEDIA_PLAN]) ||
      mediaPlanDetails?.activeVersionId !== mediaPlanDetails?.currentVersionId ||
      [AppConstants.MEDIA_PLAN_VALIDATION_IN_PROGRESS].includes(mediaPlanDetails?.validationStatus)
    );
  }, [
    hasAllPermissions,
    mediaPlanDetails?.activeVersionId,
    mediaPlanDetails?.currentVersionId,
    mediaPlanDetails?.status,
    mediaPlanDetails?.validationStatus,
  ]);

  const isValidateMediaPlanDisabled = useMemo(() => {
    return (
      !hasAllPermissions([PermissionsConstants.VALIDATE_MEDIA_PLAN]) ||
      [
        AppConstants.MEDIA_PLAN_VALIDATION_IN_PROGRESS,
        AppConstants.MEDIA_PLAN_VALIDATION_VALIDATED,
      ].includes(mediaPlanDetails?.validationStatus) ||
      mediaPlanDetails?.activeVersionId !== mediaPlanDetails?.currentVersionId ||
      !!mediaPlanDetails?.isEditing ||
      !checkIfAllRowHaveExternalId()
    );
  }, [
    checkIfAllRowHaveExternalId,
    hasAllPermissions,
    mediaPlanDetails?.activeVersionId,
    mediaPlanDetails?.currentVersionId,
    mediaPlanDetails?.isEditing,
    mediaPlanDetails?.validationStatus,
  ]);

  const buttonLabel = isSendForApprovalButtonDisabled
    ? 'Send for Approval'
    : 'Send for Approval to Manager';
  const buttonWidth = isSendForApprovalButtonDisabled ? '167px' : '272px';

  const validationLabel = useMemo(() => {
    if (
      [AppConstants.MEDIA_PLAN_VALIDATION_IN_PROGRESS].includes(mediaPlanDetails?.validationStatus)
    )
      return 'Validation in Progress';
    if ([AppConstants.MEDIA_PLAN_VALIDATION_VALIDATED].includes(mediaPlanDetails?.validationStatus))
      return 'Verified';
    return 'Validate';
  }, [mediaPlanDetails?.validationStatus]);

  const handleDrawerOpen = () => {
    if (isValidating) {
      setDrawerOpen(true);
    }
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleOpenSendApprovalToManager = () => {
    setOpenSendApprovalToManager(true);
  };

  const handleCloseSendApprovalToManager = () => {
    setOpenSendApprovalToManager(false);
  };

  const handleOpenDeleteMediaPlan = () => {
    setOpenDeleteMediaPlan(true);
  };

  const handleCloseDeleteMediaPlan = () => {
    setOpenDeleteMediaPlan(false);
  };

  const handleOpenApprovalDetailsDialog = () => {
    if (mediaPlanDetails?.activeVersionId === mediaPlanDetails?.currentVersionId)
      if (
        ![
          AppConstants.MEDIA_PLAN_STATUS_NOT_INITIATED,
          AppConstants.MEDIA_PLAN_STATUS_MANAGER_REJECTED,
        ].includes(mediaPlanDetails?.approvalStatus)
      )
        setOpenApprovedDetailsDialog(true);
  };

  const handleCloseApprovalDetailsDialog = () => {
    setOpenApprovedDetailsDialog(false);
  };

  const sendApprovalToManagerMethods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(sendApprovalToManagerSchema),
    defaultValues: {
      lineManager: '',
    },
  });

  const handleSendApprovalToManagerSubmit = data => {
    handleCloseSendApprovalToManager();

    handleMediaPlanApprovalStatusChanged(
      AppConstants.MEDIA_PLAN_STATUS_MANAGER_APPROVAL_PENDING,
      sendApprovalToManagerMethods.watch('lineManager'),
    );
  };

  const handleCancelApprovalDetailsDialog = () => {
    handleCloseApprovalDetailsDialog();

    handleMediaPlanApprovalStatusChanged(
      AppConstants.MEDIA_PLAN_STATUS_CANCELLED,
      mediaPlanDetails?.approvalManagerId,
    );
  };

  const handleValidateMediaPlan = useCallback(() => {
    if (tableRef.current && tableRef.current.api) {
      handleRequestMediaPlanValidation();
    }
  }, [mediaPlanValidationErrors, handleRequestMediaPlanValidation, tableRef]);

  const handleCancelValidateMediaPlan = useCallback(() => {
    if (tableRef.current && tableRef.current.api) {
      setCancelValidation(false);
      handleRequestMediaPlanValidationCancel();
    }
  }, [mediaPlanValidationErrors, handleRequestMediaPlanValidationCancel, tableRef]);

  const ErrorInfoButton = useMemo(
    () =>
      errorDetails && errorDetails.length > 0 ? (
        <Box
          sx={{
            backgroundColor: theme.palette.error.main,
            width: 24,
            height: 24,
            borderRadius: 25,
            position: 'absolute',
            top: -10,
            right: -10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 10,
          }}
        >
          <Typography variant="Medium-12" color={theme.palette.text['600']}>
            {errorDetails.length}
          </Typography>
        </Box>
      ) : null,
    [errorDetails, theme.palette.error.main, theme.palette.text],
  );

  const statusLabel = useMemo(() => {
    if (mediaPlanDetails?.status === 'DRAFT') {
      return capitalize(mediaPlanDetails?.status);
    } else if (mediaPlanDetails?.status === 'FINALIZED') {
      if (mediaPlanDetails?.approvalStatus === 'NOT_INITIATED') {
        return capitalize(mediaPlanDetails?.status);
      } else {
        return capitalize(mediaPlanDetails?.approvalStatus);
      }
    }
  }, [mediaPlanDetails?.approvalStatus, mediaPlanDetails?.status]);

  const statusColor = useMemo(() => {
    if (mediaPlanDetails?.status === 'DRAFT') {
      return 'warning';
    } else if (mediaPlanDetails?.status === 'FINALIZED') {
      if (
        mediaPlanDetails?.approvalStatus === AppConstants.MEDIA_PLAN_STATUS_MANAGER_APPROVED ||
        mediaPlanDetails?.approvalStatus === AppConstants.MEDIA_PLAN_STATUS_NOT_INITIATED
      ) {
        return 'success';
      } else {
        return 'warning';
      }
    }
  }, [mediaPlanDetails?.approvalStatus, mediaPlanDetails?.status]);

  const cards = [
    <RequestDialogCard
      key={1}
      headline="Manager"
      description={
        approvalDetails?.createdAt
          ? mediaPlanDetails?.approvalStatus === AppConstants.MEDIA_PLAN_STATUS_MANAGER_APPROVED
            ? `Approved by Manager on ${formatDate(approvalDetails?.createdAt, 'dd-MM-yyyy')} at ${formatDate(approvalDetails?.createdAt, 'hh:mm a')}`
            : `Requested on ${formatDate(approvalDetails?.createdAt, 'dd-MM-yyyy')} at ${formatDate(approvalDetails?.createdAt, 'hh:mm a')}`
          : ''
      }
      style={{
        color:
          mediaPlanDetails?.approvalStatus === AppConstants.MEDIA_PLAN_STATUS_MANAGER_APPROVED
            ? theme.palette.success.main
            : theme.palette.text['100'],
      }}
      ManagerName={approvalDetails?.manager}
    />,
    <RequestDialogCard
      key={2}
      headline="Requested by Planner"
      ManagerName={approvalDetails?.planner}
    />,
  ];

  const CancelValidationDialog = useMemo(
    () => (
      <Dialog
        open={isValidationCancel}
        onClose={() => {
          setCancelValidation(false);
        }}
        icon={
          <Box
            sx={{
              width: '52px',
              height: '52px',
              borderRadius: '50%',
              background: theme.palette.background,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 auto',
              marginBottom: '24px',
            }}
          >
            <DeleteIcon fill="var(--deep-navy)" width="32px" height="32px" />
          </Box>
        }
        headline={<Typography variant="SemiBold-22">Cancel Validations</Typography>}
        description={
          <Typography variant="Regular-16" sx={{ marginBottom: '24px' }}>
            Are you sure you want to cancel validations for the media plan rows?
          </Typography>
        }
        buttons={[
          <Button
            key="button1"
            width="176px"
            variant="outlined"
            label="No"
            onClick={() => {
              setCancelValidation(false);
            }}
          />,
          <Button
            key="button2"
            width="176px"
            variant="contained"
            label="Yes, Cancel"
            onClick={handleCancelValidateMediaPlan}
          />,
        ]}
        width="420px"
        headlineAlignment="center"
      />
    ),
    [isValidationCancel],
  );

  const handleCancelValidation = useCallback(() => {
    setCancelValidation(true);
  }, [isValidationCancel]);

  return (
    <>
      <Loader isLoading={isLoading} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '1.25rem',
        }}
      >
        {(mediaPlanDetails?.status || mediaPlanDetails?.approvalStatus) && (
          <Chip
            status={statusColor}
            label={statusLabel}
            styles={{
              height: '40px',
            }}
            onClick={handleOpenApprovalDetailsDialog}
          />
        )}
        <RequestDetailsDialog
          open={openApprovedDetailsDialog}
          onClose={handleCloseApprovalDetailsDialog}
          width="668px"
          headline={
            <Typography variant="SemiBold-22" alignItems="flex-start" justifyContent="flex-start">
              View Request to Manager
            </Typography>
          }
          cards={cards}
          chips={
            <Chip
              status={statusColor}
              label={statusLabel}
              styles={{
                height: '40px',
              }}
              onClick={handleOpenApprovalDetailsDialog}
            />
          }
          buttons={
            mediaPlanDetails?.approvalStatus ===
              AppConstants.MEDIA_PLAN_STATUS_MANAGER_APPROVAL_PENDING &&
            hasAllPermissions([PermissionsConstants.SEND_FOR_APPROVAL_MEDIA_PLAN]) ? (
              <Button
                variant="outlined"
                label="Cancel Request"
                width="152px"
                height="40px"
                status="success"
                onClick={handleCancelApprovalDetailsDialog}
              />
            ) : null
          }
        />

        <Box sx={{ position: 'relative' }} onClick={handleDrawerOpen}>
          {ErrorInfoButton}

          <Button
            key="errors"
            width="40px"
            height="40px"
            sx={{
              minWidth: '21px',
              padding: '5px',
              borderColor: [AppConstants.MEDIA_PLAN_VALIDATION_VALIDATED].includes(
                mediaPlanDetails?.validationStatus,
              )
                ? theme.palette.success.main
                : theme.palette.primary.main,
            }}
            variant="outlined"
            startIcon={
              <Box
                component={'span'}
                sx={{
                  width: '21px',
                  height: '21px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {[AppConstants.MEDIA_PLAN_VALIDATION_VALIDATED].includes(
                  mediaPlanDetails?.validationStatus,
                ) ? (
                  <TickIcon fill={theme.palette.success.main} />
                ) : (
                  <ErrorIcon fill={theme.palette.primary.main} />
                )}
              </Box>
            }
          />
        </Box>
        {errorDetails && (
          <Drawer
            open={drawerOpen}
            headline="Correction"
            onClose={handleDrawerClose}
            crossIcon={<CrossIcon fill={theme.palette.text['100']} />}
            cardData={errorDetails}
            width="396px"
          />
        )}
        <Button
          key="Validate"
          disabled={isValidateMediaPlanDisabled}
          onClick={handleValidateMediaPlan}
          variant="outlined"
          label={validationLabel}
        />
        {[AppConstants.MEDIA_PLAN_VALIDATION_IN_PROGRESS].includes(
          mediaPlanDetails?.validationStatus,
        ) && (
          <>
            {CancelValidationDialog}
            <Button
              key="cancel-validation"
              onClick={handleCancelValidation}
              variant="outlined"
              label="Cancel Validation"
            />
          </>
        )}
        <Button
          key="delete"
          width="40px"
          height="40px"
          sx={{
            minWidth: '21px',
            padding: '5px',
          }}
          onClick={handleOpenDeleteMediaPlan}
          disabled={isBBGCreated || !hasAllPermissions([PermissionsConstants.DELETE_MEDIA_PLAN])}
          variant="outlined"
          startIcon={
            <Box
              component={'span'}
              sx={{
                width: '21px',
                height: '21px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                opacity: isBBGCreated ? '0.5' : '1',
              }}
            >
              <DeleteIcon fill={theme.palette.primary.main} />
            </Box>
          }
        />
        {hasAllPermissions([PermissionsConstants.DELETE_MEDIA_PLAN]) && (
          <Dialog
            open={openDeleteMediaPlan}
            onClose={handleCloseDeleteMediaPlan}
            icon={
              <Box
                sx={{
                  width: '52px',
                  height: '52px',
                  borderRadius: '50%',
                  background: theme.palette.background,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '0 auto',
                  marginBottom: '24px',
                }}
              >
                <DeleteIcon fill="var(--deep-navy)" width="32px" height="32px" />
              </Box>
            }
            headline={<Typography variant="SemiBold-22">Delete Media Plan</Typography>}
            description={
              <Typography variant="Regular-16" sx={{ marginBottom: '24px' }}>
                Are you sure you want to delete this media plan? It will also delete other linked
                documents as well. This action can’t be undone
              </Typography>
            }
            buttons={[
              <Button
                key="button1"
                width="176px"
                variant="outlined"
                label="Cancel"
                onClick={handleCloseDeleteMediaPlan}
              />,
              <Button
                type="submit"
                key="button2"
                width="176px"
                variant="contained"
                label="Delete"
                onClick={deleteMediaPlanCall}
              />,
            ]}
            width="420px"
            headlineAlignment="center"
          />
        )}
        {[
          AppConstants.MEDIA_PLAN_STATUS_NOT_INITIATED,
          AppConstants.MEDIA_PLAN_STATUS_MANAGER_REJECTED,
        ].includes(mediaPlanDetails?.approvalStatus) && (
          <Button
            key="send-for-approval"
            width={buttonWidth}
            height="40px"
            variant="contained"
            onClick={handleOpenSendApprovalToManager}
            disabled={isSendForApprovalButtonDisabled}
            label={buttonLabel}
          />
        )}

        {hasAllPermissions([PermissionsConstants.SEND_FOR_APPROVAL_MEDIA_PLAN]) && (
          <FormProvider {...sendApprovalToManagerMethods}>
            <Dialog
              open={openSendApprovalToManager}
              onClose={handleCloseSendApprovalToManager}
              headline={
                <Typography
                  variant="SemiBold-22"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  Send to Manager for Approval
                </Typography>
              }
              description={
                <Box sx={{ width: '100%', textAlign: 'left' }}>
                  <form>
                    <SelectField
                      label="Line Manager"
                      id="line-manager"
                      searchable
                      options={lineManagerValues}
                      menuHeight="400px"
                      height="48px"
                      selectHeight="50px"
                      placeholder="Select manager for approval"
                      {...sendApprovalToManagerMethods.register('lineManager')}
                      clearSelectionButton={true}
                    />
                  </form>
                </Box>
              }
              buttons={[
                <Button
                  key="button-1"
                  type="submit"
                  onClick={sendApprovalToManagerMethods.handleSubmit(
                    handleSendApprovalToManagerSubmit,
                  )}
                  disabled={!sendApprovalToManagerMethods.formState.isValid}
                  width="167px"
                  variant="contained"
                  label="Send for Approval"
                />,
              ]}
              width="668px"
              height="108px"
              backgroundColor="white"
            />
          </FormProvider>
        )}
      </Box>
    </>
  );
};

export default memo(HeaderActions);
