import axiosInstance from './api';
import { ApiURLConstants } from '../constants/api-url-constants';

const globalHeaders = {
  'Content-Type': 'application/json',
};

const API_BASE_URL = process.env.REACT_APP_USER_API_BASE_URL;
const API = axiosInstance;

export const verifyUser = async () => {
  const headers = { ...globalHeaders };
  const uri = `${API_BASE_URL}${ApiURLConstants.VERIFY_USER_AFTER_LOGIN}`;
  const response = await API.get(uri, {
    headers,
  });
  return response;
};
