// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.svgIcon {
  display: inline-block;
  line-height: 0;
  color: inherit;
  fill: currentColor;
  cursor: inherit;
  pointer-events: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/libs/svg-icons/SVGIcon.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,cAAc;EACd,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,uBAAuB;AACzB","sourcesContent":[".svgIcon {\n  display: inline-block;\n  line-height: 0;\n  color: inherit;\n  fill: currentColor;\n  cursor: inherit;\n  pointer-events: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
