import React, { useState, useEffect, useCallback } from 'react';
import Dialog from '../../../../../../components/shared/dialog';
import Button from '../../../../../../components/shared/buttons';
import { Box, Typography, useTheme } from '@mui/material';
import { InfoIcon } from '../../../../../../libs/svg-icons/icons';
import Select from '../../../../../../components/shared/form/Select/select';
import { capitalize } from '../../../../../../utils/string';

export default function SendEditRequest({ statusOptions = [], sendEditRequestCall = () => {} }) {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');

  const handleCloseDialog = useCallback(() => {
    setSelectedStatus('');
    setOpen(false);
  });

  const handleOpenDialog = useCallback(() => {
    setOpen(true);
  });

  const handleEditRequest = useCallback(() => {
    sendEditRequestCall(selectedStatus);
    handleCloseDialog();
  }, [selectedStatus]);

  return (
    <>
      <Button label={'Send Edit Request'} sx={{ height: '40px' }} onClick={handleOpenDialog} />
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        icon={
          <Box
            sx={{
              width: '52px',
              height: '52px',
              borderRadius: '50%',
              background: theme.palette.background,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 auto',
              marginBottom: '24px',
            }}
          >
            <InfoIcon fill="var(--deepNavy)" width="32px" height="32px" />
          </Box>
        }
        headline={<Typography variant="SemiBold-22">Send Edit Request</Typography>}
        description={
          <>
            <Typography variant="Regular-16" sx={{ marginBottom: '24px' }}>
              Are you sure you want to send edit request to finance team? This action can’t be
              undone
            </Typography>
            <Box sx={{ width: '370px', textAlign: 'left', marginBottom: '15px' }}>
              <Select
                id="edit-request-status"
                options={statusOptions}
                placeholder="Select state for request"
                optionMapFunction={options =>
                  options?.map(x => ({ id: x, label: capitalize(x), value: x }))
                }
                value={selectedStatus}
                menuHeight="400px"
                selectHeight="48px"
                onChange={e => {
                  setSelectedStatus(e.target.value);
                }}
              />
            </Box>
          </>
        }
        buttons={[
          <Button
            key="cancel"
            width="176px"
            variant="outlined"
            label="Cancel"
            onClick={handleCloseDialog}
          />,
          <Button
            key="yes"
            width="176px"
            variant="contained"
            label={'Send Request'}
            disabled={!selectedStatus}
            onClick={handleEditRequest}
          />,
        ]}
        width="420px"
        height="150px"
        headlineAlignment="center"
      />
    </>
  );
}
