import { createTheme } from '@mui/material';
import { themePalette } from './color';

export const themeInputs = createTheme(themePalette, {
  components: {
    MuiFormControl: {
      input: {
        '&:-webkit-autofill': {
          '-webkit-box-shadow': '0 0 0 100px #000 inset',
          '-webkit-text-fill-color': themePalette.palette.secondary.white,
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            width: '100%',
            fontFamily: 'Poppins',
            '& .MuiOutlinedInput-root': {
              height: '48px',
              borderRadius: '8px',
              border: `1px solid ${themePalette.palette.text[500]}`,
              backgroundColor: themePalette.palette.white,
              '&:hover': {
                borderColor: themePalette.palette.primary.main,
              },
              '& fieldset': {
                border: 'none',
              },
              input: {
                height: 'inherit',
                color: themePalette.palette.text[100],
                '&:-webkit-autofill': {
                  transitionDelay: '9999s',
                  transitionProperty: 'background-color, color',
                },
                '&::placeholder': {
                  color: themePalette.palette.text[300],
                  fontSize: '14px',
                  lineHeight: '23px',
                  fontWeight: 500,
                },
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                border: 'none',
              },
              fontSize: '14px',
              lineHeight: '23px',
              fontWeight: 500,
            },
            '& .MuiFormHelperText-root': {
              margin: 0,
              marginTop: '4px',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '23px',
            },
            '& .MuiOutlinedInput-root.Mui-disabled': {
              backgroundColor: themePalette.palette.text[600],
              '&:hover': {
                borderColor: themePalette.palette.text[500],
              },
            },
            '& .MuiOutlinedInput-root.MuiInputBase-colorError': {
              borderColor: themePalette.palette.error.main,
              '&:hover': {
                borderColor: themePalette.palette.error.main,
              },
              '&.Mui-focused': {
                border: `1px solid ${themePalette.palette.error.main}`,
                borderRadius: '8px !important',
              },
            },
            '& .MuiOutlinedInput-root.MuiInputBase-colorWarning': {
              borderColor: themePalette.palette.warning.main,
              '&:hover': {
                borderColor: themePalette.palette.warning.main,
              },
              '&.Mui-focused': {
                border: `1px solid ${themePalette.palette.warning.main}`,
                borderRadius: '8px !important',
              },
            },
            '& .MuiOutlinedInput-root.MuiInputBase-colorSuccess': {
              borderColor: themePalette.palette.success.main,
              '&:hover': {
                borderColor: themePalette.palette.success.main,
              },
              '&.Mui-focused': {
                border: `1px solid ${themePalette.palette.success.main}`,
                borderRadius: '8px !important',
              },
            },
          },
        },
      ],
    },
  },
});
