import React, { useState } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import { Outlet } from 'react-router-dom';
import AppNavbar from '../components/layout/AppNavbar';
import AppSidebar from '../components/layout/AppSidebar';

const DashboardLayout = () => {
  const [open, setOpen] = React.useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleShowLogoutModal = () => {
    setShowLogoutModal(true);
  };

  const handleCloseLogoutModal = () => {
    setShowLogoutModal(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppNavbar
        open={open}
        showLogoutModal={showLogoutModal}
        handleShowLogoutModal={handleShowLogoutModal}
        handleCloseLogoutModal={handleCloseLogoutModal}
      />
      <AppSidebar
        open={open}
        setOpen={setOpen}
        showLogoutModal={showLogoutModal}
        handleShowLogoutModal={handleShowLogoutModal}
        handleCloseLogoutModal={handleCloseLogoutModal}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: '15px 25px',
          mt: 12.5,
          minHeight: 'calc(100vh - 100px)',
          width: 'calc(100vw - 104px)',
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default DashboardLayout;
