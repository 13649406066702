import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { styled } from '@mui/system';

const NonClickableTabs = styled(Tabs)({
  '& .MuiTabs-flexContainer': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiTab-root': {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#A1A6AE',
    padding: '0 8px',
    '&.Mui-selected': {
      color: '#16181A',
      '& .NumberCircle': {
        borderColor: '#16181A',
      },
    },
    '& .NumberCircle': {
      borderColor: '#A1A6AE',
    },
    '&:not(.Mui-selected)': {
      pointerEvents: 'none',
    },
  },
});

const NumberCircle = styled('span')({
  display: 'inline-block',
  width: '32px',
  height: '32px',
  lineHeight: '32px',
  borderRadius: '50%',
  textAlign: 'center',
  border: '1px solid',
  marginRight: '8px',
  fontFamily: 'Poppins',
  fontWeight: 600,
  fontSize: '14px',
});

const DashedDivider = styled('div')({
  width: '70px',
  height: '1px',
  borderBottom: '1px dashed #6D7581',
  margin: '0 8px',
});

const CustomTab = ({ label, number, ...props }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <Tab
      label={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <NumberCircle className="NumberCircle">{number}</NumberCircle>
          {label}
        </div>
      }
      disableRipple
      {...props}
    />
    {!props.isLast && <DashedDivider />}
  </div>
);

const NonClickableTab = ({ tabs, value, onChange }) => (
  <NonClickableTabs value={value} onChange={onChange}>
    {tabs.map((tab, index) => (
      <CustomTab
        key={index}
        label={tab.label}
        number={index + 1}
        isLast={index === tabs.length - 1}
      />
    ))}
  </NonClickableTabs>
);

export default NonClickableTab;
