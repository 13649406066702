import { Box, Typography } from '@mui/material';
import Input from '../form/Input';
import Button from '../buttons';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from '../form/Select';
import DatePicker from '../form/date-picker';
import DragAndDropFilePicker from '../../shared/form/fileupload';
import { useMemo, useState } from 'react';
import { UploadIcon } from '../../../libs/svg-icons/icons';
import { useTheme } from '@emotion/react';
import Textarea from '../form/textarea';
import Chip from '../chip';
import SearchableSelect from '../form/SearchableSelect/searchable-select';
import {
  getPOClientDetails,
  searchPOClient,
  assignPOToBBG,
} from '../../../services/media-plan-service';
import { AppConstants } from '../../../constants/app-constants';
import { useEnqueueSnackbar } from '../toast-provider/toastHook';
import { capitalize } from '../../../utils/string';
import Loader from '../loader';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useHasAllPermission } from '../../../hooks/permissions';
import { PermissionsConstants } from '../../../constants/permissions-constants';
import { uploadFile } from '../../../services/file-upload-service';
import OverflowTooltip from '../tooltip/OverflowTooltip';

const schema = yup.object().shape({
  clientPOViaGCP: yup.string(),
  poClientNo: yup.string().required('PO Client no is required'),
  status: yup.string().required('Status is required'),
  startDate: yup.object().required('Start Date is required'),
  endDate: yup.object().required('End Date is required'),
  expiredDate: yup.object().required('Expired Date is required'),
  poAmount: yup.number().required('PO Amount is required'),
  description: yup.string().required('PO Client Description is required'),
  clientPOFileUpload: yup.mixed().required('Upload PO Client Document is required'),
});

const ClientPoViaGcpForm = ({
  handleCloseGetPoCLientViaGcp,
  fetchBuyingBriefGroup = () => {},
  totalPlanBudget,
}) => {
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const enqueueSnackbar = useEnqueueSnackbar();
  const params = useParams();
  const hasAllPermissions = useHasAllPermission();

  const [isLoading, setIsLoading] = useState(false);
  const [showUploadDoc, setShowUploadDoc] = useState(false);
  const [isFileUploadDisabled, setIsFileUploadDisabled] = useState(false);

  const status = useMemo(() => ['ACTIVE', 'REVISED'], []);

  const {
    formState: { isValid },
    setError,
    clearErrors,
  } = methods;

  const theme = useTheme();

  const getMaxDate = givenDate => {
    const givenDateObj = new Date(givenDate);
    const year = givenDateObj.getFullYear();
    const month = givenDateObj.getMonth();
    const date = givenDateObj.getDate();
    let maxDate;
    if (month + 3 > 12) {
      maxDate = new Date(year + 1, (month + 3) % 12, date);
    } else {
      maxDate = new Date(year, (month + 3) % 12, date);
    }

    return dayjs(maxDate);
  };

  const handlePoClientUpload = file => {
    methods.setValue('clientPOFileUpload', file, { shouldValidate: true });
    methods.trigger();
  };

  const getPOClientSearchResults = searchText => {
    return searchPOClient({ searchText })
      .then(response => {
        const { data } = response?.data ?? {};
        return data;
      })
      .catch(error => {
        const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
        enqueueSnackbar(message, 'error');
      });
  };

  const handlePOClientOptionSelect = poClientNo => {
    setIsLoading(true);
    getPOClientDetails(poClientNo)
      .then(response => {
        const { data } = response?.data ?? {};
        methods.reset({
          clientPOViaGCP: data?.clientPoNumber,
          poClientNo: data?.clientPoNumber,
          status: data?.status,
          startDate: dayjs(data?.startDate),
          endDate: dayjs(data?.endDate),
          expiredDate: dayjs(data?.expiredDate),
          description: data?.clientPoDescription,
          clientPOFileUpload: data?.clientPoUrl,
          poAmount: data?.clientPoAmount,
        });

        if (totalPlanBudget > data?.clientPoAmount) {
          setError('poAmount', {
            message: 'Media plan budget should not be greater than PO amount',
          });
          setIsFileUploadDisabled(true);
        } else {
          clearErrors('poAmount');
          setIsFileUploadDisabled(false);
        }

        setShowUploadDoc(!data?.clientPoUrl);
      })
      .catch(error => {
        const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
        enqueueSnackbar(message, 'error');
      })
      .finally(() => setIsLoading(false));
  };

  const handleSearchCloseClick = () => {
    methods.reset({
      clientPOViaGCP: '',
      poClientNo: '',
      status: '',
      startDate: '',
      endDate: '',
      expiredDate: '',
      description: '',
      clientPOFileUpload: '',
      poAmount: '',
    });
    clearErrors();
  };

  const handlePoClientRemoved = () => {
    methods.setValue('clientPOFileUpload', undefined, { shouldValidate: true });
    methods.trigger();
  };

  const handlePoClientFileUpload = file => {
    if (hasAllPermissions([PermissionsConstants?.UPDATE_BUYING_BRIEF_GROUP])) {
      setIsLoading(true);
      if (showUploadDoc) {
        const fileUploadDto = {
          id: params?.bgid,
          fileOrigin: 'INTERNAL_BRIEF',
        };
        const formData = new FormData();
        formData.append(
          'fileUploadDto',
          new Blob([JSON.stringify(fileUploadDto)], {
            type: 'application/json',
          }),
        );
        formData.append('file', file);

        uploadFile(formData)
          .then(response => {
            const { data } = response.data;
            if (data) {
              return data;
            }
            return null;
          })
          .then(data => {
            if (data) {
              const payload = {
                'bbgId': params?.bgid,
                'clientPoUrl': data?.url,
              };

              assignPOToBBG(methods.watch('poClientNo'), payload)
                .then(() => {
                  enqueueSnackbar(
                    'PO client assign to buying brief group successfully!',
                    'success',
                  );
                  fetchBuyingBriefGroup();
                  handleCloseGetPoCLientViaGcp();
                })
                .catch(error => {
                  const { message = AppConstants.SOMETHING_WENT_WRONG } =
                    error?.response?.data || {};
                  enqueueSnackbar(message, 'error');
                });
            }
          })
          .catch(error => {
            const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
            enqueueSnackbar(message, 'error');
          })
          .finally(() => setIsLoading(false));
      } else {
        const payload = {
          'bbgId': params?.bgid,
          'clientPoUrl': methods.watch('clientPOFileUpload'),
        };
        assignPOToBBG(methods.watch('poClientNo'), payload)
          .then(() => {
            enqueueSnackbar('PO client assign to buying brief group successfully!', 'success');
            fetchBuyingBriefGroup();
            handleCloseGetPoCLientViaGcp();
          })
          .catch(error => {
            const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
            enqueueSnackbar(message, 'error');
          })
          .finally(() => setIsLoading(false));
      }
    }
  };

  const poClientStatusColor = status => {
    switch (status) {
      case 'ACTIVE':
        return 'success';
      case 'REVISED':
        return 'warning';
      case 'CANCELLED':
        return 'error';
      default:
        return 'warning';
    }
  };

  return (
    <>
      <Loader isLoading={isLoading} />

      <Box
        sx={{
          height: 'auto',
          overflowY: 'auto',
          paddingRight: '8px',
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#FFFFFF',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#CDD1D7',
            borderRadius: '4px',
          },
        }}
      >
        <FormProvider {...methods}>
          <form>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '620px',
                gap: '24px',
              }}
            >
              <Box sx={{ width: '100%' }}>
                <SearchableSelect
                  onOptionSearch={getPOClientSearchResults}
                  optionMapFunction={options =>
                    options?.map(x => ({
                      id: x.clientPoNumber,
                      label: x.clientPoNumber,
                      value: x.clientPoNumber,
                      status: x.status,
                      bbgId: x.webappBbgNumber,
                    }))
                  }
                  placeholder="Search Client PO by PO number..."
                  optionRenderer={option => (
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography variant="Regular-16">{option.label}</Typography>

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '8px',
                        }}
                      >
                        <Box
                          sx={{
                            width: '175px',
                            height: '30px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            backgroundColor: theme.palette.text['600'],
                            padding: '6px 12px',
                            borderRadius: '5px',
                            border: `1px solid ${theme.palette.text['500']}`,
                          }}
                        >
                          <Typography variant="Medium-12" color="#6D7581" sx={{ width: '70px' }}>
                            BBG ID:
                          </Typography>
                          <OverflowTooltip text={option.bbgId} sx={{ textAlign: 'right' }}>
                            <Typography variant="Medium-12" color="#16181A">
                              {option.bbgId ?? '-'}
                            </Typography>
                          </OverflowTooltip>
                        </Box>
                        {option.status ? (
                          <Chip
                            label={capitalize(option.status)}
                            status={poClientStatusColor(option.status)}
                            height="25px"
                            styles={{
                              fontSize: '0.75rem',
                              height: '30px',
                              width: '110px',
                            }}
                          />
                        ) : null}
                      </Box>
                    </Box>
                  )}
                  onOptionSelect={handlePOClientOptionSelect}
                  onSearchCloseClick={handleSearchCloseClick}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
                <Box sx={{ width: '50%' }}>
                  <Input
                    id="po-client-no"
                    inputHeight="48px"
                    type="text"
                    label="PO Client Number"
                    placeholder="Enter PO client number"
                    disabled={true}
                    {...methods.register('poClientNo')}
                  />
                </Box>
                <Box sx={{ width: '50%', textAlign: 'left' }}>
                  <Select
                    id="status-filter"
                    options={status}
                    type="text"
                    selectHeight="48px"
                    label="Status"
                    placeholder="Select status"
                    disabled={true}
                    optionMapFunction={options =>
                      options?.map(x => ({ id: x, label: x, value: x }))
                    }
                    {...methods.register('status')}
                  />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
                <Box sx={{ width: '50%', textAlign: 'left' }}>
                  <DatePicker
                    id="start-date-picker"
                    label="Start Date"
                    placeholder="Select start date"
                    {...methods.register('startDate')}
                    onChange={() => null}
                    disabled={true}
                    views="MM/DD"
                    minDate={dayjs(new Date())}
                    maxDate={getMaxDate(new Date())}
                  />
                </Box>
                <Box sx={{ width: '50%', textAlign: 'left' }}>
                  <DatePicker
                    id="end-date-picker"
                    label="End Date"
                    placeholder="Select end date"
                    {...methods.register('endDate')}
                    onChange={() => null}
                    disabled={true}
                    views="MM/DD"
                    minDate={dayjs(new Date())}
                    maxDate={getMaxDate(new Date())}
                  />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
                <Box sx={{ width: '50%', textAlign: 'left' }}>
                  <DatePicker
                    id="expired-date-picker"
                    label="Expired Date"
                    placeholder="Select expired date"
                    {...methods.register('expiredDate')}
                    onChange={() => null}
                    disabled={true}
                    views="MM/DD"
                    minDate={dayjs(new Date())}
                    maxDate={getMaxDate(new Date())}
                  />
                </Box>
                <Box sx={{ width: '50%' }}>
                  <Input
                    id="po-amount"
                    inputHeight="48px"
                    type="number"
                    label="PO Amount"
                    placeholder="Enter PO amount"
                    {...methods.register('poAmount')}
                    endIcon={
                      <Typography variant="Medium-14" color={theme.palette.text['400']}>
                        IDR
                      </Typography>
                    }
                    disabled={true}
                    error={!!methods.formState.errors.poAmount}
                    helperText={methods.formState.errors.poAmount?.message}
                  />
                </Box>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ width: '100%' }}>
                  <Textarea
                    id="po-client-description"
                    inputHeight="130px"
                    type="text"
                    label="PO Client Description"
                    placeholder="Write PO client description..."
                    {...methods.register('description')}
                    disabled={true}
                  />
                </Box>
              </Box>
              <Box sx={{ display: 'flex' }}>
                {showUploadDoc ? (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '5px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Typography variant="Medium-16">Upload PO Client</Typography>
                    </Box>

                    <DragAndDropFilePicker
                      height="100px"
                      multi={false}
                      icon={<UploadIcon fill={theme.palette.primary.main} />}
                      onUpload={handlePoClientUpload}
                      headline="Upload PO Client Document"
                      description="((Only pdf file, maximum 20MB file size))"
                      onRemove={handlePoClientRemoved}
                      acceptedfileExtensions={['pdf']}
                      disabled={isFileUploadDisabled}
                      {...methods.register('clientPOFileUpload')}
                    />
                  </Box>
                ) : null}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                }}
              >
                <Button
                  variant="contained"
                  label="Get Purchase Order"
                  disabled={!isValid}
                  onClick={() => {
                    handlePoClientFileUpload(methods.watch('clientPOFileUpload'));
                  }}
                />
              </Box>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </>
  );
};

export default ClientPoViaGcpForm;
