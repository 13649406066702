import { createIcon } from '../createSVGIcon';

const path = (
  <g>
    <path d="M5.33329 25.3333H26.6666V16H29.3333V26.6667C29.3333 27.0203 29.1928 27.3594 28.9428 27.6095C28.6927 27.8595 28.3536 28 28 28H3.99996C3.64634 28 3.3072 27.8595 3.05715 27.6095C2.8071 27.3594 2.66663 27.0203 2.66663 26.6667V16H5.33329V25.3333ZM17.3333 12V21.3333H14.6666V12H7.99996L16 4L24 12H17.3333Z" />
  </g>
);

export const UploadClientIcon = createIcon(path, {
  width: 32,
  height: 32,
  viewBox: '0 0 32 32',
  fill: 'none',
});
