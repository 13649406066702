import { Backdrop, Typography } from '@mui/material';
import React from 'react';
import Button from '../buttons';
import './backdrop.css';
import PermissionIcon from '../../../assets/images/permissions.svg';

export default function BackdropOverlay({
  open,
  pageWarning = 'You are not authorized to view this page',
  handleClose = () => {},
  buttonLabel = 'Redirect',
  clickAction = () => {},
}) {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={handleClose}
    >
      <div style={{ textAlign: 'center' }}>
        <div>
          <img
            alt="permissions"
            src={PermissionIcon}
            style={{ width: '50px', height: '50px', marginBottom: '5px' }}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <Typography variant="Medium-20">{pageWarning}</Typography>
        </div>
        <div style={{ marginTop: '30px' }}>
          <Button
            type="button"
            key="redirect-btn"
            variant="contained"
            label={buttonLabel}
            onClick={clickAction}
          />
        </div>
      </div>
    </Backdrop>
  );
}
