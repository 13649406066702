import React, { Suspense } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AppRouter from './pages/app';
import { persistor } from './redux/store';
import AuthRouter from './pages/auth';
import ProtectedRoute from './components/router/ProtectedRoute';
import PublicRoute from './components/router/PublicRoute';
import DocumentViewerPage from './pages/document-viewer';
import PageNotFound from './pages/404';
import { Box } from '@mui/material';

function App() {
  return (
    <PersistGate persistor={persistor}>
      <Suspense fallback={<p>Loading</p>}>
        <Routes>
          {/* Redirect to /app from / */}
          <Route index element={<Navigate to="/app" />} />
          <Route
            path="/auth/*"
            element={
              <PublicRoute>
                <AuthRouter />
              </PublicRoute>
            }
          />
          <Route
            path="/app/*"
            element={
              <ProtectedRoute>
                <AppRouter />
              </ProtectedRoute>
            }
          />
          <Route
            path="document-viewer/"
            element={
              <ProtectedRoute>
                <DocumentViewerPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="*"
            element={
              <Box
                sx={{
                  width: '100vw',
                  height: '100vh',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <PageNotFound isRoot />
              </Box>
            }
          />
        </Routes>
      </Suspense>
    </PersistGate>
  );
}

export default App;
