import axiosInstance from './api';
import { ApiURLConstants } from '../constants/api-url-constants';

const NOTIFICATION_URL = process.env.REACT_APP_NOTIFICATION_URL;
const API = axiosInstance;

const globalHeaders = {
  'Content-Type': 'application/json',
};

export const saveFCMToken = async fcmToken => {
  const uri = `${NOTIFICATION_URL}${ApiURLConstants.SAVE_FCM_TOKEN}`;
  const headers = { ...globalHeaders };
  const payload = { fcmToken };

  const response = await API.put(uri, payload, {
    headers,
  });
  return response;
};

export const markNotificationsAsRead = async payload => {
  const uri = `${NOTIFICATION_URL}${ApiURLConstants.NOTIFICATION_MARK_AS_READ}`;
  const headers = { ...globalHeaders };

  const response = await API.put(uri, payload, {
    headers,
  });
  return response;
};

export const getNotificationList = async params => {
  const uri = `${NOTIFICATION_URL}${ApiURLConstants.LIST_NOTIFICATIONS(params)}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const getNotificationCount = async params => {
  const uri = `${NOTIFICATION_URL}${ApiURLConstants.GET_NOTIFICATION_COUNT}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};
