import React, { useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Divider, Typography } from '@mui/material';
import Table from '../../../../../components/shared/table';
import { columns, data } from './ColumnData';
import Card from '../../../../../components/shared/card';
import Tabs from '../../../../../components/shared/tabs';
import Header from '../../../../../components/shared/header';
import VendorInvoiceDocumentButtons from './VendorInvoiceDocumentsButtons';
import Comments from '../../../../../components/shared/comments';

const VendorInvoiceDocuments = () => {
  const params = useParams();
  const vendoreInvoiceDocumentRef = useRef();

  const defaultColDef = useMemo(
    () => ({
      resizable: false,
      editable: false,
      flex: 1,
    }),
    [],
  );

  const VendorInvoiceDocumentsTable = useMemo(
    () => (
      <Table
        ref={vendoreInvoiceDocumentRef}
        data={data}
        columns={columns}
        defaultColDef={defaultColDef}
        height={`calc(5 * 2.75rem - 62px)`}
      />
    ),
    [],
  );

  return (
    <>
      <Card sx={{ alignItems: 'flex-start' }}>
        <Header breadcrumbs={[{ name: 'View PO Vendor' }]} />
        <Tabs
          items={[
            {
              value: 'po-vendor',
              label: 'PO Vendor',
              link: `/app/vendors/view/${params.id}`,
            },
            {
              value: 'vendor-invoice-documents',
              label: 'Vendor Invoice & Documents',
              link: `/app/vendors/view/${params.id}/vendor-invoice`,
            },
          ]}
          active="vendor-invoice-documents"
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            flexDirection: 'column',
            gap: '40px',
          }}
        >
          <VendorInvoiceDocumentButtons />
          <Box sx={{ width: '100%' }}>{VendorInvoiceDocumentsTable}</Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              gap: '6px',
            }}
          >
            <Typography variant="Medium-16" sx={{ color: '#FFA800' }}>
              Total Invoice Amount is Less than the PO Amount
            </Typography>
            <Typography variant="Medium-16">
              Reason from Vendor: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              faucibus ultrices dui sit amet aliquam.
            </Typography>
          </Box>
          <Divider sx={{ width: '100%' }} />
          <Comments entityId={params.id} entityType="BUYING_BRIEF_GROUP" />
        </Box>
      </Card>
    </>
  );
};

export default VendorInvoiceDocuments;
