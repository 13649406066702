import React from 'react';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ErrorTriangleIcon } from '../../../libs/svg-icons/icons';
import { useTheme } from '@mui/material/styles';

const ErrorInHeader = ({ onClose = () => {}, content = '' }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: '100%',
        padding: '15px 25px 10px 25px',
        background: theme.palette.error.light,
        fontFamily: 'poppins',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div style={{ flex: '1', paddingRight: '10px', display: 'flex' }}>
        <ErrorTriangleIcon style={{ marginRight: '10px', marginTop: '2px', minWidth: '20px' }} />{' '}
        {content}
      </div>
      <div>
        <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
      </div>
    </Box>
  );
};

export default ErrorInHeader;
