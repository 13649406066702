import { createTheme } from '@mui/material/styles';
import { themePalette } from './color';

export const themeTypography = createTheme(themePalette, {
  typography: {
    'Bold-38': {
      fontFamily: 'Poppins',
      fontSize: '2.375rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
    },
    'SemiBold-38': {
      fontFamily: 'Poppins',
      fontSize: '2.375rem',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
    },
    'Bold-32': {
      fontFamily: 'Poppins',
      fontSize: '2rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
    },
    'SemiBold-32': {
      fontFamily: 'Poppins',
      fontSize: '2rem',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
    },
    'Bold-28': {
      fontFamily: 'Poppins',
      fontSize: '1.75rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
    },
    'SemiBold-28': {
      fontFamily: 'Poppins',
      fontSize: '1.75rem',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
    },
    'Bold-24': {
      fontFamily: 'Poppins',
      fontSize: '1.5rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '28.6px',
    },
    'SemiBold-24': {
      fontFamily: 'Poppins',
      fontSize: '1.5rem',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '28.6px',
    },
    'Bold-22': {
      fontFamily: 'Poppins',
      fontSize: '1.375rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
    },
    'SemiBold-22': {
      fontFamily: 'Poppins',
      fontSize: '1.375rem',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
    },
    'Bold-18': {
      fontFamily: 'Poppins',
      fontSize: '1.125rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
    },
    'SemiBold-18': {
      fontFamily: 'Poppins',
      fontSize: '1.125rem',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
    },
    'Medium-18': {
      fontFamily: 'Poppins',
      fontSize: '1.125rem',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
    },
    'Regular-18': {
      fontFamily: 'Poppins',
      fontSize: '1.125rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '170%',
    },
    'Medium-16': {
      fontFamily: 'Poppins',
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '170%',
    },
    'Medium-20': {
      fontFamily: 'Poppins',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '170%',
    },
    'Regular-16': {
      fontFamily: 'Poppins',
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '170%',
    },
    'Medium-14': {
      fontFamily: 'Poppins',
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '170%',
    },
    'Regular-14': {
      fontFamily: 'Poppins',
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '170%',
    },
    'SemiBold-12': {
      fontFamily: 'Poppins',
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '18px',
    },
    'Medium-12': {
      fontFamily: 'Poppins',
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
    },
    'Regular-12': {
      fontFamily: 'Poppins',
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '170%',
    },
    'Underline-Regular-14': {
      fontFamily: 'Poppins',
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      textDecorationLine: 'underline',
    },
    'Delete-Regular-14': {
      fontFamily: 'Poppins',
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      textDecorationLine: 'line-through',
    },
    'Bold-14': {
      fontFamily: 'Poppins',
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
    },
  },
});
