import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import OverflowTooltip from '../tooltip/OverflowTooltip';
import { capitalize } from '../../../utils/string';

const UserDetails = ({
  fullName = '',
  initials = '',
  email = null,
  fullNameColor = null,
  fullNameVariant = null,
  emailColor = null,
  emailVariant = null,
  initialsBackgroundColor = null,
  initialsColor = null,
  initialsVariant = null,
  initailsContainer = null,
  tooltipPlacement = 'top',
  sx = {},
  showFullName = false,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '16px',
        ...sx,
      }}
    >
      {initials && (
        <Box
          sx={{
            width: initailsContainer ?? '48px',
            height: initailsContainer ?? '48px',
            borderRadius: '50%',
            backgroundColor: initialsBackgroundColor ?? theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant={initialsVariant ?? 'Bold-18'}
            color={initialsColor ?? theme.palette.white}
          >
            {typeof initials === 'string'
              ? initials.toUpperCase()
              : initials.map(x => x?.toUpperCase())}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: showFullName ? 'fit-content' : initials ? 'calc(100% - 64px)' : '100%',
        }}
      >
        <OverflowTooltip
          text={capitalize(fullName)}
          sx={{ textAlign: 'left', color: fullNameColor ?? theme.palette.text['100'] }}
          tooltipPlacement={tooltipPlacement}
        >
          <Typography
            variant={fullNameVariant ?? 'Medium-18'}
            color={fullNameColor ?? theme.palette.text['100']}
          >
            {capitalize(fullName)}
          </Typography>
        </OverflowTooltip>
        {email && (
          <OverflowTooltip
            text={email}
            sx={{ textAlign: 'left', color: emailColor ?? theme.palette.text['300'] }}
            tooltipPlacement={tooltipPlacement}
          >
            <Typography
              variant={emailVariant ?? 'Medium-14'}
              color={emailColor ?? theme.palette.text['300']}
            >
              {email}
            </Typography>
          </OverflowTooltip>
        )}
      </Box>
    </Box>
  );
};

export default UserDetails;
