import { createTheme, hexToRgb } from '@mui/material/styles';

export const themePalette = createTheme({
  palette: {
    white: hexToRgb('#FFFFFF'),
    black: hexToRgb('#000000'),
    deepNavy: hexToRgb('#0A2756'),
    brightBlue: hexToRgb('#0080FF'),
    trueTeal: hexToRgb('#00B5B1'),
    electricGreen: hexToRgb('#00FFB4'),
    orangeHighlight: hexToRgb('#EF5B2B'),
    goldHighlight: hexToRgb('#FFA800'),
    lightTeal: hexToRgb('#B2E9E7'),
    mint: hexToRgb('#99FDD1'),
    blush: hexToRgb('#EFB6A3'),
    electricBeige: hexToRgb('#FFEDCA'),

    text: {
      100: hexToRgb('#16181A'),
      200: hexToRgb('#3A3E45'),
      300: hexToRgb('#6D7581'),
      400: hexToRgb('#A1A6AE'),
      500: hexToRgb('#CDD1D7'),
      600: hexToRgb('#F4F4F4'),
    },

    primary: {
      main: hexToRgb('#0A2756'),
      light: hexToRgb('#0080FF'),
    },

    tint: {
      main: hexToRgb('#00FFB4'),
      light: hexToRgb('#99FDD1'),
    },

    success: {
      main: hexToRgb('#00B5B1'),
      light: hexToRgb('#B2E9E7'),
    },

    error: {
      main: hexToRgb('#EF5B2B'),
      light: hexToRgb('#EFB6A3'),
      veryLight: hexToRgb('#EEDDD7'),
    },

    warning: {
      main: hexToRgb('#FFA800'),
      light: hexToRgb('#FFEDCA'),
    },

    background: hexToRgb('#F4F4F4'),
  },
});
