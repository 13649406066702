import { Box, alpha } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { ErrorIcon } from '../../../libs/svg-icons/icons';
import Tooltip from '../tooltip';

const CellWithError = ({
  hasError = false,
  hasWarning = false,
  errorMessage = null,
  isCheckbox = false,
  children,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        background: !!hasError
          ? alpha(theme.palette.error.light, 0.3)
          : !!hasWarning
            ? alpha(theme.palette.warning.light, 0.3)
            : 'transparent',
        padding: isCheckbox ? '0px' : '0 15px',
      }}
    >
      <Box sx={{ width: !!hasError || !!hasWarning ? 'calc(100% - 28px)' : '100%' }}>
        {children}
      </Box>

      <Tooltip placement="top" title={errorMessage}>
        {
          <Box
            sx={{
              position: 'relative',
              width: !!hasError | !!hasWarning ? '20px' : '0px',
              height: '20px',
              display: !!hasError | !!hasWarning ? 'flex' : 'none',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
          >
            <ErrorIcon
              fill={
                !!hasError
                  ? theme.palette.error.main
                  : !!hasWarning
                    ? theme.palette.warning.main
                    : null
              }
            />
          </Box>
        }
      </Tooltip>
    </Box>
  );
};

export default CellWithError;
