import React, { useEffect, useMemo, useState } from 'react';
import Header from '../../../../../components/shared/header';
import Card from '../../../../../components/shared/card';
import { getHeaderNameFromParams } from '..';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import Button from '../../../../../components/shared/buttons';
import MediaPlanDetailsHeader from '../../MediaPlanDetailsHeader.json';
import UnmatchedColumnDetails from './UnmatchedColumnDetails';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { createMediaPlanWithTemplate } from '../../../../../services/media-plan-service';
import { useEnqueueSnackbar } from '../../../../../components/shared/toast-provider/toastHook';
import Loader from '../../../../../components/shared/loader';
import { createMediaPlanActions } from '../../../../../redux/slice/createMediaPlan';
import { AppConstants } from '../../../../../constants/app-constants';
import { useHasAllPermission } from '../../../../../hooks/permissions';
import { PermissionsConstants } from '../../../../../constants/permissions-constants';
import Dialog from '../../../../../components/shared/dialog';
import { useTheme, alpha } from '@mui/material/styles';
import { isEmpty } from 'lodash';

const MapColumns = () => {
  const theme = useTheme();
  const params = useParams();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [requiredMediaPlanHeaders, setRequiredMediaPlanHeaders] = useState([]);
  const [unmappedHeaderDetails, setUnmappedHeaderDetails] = useState([]);
  const [validationErrors, setValidationErrors] = useState(null);
  const [showValidationErrorDialog, setShowValidationErrorDialog] = useState(false);

  const createMediaPlanPayload = useAppSelector(store => store.createMediaPlan);

  const unmatchedHeader = useAppSelector(store => store.createMediaPlan.unmatchedHeader);

  const unmappedHeader = useAppSelector(store => store.createMediaPlan.unmappedHeader);

  const mappedColumn = useAppSelector(store => store.createMediaPlan.mappedColumn);

  const enqueueSnackbar = useEnqueueSnackbar();
  const navigate = useNavigate();
  const hasAllPermissions = useHasAllPermission();

  useEffect(() => {
    if (!isEmpty(createMediaPlanPayload?.errors)) {
      setValidationErrors(createMediaPlanPayload?.errors);
      setShowValidationErrorDialog(true);
    }
  }, [createMediaPlanPayload?.errors]);

  useEffect(() => {
    if (unmappedHeader)
      setUnmappedHeaderDetails(
        unmappedHeader.map(x => {
          let isExist = mappedColumn.find(ele => ele.label === x);
          return isExist ? isExist : { label: x, isMapped: false, mappedWith: null };
        }),
      );
    else setUnmappedHeaderDetails([]);
  }, [unmappedHeader, mappedColumn]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const mappedOptions = unmappedHeaderDetails.reduce(
          (a, x) => (x.isMapped ? [...a, x.mappedWith] : a),
          [],
        );
        setRequiredMediaPlanHeaders(
          MediaPlanDetailsHeader.filter(x => unmatchedHeader.includes(x.value)).filter(
            x => !mappedOptions.includes(x.value),
          ),
        );
      } catch (error) {
        console.error('Error fetching headers:', error);
      }
    };

    fetchData();
  }, [unmappedHeaderDetails, unmatchedHeader]);

  const isFormValid = useMemo(() => {
    if (unmappedHeaderDetails && unmappedHeaderDetails.length > 0) {
      return unmappedHeaderDetails.reduce((a, x) => a && x.isMapped && !!x.mappedWith, true);
    }
    return false;
  }, [unmappedHeaderDetails]);

  const handleOptionSelected = (label, option) => {
    setUnmappedHeaderDetails(prev =>
      prev.map(x =>
        x.label === label
          ? {
              ...x,
              isMapped: option ? true : false,
              mappedWith: option,
            }
          : x,
      ),
    );
  };

  // const [continueError, setContinueError] = useState('');

  // useEffect(() => {
  //   const d = unmappedHeaderDetails.map(e => e.mappedWith);
  //   let t = [];
  //   MediaPlanDetailsHeader.map(ele => {
  //     if (!d.includes(ele.value)) {
  //       t.push(ele.label);
  //     }
  //   });
  //   let str = `These columns are missing from excel ${t.map(s => `${s}, `)}`;
  //   setContinueError(str);
  // }, [unmappedHeaderDetails]);

  const handleSaveMediaPlan = async () => {
    const {
      clientId,
      brandId,
      categoryId,
      businessUnitId,
      mediaPlanTypeId,
      name,
      year,
      startDate,
      endDate,
    } = createMediaPlanPayload;

    const mediaPlanDto = {
      clientId,
      brandId,
      categoryId,
      businessUnitId,
      mediaPlanTypeId,
      name,
      year,
      startDate,
      endDate,
    };

    let mapping = {};
    unmappedHeaderDetails.forEach(clm => {
      mapping[clm.mappedWith] = clm.label;
    });

    const file = createMediaPlanPayload.mediaPlanTemplate;

    let formData = new FormData();

    formData.append(
      'mediaPlanDto',
      new Blob([JSON.stringify(mediaPlanDto)], {
        type: 'application/json',
      }),
    );
    formData.append(
      'mapping',
      new Blob([JSON.stringify(mapping)], {
        type: 'application/json',
      }),
    );
    formData.append('file', file);

    setIsLoading(true);
    dispatch(
      createMediaPlanActions.create({
        ...createMediaPlanPayload,
        mappedColumn: unmappedHeaderDetails,
      }),
    );
    createMediaPlanWithTemplateCall(formData);
  };

  const createMediaPlanWithTemplateCall = async payload => {
    if (hasAllPermissions([PermissionsConstants?.CREATE_MEDIA_PLAN])) {
      createMediaPlanWithTemplate(payload)
        .then(response => {
          enqueueSnackbar('Media plan created successfully.', 'success');
          dispatch(createMediaPlanActions.clear());
          navigate('/app/media-plans');
        })
        .catch(error => {
          const defaultError =
            'Failed to upload the Excel file. Please ensure the file is in the correct format and try again.';
          const { message = defaultError } = error?.response?.data || {};
          if (message.includes('Media plan already exist by name')) {
            enqueueSnackbar(message, 'error');
          } else {
            enqueueSnackbar(defaultError, 'error');
          }
          const errors = error?.response?.data?.errors || {};
          if (!isEmpty(errors)) {
            setValidationErrors(errors);
            setShowValidationErrorDialog(true);
            dispatch(createMediaPlanActions.setErrors(errors));
          }
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleOpenValidationErrors = () => {
    setShowValidationErrorDialog(true);
  };

  const handleCloseValidationErrors = () => {
    setShowValidationErrorDialog(false);
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Dialog
        open={showValidationErrorDialog}
        width="73vw"
        onClose={handleCloseValidationErrors}
        headline={
          <Typography variant="SemiBold-22" alignItems="flex-start" justifyContent="flex-start">
            Excel Validation Error ({validationErrors?.length})
          </Typography>
        }
        description={
          <Box
            sx={{
              width: '100%',
              maxHeight: '80vh',
              textAlign: 'left',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: '1.25rem',
            }}
          >
            {validationErrors?.map(error => (
              <Box
                sx={{
                  width: 'calc(33% - 12px)',
                  background: theme.palette.background,
                  borderRadius: '8px',
                  padding: '8px 12px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                }}
                key={`${error.rowNumber}-${error.property}`}
              >
                <Typography variant="Medium-14" color={theme.palette.text['300']}>
                  Row Number: {error.rowNumber}
                </Typography>
                <Typography variant="Medium-14" color={theme.palette.text['100']}>
                  {error.property}
                </Typography>
                <Typography variant="Medium-14" color={theme.palette.error.main}>
                  {error.rejectedValue}
                </Typography>
                <Box
                  sx={{
                    height: '1px',
                    width: '100%',
                    background: alpha(theme.palette.primary.main, 0.16),
                  }}
                />
                <Typography>Message: {error.message}</Typography>
              </Box>
            ))}
          </Box>
        }
        backgroundColor="white"
      />
      <Card
        sx={{
          minHeight: 'calc(100vh - 130px)',
          width: '100%',
          alignItems: 'revert-layer',
          gap: '40px',
          justifyContent: 'flex-start',
        }}
      >
        <Header
          breadcrumbs={[{ name: `Create Media Plan ${getHeaderNameFromParams(params.method)}` }]}
          backHref="/app/media-plans/create/upload"
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            gap: '16px',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '16px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                gap: '16px',
              }}
            >
              <Typography variant="SemiBold-22">
                Please map the unmatched columns with the media plan.
              </Typography>
              <Typography variant="Medium-18">
                {unmappedHeaderDetails.filter(x => x.isMapped).length} of{' '}
                {MediaPlanDetailsHeader.length} columns matched
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-end',
              gap: '24px',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              {unmappedHeaderDetails.map(header => (
                <UnmatchedColumnDetails
                  label={header.label}
                  isMapped={header.isMapped}
                  value={header.mappedWith}
                  availableOptions={
                    header.isMapped
                      ? [
                          MediaPlanDetailsHeader.find(x => x.value === header.mappedWith),
                          ...requiredMediaPlanHeaders,
                        ]
                      : requiredMediaPlanHeaders
                  }
                  onOptionSelect={handleOptionSelected}
                />
              ))}
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '16px',
              }}
            >
              {/* <div>{continueError}</div> */}
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: '16px',
                }}
              >
                {validationErrors && validationErrors.length > 0 && (
                  <Button
                    variant="outlined"
                    label="View errors"
                    sx={{ height: '40px' }}
                    onClick={handleOpenValidationErrors}
                  />
                )}
                <Button
                  variant="contained"
                  label="Continue"
                  disabled={!isFormValid || (validationErrors && validationErrors?.length > 0)}
                  sx={{ width: '105px', height: '40px' }}
                  onClick={handleSaveMediaPlan}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default MapColumns;
