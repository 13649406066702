import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  PlansIcon,
  POClientIcon,
  ProjectsIcon,
  VendorIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  MarvelIcon,
  LogoutIcon,
  VendorInvoiceDocIcon,
} from '../../../libs/svg-icons/icons';
import { NavLink } from 'react-router-dom';
import LogoutModal from '../LogoutModal';
import { ContactUsIcon } from '../../../libs/svg-icons/icons/ContactUsIcon';
import ContactusModel from '../LogoutModal/contactusModel';

const appRoutes = [
  {
    id: 1,
    name: 'Media Plans',
    path: 'media-plans',
    icon: props => <PlansIcon fill={props.isActive ? 'var(--primary)' : 'var(--background)'} />,
  },
  {
    id: 2,
    name: 'Projects',
    path: 'projects',
    icon: props => <ProjectsIcon fill={props.isActive ? 'var(--primary)' : 'var(--background)'} />,
  },
  {
    id: 3,
    name: 'PO Client',
    path: 'po-client',
    icon: props => <POClientIcon fill={props.isActive ? 'var(--primary)' : 'var(--background)'} />,
  },
  {
    id: 4,
    name: 'Vendor',
    path: 'vendors',
    icon: props => <VendorIcon fill={props.isActive ? 'var(--primary)' : 'var(--background)'} />,
  },
  {
    id: 5,
    name: 'Vendor Invoice',
    path: 'vendor-invoice',
    icon: props => (
      <VendorInvoiceDocIcon fill={props.isActive ? 'var(--primary)' : 'var(--background)'} />
    ),
  },
];

const drawerWidth = 190;

const transitionOpenedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const transitionClosedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
});

const openedMixin = theme => ({
  width: drawerWidth,
  ...transitionOpenedMixin(theme),
  overflow: 'visible',
});

const closedMixin = theme => ({
  ...transitionClosedMixin(theme),
  width: `calc(${theme.spacing(9)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(11)} + 1px)`,
  },
  overflow: 'visible',
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({
  theme,
  open,
}) => {
  return {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.primary.main,
    overflow: 'visible',
    '& .MuiPaper-root': {
      overflow: 'visible',
      backgroundColor: theme.palette.primary.main,
    },
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  };
});

const AppSidebar = ({
  open = false,
  setOpen = () => null,
  showLogoutModal = () => null,
  handleShowLogoutModal = () => null,
  handleCloseLogoutModal = () => null,
}) => {
  const theme = useTheme();

  const handleToggleOpen = () => {
    setOpen(prev => !prev);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [showContactUs, setShowConactUs] = useState(false);

  const handleOpenContactUsModal = () => {
    setShowConactUs(true);
  };

  const handleCloseContactUsModal = () => {
    setShowConactUs(false);
  };

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader
        sx={{
          height: '100px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <IconButton onClick={handleDrawerClose}>
          <MarvelIcon />
        </IconButton>
      </DrawerHeader>
      <IconButton
        sx={{
          position: 'absolute',
          right: -10,
          top: 90,
          backgroundColor: theme.palette.white,
          width: 20,
          height: 20,
          zIndex: 999,
          borderRadius: '100px',
          '&:hover': {
            backgroundColor: theme.palette.white,
          },
        }}
        onClick={handleToggleOpen}
      >
        {open ? (
          <ChevronLeftIcon fill="var(--deep-navy)" />
        ) : (
          <ChevronRightIcon fill="var(--deep-navy)" />
        )}
      </IconButton>
      <List
        sx={{
          overflow: 'hidden',
        }}
      >
        {appRoutes.map((detail, index) => (
          <NavLink
            key={detail.id}
            to={detail.path}
            style={{
              textDecoration: 'none',
              color: theme.palette.primary.main,
            }}
          >
            {({ isActive }) => (
              <ListItem
                key={detail.id}
                disablePadding
                sx={{
                  mx: 'auto',
                  display: 'flex',
                  borderRadius: '6px',
                  width: !open ? '60px' : '160px',
                  height: '48px',
                  justifyContent: 'initial',
                  alignItems: 'center',
                  gap: '8px',
                  backgroundColor: isActive ? theme.palette.background : 'transparent',
                  ...(open ? transitionClosedMixin(theme) : transitionOpenedMixin(theme)),
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    px: '20px',
                    py: '12px',
                    justifyContent: open ? 'initial' : 'center',
                    alignItems: 'center',
                    gap: open ? '8px' : 0,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                    }}
                  >
                    {detail.icon({ isActive })}
                  </ListItemIcon>
                  <ListItemText
                    primary={detail.name}
                    sx={{
                      color: isActive ? theme.palette.primary.main : theme.palette.text[600],
                      opacity: open ? 1 : 0,
                      mt: 0.25,
                      mb: 0,
                      '& .MuiTypography-root': {
                        fontWeight: '500',
                        fontSize: '14px',
                        lineHeight: '23px',
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem>
            )}
          </NavLink>
        ))}
      </List>
      <Box sx={{ flexGrow: 1 }} />
      <ListItem
        disablePadding
        sx={{
          mx: 'auto',
          display: 'flex',
          borderRadius: '6px',
          width: !open ? '60px' : '160px',
          height: '48px',
          mb: 4.5,
          justifyContent: 'initial',
          alignItems: 'center',
          gap: '8px',
          ...(open ? transitionClosedMixin(theme) : transitionOpenedMixin(theme)),
        }}
        onClick={handleOpenContactUsModal}
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            px: '20px',
            py: '12px',
            justifyContent: open ? 'initial' : 'center',
            alignItems: 'center',
            gap: open ? '8px' : 0,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
            }}
          >
            <ContactUsIcon fill="#F4F4F4" />
          </ListItemIcon>
          <ListItemText
            primary="Logout"
            sx={{
              color: theme.palette.text[600],
              opacity: open ? 1 : 0,
              mt: 0.25,
              mb: 0,
              '& .MuiTypography-root': {
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '23px',
              },
            }}
          />
        </ListItemButton>
      </ListItem>
      <ListItem
        disablePadding
        sx={{
          mx: 'auto',
          display: 'flex',
          borderRadius: '6px',
          width: !open ? '60px' : '160px',
          height: '48px',
          mb: 4.5,
          justifyContent: 'initial',
          alignItems: 'center',
          gap: '8px',
          ...(open ? transitionClosedMixin(theme) : transitionOpenedMixin(theme)),
        }}
        onClick={handleShowLogoutModal}
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            px: '20px',
            py: '12px',
            justifyContent: open ? 'initial' : 'center',
            alignItems: 'center',
            gap: open ? '8px' : 0,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
            }}
          >
            <LogoutIcon fill="#F4F4F4" />
          </ListItemIcon>
          <ListItemText
            primary="Logout"
            sx={{
              color: theme.palette.text[600],
              opacity: open ? 1 : 0,
              mt: 0.25,
              mb: 0,
              '& .MuiTypography-root': {
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '23px',
              },
            }}
          />
        </ListItemButton>
      </ListItem>
      <LogoutModal open={showLogoutModal} handleClose={handleCloseLogoutModal} />
      <ContactusModel open={showContactUs} handleClose={handleCloseContactUsModal} />
    </Drawer>
  );
};

export default AppSidebar;
