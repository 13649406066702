import Header from '../../../../components/shared/header';
import Card from '../../../../components/shared/card';
import { Box, Typography } from '@mui/material';
import React, { useMemo, useRef } from 'react';
import Button from '../../../../components/shared/buttons';
import { DownloadIcon } from '../../../../libs/svg-icons/icons';
import { useTheme } from '@mui/material/styles';
import Table from '../../../../components/shared/table';
import { columns, data, invoiceColumns, invoiceData } from './ColumnData';
import Comments from '../../../../components/shared/comments';
import { useParams } from 'react-router-dom';

const POClientDetails = () => {
  const theme = useTheme();
  const viewPoClientRef = useRef();
  const viewInvoices = useRef();
  const params = useParams();

  const defaultColDef = useMemo(
    () => ({
      resizable: false,
      editable: false,
      flex: 1,
    }),
    [],
  );

  const ViewPOClientTable = useMemo(
    () => (
      <Table
        ref={viewPoClientRef}
        data={data}
        columns={columns}
        defaultColDef={defaultColDef}
        height={`calc(5 * 2.75rem - 62px)`}
      />
    ),
    [],
  );

  const ViewInvoicesTable = useMemo(
    () => (
      <Table
        ref={viewInvoices}
        data={invoiceData}
        columns={invoiceColumns}
        defaultColDef={defaultColDef}
        height={`calc(5 * 2.75rem + 118px)`}
      />
    ),
    [],
  );
  return (
    <Card sx={{ alignItems: 'flex-start', minHeight: 'calc(100vh - 130px)' }}>
      <Header
        breadcrumbs={[{ name: 'View PO Client' }]}
        rightActions={
          <Box>
            <Button
              width="40px"
              height="40px"
              sx={{
                minWidth: '21px',
                padding: '5px',
              }}
              variant="outlined"
              startIcon={
                <Box
                  component={'span'}
                  sx={{
                    width: '21px',
                    height: '21px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <DownloadIcon fill={theme.palette.primary.main} />
                </Box>
              }
            />
          </Box>
        }
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          gap: '1.5rem',
        }}
      >
        <Box sx={{ width: '100%', marginTop: '20px' }}>{ViewPOClientTable}</Box>
        <Box sx={{ width: '100%' }}>
          <Typography variant="SemiBold-18">View Invoices (4)</Typography>
        </Box>
        <Box sx={{ width: '100%' }}>{ViewInvoicesTable}</Box>
      </Box>
      <Comments entityId={params.id} entityType="BUYING_BRIEF_GROUP" />
    </Card>
  );
};

export default POClientDetails;
