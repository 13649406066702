import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Button from '../../../../components/shared/buttons';
import DragAndDropFilePicker from '../../../../components/shared/form/fileupload';

import { useTheme } from '@mui/material/styles';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import Dialog from '../../../../components/shared/dialog';
import { UploadIcon } from '../../../../libs/svg-icons/icons';

const schema = yup.object().shape({
  BastDocument: yup.mixed().required('BAST document is required'),
});

export default function UploadBastDocument() {
  const theme = useTheme();
  const [openUploadBastDocumentDialog, setOpenBastDocumentDialog] = useState(false);

  const handleOpenBastDocumentDialog = () => {
    setOpenBastDocumentDialog(true);
  };

  const handleCloseBastDocumentDialog = () => {
    setOpenBastDocumentDialog(false);
  };

  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      BastDocument: null,
    },
  });

  const watchValues = methods.watch();

  const handleSubmitSignIB = () => {
    handleCloseBastDocumentDialog();
  };

  const handleBastDocumentUpload = file => {};

  const handleBastDocumentRemoved = () => {
    methods.setValue('BastDocument', undefined, {
      shouldValidate: true,
    });
  };

  return (
    <>
      <Button label="Upload" variant="outlined" onClick={handleOpenBastDocumentDialog} />
      <FormProvider {...methods}>
        <Dialog
          open={openUploadBastDocumentDialog}
          onClose={handleCloseBastDocumentDialog}
          headline={
            <Typography variant="SemiBold-22" alignItems="flex-start" justifyContent="flex-start">
              Upload BAST Document{' '}
            </Typography>
          }
          description={
            <Box sx={{ width: '100%', textAlign: 'left', margin: '27px 0px', padding: '0px 4px' }}>
              <Typography variant="Medium-16" alignItems="flex-start" justifyContent="flex-start">
                Upload BAST{' '}
              </Typography>
              <Box sx={{ width: '100%', marginTop: '5px' }}>
                <DragAndDropFilePicker
                  multi={false}
                  icon={<UploadIcon fill={theme.palette.primary.main} />}
                  headline="Upload BAST Document"
                  description="(Only pdf file, maximum 20MB file size)"
                  onUpload={handleBastDocumentUpload}
                  onRemove={handleBastDocumentRemoved}
                  acceptedfileExtensions={['pdf']}
                  draggable={false}
                  maxFileSize={5}
                  {...methods.register('BastDocument')}
                />
              </Box>
            </Box>
          }
          buttons={
            <Button
              key="button-1"
              type="submit"
              variant="contained"
              label="Upload BAST"
              onClick={handleSubmitSignIB}
              disabled={!methods.formState.isValid}
              height="40px"
            />
          }
          width="520px"
          backgroundColor="white"
        />
      </FormProvider>
    </>
  );
}
