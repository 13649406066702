import { useGridFilter } from 'ag-grid-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Box, Checkbox, Divider, ListSubheader } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Input from '../../form/Input/input';
import { PinIcon } from '../../../../libs/svg-icons/icons';

const SetFilter = ({ values = [], valueFormatter, getValue, model, onModelChange, ...props }) => {
  const theme = useTheme();
  const [searchText, setSearchText] = useState('');
  const [options, setOptions] = useState([]);
  const [isColPinned, setIsColPinned] = useState(false);
  const colId = props.column.colId;

  useEffect(() => {
    if (values)
      setOptions(
        values.map(x => ({
          value: x,
          checked: true,
        })),
      );
  }, [values]);

  useEffect(() => {
    if (options && options.length > 0) {
      onModelChange({
        type: 'set',
        selected: options,
        default: options.reduce((a, x) => a && x.checked, true),
      });
    } else {
      onModelChange(null);
    }
  }, [onModelChange, options, values]);

  const handleSearchTextChange = e => {
    setSearchText(e.target.value);
  };

  const handleSearchCloseClick = () => {
    setSearchText('');
  };

  const doesFilterPass = useCallback(
    ({ data, node }) => {
      const value = getValue(node);
      if (!model) return true;
      return model?.selected
        ?.reduce((a, x) => (x.checked ? [...a, x.value] : a), [])
        .includes(value);
    },
    [getValue, model],
  );

  const handleMenuItemClick = value => {
    setOptions(prev =>
      prev.map((x, index) => (x.value === value ? { ...x, checked: !x.checked } : x)),
    );
  };

  const handleColumnPinClicked = () => {
    if (props?.api && colId) {
      if (isColPinned) {
        props.api.applyColumnState({ state: [{ colId, pinned: false }] });
        setIsColPinned(false);
      } else {
        props.api.applyColumnState({ state: [{ colId, pinned: true }] });
        setIsColPinned(true);
      }
      props.api.hidePopupMenu();
    }
  };

  const handleSelectDeselectAllOptions = () => {
    if (options?.reduce((acc, option) => acc && option?.checked, true)) {
      setOptions(prev => prev?.map(x => ({ ...x, checked: false })));
    } else {
      setOptions(prev => prev?.map(x => ({ ...x, checked: true })));
    }
  };

  useGridFilter({ doesFilterPass });

  const searchedOptions = useMemo(
    () =>
      !!searchText
        ? options
            .filter(x =>
              valueFormatter
                ? valueFormatter(x?.value)?.toLowerCase().includes(searchText?.toLowerCase())
                : x?.value?.toLowerCase().includes(searchText?.toLowerCase()),
            )
            .slice(0, 100)
        : options.slice(0, 100),
    [options, searchText, valueFormatter],
  );

  return (
    <List sx={{ p: 1, pt: 0, width: 'auto', maxHeight: '370px' }}>
      <ListSubheader sx={{ backgroundColor: '#FFFFFF', position: 'sticky', py: 1, px: 0 }}>
        <Input
          value={searchText}
          inputHeight="36px"
          onChange={handleSearchTextChange}
          onSearchCloseClick={handleSearchCloseClick}
          placeholder="Search here"
          searchable
          sx={{ position: 'sticky' }}
          autoFocus
          onKeyDown={e => {
            if (e.key !== 'Escape') {
              e.stopPropagation();
            }
          }}
        />
      </ListSubheader>
      <ListItem
        onClick={handleSelectDeselectAllOptions}
        sx={{
          cursor: 'pointer',
          paddingTop: 0,
          paddingBottom: 0,
          marginBottom: '4px',
          '&:hover': {
            backgroundColor: '#0A275629',
            borderRadius: '8px',
          },
        }}
      >
        <Checkbox checked={options?.reduce((acc, option) => acc && option?.checked, true)} />{' '}
        {options?.reduce((acc, option) => acc && option?.checked, true)
          ? 'Unselect All'
          : 'Select All'}
      </ListItem>
      {searchedOptions.map((option, index) => (
        <ListItem
          key={option.value}
          onClick={() => handleMenuItemClick(option.value)}
          sx={{
            cursor: 'pointer',
            paddingTop: 0,
            paddingBottom: 0,
            marginBottom: '4px',
            '&:hover': {
              backgroundColor: '#0A275629',
              borderRadius: '8px',
            },
          }}
        >
          <Checkbox checked={searchedOptions[index].checked} />{' '}
          {valueFormatter ? valueFormatter(option.value) : option.value}
        </ListItem>
      ))}
      <Divider />

      <ListItem
        sx={{
          backgroundColor: '#FFFFFF',
          position: 'sticky',
          mt: 0.5,
          py: 1,
          px: 0,
          '&:hover': {
            backgroundColor: '#0A275629',
            borderRadius: '8px',
          },
        }}
        onClick={handleColumnPinClicked}
      >
        <Box
          sx={{
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '10px',
            cursor: 'pointer',
            padding: '0 28px',
          }}
        >
          <PinIcon fill={theme} />
          {isColPinned ? 'Unpin' : 'Pin'} Column
        </Box>
      </ListItem>
    </List>
  );
};

export default SetFilter;
