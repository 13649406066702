import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../../../../components/shared/card';
import Header from '../../../../../components/shared/header';
import Tabs from '../../../../../components/shared/tabs';
import { Box } from '@mui/material';
import CustomTabs from '../../../../../components/shared/tabs/BoxTab';
import Select from '../../../../../components/shared/form/Select';
import { FormProvider, useForm } from 'react-hook-form';
import Table from '../../../../../components/shared/table';
import { getSummaryTableHeader, summaryTabItems } from './getSummaryTableHeader';
import { getAllSummary, getMediaPlan } from '../../../../../services/media-plan-service';
import Loader from '../../../../../components/shared/loader';
import { useEnqueueSnackbar } from '../../../../../components/shared/toast-provider/toastHook';
import { AppConstants } from '../../../../../constants/app-constants';
import MediaPlanDetails from '../common/MediaPlanDetails';
import { useAppSelector } from '../../../../../redux/hooks';
import BackdropOverlay from '../../../../../components/shared/backdropOverlay';

const filter = [
  { label: 'Semester', value: 'semester' },
  { label: 'Quarter', value: 'quarter' },
  { label: 'Month', value: 'month' },
];

const Summary = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [timeSpan, setTimeSpan] = useState(filter[0].value);
  const [summaryData, setSummaryData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mediaPlanDetails, setMediaPlanDetails] = useState(null);

  const userBusinessUnit = useAppSelector(state => state.auth.businessUnit);

  const backdropOpen = useMemo(() => {
    const { name = '' } = userBusinessUnit || {};
    const { businessUnit = null } = mediaPlanDetails || {};
    return !isLoading && name !== businessUnit;
  }, [mediaPlanDetails]);

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: { timeSpan: filter[0].value },
  });

  const watchValues = methods.watch();
  const enqueueSnackbar = useEnqueueSnackbar();

  useEffect(() => {
    if (watchValues.timeSpan) {
      setTimeSpan(watchValues.timeSpan);
    }
  }, [watchValues]);

  const handleUpdateMediaPlanDetails = props => {
    setMediaPlanDetails(prev => ({
      ...prev,
      ...props,
    }));
  };

  const getSummaryData = () => {
    setIsLoading(true);
    const summaryType = summaryTabItems[activeTab].value;

    if (params.currentVersionId) {
      getAllSummary({
        type: summaryType,
        filter: timeSpan,
        mediaPlanId: params.id,
        mediaPlanVersionId: params.currentVersionId,
      })
        .then(response => {
          setSummaryData(response?.data?.data?.tableResponse);
          setMediaPlanDetails(
            response?.data?.data?.mediaPlan
              ? {
                  ...response?.data?.data?.mediaPlan,
                  calculatedBudget: response?.data?.data?.mediaPlan?.givenBudget,
                }
              : {},
          );
        })
        .catch(error => {
          const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
          enqueueSnackbar(message, 'error');
          setSummaryData(null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      getMediaPlan(params.id)
        .then(mediaPlanData => {
          const activeVersionId = mediaPlanData.data.data.activeVersion.id;

          return getAllSummary({
            type: summaryType,
            filter: timeSpan,
            mediaPlanId: params.id,
            mediaPlanVersionId: activeVersionId,
          });
        })
        .then(response => {
          setSummaryData(response?.data?.data?.tableResponse);
          setMediaPlanDetails(
            response?.data?.data?.mediaPlan
              ? {
                  ...response?.data?.data?.mediaPlan,
                  calculatedBudget: response?.data?.data?.mediaPlan?.givenBudget,
                }
              : {},
          );
        })
        .catch(error => {
          const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
          enqueueSnackbar(message, 'error');
          setSummaryData(null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (timeSpan && params) {
      getSummaryData();
    }
  }, [params, timeSpan, activeTab]);

  const handleTabChange = (event, newValue) => {
    const selectedTab = summaryTabItems.find(item => item.value === newValue);
    if (selectedTab) {
      setActiveTab(summaryTabItems.indexOf(selectedTab));
    }
  };

  const handleTimeSpanChange = (e, v) => {};

  useEffect(() => {
    methods.setValue('timeSpan', filter[0].value);
  }, []);

  const summaryTableRef = useRef(null);

  const getRowData = () => {
    return summaryData || [];
  };

  const columns = useMemo(() => getSummaryTableHeader(activeTab, timeSpan), [activeTab, timeSpan]);

  const rowData = useMemo(() => getRowData(), [summaryData]);

  const defaultColDef = useMemo(
    () => ({
      resizable: false,
      editable: false,
      flex: 1,
    }),
    [],
  );
  const parseNumber = value => {
    if (typeof value === 'string') {
      const numericValue = parseFloat(value.replace(/[^\d.-]/g, ''));
      return numericValue || 0;
    }
    return value || 0;
  };

  const calculateColumnTotal = (columnId, data) => {
    if (!data) {
      return 0;
    }

    let total = 0;
    data.forEach(row => {
      const value = row[columnId];
      total += parseNumber(value);
    });

    return total;
  };

  const h1Total = calculateColumnTotal('h1', rowData);
  const h2Total = calculateColumnTotal('h2', rowData);
  const q1Total = calculateColumnTotal('q1', rowData);
  const q2Total = calculateColumnTotal('q2', rowData);
  const q3Total = calculateColumnTotal('q3', rowData);
  const q4Total = calculateColumnTotal('q4', rowData);
  const janTotal = calculateColumnTotal('january', rowData);
  const febTotal = calculateColumnTotal('february', rowData);
  const marTotal = calculateColumnTotal('march', rowData);
  const aprTotal = calculateColumnTotal('april', rowData);
  const mayTotal = calculateColumnTotal('may', rowData);
  const junTotal = calculateColumnTotal('june', rowData);
  const julTotal = calculateColumnTotal('july', rowData);
  const augTotal = calculateColumnTotal('august', rowData);
  const sepTotal = calculateColumnTotal('september', rowData);
  const octTotal = calculateColumnTotal('october', rowData);
  const novTotal = calculateColumnTotal('november', rowData);
  const decTotal = calculateColumnTotal('december', rowData);

  const grandTotalTotal = calculateColumnTotal('grandTotal', rowData);

  const pinnedBottomRowData = useMemo(() => {
    if (rowData.length === 0) {
      return [
        {
          id: 'total',
          media: 'Grand Total',
          h1: '-',
          h2: '-',
          q1: '-',
          q2: '-',
          q3: '-',
          q4: '-',
          january: '-',
          february: '-',
          march: '-',
          april: '-',
          may: '-',
          june: '-',
          july: '-',
          august: '-',
          september: '-',
          october: '-',
          november: '-',
          december: '-',
          grandTotal: '-',
        },
      ];
    } else {
      return [
        {
          id: 'total',
          media: summaryTabItems[activeTab].value === 'BUDGET' ? '' : 'Grand Total',
          campaign: summaryTabItems[activeTab].value === 'BUDGET' ? 'Grand Total' : '',
          h1: h1Total,
          h2: h2Total,
          q1: q1Total,
          q2: q2Total,
          q3: q3Total,
          q4: q4Total,
          january: janTotal,
          february: febTotal,
          march: marTotal,
          april: aprTotal,
          may: mayTotal,
          june: junTotal,
          july: julTotal,
          august: augTotal,
          september: sepTotal,
          october: octTotal,
          november: novTotal,
          december: decTotal,
          grandTotal: grandTotalTotal,
        },
      ];
    }
  }, [
    activeTab,
    rowData.length,
    h1Total,
    h2Total,
    q1Total,
    q2Total,
    q3Total,
    q4Total,
    janTotal,
    febTotal,
    marTotal,
    aprTotal,
    mayTotal,
    junTotal,
    julTotal,
    augTotal,
    sepTotal,
    octTotal,
    novTotal,
    decTotal,
    grandTotalTotal,
  ]);

  const SummaryTable = useMemo(
    () => (
      <Table
        height={`calc(${(rowData?.length ? rowData.length : 10) * 3.77}rem + 143px)`}
        ref={summaryTableRef}
        data={rowData}
        columns={columns}
        defaultColDef={defaultColDef}
        pinnedBottomRowData={pinnedBottomRowData}
        margin={0}
      />
    ),
    [columns, defaultColDef, pinnedBottomRowData, rowData],
  );

  const MediaPlanInfo = useMemo(
    () =>
      mediaPlanDetails ? (
        <MediaPlanDetails
          mediaPlanDetails={mediaPlanDetails}
          showDetails
          handleUpdateMediaPlanDetails={handleUpdateMediaPlanDetails}
          disableGivenBudget
        />
      ) : null,
    [mediaPlanDetails],
  );

  return (
    <>
      {!isLoading && mediaPlanDetails && (
        <BackdropOverlay
          open={backdropOpen}
          buttonLabel="Redirect To Media Plan List"
          clickAction={() => {
            navigate(`/app/media-plans`);
          }}
        />
      )}
      <Loader isLoading={isLoading} />
      <Card sx={{ alignItems: 'flex-start' }}>
        <Header breadcrumbs={[{ name: 'View Media Plan' }]} />
        <Tabs
          items={[
            {
              value: 'media-plan',
              label: 'Media Plan',
              link: `/app/media-plans/${params.id}/media-plan`,
            },
            {
              value: 'summary',
              label: 'Summary',
              link: `/app/media-plans/${params.id}/summary`,
            },
            {
              value: 'buying-group',
              label: 'Buying Brief Group',
              link: `/app/media-plans/${params.id}/buying-group`,
            },
          ]}
          active="summary"
        />
        {MediaPlanInfo}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '16px',
          }}
        >
          <Box
            sx={{
              border: '1px solid #CDD1D7',
              padding: '8px',
              gap: '20px',
              borderRadius: '6px',
              width: 'auto',
            }}
          >
            <CustomTabs
              tabs={summaryTabItems}
              value={summaryTabItems[activeTab].value}
              onChange={handleTabChange}
            />
          </Box>
          <Box sx={{ width: '200px' }}>
            <FormProvider {...methods}>
              <form>
                <Select
                  id="filter-options"
                  options={filter}
                  optionMapFunction={options =>
                    options?.map(x => ({ id: x.label, label: x.label, value: x.value }))
                  }
                  onChange={handleTimeSpanChange}
                  {...methods.register('timeSpan')}
                />
              </form>
            </FormProvider>
          </Box>
        </Box>

        <Box sx={{ width: '100%' }}>{SummaryTable}</Box>
      </Card>
    </>
  );
};

export default Summary;
