import React from 'react';
import Button from '../../../components/shared/buttons';
import { dispatcher } from '../../../redux/store';
import { authActions } from '../../../redux/slice';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import backgroundImage from '../../../assets/images/marvelai-login.jpeg';
import { useAuth } from 'react-oidc-context';

const Login = () => {
  const theme = useTheme();
  const auth = useAuth();

  const handleLogin = () => {
    auth.signinRedirect();
  };
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '50vw',
          height: '100vh',
          background: `url(${backgroundImage}) no-repeat center center`,
          backgroundSize: 'cover',
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            '@media (max-width: 768px)': {
              bottom: '50%',
              transform: 'translateY(50%)',
            },
          }}
        >
          <Box
            sx={{
              padding: '4.5rem 6.5rem 4.5rem 4.5rem',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            <Typography variant="Bold-38" color={theme.palette.text['600']}>
              shaping the media industry with the help of Artificial intelligence!
            </Typography>
            <Box
              sx={{
                marginTop: '57px',
                marginBottom: '14px',
                height: '4px',
                width: '80px',
                background: theme.palette.text['600'],
              }}
            />
            <Typography variant="SemiBold-32" color={theme.palette.text['600']}>
              Marvel.ai
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: '50vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            gap: '40px',
            width: '58%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              gap: '8px',
            }}
          >
            <Typography variant="SemiBold-28" color={theme.palette.text['100']}>
              Welcome to Marvel.ai
            </Typography>
            <Typography variant="Medium-16" color={theme.palette.text['100']}>
              Please sign In to continue
            </Typography>
          </Box>
          <Button
            key="button2"
            onClick={handleLogin}
            variant="contained"
            label="Sign In"
            width="100%"
            height="50px"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
