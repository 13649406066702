import React, { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TextArea from './textarea';

/**
 * Custom textarea field with React Hook Form integration.
 *
 * @param {string} id - The `id` of `textarea` element.
 * @param {string} label - The `label` of `textarea` element.
 * @param {string} name - The `name` of `textarea` element.
 * @param {string} value - The `value` of `textarea` element.
 * @param {function} onChange - The function that is triggered when there is a change in the `textarea` element.
 * @param {function} onBlur - The function that is triggered when the`textarea` element is blurred.
 * @param {boolean} required - If `required` is true, the `textarea` element will require a value and an asteric will be shown after the label.
 * @param {string} placeholder - The short hint displayed in the `textarea` element before the user enters a value.
 * @param {boolean} disabled - If `disabled` is true, the `textarea` element will be disabled.
 * @param {string} error - If `error` message is present, it will error success message at the bottom of the `textarea` element and the border of the `textarea` element will be of color error.
 * @param {string} success - If `success` message is present, it will show success message at the bottom of the `textarea` element and the border of the `textarea` element will be of color success.
 * @param {string} helperText - Any text that we want to show at the bottom of the `textarea` element, as a description.
 * @returns {Element} The `textarea` element.
 */

const TextAreaField = (
  {
    id = '',
    label = '',
    name = '',
    minRows = 4,
    rows = 4,
    value = '',
    onChange = () => null,
    onBlur = () => null,
    required = false,
    placeholder = '',
    disabled = false,
    error = '',
    success = '',
    helperText = '',
    height = '',
  },
  ref,
) => {
  const {
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      defaultValue={value}
      control={control}
      render={({ field: { ref, value, onChange, onBlur }, formState: { submitCount } }) => {
        return (
          <TextArea
            ref={ref}
            id={id}
            name={name}
            label={label}
            minRows={minRows}
            rows={rows}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            disabled={disabled}
            required={required}
            error={errors[name]?.message || error}
            success={success}
            helperText={helperText}
            height={height}
          />
        );
      }}
    />
  );
};

export default forwardRef(TextAreaField);
