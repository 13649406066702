export const getReaminingDaysPercentage = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const today = new Date();

  function countWeekdays(start, end) {
    let count = 0;
    let currentDate = new Date(start);

    while (currentDate <= end) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        count++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return count;
  }

  const totalWeekdays = countWeekdays(start, end);

  const weekdaysPassed = countWeekdays(start, Math.min(today, end));

  const percentage = (weekdaysPassed / totalWeekdays) * 100;

  return { progress: percentage.toFixed(2), remainingDays: totalWeekdays - weekdaysPassed };
};

export const getFirstCharOfEveryWord = str => {
  return str
    ?.split(' ') // Split the string by spaces to get each word
    ?.map(word => word[0]?.toUpperCase()) // Get the first character of each word and convert it to uppercase
    ?.join(''); // Join all the characters together into a single string
};

export const getUniqueNamesWithIds = data => {
  const nameCount = {};
  const updatedData = data.map(item => {
    if (!nameCount[item.name]) {
      nameCount[item.name] = 1;
    } else {
      nameCount[item.name]++;
    }

    if (nameCount[item.name] > 1 || data.filter(obj => obj.name === item.name).length > 1) {
      item.name = `${item.name} - ${item?.externalId || item?.id}`;
    }

    return item;
  });

  return updatedData;
};
