import { Box, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import OverflowTooltip from '../../../../../components/shared/tooltip/OverflowTooltip';
import { EditIcon } from '../../../../../libs/svg-icons/icons';
import { useTheme } from '@mui/material/styles';
import Dialog from '../../../../../components/shared/dialog';
import Button from '../../../../../components/shared/buttons';
import InputField from '../../../../../components/shared/form/Input';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { AppConstants } from '../../../../../constants/app-constants';

const editRemarkSchema = yup.object().shape({
  taskRemark: yup
    .string()
    .trim() // Removes whitespace from both ends
    .max(32, 'Remark must not exceed 32 characters')
    .required('This field is required'),
});

export default function TaskRemark({ data = {}, updateRemarkCall = () => null, status = '' }) {
  const theme = useTheme();
  const params = useParams();
  const [editRemark, setEditRemark] = useState(false);

  const { remark = '', remarkId } = data || {};

  const editRemarkMethods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(editRemarkSchema),
    defaultValues: {
      taskRemark: remark,
    },
  });

  const handleCloseEditRemark = () => {
    editRemarkMethods.setValue('taskRemark', remark);
    setEditRemark(false);
  };

  const handleOpenEditRemark = () => {
    setEditRemark(true);
  };

  const updateRemarkcall = () => {
    let payload = {
      remark: editRemarkMethods.getValues('taskRemark'),
    };

    let urlParams = {
      projectId: params?.id,
      projectTaskId: remarkId,
    };

    updateRemarkCall(urlParams, payload, () => {
      setEditRemark(false);
    });
  };

  useEffect(() => {
    if (editRemark) editRemarkMethods.clearErrors();
  }, [editRemark]);

  return (
    <>
      <Box style={{ display: 'flex', gap: '20px' }}>
        <OverflowTooltip text={remark} sx={{ flex: '1' }}>
          {remark || '-'}
        </OverflowTooltip>
        <Box
          component={'span'}
          sx={{
            cursor: 'pointer',
            display: status === AppConstants.PROJECT_STATUS_CANCEL ? 'none' : 'block',
          }}
        >
          <EditIcon
            width={16}
            height={16}
            fill={theme.palette.primary.main}
            onClick={handleOpenEditRemark}
          />
        </Box>
      </Box>

      <FormProvider {...editRemarkMethods}>
        <Dialog
          open={editRemark}
          onClose={handleCloseEditRemark}
          headline={
            <Typography variant="SemiBold-22" alignItems="flex-start" justifyContent="flex-start">
              Edit Remark
            </Typography>
          }
          description={
            <Box sx={{ width: '100%' }}>
              <InputField
                id="taskRemark"
                type="text"
                label="Remark"
                disabled={false}
                placeholder={'Write You Remark here'}
                // value={remark}
                {...editRemarkMethods.register('taskRemark')}
              />
            </Box>
          }
          buttons={[
            <Button
              type="submit"
              onClick={updateRemarkcall}
              width="167px"
              variant="contained"
              label="Submit"
              disabled={!editRemarkMethods.formState.isValid}
            />,
          ]}
          width="620px"
          height="120px"
          backgroundColor="white"
        />
      </FormProvider>
    </>
  );
}
