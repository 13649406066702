import React from 'react';
import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';

const Tooltip = styled(({ className, ...props }) => (
  <MuiTooltip
    {...props}
    arrow
    classes={{ popper: className }}
    PopperProps={{
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ],
    }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'var(--white)',
    color: 'var(--text-100)',
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: '20px',
    boxShadow: '0px 5px 15px 0px rgba(0,0,0,0.3)',
    borderRadius: '8px',
    padding: '12px',
    textAlign: 'center',

    '& .MuiTooltip-arrow': {
      '&::before': {
        backgroundColor: 'var(--white)',
        boxShadow: '0px 5px 15px 0px rgba(0,0,0,0.3)',
      },
    },
  },
}));

export default Tooltip;
