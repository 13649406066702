import { Skeleton } from '@mui/material';
import React from 'react';
import CellWithError from './CellWithError';
import CellWithClipboard from './CellWithClipboard';
import OverflowTooltip from '../tooltip/OverflowTooltip';
import { useTheme } from '@mui/material/styles';

const CellRenderer = ({ col, showClipboard, isLoading, ...params }) => {
  const theme = useTheme();

  if (params.node?.id === undefined || isLoading) {
    return <Skeleton variant="rectangular" width="80%" height="80%" sx={{ margin: '0 15px' }} />;
  }
  if (col.cellRenderer) {
    const colId = params.colDef.colId;
    const warningFields =
      colId && params?.data?.warningFields && Object.keys(params?.data?.warningFields);
    const errorFields =
      colId && params?.data?.errorFields && Object.keys(params?.data?.errorFields);
    const hasWarning = errorFields && warningFields?.includes(colId);
    const hasError = errorFields && errorFields?.includes(colId);
    const errorMessage = hasError
      ? params?.data?.errorFields[colId]
      : hasWarning
        ? params?.data?.warningFields[colId]
        : null;

    return (
      <CellWithError
        hasError={hasError}
        hasWarning={hasWarning}
        errorMessage={errorMessage}
        isCheckbox={params?.colDef?.checkboxSelection}
      >
        <CellWithClipboard
          showClipboard={hasError ? false : showClipboard}
          valueFormatted={params.valueFormatted}
          value={params.value}
        >
          <OverflowTooltip
            text={params.valueFormatted ?? params.value}
            sx={
              params.column?.userProvidedColDef?.cellStyle &&
              params.column?.userProvidedColDef?.cellStyle(params)
            }
          >
            {col.cellRenderer(params)}
          </OverflowTooltip>
        </CellWithClipboard>
      </CellWithError>
    );
  } else {
    const colId = params.colDef.colId;
    const errorFields =
      colId && params?.data?.errorFields && Object.keys(params?.data?.errorFields);
    const warningFields =
      colId && params?.data?.warningFields && Object.keys(params?.data?.warningFields);
    const hasError = errorFields && errorFields?.includes(colId);
    const hasWarning = errorFields && warningFields?.includes(colId);
    const errorMessage = hasError
      ? params?.data?.errorFields[colId]
      : hasWarning
        ? params?.data?.warningFields[colId]
        : null;

    return (
      <CellWithError hasError={hasError} hasWarning={hasWarning} errorMessage={errorMessage}>
        <CellWithClipboard showClipboard={hasError ? false : showClipboard} {...params}>
          <OverflowTooltip
            text={params.valueFormatted ?? params.value}
            sx={{
              color: theme,
              ...(params.column?.userProvidedColDef?.cellStyle
                ? params.column?.userProvidedColDef?.cellStyle(params)
                : {}),
            }}
          >
            {params.valueFormatted ?? params.value}
          </OverflowTooltip>
        </CellWithClipboard>
      </CellWithError>
    );
  }
};

export default CellRenderer;
