import React from 'react';
import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@emotion/react';

const RequestDetailsDialog = ({
  open = false,
  onClose = () => null,
  headline = '',
  cards = null,
  buttons = null,
  chips = null,
  description = null,
  width = '',
  height = '',
  headlineAlignment,
  backgroundColor = null,
  showDialogActions = true,
}) => {
  const theme = useTheme();

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={false}
      PaperProps={{
        style: {
          width: width,
          borderRadius: '8px',
          gap: '40px',
          backgroundColor: backgroundColor ?? theme.palette.white,
        },
      }}
    >
      <DialogTitle
        style={{
          background: 'white',
          borderRadius: '8px 8px 0 0',
        }}
      >
        {headline && onClose && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        )}

        <Box>{headline}</Box>
      </DialogTitle>
      <DialogContent
        style={{
          background: 'white',
          width: width,
          height: height,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          textAlign: 'center',
          padding: '0px 24px',
          borderRadius: showDialogActions ? 0 : '0 0 8px 8px',
          overflowX: 'hidden',
          gap: '24px',
        }}
      >
        {cards && cards.map((card, index) => <Box key={index}>{card}</Box>)}
        {description && <Box>{description}</Box>}
      </DialogContent>
      {showDialogActions && (
        <DialogActions
          style={{
            display: 'flex',
            padding: '20px',
            alignItems: 'flex-start',
            gap: '20px',
            borderRadius: '0 0 8px 8px',
            background: backgroundColor,
          }}
        >
          {chips}
          {buttons}
        </DialogActions>
      )}
    </MuiDialog>
  );
};

export default RequestDetailsDialog;
