/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import Header from '../../../../../../components/shared/header';
import { useTheme } from '@emotion/react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import Button from '../../../../../../components/shared/buttons';
import { DownloadIcon } from '../../../../../../libs/svg-icons/icons';
import { AppConstants } from '../../../../../../constants/app-constants';
import SignIBPopup from './signIBPopup';
import UploadSignIBPopup from './uploadSignIBPopup';
import html2pdf from 'html2pdf.js';
import { useHasAllPermission } from '../../../../../../hooks/permissions';
import { PermissionsConstants } from '../../../../../../constants/permissions-constants';

const HeaderActions = ({
  handleIBSignData = () => {},
  handleUploadSignedIB = () => {},
  status = '',
  hasDownloadPath,
  signDownloadPath = '',
  createdAtIB = '',
}) => {
  const theme = useTheme();
  const params = useParams();
  const hasAllPermissions = useHasAllPermission();

  const [openSignPopup, setOpenSignPopup] = useState(false);

  const [openUploadIBPopup, setOpenUploadIBPopup] = useState(false);

  const handleSignIBPopup = () => {
    setOpenSignPopup(true);
  };

  const handleCloseSignIBPopup = () => {
    setOpenSignPopup(false);
  };

  const handleUploadIBPopup = () => {
    setOpenUploadIBPopup(true);
  };

  const handleCloseUploadIBPopup = () => {
    setOpenUploadIBPopup(false);
  };

  const downloadPDF = () => {
    if (signDownloadPath) {
      window.open(signDownloadPath);
    } else {
      const element = document.getElementById('ib-html-box');

      html2pdf()
        .set({
          margin: 5,
          filename: `Internal_brief_${params?.ibid || 'null'}_file.pdf`,
          html2canvas: {
            scale: 4,
            width: 1270,
            useCORS: true,
            allowTaint: true,
            backgroundColor: '#F4F4F4',
          },
          jsPDF: {
            unit: 'px',
            format: [1270, element.offsetHeight],
            orientation: 'portrait',
            precision: 8,
          },
        })
        .from(element)
        .save();
    }
  };

  return (
    <>
      <Header
        breadcrumbs={[
          { name: 'View Media Plan', href: `/app/media-plans/${params.id}/media-plan` },
          { name: 'Buying Group', href: `/app/media-plans/${params.id}/buying-group` },
          { name: params.bgid, href: `/app/media-plans/${params.id}/buying-group/${params.bgid}` },
          { name: 'View Internal Brief' },
        ]}
        backHref={`/app/media-plans/${params.id}/buying-group/${params.bgid}`}
        rightActions={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '1.25rem',
            }}
          >
            {hasAllPermissions([PermissionsConstants.SIGN_INTERNAL_BRIEF]) &&
              [AppConstants.IB_STATUS_NOT_SAVED].includes(status) && (
                <Button variant="contained" label="Sign IB" onClick={handleSignIBPopup} />
              )}
            {hasAllPermissions([PermissionsConstants.SIGN_INTERNAL_BRIEF]) && (
              <SignIBPopup
                open={openSignPopup}
                onClose={handleCloseSignIBPopup}
                handleIBSignData={handleIBSignData}
              />
            )}
            {[AppConstants.IB_STATUS_SAVED, AppConstants.IB_STATUS_CLIENT_UPLOADED].includes(
              status,
            ) && (
              <Button
                width="40px"
                height="40px"
                sx={{
                  minWidth: '21px',
                  padding: '5px',
                }}
                variant="outlined"
                startIcon={
                  <Box
                    component={'span'}
                    sx={{
                      width: '21px',
                      height: '21px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <DownloadIcon fill={theme.palette.primary.main} />
                  </Box>
                }
                onClick={downloadPDF}
              />
            )}
            {hasAllPermissions([PermissionsConstants.SIGN_INTERNAL_BRIEF]) &&
              [AppConstants.IB_STATUS_SAVED].includes(status) &&
              !hasDownloadPath && (
                <Button
                  variant="contained"
                  label="Upload Signed IB from Client"
                  onClick={handleUploadIBPopup}
                />
              )}
            {hasAllPermissions([PermissionsConstants.SIGN_INTERNAL_BRIEF]) && openUploadIBPopup && (
              <UploadSignIBPopup
                open={openUploadIBPopup}
                onClose={handleCloseUploadIBPopup}
                handleUploadSignedIB={handleUploadSignedIB}
                createdAtIB={createdAtIB}
              />
            )}
          </Box>
        }
      />
    </>
  );
};

export default HeaderActions;
