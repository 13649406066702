import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useSearchParams } from 'react-router-dom';
import { CrossIcon } from '../../libs/svg-icons/icons';
import DocumentViewer from '../../components/shared/document/viewer';

const DocumentViewerPage = () => {
  const [searchParams] = useSearchParams();
  const title = decodeURIComponent(searchParams.get('title'));
  const link = decodeURIComponent(searchParams.get('link'));

  const handleClose = () => {
    window.opener = null;
    window.open('', '_self');
    window.close();
  };

  if (!link) return null;

  return (
    <Box sx={{ height: '100vh' }}>
      <Box
        sx={theme => ({
          padding: '9px 16px',
          display: 'flex',
          justifyContent: 'space-between',
        })}
      >
        <Typography variant="SemiBold-24">{title}</Typography>
        <IconButton onClick={handleClose}>
          <CrossIcon fill="var(--text-300)" />
        </IconButton>
      </Box>
      <Box sx={{ p: 4, pb: 0, height: 'calc(100vh - 80px)' }}>
        <DocumentViewer show={true} link={link} fileName={title} fullPage />
      </Box>
    </Box>
  );
};

export default DocumentViewerPage;
