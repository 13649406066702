import { Box, Typography } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import OverflowTooltip from '../../../../../components/shared/tooltip/OverflowTooltip';
import { useTheme } from '@mui/material/styles';
import Input from '../../../../../components/shared/form/Input/input';
import Accordion from '../../../../../components/shared/accordion';
import { getMediaPlan, saveGivenBudget } from '../../../../../services/media-plan-service';
import Loader from '../../../../../components/shared/loader';
import { useEnqueueSnackbar } from '../../../../../components/shared/toast-provider/toastHook';
import { isEmpty } from 'lodash';
import { formatDate } from '../../../../../libs/date/format';
import { PermissionsConstants } from '../../../../../constants/permissions-constants';
import { useHasAllPermission } from '../../../../../hooks/permissions';
import { capitalize, formatCurrency } from '../../../../../utils/string';
import { AppConstants } from '../../../../../constants/app-constants';
import { BorderColor } from '@mui/icons-material';

const MediaPlanDetails = ({
  mediaPlanDetails,
  showDetails = false,
  handleUpdateMediaPlanDetails,
  disableGivenBudget,
  errorDetails = [],
}) => {
  const inputRef = useRef(null);

  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  const enqueueSnackbar = useEnqueueSnackbar();
  const hasAllPermissions = useHasAllPermission();

  const details = [
    { name: 'Brand', description: mediaPlanDetails?.brand?.name },
    { name: 'Business Unit', description: mediaPlanDetails?.businessUnit },
    {
      name: 'Start Date',
      description: mediaPlanDetails?.startDate
        ? formatDate(new Date(mediaPlanDetails?.startDate), 'dd/MM/yyyy')
        : '-',
    },
    { name: 'Planner', description: capitalize(mediaPlanDetails?.planner) },
    { name: 'Category', description: mediaPlanDetails?.category?.name },
    {
      name: 'End Date',
      description: mediaPlanDetails?.endDate
        ? formatDate(new Date(mediaPlanDetails?.endDate), 'dd/MM/yyyy')
        : '-',
    },
  ];

  const handleUpdateGivenBudget = e => {
    const t = e.target.value.replace(/[^0-9.]/g, '');

    if (!isEmpty(t) && parseFloat(mediaPlanDetails?.calculatedBudget) !== parseFloat(t)) {
      setIsLoading(true);
      saveGivenBudget({
        budget: t,
        id: mediaPlanDetails?.id,
      })
        .then(response => {
          enqueueSnackbar('Successfully saved budget.', 'success');
          return getMediaPlan(mediaPlanDetails?.id);
        })
        .then(response => {
          const { data: mediaPlanData } = response.data;
          handleUpdateMediaPlanDetails({
            totalBudget: mediaPlanData.totalBudget,
            calculatedBudget: mediaPlanData.givenBudget,
          });
        })
        .catch(error => {
          const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
          enqueueSnackbar(message, 'error');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      handleUpdateGivenBudget(event);
      inputRef.current.blur();
    }
  };

  const isgivenBudgetError = useMemo(() => {
    const [t = null] = errorDetails?.filter(x => x.column === 'givenBudget');

    return t ? t?.message : '';
  }, [errorDetails]);

  return (
    <>
      <Loader isLoading={isLoading} />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '24px',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '16px',
          }}
        >
          <Box
            sx={{
              width: 'calc((100%/3) - 8px)',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '24px',
              backgroundColor: theme.palette.background,
              borderRadius: '6px',
              border: `1px solid ${theme.palette.text['500']}`,
              padding: '12px',
            }}
          >
            <Box sx={{ maxWidth: 'calc(50% - 24px)' }}>
              <Typography variant="Medium-14" color={theme.palette.text['300']}>
                Media Plan Name
              </Typography>
            </Box>
            <Box sx={{ maxWidth: 'calc(50% - 24px)' }}>
              <OverflowTooltip
                text={mediaPlanDetails?.name}
                sx={{ color: theme.palette.text['100'] }}
              >
                {mediaPlanDetails?.name}
              </OverflowTooltip>
            </Box>
          </Box>
          <Box
            sx={{
              width: 'calc((100%/3) - 8px)',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '24px',
              backgroundColor: theme.palette.background,
              borderRadius: '6px',
              border: `1px solid ${theme.palette.text['500']}`,
              padding: '12px',
            }}
          >
            <Box sx={{ maxWidth: 'calc(50% - 24px)' }}>
              <Typography variant="Medium-14" color={theme.palette.text['300']}>
                Media Plan Budget
              </Typography>
            </Box>
            <Box sx={{ maxWidth: 'calc(50% - 24px)' }}>
              <OverflowTooltip
                text={`${formatCurrency(mediaPlanDetails?.totalBudget)} IDR`}
                sx={{ color: theme.palette.text['100'] }}
              >
                {mediaPlanDetails?.totalBudget ? formatCurrency(mediaPlanDetails?.totalBudget) : 0}{' '}
                IDR
              </OverflowTooltip>
            </Box>
          </Box>
          {disableGivenBudget ? (
            <Box
              sx={{
                width: 'calc((100%/3) - 8px)',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '24px',
                backgroundColor: theme.palette.background,
                borderRadius: '6px',
                border: `1px solid ${theme.palette.text['500']}`,
                padding: '12px',
              }}
            >
              <Box sx={{ maxWidth: 'calc(50% - 24px)' }}>
                <Typography variant="Medium-14" color={theme.palette.text['300']}>
                  Given Budget
                </Typography>
              </Box>
              <Box sx={{ maxWidth: 'calc(50% - 24px)' }}>
                <OverflowTooltip
                  text={`${formatCurrency(mediaPlanDetails?.calculatedBudget)} IDR`}
                  sx={{ color: theme.palette.text['100'] }}
                >
                  {mediaPlanDetails?.calculatedBudget
                    ? formatCurrency(mediaPlanDetails?.calculatedBudget)
                    : 0}{' '}
                  IDR
                </OverflowTooltip>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                width: 'calc((100%/3) - 8px)',
              }}
            >
              <Input
                name="givenBudget"
                value={formatCurrency(mediaPlanDetails?.calculatedBudget)}
                type="text"
                placeholder="Given Budget"
                inputHeight="50px"
                endIcon={
                  <Typography variant="Medium-14" color={theme.palette.text['100']}>
                    IDR
                  </Typography>
                }
                ref={inputRef}
                onBlur={handleUpdateGivenBudget}
                onKeyDown={handleKeyDown}
                disabled={!hasAllPermissions([PermissionsConstants.UPDATE_MEDIA_PLAN])}
                error={isgivenBudgetError ? true : ''}
                showErrorIcon={isgivenBudgetError || ''}
                iconInfo={isgivenBudgetError || ''}
              />
            </Box>
          )}
        </Box>
        {showDetails && (
          <Box sx={{ width: '100%' }}>
            <Accordion
              summary={
                <Typography variant="Medium-14" color={theme.palette.text['100']}>
                  Media Plan Details
                </Typography>
              }
              details={
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '40px',
                    rowGap: '16px',
                  }}
                >
                  {details.map(detail => (
                    <Box
                      key={detail.name}
                      sx={{
                        width: 'calc((100%/3) - 27px)',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '24px',
                        backgroundColor: theme.palette.background,
                        borderRadius: '6px',
                        border: `1px solid ${theme.palette.text['500']}`,
                        padding: '12px',
                      }}
                    >
                      <Box sx={{ width: 'calc(50% - 12px)' }}>
                        <OverflowTooltip
                          text={detail.name}
                          sx={{ color: theme.palette.text['100'] }}
                        >
                          <Typography variant="Medium-14" color={theme.palette.text['300']}>
                            {detail.name}
                          </Typography>
                        </OverflowTooltip>
                      </Box>
                      <Box sx={{ width: 'calc(50% - 12px)' }}>
                        <OverflowTooltip
                          text={detail.description}
                          sx={{ color: theme.palette.text['100'] }}
                        >
                          {detail.description}
                        </OverflowTooltip>
                      </Box>
                    </Box>
                  ))}
                </Box>
              }
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default MediaPlanDetails;
