import React, { useCallback, useMemo, useState } from 'react';
import Chip from '../../../../../../components/shared/chip';
import SignPurchaseOrder from './SignPurchaseOrder.jsx';
import Button from '../../../../../../components/shared/buttons';
import { Box } from '@mui/material';
import { DownloadIcon } from '../../../../../../libs/svg-icons/icons';
import Select from '../../../../../../components/shared/form/Select/select';
import { html2pdf } from 'html2pdf.js';
import { useParams } from 'react-router-dom';

export default function HeaderActions({ hasDownloadPath, signDownloadPath = '' }) {
  const [openSign, setOpenSign] = useState(false);
  const params = useParams();

  const actionOptions = useMemo(() => ['To Do', 'X Y Z', 'A B C'], []);
  const [selectedAction, setSelectedAction] = useState(actionOptions[0]);

  const handleActionChanged = useCallback(e => {
    setSelectedAction(e.target.value);
  }, []);

  const downloadPDF = () => {
    if (signDownloadPath) {
      window.open(signDownloadPath);
    } else {
      const element = document.getElementById('ib-html-box');

      html2pdf()
        .set({
          margin: 5,
          filename: `PO_Vendor_File.pdf`,
          html2canvas: {
            scale: 4,
            width: 1270,
            useCORS: true,
            allowTaint: true,
            backgroundColor: '#F4F4F4',
          },
          jsPDF: {
            unit: 'px',
            format: [1270, element.offsetHeight],
            orientation: 'portrait',
            precision: 8,
          },
        })
        .from(element)
        .save()
        .catch(error => {
          console.error('Error generating PDF:', error);
        });
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <Button
        sx={{
          minWidth: '40px',
          padding: '5px',
          marginRight: '20px',
        }}
        variant="outlined"
        startIcon={
          <Box
            component={'span'}
            sx={{
              width: '21px',
              height: '21px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <DownloadIcon fill="var(--primary)" />
          </Box>
        }
        onClick={downloadPDF}
      />

      <Box sx={{ width: '12.5rem' }}>
        <Select
          id="media-filter"
          inputHeight="40px"
          options={actionOptions}
          value={selectedAction}
          optionMapFunction={options => options?.map(x => ({ id: x, label: x, value: x }))}
          searchable
          menuHeight="400px"
          menuWidth="12rem"
          selectHeight="40px"
          onChange={handleActionChanged}
        />
      </Box>

      <div style={{ marginLeft: 'auto', marginRight: '20px' }}>
        <Chip label="Draft" styles={{}} onClick={() => {}} />
      </div>

      <Button
        variant="contained"
        label="Approve with E Sign"
        onClick={() => {
          setOpenSign(true);
        }}
      />

      <SignPurchaseOrder
        open={openSign}
        onClose={() => {
          setOpenSign(false);
        }}
        handleIBSignData={() => {}}
      />
    </div>
  );
}
