import { createIcon } from '../createSVGIcon';

const path = (
  <g>
    <path d="M3.81268 3.83564L20.5727 1.4423C20.6673 1.42873 20.7637 1.43566 20.8553 1.4626C20.947 1.48955 21.0318 1.53589 21.104 1.59848C21.1762 1.66107 21.2341 1.73845 21.2738 1.82538C21.3135 1.91231 21.334 2.00675 21.334 2.1023V29.897C21.334 29.9924 21.3135 30.0867 21.2739 30.1735C21.2343 30.2603 21.1765 30.3377 21.1045 30.4002C21.0324 30.4628 20.9478 30.5092 20.8563 30.5362C20.7648 30.5632 20.6685 30.5703 20.574 30.557L3.81135 28.1636C3.49351 28.1184 3.20267 27.9599 2.99227 27.7174C2.78186 27.475 2.66602 27.1647 2.66602 26.8436V5.15564C2.66602 4.83459 2.78186 4.52432 2.99227 4.28183C3.20267 4.03934 3.49351 3.8809 3.81135 3.83564H3.81268ZM5.33402 6.31297V25.6863L18.6674 27.5916V4.40764L5.33402 6.31297ZM22.6674 25.333H26.6673V6.6663H22.6674V3.99964H28.0007C28.3543 3.99964 28.6934 4.14011 28.9435 4.39016C29.1935 4.64021 29.334 4.97935 29.334 5.33297V26.6663C29.334 27.0199 29.1935 27.3591 28.9435 27.6091C28.6934 27.8592 28.3543 27.9996 28.0007 27.9996H22.6674V25.333ZM13.6007 15.9996L17.334 21.333H14.134L12.0007 18.285L9.86735 21.333H6.66735L10.4007 15.9996L6.66735 10.6663H9.86735L12.0007 13.7143L14.134 10.6663H17.334L13.6007 15.9996Z" />
  </g>
);

export const UploadMediaPlan = createIcon(path, {
  width: 32,
  height: 32,
  viewBox: '0 0 32 32',
  fill: 'none',
});
