import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import Button from '../../../../../components/shared/buttons';
import { themePalette } from '../../../../../constants/theme/color';
import { PermissionsConstants } from '../../../../../constants/permissions-constants';
import { useHasAllPermission } from '../../../../../hooks/permissions';

function ViewMediaPlanCheckbox({
  selectedCheckboxes,
  gridTableRef,
  onClearAll,
  setSelectedCheckboxes,
  duplicateSelectedRows,
  createBuyingBriefGroup,
  mediaPlanDetails,
  anyBBGCreated,
  deleteMultipleRows,
}) {
  const hasAllPermissions = useHasAllPermission();

  const checkedCount = selectedCheckboxes ? selectedCheckboxes.length : 0;

  const totalRows = useMemo(() => {
    let totalRows = 0;
    gridTableRef.current?.api.forEachNode(x => {
      totalRows += 1;
    });
    return totalRows;
  }, [gridTableRef]);

  const handleClearAll = () => {
    if (onClearAll) {
      onClearAll();
    }
  };

  const handleDuplicateSelectedRows = () => {
    if (duplicateSelectedRows) {
      duplicateSelectedRows();
    }
  };

  const handleMultipleDeleteRow = () => {
    if (deleteMultipleRows) {
      if (selectedCheckboxes.length > 0) {
        deleteMultipleRows(selectedCheckboxes);
      } else {
        console.log('No rows selected for deletion');
      }
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '16px',
        padding: '13px 16px',
        border: '1px solid #CDD1D7',
        backgroundColor: themePalette.palette.text[600],
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '5px',
          flexDirection: 'row',
        }}
      >
        <Typography variant="SemiBold-18">
          <span style={{ color: '#0080FF' }}>{`${String(checkedCount).padStart(2, '0')}`}</span>
        </Typography>
        <Typography variant="SemiBold-18">
          <span style={{ color: '#16181A' }}>/</span>{' '}
        </Typography>
        <Typography variant="SemiBold-18">
          <span style={{ color: '#16181A' }}>{`${String(totalRows).padStart(2, '0')}`}</span>{' '}
        </Typography>
      </Box>

      {mediaPlanDetails?.activeVersionId === mediaPlanDetails?.currentVersionId && (
        <>
          {mediaPlanDetails.approvalStatus === 'MANAGER_APPROVED' &&
            hasAllPermissions([PermissionsConstants.CREATE_BUYING_BRIEF_GROUP]) && (
              <Button
                variant="contained"
                onClick={createBuyingBriefGroup}
                label="Create Buying Group"
                height="30px"
              />
            )}
          <Button variant="outlined" label="Clear All" height="30px" onClick={handleClearAll} />
          <Button
            variant="outlined"
            label="Duplicate"
            height="30px"
            onClick={handleDuplicateSelectedRows}
          />
          {!anyBBGCreated && (
            <Button
              variant="outlined"
              label="Multiple Delete Row"
              height="30px"
              onClick={handleMultipleDeleteRow}
            />
          )}
        </>
      )}
    </Box>
  );
}

export default ViewMediaPlanCheckbox;
