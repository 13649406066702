import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import Progress from '../../../../../components/shared/progress';
import Tooltip from '../../../../../components/shared/tooltip';
import { InfoIcon } from '../../../../../libs/svg-icons/icons';
import { capitalize } from '../../../../../utils/string';

export default function ProgressDateComp({
  remainingDays = '',
  progress = '',
  startDate = '',
  endDate = '',
  statusModifiedDate = '',
  userName = '',
}) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '1.5rem',
      }}
    >
      <Box
        sx={{
          maxWidth: '235px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          gap: '8px',
        }}
      >
        <Typography variant="Medium-12">Time Remaining - {remainingDays || '0'} Days</Typography>
        <Progress variant="linear" current={progress || 0} />
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.background,
          borderRadius: '6px',
          padding: '10px',
        }}
      >
        <Typography variant="Medium-14">Start Date - {startDate || '-'}</Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.background,
          borderRadius: '6px',
          padding: '10px',
        }}
      >
        <Typography variant="Medium-14">End Date - {endDate || '-'}</Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: theme.palette.background,
          borderRadius: '6px',
          padding: '10px',
        }}
      >
        <Typography variant="Medium-14" style={{ display: 'flex' }}>
          Last Modified
          {userName && (
            <Tooltip title={capitalize(userName || '')} placement="top">
              <div>
                <InfoIcon
                  width={14}
                  height={16}
                  fill={theme.palette.primary.main}
                  style={{ marginBottom: '-3px', marginLeft: '3px', marginRight: '3px' }}
                />
              </div>
            </Tooltip>
          )}
          - {statusModifiedDate || '-'}
        </Typography>
      </Box>
    </Box>
  );
}
