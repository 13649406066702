import { createIcon } from '../createSVGIcon';

const path = (
  <g>
    <path d="M2.64167 1.95501C3.85138 0.906788 5.399 0.330781 6.99967 0.333014C10.6817 0.333014 13.6663 3.31768 13.6663 6.99968C13.6663 8.42368 13.2197 9.74368 12.4597 10.8263L10.333 6.99968H12.333C12.3331 5.9541 12.0258 4.93155 11.4495 4.05918C10.8731 3.18682 10.053 2.50312 9.09113 2.0931C8.12929 1.68307 7.06815 1.56482 6.03965 1.75303C5.01114 1.94124 4.06064 2.42761 3.30634 3.15168L2.64167 1.95501ZM11.3577 12.0443C10.148 13.0926 8.60035 13.6686 6.99967 13.6663C3.31767 13.6663 0.333008 10.6817 0.333008 6.99968C0.333008 5.57568 0.779674 4.25568 1.53967 3.17301L3.66634 6.99968H1.66634C1.66626 8.04526 1.97351 9.06781 2.5499 9.94018C3.12629 10.8125 3.94639 11.4962 4.90822 11.9063C5.87006 12.3163 6.9312 12.4345 7.9597 12.2463C8.98821 12.0581 9.93871 11.5717 10.693 10.8477L11.3577 12.0443Z" />
  </g>
);

export const RefreshIcon = createIcon(path, {
  width: 14,
  height: 14,
  viewBox: '0 0 14 14',
  fill: 'none',
});
