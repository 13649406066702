import React, { memo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Select from '../../../../../components/shared/form/Select/select';

const UnmatchedColumnDetails = ({ availableOptions, label, isMapped, value, onOptionSelect }) => {
  const theme = useTheme();
  const [selectedOption, setSelectedOption] = useState(isMapped ? value : '');

  const handleOptionSelect = e => {
    onOptionSelect(label, e.target.value);
    setSelectedOption(e.target.value);
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '16px',
        padding: '28px 16px',
        borderBottom: `1px solid ${theme.palette.text['500']}`,
      }}
    >
      <Box>
        <Typography variant="Medium-14">{label}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '32px',
        }}
      >
        <Typography
          variant="Medium-14"
          color={isMapped ? theme.palette.success.main : theme.palette.error.main}
        >
          {isMapped ? 'Mapped' : 'Unmapped'}
        </Typography>
        <Box sx={{ width: '396px' }}>
          <Select
            id="year-filter"
            options={availableOptions}
            value={selectedOption}
            placeholder="Select Media Plan Column"
            optionMapFunction={options =>
              options?.map(x => ({ id: x?.value, label: x?.label, value: x?.value }))
            }
            searchable
            onChange={handleOptionSelect}
            menuHeight="400px"
            selectHeight="40px"
            clearSelectionButton={true}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default memo(UnmatchedColumnDetails);
