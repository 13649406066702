import React, { useMemo, useState } from 'react';

import { Box, Divider, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import Button from '../../../../components/shared/buttons';
import { EditIcon, InvoiceIcon, UploadIcon } from '../../../../libs/svg-icons/icons';
import Dialog from '../../../../components/shared/dialog';
import { FormProvider, useForm } from 'react-hook-form';
import InputField from '../../../../components/shared/form/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import DatePicker from '../../../../components/shared/form/date-picker';
import Select from '../../../../components/shared/form/Select';
import DragAndDropFilePicker from '../../../../components/shared/form/fileupload';
import Textarea from '../../../../components/shared/form/textarea';
import PoVendorSelectionTable from './PoVendorSelectionTable';

const dataPO = [
  {
    poNumber: '123123',
    poAmount: '98079879182',
    poIssueDate: '2022-01-01',
    action: '',
  },
  {
    poNumber: '123123',
    poAmount: '98079879182',
    poIssueDate: '2022-01-01',
    action: '',
  },
  {
    poNumber: '123123',
    poAmount: '98079879182',
    poIssueDate: '2022-01-01',
    action: '',
  },
];

const schema = yup.object().shape({
  invoicenumber: yup.string().required('Invoice Number is Required'),
  invoicedate: yup.string().required('Invoice Date is Required'),
  currency: yup.string().required('Currency is Required'),
  invoiceAmount: yup.string().required('Invoice Amount is Required'),
  vatAmount: yup.string().nullable(''),

  taxInvoiceNumber: yup.string().required('Tax Invoice Number is Required'),
  taxInvoiceDate: yup.string().required('Tax Invoice Date is Required'),
  uploadinvoice: yup
    .mixed()
    .required('A file is required')
    .test(
      'fileSize',
      'File size is too large. Maximum allowed size is 20MB.',
      value => !value || (value && value.size <= 20 * 1024 * 1024), // 20MB limit
    )
    .test(
      'fileFormat',
      'Unsupported file format. Allowed formats: .pdf',
      value => !value || (value && ['application/pdf'].includes(value.type)),
    ),
  comments: yup.string().nullable(''),
});

const ViewInvoiceEditView = ({ readOnly = false }) => {
  const theme = useTheme();

  const schemaMethods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const handleInvoiceUpload = file => {};

  const handleInvoiceRemoved = () => {
    schemaMethods.setValue('uploadinvoice', undefined, {
      shouldValidate: true,
    });
  };

  const details = [
    { label: 'Invoice Number', value: '0012973870284722' },
    { label: 'Invoice Date', value: '01-08-2023' },
    { label: 'Currency', value: 'IDR' },
    { label: 'Invoice Amount', value: 'PO567890' },
    { label: 'VAT Amount (Indonesia Vendor Only)', value: '9045000' },
  ];

  const popdetails = [
    { label: 'Tax Invoice Number', value: '012345' },
    { label: 'Tax Invoice Date', value: '01-08-2023' },
  ];

  const totalDetails = [
    { label: 'Subtotal exclusive taxes', value: '68,000,000.00 IDR' },
    {
      label: 'ID VAT Standard 11% of 68,000,000.00 IDR (Valid from 01-08-2023)',
      value: '7,480,000.00 IDR',
    },
  ];

  const currencyOption = [
    { value: 'notContains', label: 'Does Not Contain' },
    { value: 'equals', label: 'Equals' },
    { value: 'notEquals', label: 'Does Not Equal' },
  ];

  const TotleIDRComp = useMemo(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '20px',
          flexWrap: 'wrap',
        }}
      >
        {totalDetails?.map(detail => (
          <Box key={detail.label} sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <Box sx={{ display: 'flex', width: '70%' }}>
              <Typography variant="Regular-18">{detail.label}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '30%',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Typography variant="Regular-18">{detail.value}</Typography>
            </Box>
          </Box>
        ))}

        <Divider sx={{ width: '100%' }} />
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '80px', width: '100%' }}>
          <Box sx={{ display: 'flex', width: '60%' }}>
            <Typography variant="Medium-18">Total IDR</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '40%',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Typography variant="Medium-18">0.00 IDR</Typography>
          </Box>
        </Box>
      </Box>
    );
  }, [dataPO, totalDetails]);

  const btnLabel = useMemo(() => {
    return true ? 'Create Invoice & Submit' : 'Save Changes';
  }, []);

  return (
    <>
      {readOnly ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%',
            flexDirection: 'column',
            gap: '40px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '4px',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
            }}
          >
            {details?.map(detail => (
              <Box
                sx={{
                  display: 'flex',
                  gap: '24px',
                  flexDirection: 'row',
                  width: '33%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    width: '300px',
                    gap: '4px',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    marginTop: '16px',
                  }}
                >
                  <Typography variant="Medium-16">{detail.label}</Typography>
                  <Typography variant="Regular-14">{detail.value}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <Divider sx={{ width: '100%' }} />

          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '4px',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
            }}
          >
            {popdetails?.map(detail => (
              <Box
                sx={{
                  display: 'flex',
                  gap: '24px',
                  flexDirection: 'row',
                  width: '33%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    width: '300px',
                    gap: '4px',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    marginTop: '16px',
                  }}
                >
                  <Typography variant="Medium-16">{detail.label}</Typography>
                  <Typography variant="Regular-14">{detail.value}</Typography>
                </Box>
              </Box>
            ))}
          </Box>

          <Divider sx={{ width: '100%' }} />

          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '80px', width: '100%' }}>
            <PoVendorSelectionTable tableRows={dataPO} readOnly={readOnly} />
          </Box>

          <Divider sx={{ width: '100%' }} />

          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '191px',
              borderRadius: '8px',
              border: '1px solid #CDD1D7',
              backgroundColor: '#F4F4F4',
              padding: '16px',
            }}
          >
            {TotleIDRComp}
          </Box>
          <Box
            sx={{
              width: '242px',
              height: '244px',
              display: 'flex',
              flexDirection: 'column',
              border: '1px solid #CDD1D7',
              borderRadius: '8px',
              gap: '80px',
              padding: '24px 0px 0px 0px ',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                padding: '0px 24px',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100px',
                height: '52px',
              }}
            >
              <InvoiceIcon fill="" />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                border: '1px solid #CDD1D7',
                backgroundColor: '#F4F4F4',
                height: '88px',
                width: '100%',
                padding: '16px',
                gap: '5px',
              }}
            >
              <Typography variant="Medium-16">Invoice</Typography>
              <Typography variant="Regular-14">18th Aug, 2023 at 12:00 PM</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              gap: '4px',
            }}
          >
            <Typography variant="Medium-16">Comments</Typography>
            <Typography variant="Regular-14">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla faucibus ultrices dui
              sit amet aliquam.
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%',
            flexDirection: 'column',
            gap: '40px',
          }}
        >
          <FormProvider {...schemaMethods}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '20px',
                marginTop: '24px',
              }}
            >
              <Box sx={{ width: '31.5%' }}>
                <InputField
                  type="text"
                  id="invoicenumber"
                  inputHeight="48px"
                  label="Invoice Number"
                  {...schemaMethods.register('invoicenumber')}
                />
              </Box>

              <Box sx={{ width: '31.5%', textAlign: 'left' }}>
                <DatePicker
                  id="invoicedate"
                  label="Invoice Date"
                  placeholder="Select date"
                  {...schemaMethods.register('invoicedate')}
                  onChange={() => null}
                  views="MM/DD"
                />
              </Box>

              <Box sx={{ width: '31.5%' }}>
                <Select
                  id="currency"
                  label="currency"
                  options={currencyOption}
                  selectHeight="48px"
                  placeholder="Select currency"
                  {...schemaMethods.register('currency')}
                />
              </Box>
              <Box sx={{ width: '31.5%' }}>
                <InputField
                  type="text"
                  id="invoiceAmount"
                  inputHeight="48px"
                  placeholder="Enter invoice amount"
                  label="Invoice Amount"
                  {...schemaMethods.register('invoiceAmount')}
                />
              </Box>

              <Box sx={{ width: '31.5%', textAlign: 'left' }}>
                <InputField
                  type="text"
                  inputHeight="48px"
                  id="vatAmount"
                  label="VAT Amount (Indonesia Vendor Only)"
                  placeholder="Enter VAT amount"
                  {...schemaMethods.register('vatAmount')}
                />
              </Box>
            </Box>
            <Divider sx={{ width: '100%' }} />
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <Box sx={{ width: '31.5%' }}>
                <InputField
                  type="text"
                  id="taxInvoiceNumber"
                  inputHeight="48px"
                  placeholder="Enter tax invoice number"
                  label="Tax Invoice Number"
                  {...schemaMethods.register('taxInvoiceNumber')}
                />
              </Box>
              <Box sx={{ width: '31.5%' }}>
                <DatePicker
                  id="taxInvoiceDate"
                  label="Tax Invoice Date"
                  placeholder="Select date"
                  {...schemaMethods.register('taxInvoiceDate')}
                  onChange={() => null}
                  views="MM/DD"
                />
              </Box>
            </Box>
            <Divider sx={{ width: '100%' }} />

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '80px', width: '100%' }}>
              <PoVendorSelectionTable tableRows={[...dataPO]} readOnly={readOnly} />
            </Box>

            <Divider sx={{ width: '100%' }} />

            <Box
              sx={{
                display: 'flex',
                width: '100%',
                height: '200px',
                borderRadius: '8px',
                border: '1px solid #CDD1D7',
                backgroundColor: '#F4F4F4',
                padding: '16px',
              }}
            >
              {TotleIDRComp}
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'row',
                gap: '20px',
              }}
            >
              <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '4px' }}>
                <Typography
                  variant="Medium-16"
                  sx={{ display: 'flex', justifyContent: 'flex-start' }}
                >
                  Upload Media Plan Template
                </Typography>
                <DragAndDropFilePicker
                  multi={false}
                  label="Upload Document"
                  icon={<UploadIcon fill={theme.palette.primary.main} />}
                  headline="Upload Document"
                  description="(Only .pdf file, maximum 20MB file size)"
                  onUpload={handleInvoiceUpload}
                  onRemove={handleInvoiceRemoved}
                  acceptedfileExtensions={['pdf']}
                  draggable={true}
                  maxFileSize={5}
                  {...schemaMethods.register('uploadinvoice')}
                />
              </Box>
              <Box sx={{ width: '50%' }}>
                <Textarea
                  id="comments"
                  type="text"
                  rows={8}
                  label="Comments"
                  placeholder="Write your comment here.."
                  {...schemaMethods.register('comments')}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Button label={btnLabel} variant="contained" />
            </Box>
          </FormProvider>
        </Box>
      )}
    </>
  );
};

export default ViewInvoiceEditView;
