import { Routes, Route, Outlet, Navigate, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useHasAllPermission } from '../../hooks/permissions';
import PageNotFound from '../../pages/404';
import BackdropOverlay from '../shared/backdropOverlay';
import { AppConstants } from '../../constants/app-constants';

const RouteHandler = ({ routes = [], container = null, defaultRoute = '' }) => {
  const hasAllPermissions = useHasAllPermission();
  const navigate = useNavigate();

  routes = [
    ...routes,
    {
      component: (
        <Box>
          <BackdropOverlay
            open={true}
            pageWarning={AppConstants.PAGE_ACCESS_ERROR}
            buttonLabel="Redirect To Home"
            clickAction={() => {
              navigate(`/app`);
            }}
            autharizationWarning={true}
          />
        </Box>
      ),
      path: 'unauthorized',
    },
    {
      component: <PageNotFound />,
      path: '*',
    },
  ];

  return (
    <Routes>
      {defaultRoute && <Route index element={<Navigate to={defaultRoute} replace={false} />} />}
      <Route path="/" element={container}>
        {routes.map((route, i) => {
          if (route.permissions && !hasAllPermissions(route.permissions)) {
            return (
              <Route
                key={route.key || route.path}
                path={route.path}
                element={<Navigate to={'../unauthorized'} replace={true} />}
              />
            );
          }

          if (route.isIndex) {
            return <Route index element={route.component || <Outlet />} key={route.path || i} />;
          }

          return <Route element={route.component} path={route.path} key={route.path} />;
        })}
      </Route>
    </Routes>
  );
};

export default RouteHandler;
