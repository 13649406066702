import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import { useTheme } from '@mui/material/styles';
import OverflowTooltip from '../tooltip/OverflowTooltip';
import { SortDescIcon } from '../../../libs/svg-icons/icons/SortDesc';
import { SortAscIcon } from '../../../libs/svg-icons/icons/SortAsc';
import { FilterIcon } from '../../../libs/svg-icons/icons';
import MuiMenu from './MuiMenu';

const MuiTableHeader = ({
  column,
  order,
  orderBy,
  onRequestSort,
  onRequestPinColumn,
  filterProps,
  onRequestFilterChange,
  filterModel = {},
}) => {
  const theme = useTheme();

  const [ascSort, setAscSort] = useState('inactive');
  const [descSort, setDescSort] = useState('inactive');
  const [noSort, setNoSort] = useState('active');
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const showMenu = filterModel?.hasOwnProperty(column.id);

  const handleMenuOpen = event => {
    setMenuAnchorEl(prev => (prev ? null : event.currentTarget));
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  useEffect(() => {
    if (orderBy !== column.id) {
      setAscSort('inactive');
      setDescSort('inactive');
      setNoSort('active');
    } else {
      setAscSort(order === 'asc' ? 'active' : 'inactive');
      setDescSort(order === 'desc' ? 'active' : 'inactive');
      setNoSort(order === null ? 'active' : 'inactive');
    }
  }, [orderBy, order, column.id]);

  const handleSort = useCallback(() => {
    if (column.sortable) {
      let direction = null;
      if (noSort === 'active' && ascSort === 'inactive') {
        direction = 'asc';
      } else if (noSort === 'inactive' && ascSort === 'active') {
        direction = 'desc';
      }
      // const direction = noSort === 'active' ? 'asc' : ascSort === 'active' ? 'desc' : null;

      onRequestSort(column.id, direction);
    }
  }, [noSort, ascSort, descSort]);

  let menu = null;
  if (column.filter) {
    menu = (
      <>
        <Box
          className="menu-icon-wrapper"
          sx={{ position: 'relative', zIndex: 10, cursor: 'pointer' }}
          onClick={handleMenuOpen}
          aria-controls={menuAnchorEl ? 'mui-header-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={menuAnchorEl ? 'true' : undefined}
        >
          <FilterIcon height={10} width={16} fill={theme.palette.text['100']} />
        </Box>
        <MuiMenu
          column={column}
          anchorEl={menuAnchorEl}
          onRequestPinColumn={onRequestPinColumn}
          onClose={handleMenuClose}
          filterProps={filterProps}
          filterModel={filterModel}
          onRequestFilterChange={onRequestFilterChange}
        />
      </>
    );
  }

  let sort = null;
  if (column.sortable) {
    sort = (
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        {noSort === 'inactive' ? (
          ascSort === 'active' ? (
            <Box className={`customSortUpLabel asc-${ascSort}`}>
              <SortAscIcon height={12} width={14} fill={theme.palette.text['100']} />
            </Box>
          ) : descSort === 'active' ? (
            <Box className={`customSortRemoveLabel desc-${descSort}`}>
              <SortDescIcon height={12} width={14} fill={theme.palette.text['100']} />
            </Box>
          ) : null
        ) : null}
      </Box>
    );
  }

  return (
    <TableCell
      key={column.id}
      sx={{
        minWidth: column.minWidth,
        width: column.width,
        maxWidth: column.maxWidth,
        height: '60px',
        padding: '0 !important',
      }}
      align={column.align ?? 'left'}
      colSpan={column.colSpan ?? 1}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',

          padding: '0 15px 0 0',

          '& .column-name-wrapper': {
            width: `calc(100% - ${showMenu ? (noSort === 'inactive' ? '34px' : '20px') : noSort === 'inactive' ? '14px' : '0px'})`,
            height: '100%',
            padding: '17px 0 15px 15px',
            cursor: column.sortable ? 'pointer' : 'default',
          },

          '&:hover .column-name-wrapper': {
            width: `calc(100% - ${column.filter ? (noSort === 'inactive' ? '34px' : '20px') : noSort === 'inactive' ? '14px' : '0px'})`,
          },

          '& .menu-icon-wrapper': {
            display: menuAnchorEl || showMenu ? 'block' : 'none',
          },

          '&:hover .menu-icon-wrapper': {
            display: 'block',
          },
        }}
      >
        <Box className="column-name-wrapper" onClick={handleSort} onTouchEnd={handleSort}>
          <OverflowTooltip text={column.headerName}>
            <Typography variant="Medium-14" color={theme.palette.text['100']}>
              {column.headerName}
            </Typography>
          </OverflowTooltip>
        </Box>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          {sort}
          {menu}
        </Box>
      </Box>
    </TableCell>
  );
};

export default MuiTableHeader;
