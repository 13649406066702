import axiosInstance from './api';
import { ApiURLConstants } from '../constants/api-url-constants';

const globalHeaders = {
  'Content-Type': 'application/json',
};

const API_BASE_URL = process.env.REACT_APP_MEDIA_API_BASE_URL;
const API = axiosInstance;

export const listVendorPoOLD = async params => {
  const uri = `${API_BASE_URL}${ApiURLConstants.LIST_VENDOR_PO_NEW(params)}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;

  // const response = await API.post(uri, { ...params },{ headers});
  // return response;
};

export const listVendorPo = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.LIST_VENDOR_PO}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;

  // const response = await API.post(uri, { ...params },{ headers});
  // return response;
};
