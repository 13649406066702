import React, { useCallback, useState } from 'react';
import Button from '../buttons';
import { FilterJarIcon } from '../../../libs/svg-icons/icons';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import Dialog from '../dialog';
import FilterListing from './filterlisting';

export default function FilterComponent({
  filterState = {},
  setFilterState = () => {},
  filterData = [],
}) {
  const theme = useTheme();
  const [filterOpen, setFilterOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleFilterOpen = useCallback(() => {
    setFilterOpen(true);
  }, []);

  const handleFilterClose = useCallback(() => {
    setFilterOpen(false);
  }, []);

  return (
    <>
      <Button
        variant="outlined"
        label="Filters"
        onClick={handleFilterOpen}
        startIcon={<FilterJarIcon fill={theme.palette.primary.main} />}
        sx={{ marginTop: '-5px', marginRight: '15px' }}
      />

      <Dialog
        open={filterOpen}
        onClose={handleFilterClose}
        headline={
          <Typography variant="SemiBold-22" alignItems="flex-start" justifyContent="flex-start">
            Filters
          </Typography>
        }
        description={
          isLoading ? (
            <div style={styles.centerDivItems}>
              <CircularProgress size={35} style={{ margin: '70px auto' }} />
            </div>
          ) : (
            <Box
              sx={{
                width: '100%',
                gap: '27px',
                height: '550px',
              }}
            >
              <FilterListing
                filterState={filterState}
                setFilterState={setFilterState}
                handleFilterClose={handleFilterClose}
                filterData={filterData}
              />
            </Box>
          )
        }
        width="860px"
        showDialogActions={false}
      />
    </>
  );
}

const styles = {
  centerDivItems: {
    height: '300px',
    width: '100%',
    textAlign: 'center',
    paddingTop: '60px',
    display: 'flex',
    flexDirection: 'column',
  },
};
