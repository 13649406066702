import React, { useEffect, useState } from 'react';
import Dialog from '../../../../components/shared/dialog';
import { Box, CircularProgress, Typography } from '@mui/material';
import Button from '../../../../components/shared/buttons';
import { getProjectSubmissionData } from '../../../../services/project-service';
import { AppConstants } from '../../../../constants/app-constants';
import { useEnqueueSnackbar } from '../../../../components/shared/toast-provider/toastHook';
import EmptyTableIllustration from '../../../../assets/images/empty-table-illustration.svg';
import { handleError } from '../../../../utils/errorHandling';

const ViewDataDialog = ({ params }) => {
  const enqueueSnackbar = useEnqueueSnackbar();

  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  const [submissionData, setSubmissionData] = useState([]);

  useEffect(() => {
    if (openDialog) {
      fetchProjectSubmissionData();
    }
  }, [openDialog]);

  const fetchProjectSubmissionData = async () => {
    setIsloading(true);
    await getProjectSubmissionData(params?.data?.id)
      .then(response => {
        const { data = [] } = response?.data || {};
        setSubmissionData(data);
      })
      .catch(error => {
        let msg = handleError(error);
        enqueueSnackbar(msg, 'error');
        setSubmissionData([]);
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const centerDivItems = {
    height: '300px',
    width: '100%',
    textAlign: 'center',
    paddingTop: '60px',
    display: 'flex',
    flexDirection: 'column',
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '16px',
      }}
    >
      <Button
        height="30px"
        sx={{
          minWidth: '21px',
          padding: '6px 14px',
        }}
        variant="outlined"
        label={<Typography variant="Medium-12">View</Typography>}
        onClick={handleOpenDialog}
      />
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        headline={
          <Typography variant="SemiBold-22" alignItems="flex-start" justifyContent="flex-start">
            Project Submission Data
          </Typography>
        }
        description={
          isLoading ? (
            <div style={centerDivItems}>
              <CircularProgress size={35} style={{ margin: '70px auto' }} />
            </div>
          ) : (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                gap: '27px',
                marginTop: '10px',
              }}
            >
              {!!submissionData?.length &&
                submissionData?.map(item => {
                  return (
                    <Box
                      sx={{
                        width: 'calc(33.33% - 18px)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        gap: '4px',
                      }}
                    >
                      <Typography variant="Medium-14">{item?.name || 'Label'}</Typography>
                      <Typography variant="Regular-14">{item?.columnValue || '-'}</Typography>
                    </Box>
                  );
                })}

              {!submissionData?.length && (
                <div style={centerDivItems}>
                  <img
                    src={EmptyTableIllustration}
                    alt="No data present"
                    width={150}
                    height={150}
                    style={{ margin: '0 auto' }}
                  />
                  <Typography variant="Medium-16">Data Not Found</Typography>
                </div>
              )}
            </Box>
          )
        }
        width="1024px"
        showDialogActions={false}
      />
    </Box>
  );
};

export default ViewDataDialog;
