import { formatDate } from '../../../../../libs/date/format';
import { formatCurrency } from '../../../../../utils/string';

export const defaultColDef = {
  resizable: false,
  flex: 1,
};

export const columns = handleBuyingBriefGroupIdClicked => [
  {
    id: 'webappBbgNumber',
    headerName: 'Buying Brief Group ID',
    field: 'webappBbgNumber',
    sortable: false,
    minWidth: 250,
    maxWidth: 250,
    cellStyle: params => ({
      background: 'var(--text-600)',
      cursor: 'pointer',
    }),
    onCellClicked: handleBuyingBriefGroupIdClicked,
  },
  {
    id: 'brand',
    headerName: 'Brand',
    field: 'brand.name',
    sortable: false,
    minWidth: 200,
    maxWidth: 200,
    cellStyle: params => ({
      background: 'var(--text-600)',
    }),
    valueFormatter: params => params.value ?? '-',
  },
  {
    id: 'mediaPlanTotalBudget',
    headerName: 'Plan Budget',
    field: 'mediaPlanTotalBudget',
    sortable: false,
    minWidth: 200,
    maxWidth: 200,
    valueFormatter: params => (params.value ? `${formatCurrency(params.value)} IDR` : '-'),
  },
  {
    id: 'campaign',
    headerName: 'Campaign',
    field: 'campaign.name',
    sortable: false,
    minWidth: 200,
    maxWidth: 200,
    valueFormatter: params => params.value ?? '-',
  },
  {
    id: 'productId',
    headerName: 'Product ID',
    field: 'variantFinanceName.name',
    sortable: false,
    minWidth: 200,
    maxWidth: 200,
    valueFormatter: params => params.value ?? '-',
  },
  {
    id: 'clientPO',
    headerName: 'Client PO',
    field: 'webappClientPO.clientPoNumber',
    sortable: false,
    minWidth: 145,
    valueFormatter: params => params.value ?? '-',
  },
  {
    id: 'clientPODescription',
    headerName: 'Client PO Description',
    field: 'webappClientPO.clientPoDescription',
    sortable: false,
    minWidth: 250,
    valueFormatter: params => params.value ?? '-',
  },
  {
    id: 'startDate',
    headerName: 'Start Date',
    field: 'webappClientPO.startDate',
    sortable: false,
    minWidth: 170,
    valueFormatter: params =>
      params.value ? formatDate(new Date(params.value), 'dd/MM/yyyy') : '-',
  },
  {
    id: 'endDate',
    headerName: 'End Date',
    field: 'webappClientPO.endDate',
    sortable: false,
    minWidth: 170,
    valueFormatter: params =>
      params.value ? formatDate(new Date(params.value), 'dd/MM/yyyy') : '-',
  },
  {
    id: 'expiryDate',
    headerName: 'Expiry Date',
    field: 'webappClientPO.expiryDate',
    sortable: false,
    minWidth: 170,
    maxWidth: 170,
    valueFormatter: params =>
      params.value ? formatDate(new Date(params.value), 'dd/MM/yyyy') : '-',
  },
  {
    id: 'clientPOAmount',
    headerName: 'Client PO Amount',
    field: 'webappClientPO.clientPoAmount',
    sortable: false,
    minWidth: 170,
    maxWidth: 170,
    valueFormatter: params => (params.value ? `${formatCurrency(params.value)} IDR` : '-'),
  },
  {
    id: 'poBudgetSpent',
    headerName: 'PO Budget Spent',
    field: 'webappClientPO.clientPoAmount',
    sortable: false,
    minWidth: 170,
    maxWidth: 170,
    valueFormatter: params =>
      params.data?.webappClientPO?.remainingBudget && params.data?.webappClientPO?.clientPoAmount
        ? `${formatCurrency(params.data?.webappClientPO?.clientPoAmount - params.data?.webappClientPO?.remainingBudget)} IDR`
        : '-',
  },
  {
    id: 'poBudgetRemaining',
    headerName: 'PO Budget Remaining',
    field: 'webappClientPO.remainingBudget',
    sortable: false,
    minWidth: 200,
    maxWidth: 200,
    cellStyle: params => ({
      background: 'var(--text-600)',
    }),
    valueFormatter: params => (params.value ? `${formatCurrency(params.value)} IDR` : '-'),
  },
];
