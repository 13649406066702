import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ChevronDownIcon, ChevronRightIcon } from '../../../libs/svg-icons/icons';
import OverflowTooltip from '../tooltip/OverflowTooltip';

const MuiTableRow = ({
  row = [],
  columns = [],
  childData = [],
  childColumns = [],
  hasChild = false,
  pinnedColumns = [],
  expandAll = false,
  showChildHeader = true,
  childStyles = {},
  isLoading = false,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(expandAll);
  }, [expandAll]);

  const childRowColSpan = useMemo(() => {
    if (hasChild && childColumns && childColumns.length > 0) {
      return (
        childColumns?.reduce(
          (acc, column) => (column.colSpan ? acc + column.colSpan : acc + 1),
          0,
        ) + 1
      );
    } else return 0;
  }, [childColumns, hasChild]);

  const childDataForThisRow = useMemo(() => {
    return childData?.filter(x => x.parentId === row.id);
  }, [childData, row.id]);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell
          className="sticky"
          sx={{
            position: 'sticky',
            background: theme.palette.white,
            left: 0,
            zIndex: 10,
            padding: '0 !important',
          }}
        >
          {hasChild && (
            <TableCell
              sx={{
                minWidth: '60px',
                width: '60px',
                maxWidth: '60px',
                height: '60px',
                background: theme.palette.white,
                borderBottom: 'none !important',
              }}
            >
              {hasChild && childDataForThisRow && childDataForThisRow.length > 0 && (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  sx={{
                    padding: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '32px',
                    height: '32px',
                  }}
                  onClick={() => setOpen(!open)}
                >
                  {open ? (
                    <ChevronDownIcon fill="var(--deep-navy)" />
                  ) : (
                    <ChevronRightIcon fill="var(--deep-navy)" />
                  )}
                </IconButton>
              )}
            </TableCell>
          )}
          {isLoading
            ? columns
                ?.filter(x => !x.hide && pinnedColumns.includes(x.id))
                ?.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sx={{
                      minWidth: column.minWidth,
                      width: column.width,
                      maxWidth: column.maxWidth,
                      height: '60px',
                      cursor: column.onCellClicked ? 'pointer' : 'auto',
                      borderRight: `1px solid ${theme.palette.text['500']}`,
                      borderBottom: `1px solid ${theme.palette.text['500']}`,
                    }}
                    colSpan={column.colSpan ?? 1}
                  >
                    <Skeleton
                      variant="rectangular"
                      width="80%"
                      height="80%"
                      sx={{ margin: '0 15px' }}
                    />
                  </TableCell>
                ))
            : columns
                ?.filter(x => !x.hide && pinnedColumns.includes(x.id))
                .map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sx={{
                      minWidth: column.minWidth,
                      width: column.width,
                      maxWidth: column.maxWidth,
                      height: '60px',
                      cursor: column.onCellClicked ? 'pointer' : 'auto',
                      borderBottom: 'none !important',
                    }}
                    colSpan={column.colSpan ?? 1}
                    onClick={() => (column.onCellClicked ? column.onCellClicked(row) : null)}
                  >
                    {column.cellRenderer ? (
                      column.cellRenderer(row)
                    ) : (
                      <OverflowTooltip text={row[column.field]}>
                        {row[column.field]}
                      </OverflowTooltip>
                    )}
                  </TableCell>
                ))}
        </TableCell>
        {isLoading
          ? columns
              ?.filter(x => !x.hide && !pinnedColumns.includes(x.id))
              ?.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    minWidth: column.minWidth,
                    width: column.width,
                    maxWidth: column.maxWidth,
                    height: '60px',
                    cursor: column.onCellClicked ? 'pointer' : 'auto',
                    borderRight: `1px solid ${theme.palette.text['500']}`,
                    borderBottom: `1px solid ${theme.palette.text['500']}`,
                  }}
                  colSpan={column.colSpan ?? 1}
                >
                  <Skeleton
                    variant="rectangular"
                    width="80%"
                    height="80%"
                    sx={{ margin: '0 15px' }}
                  />
                </TableCell>
              ))
          : columns
              ?.filter(x => !x.hide && !pinnedColumns.includes(x.id))
              ?.map(column =>
                column.hide || column.pinned ? null : (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sx={{
                      minWidth: column.minWidth,
                      width: column.width,
                      maxWidth: column.maxWidth,
                      height: '60px',
                      cursor: column.onCellClicked ? 'pointer' : 'auto',
                      borderRight: `1px solid ${theme.palette.text['500']}`,
                      borderBottom: `1px solid ${theme.palette.text['500']}`,
                      position: column.pinned ? 'sticky' : 'relative',
                      background: theme.palette.white,
                      left: column.pinned ? 0 : 'auto',
                      zIndex: column.pinned ? 10 : 0,
                    }}
                    colSpan={column.colSpan ?? 1}
                    onClick={() => (column.onCellClicked ? column.onCellClicked(row) : null)}
                  >
                    {column.cellRenderer ? (
                      column.cellRenderer(row)
                    ) : (
                      <OverflowTooltip text={row[column.field]}>
                        {row[column.field]}
                      </OverflowTooltip>
                    )}
                  </TableCell>
                ),
              )}
      </TableRow>
      {isLoading
        ? null
        : hasChild &&
          childDataForThisRow &&
          childDataForThisRow.length > 0 && (
            <TableRow>
              <TableCell sx={{ padding: '0px !important' }} colSpan={childRowColSpan}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <TableContainer sx={{ padding: 0, ...childStyles }}>
                    <MuiTable
                      aria-label="child-table"
                      sx={{
                        '& .MuiTableCell-root': {
                          padding: '16px',
                          borderLeft: `1px solid ${theme.palette.text['500']}`,
                        },
                        '& .MuiTableHead-root': {
                          '& .MuiTableRow-root': {
                            '& .MuiTableCell-root': {
                              backgroundColor: theme.palette.text['400'],
                              borderTop: 'none',
                              borderBottom: `1px solid ${theme.palette.text['500']}`,
                            },
                          },
                        },
                        '& .MuiTableBody-root': {
                          '& .MuiTableRow-root': {
                            '& .MuiTableCell-root': {
                              borderBottom: `1px solid ${theme.palette.text['500']}`,
                            },
                          },
                        },
                      }}
                    >
                      {showChildHeader && (
                        <TableHead>
                          <TableRow>
                            {childColumns.map(childColumn => (
                              <TableCell
                                key={childColumn.id}
                                sx={{
                                  minWidth: childColumn.minWidth,
                                  width: childColumn.width,
                                  maxWidth: childColumn.maxWidth,
                                  height: '60px',
                                  borderTop: 'none',
                                  borderBottom: `1px solid ${theme.palette.text['500']}`,
                                }}
                                align={childColumn.align ?? 'left'}
                                colSpan={childColumn.colSpan ?? 1}
                              >
                                {childColumn.headerName}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                      )}
                      <TableBody>
                        {childDataForThisRow?.map(childRow => (
                          <TableRow key={childRow.id}>
                            {childColumns.map(childColumn => (
                              <TableCell
                                key={childColumn.id}
                                align={childColumn.align}
                                sx={{
                                  minWidth: childColumn.minWidth,
                                  width: childColumn.width,
                                  maxWidth: childColumn.maxWidth,
                                  height: '60px',
                                  cursor: childColumn.onCellClicked ? 'pointer' : 'auto',
                                  borderBottom: `1px solid ${theme.palette.text['500']}`,
                                }}
                                colSpan={childColumn.colSpan ?? 1}
                                onClick={() =>
                                  childColumn.onCellClicked
                                    ? childColumn.onCellClicked(childRow)
                                    : null
                                }
                              >
                                {childColumn.cellRenderer ? (
                                  childColumn.cellRenderer(childRow)
                                ) : (
                                  <OverflowTooltip text={childRow[childColumn.field]}>
                                    {childRow[childColumn.field]}
                                  </OverflowTooltip>
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </MuiTable>
                  </TableContainer>
                </Collapse>
              </TableCell>
            </TableRow>
          )}
    </React.Fragment>
  );
};

export default MuiTableRow;
