import { Box, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from '../../../../components/shared/buttons';
import Card from '../../../../components/shared/card';
import { useNavigate } from 'react-router-dom';
import Table from '../../../../components/shared/table';
import Input from '../../../../components/shared/form/Input/input';
import Select from '../../../../components/shared/form/Select/select';
import EmptyTable from '../../../../components/shared/table/EmptyTable';
import { debounce } from 'lodash';
import { columnData } from './columnsData';
import { AddIcon, FilterJarIcon } from '../../../../libs/svg-icons/icons';
import CheckboxMenu from '../../../../components/shared/menu/CheckboxMenu';
import FilterComponent from '../../../../components/shared/filterComponent';
import axios from 'axios';

const PAGE_SIZE = 20;

const VendorInvoiceList = () => {
  const gridTableRef = useRef(null);
  const theme = useTheme();
  const navigate = useNavigate();

  const filterOptions = useMemo(
    () => ['All Media', 'Human', 'Humanoid', 'Alien', 'Mythological Creature', 'Unknown'],
    [],
  );

  const [searchText, setSearchText] = useState('');
  const [showEmptyTableComponent, setShowEmptyTableComponent] = useState(false);

  const [filterType, setFilterType] = useState('All Media');
  const [brandType, setBrandType] = useState('All Brands');
  const [searchError, setSearchError] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [fieldAnchorEl, setFieldAnchorEl] = useState(null);

  const [filterState, setFilterState] = useState({
    productName: [],
    sample: [],
    year: [],
  });

  const handleViewInvoiceDetails = useCallback(
    event => {
      if (event.data?.id) {
        navigate(`view/${event.data?.id}`, {
          replace: false,
        });
      }
    },
    [navigate],
  );

  const [vendorColumns, setVendorColumns] = useState(columnData(handleViewInvoiceDetails));

  const handleMenuItemClick = useCallback((colId, checked) => {
    setVendorColumns(prev => prev.map(x => (x.id === colId ? { ...x, hide: !checked } : x)));
  }, []);

  const handleOpenFieldsMenu = event => {
    setFieldAnchorEl(prev => (prev ? null : event.currentTarget));
  };

  const handleCloseFieldsMenu = () => {
    setFieldAnchorEl(null);
  };

  const getDataSource = useCallback(
    selectedMediaType => {
      const dataSource = {
        rowCount: undefined,
        getRows: async params => {
          let uri = `https://rickandmortyapi.com/api/character?page=${params.endRow / PAGE_SIZE ?? 1}`;

          if (!!searchText) {
            uri = uri + `&name=${searchText}`;
          }
          const response = await fetch(uri);
          const jsonData = await response.json();

          if (response.status === 200) {
            const totalRowCount = jsonData?.info?.count;

            let lastRow = -1;
            if (totalRowCount <= params.endRow) {
              lastRow = totalRowCount;
            }

            if ((!totalRowCount || totalRowCount.length === 0) && !searchText)
              setShowEmptyTableComponent(true);
            params.successCallback(jsonData?.results, lastRow);
          } else {
            params.successCallback(null, 0);
          }
        },
      };
      return dataSource;
    },
    [filterOptions, searchText],
  );

  const getRowId = useMemo(() => {
    return params => {
      return params.data.id;
    };
  }, []);

  const handleViewPOClientDetails = useCallback(
    event => {
      if (event.data?.id) {
        navigate(`view/${event.data?.id}`, {
          replace: false,
        });
      }
    },
    [navigate],
  );

  const datasource = useMemo(() => {
    return getDataSource();
  }, [getDataSource]);

  const handleSearched = e => {
    const text = e.target.value;

    debounce(() => setSearchText(text), 500, { trailing: true })();
  };

  const handleSearchCleared = () => {
    setSearchText('');
  };

  const defaultColDef = useMemo(
    () => ({
      resizable: false,
      editable: false,
      flex: 1,
    }),
    [],
  );

  const MediaPlanListTable = useMemo(
    () =>
      !showEmptyTableComponent ? (
        <Table
          ref={gridTableRef}
          columns={vendorColumns}
          datasource={datasource}
          defaultColDef={defaultColDef}
          height="calc(100vh - 312px)"
          rowSelection="single"
          rowModelType="infinite"
          getRowId={getRowId}
          maxBlocksInCache={100}
          rowBuffer={1}
          cacheBlockSize={PAGE_SIZE}
          cacheOverflowSize={1}
          maxConcurrentDatasourceRequests={1}
          infiniteInitialRowCount={10}
          suppressRowClickSelection={true}
          blockLoadDebounceMillis={500}
        />
      ) : null,
    [datasource, defaultColDef, getRowId, showEmptyTableComponent, vendorColumns],
  );

  const vendorInvoiceHeaderOptions = useMemo(() => {
    return columnData()?.reduce(
      (acc, x) =>
        x.headerName
          ? [
              ...acc,
              {
                label: x.headerName,
                value: x.id,
                checked: !x.hide,
              },
            ]
          : acc,
      [],
    );
  }, [columnData]);

  useEffect(() => {
    console.log('Applied Filter: ', filterState);
  }, [filterState]);

  const apiFetchFunction = async searchTerm => {
    return axios.get(`https://rickandmortyapi.com/api/character/?name=${searchTerm}`);
  };

  const sampleData = Array.from({ length: 10000 }, (_, i) => `Sample ${i + 1} Data`);

  const filterTypes = useMemo(() => {
    return [
      {
        title: 'Sample Filter',
        key: 'sample',
        isApiSearch: false,
        apiFetchFunction: null,
        options: sampleData,
      },
      {
        title: 'Product Name',
        key: 'productName',
        isApiSearch: true,
        apiFetchFunction: apiFetchFunction,
        formatResponse: data => {
          return data.results.map(x => x.name);
        },
        options: [],
      },
      {
        title: 'Invoice Year',
        key: 'year',
        isApiSearch: false,
        apiFetchFunction: null,
        options: [
          '2012',
          '2013',
          '2014',
          '2015',
          '2016',
          '2017',
          '2018',
          '2019',
          '2020',
          '2021',
          '2022',
          '2023',
          '2024',
        ],
      },
    ];
  }, []);

  return (
    <Card
      sx={{
        minHeight: 'calc(100vh - 130px)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Typography variant="Bold-28">VENDOR INVOICE & DOCUMENTS</Typography>
      </Box>
      {showEmptyTableComponent ? (
        <EmptyTable
          title="Invoice and Documents Not Found"
          description=""
          actionItems={[]}
          height="calc(100vh - 244px)"
        />
      ) : (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '20px',
            }}
          >
            <Box sx={{ width: '15rem', display: 'block' }}>
              {/* This field has been remove from UI for now we will enable this after BE api changes (8th July 2024) */}
              <Select
                id="child-col-filter"
                // options={childColumns?.map(x => x.headerName)}
                options={filterOptions?.map(x => x)}
                value={filterType}
                onChange={e => {
                  setFilterType(e.target.value);
                }}
                optionMapFunction={options => options?.map(x => ({ id: x, label: x, value: x }))}
                menuHeight="400px"
                selectHeight="40px"
              />
            </Box>

            <Box sx={{ width: '24.75rem', marginBottom: searchError ? '-23px' : '0px' }}>
              <Input
                id="media-plan-list-search"
                inputHeight="40px"
                type="text"
                placeholder={`Search by ${filterType?.toLocaleLowerCase()}...`}
                searchable
                onBlur={handleSearched}
                onKeyDown={event => {
                  if (event.key === 'Enter' || event.key === 'Tab') {
                    handleSearched(event);
                    event.target.blur();
                  }
                }}
                onChange={event => {
                  if (
                    /[!@#$%^&*(),.?":{}|<>]/.test(event.target.value) ||
                    /[+\-_=\/\*]/.test(event.target.value)
                  ) {
                    setSearchError('Special characters are not allowed in search');
                    return;
                  } else {
                    setSearchError('');
                  }
                }}
                onSearchCloseClick={handleSearchCleared}
                error={searchError}
              />
            </Box>

            <Box sx={{ width: '12rem', display: 'block', marginLeft: 'auto' }}>
              <Select
                id="child-col-filter"
                options={['All Brands', 'Demo 1', 'Demo 2']}
                value={brandType}
                onChange={e => {
                  setBrandType(e.target.value);
                }}
                optionMapFunction={options => options?.map(x => ({ id: x, label: x, value: x }))}
                menuHeight="400px"
                selectHeight="40px"
              />
            </Box>

            <Box sx={{ width: 'auto', marginLeft: '0px' }}>
              {/* <Button
                variant="outlined"
                label="Filters"
                onClick={() => {}}
                startIcon={<FilterJarIcon fill={theme.palette.primary.main} />}
                sx={{ marginTop: '-5px', marginRight: '15px' }}
              /> */}
              <FilterComponent
                filterState={filterState}
                setFilterState={data => {
                  setFilterState(data);
                }}
                filterData={filterTypes}
              />
              <Button
                aria-controls={fieldAnchorEl ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={fieldAnchorEl ? 'true' : undefined}
                variant="outlined"
                label="Field"
                onClick={handleOpenFieldsMenu}
                startIcon={<AddIcon fill={theme.palette.primary.main} />}
                sx={{ marginTop: '-5px' }}
              />
              <CheckboxMenu
                anchorEl={fieldAnchorEl}
                values={vendorInvoiceHeaderOptions}
                onMenuItemClick={handleMenuItemClick}
                onClose={handleCloseFieldsMenu}
                checkboxProps={{ checked: true }}
                searchPlaceholder="Select Column"
              />
            </Box>
          </Box>
          {MediaPlanListTable}
        </Box>
      )}
    </Card>
  );
};

export default VendorInvoiceList;
