import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import { FormHelperText } from '@mui/material';

const CustomCheckbox = styled(Checkbox)({
  '&.Mui-checked': {
    color: '#0a2756',
  },
  '&.Mui-disabled': {
    opacity: '0.5',
  },
});

const ReusableCheckbox = ({ label, checked, onChange, disabled, error }) => {
  return (
    <>
      <FormControlLabel
        className="checkbox-label"
        style={{ width: '100%' }}
        control={<CustomCheckbox checked={checked} onChange={onChange} disabled={disabled} />}
        label={label}
      />
      {!!error && <FormHelperText sx={{ color: 'red' }}>{error}</FormHelperText>}
    </>
  );
};

export default ReusableCheckbox;
