import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';
import { Box } from '@mui/material';
import TextFilter from './filters/TextFilter';
import ColumnHeader from './ColumnHeader';
import CellRenderer from './CellRenderer';
import { useErrorBoundary, withErrorBoundary } from 'react-use-error-boundary';

const Grid = styled(AgGridReact)`
  .ag-header-cell-label {
    align-items: center;
    justify-content: flex-start;
  }

  .ag-root-wrapper {
    border-radius: 0px;
    border-left: none;
    border-right: none;
    border-bottom: none;
    display: flex;
    justify-content: flex-start;
  }

  .ag-header {
    background-color: var(--background);
  }
  .ag-cell[col-id='id'] {
    background-color: var(--background);
  }
  .menu-utils > .ag-cell-wrapper {
    flex-direction: row-reverse;
  }



  .ag-cell {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: Poppins;
    font-weight: 500;
    font-size: 14px;
    padding: 0;

    &.menu-utils {
      padding-left: 8px;
    }

    &.checkbox {
      padding-left: 16px;
    }
  }

  .ag-cell-wrapper[col-id=''] {
    flex-direction: row-reverse;
  }

  .ag-header-cell[col-id='checkbox'] {
    justify-content: flex-end;
    flex-direction: row-reverse;

    .ag-checkbox {
      margin-right: 25px;
    }
  }

  .ag-cell,
  .ag-header-cell {
    border-left: 1px solid var(--text-500);
  }

  .ag-cell:first-child {
    border-left: none;
  }

  .ag-header-cell {
    padding: 0;
  }

  .ag-row .ag-cell:last-child,
  .ag-header-row .ag-header-cell:last-child {
    border-right: 1px solid var(--text-500);
  }

  .ag-body, .ag-header {
    border-left: 1px solid var(--text-500);
    border-right: 1px solid var(--text-500);
  }

  .ag-body {
    border-bottom: 1px solid var(--text-500);
    margin-bottom: 20px !important;
  }

  .ag-body-vertical-scroll-viewport,
  .ag-body-horizontal-scroll-viewport {
    background: var(--white);
  }

  .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track {
    border: 1px solid var(--text-500);
    background-color: transparent;
    border-radius: 8px;
  }
  .ag-body-horizontal-scroll-viewport::-webkit-scrollbar {
    margin: 0 3px;
    height: 12px;
    background-color: transparent;
  }

  .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 8px;
    background-color: var(--text-500);
  }

  .ag-body-horizontal-scroll::after {
    content: '';
    position: absolute;
    width: calc(100%);
    height: 24px;
    bottom: 12px;
    left: 0;
    background-color: var(--white);
    z-index: 5;
    border-top: 1px solid var(--text-500);
  }

  ,
  .ag-body-vertical-scroll {
    top: -50px;
    height: calc(100% + 50px);
  }

  .ag-checkbox-input-wrapper.ag-checked::after {
    color: var(--primary);
  }

  .ag-checkbox-input-wrapper.ag-disabled {
    background: var(--text-600);
    & .ag-checkbox-input {
      cursor: not-allowed !important;
    }
  }

  .ag-body-vertical-scroll-viewport::-webkit-scrollbar {
    border: 1px solid var(--text-500);
    background-color: transparent;
    border-radius: 8px;
    width: 12px;
  }

  .ag-body-vertical-scroll-viewport::-webkit-scrollbar-track {
    margin: 0 3px;
    height: 12px;
    background-color: transparent;
  }

  .ag-body-vertical-scroll-viewport::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 8px;
    background-color: var(--text-500);
  }

  .ag-floating-bottom {
    border-bottom: 1px solid var(--text-500);
    border-left: 1px solid var(--text-500);
    border-right: 1px solid var(--text-500);
  }

  .ag-body-vertical-scroll {
    top: -62px;
    height: calc(100% + 62px);
  }

  .ag-header-cell[col-id='id'] {
    padding-right: 0px;
  }
  .ag-header-cell[col-id='id'] .ag-header-cell-label {
    padding-left: 8px;
  }

  .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    border: none;
    border-left: 1px solid var(--text-500);
  }

  .ag-row .ag-cell:last-child.ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    border-right: 1px solid var(--text-500);
  }

  .ag-horizontal-left-spacer {
    display: none;
  }

  .ag-menu {
    background: var(--white);
    border-radius: 8px;
    // z-index: 99999999 !important;
  }

  .ag-filter-icon {
    display: none;
  }
  input[type='date'] {
    padding: 0 15px !important;
  }
  .ag-row-pinned {
    background-color: var(--text-600);

  .ag-disabled .ag-checkbox-input {
    cursor: not-allowed !important;
  }


  .task {
    display:'flex',
    justify-content: center;
    align-items: center;
  }
 
`;

const GridTable = (
  {
    isLoading = false,
    data = [],
    pinnedBottomRowData = [],
    datasource = null,
    onGridReady = () => null,
    columns = [],
    defaultColDef = {},
    rowSelection = 'single',
    onRowClick = () => null,
    onRowSelected = () => null,
    onCellValueChanged = () => null,
    rowDragEntireRow = false,
    height = null,
    rowModelType = 'clientSide',
    getRowId = null,
    maxBlocksInCache = null,
    rowBuffer = 10,
    cacheBlockSize = 20,
    cacheOverflowSize = 2,
    maxConcurrentDatasourceRequests = 2,
    infiniteInitialRowCount = 1,
    initialRowCount = 10,
    suppressRowClickSelection = true,
    blockLoadDebounceMillis = 500,
    isRowSelectable = () => true,
    onDragStopped = () => null,
  },
  ref,
) => {
  const [rowData, setRowData] = useState([]);

  const estimatedWidth = ref?.current?.api
    .getColumnState()
    ?.reduce((a, c) => (c.minWidth ? a + c.minWidth : a + 200), 0);

  const GridContainer = muiStyled(Box)(() => ({
    height: height
      ? estimatedWidth > (window.innerWidth ?? 0 - 190)
        ? pinnedBottomRowData?.length && pinnedBottomRowData.length > 0
          ? `calc(${height} + 20px)`
          : height
        : height
      : `calc((${Math.min(data?.length, 10) ?? 0} * 3.75rem) + 80px)`,
    '& .ag-body': {
      marginBottom:
        estimatedWidth > (window.innerWidth ?? 0 - 190)
          ? pinnedBottomRowData?.length && pinnedBottomRowData.length > 0
            ? '0px !important'
            : '20px !important'
          : '0px !important',
    },
    '& .ag-floating-bottom': {
      marginBottom: pinnedBottomRowData?.length && pinnedBottomRowData.length > 0 ? '20px' : '0px',
    },
  }));

  useEffect(() => {
    if (isLoading) setRowData(Array(initialRowCount).fill(undefined));
    else if (data) setRowData(data);
  }, [data, initialRowCount, isLoading]);

  const columnDefs = useMemo(() => {
    if (columns && columns.length > 0) {
      return columns.map(col => {
        const showClipboard = col.clipboard;
        delete col.clipboard;
        return {
          ...col,
          showDisabledCheckboxes: true,
          cellRenderer: CellRenderer,
          cellRendererParams: {
            col,
            showClipboard,
            isLoading,
          },
        };
      });
    }
    return [];
  }, [columns, isLoading]);

  const onSelectionChanged = useCallback(() => {
    const selectedRows = ref.current?.api.getSelectedRows();
    if (selectedRows && selectedRows.length > 0) onRowClick(selectedRows[0]);
  }, [onRowClick, ref]);

  const onCheckBoxClick = useCallback(
    e => {
      onRowSelected(e.api.getSelectedNodes());
    },
    [onRowSelected],
  );

  const components = useMemo(() => {
    return {
      agColumnHeader: ColumnHeader,
    };
  }, []);

  return (
    <GridContainer className="ag-theme-quartz">
      <Grid
        ref={ref}
        rowData={rowData}
        datasource={datasource}
        columnDefs={columnDefs}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        suppressDragLeaveHidesColumns={true}
        pinnedBottomRowData={pinnedBottomRowData}
        components={components}
        onCellValueChanged={onCellValueChanged}
        suppressMenuHide={true}
        rowSelection={rowSelection}
        onSelectionChanged={onSelectionChanged}
        onRowSelected={onCheckBoxClick}
        rowHeight={60}
        headerHeight={60}
        rowDragEntireRow={rowDragEntireRow}
        rowDragManaged={true}
        maintainColumnOrder={true}
        rowModelType={rowModelType}
        getRowId={getRowId ?? undefined}
        maxBlocksInCache={maxBlocksInCache ?? undefined}
        rowBuffer={rowBuffer}
        cacheBlockSize={rowModelType === 'clientSide' ? undefined : cacheBlockSize}
        cacheOverflowSize={cacheOverflowSize}
        maxConcurrentDatasourceRequests={maxConcurrentDatasourceRequests}
        infiniteInitialRowCount={infiniteInitialRowCount}
        suppressRowClickSelection={suppressRowClickSelection}
        blockLoadDebounceMillis={blockLoadDebounceMillis}
        frameworkComponents={{ customTextFilter: TextFilter }}
        isRowSelectable={isRowSelectable}
        reactiveCustomComponents
        checkboxSelection
        onDragStopped={onDragStopped}
      />
    </GridContainer>
  );
};

export default forwardRef(GridTable);
