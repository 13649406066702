/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Card from '../../../../../components/shared/card';
import { Box, Typography } from '@mui/material';
import Table from '../../../../../components/shared/table';
import Button from '../../../../../components/shared/buttons';
import Header from '../../../../../components/shared/header';
import Tabs from '../../../../../components/shared/tabs';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { DeleteIcon } from '../../../../../libs/svg-icons/icons';
import { useTheme } from '@mui/material/styles';
import { columns as columnData } from './ColumnData';
import ViewMediaPlanTableUtils from './ViewMediaPlanTableUtils';
import ViewMediaPlanCheckbox from './ViewMediaPlanCheckbox';
import Dialog from '../../../../../components/shared/dialog';
import HeaderActions from './HeaderActions';
import Comments from '../../../../../components/shared/comments';
import MediaPlanDetails from '../common/MediaPlanDetails';
import ApproveRejectAction from '../../../../../components/shared/approve-reject';
import {
  getMediaPlanMasterData,
  getMediaPlan,
  createMediaPlanRow,
  changeMediaPlanStatus,
  updateApprovalStatus,
  getCreateMediaPlanMasterData,
  saveNewVersionForMediaPlan,
  getMediaPlanVersionDetails,
  getNGRPReportData,
  getPlanBudgetData,
  createBuyingBriefGroup,
  cancelMediaPlanRow,
  validateMediaPlan,
  getMediaPlanValidations,
  cancelValidateMediaPlan,
} from '../../../../../services/media-plan-service';
import PageNotFound from '../../../../404';
import emptyMediaPlanDetailsData from '../../MediaPlanDetailsData.json';
import {
  flattenMasterDataHierarchy,
  getExternalIdList,
  getIdList,
  getMaterialCodeList,
  lookupValue,
} from '../../../../../utils/array';
import { AppConstants } from '../../../../../constants/app-constants';
import { PermissionsConstants } from '../../../../../constants/permissions-constants';
import { useHasAllPermission } from '../../../../../hooks/permissions';
import Loader from '../../../../../components/shared/loader';
import { useEnqueueSnackbar } from '../../../../../components/shared/toast-provider/toastHook';
import { capitalize } from '../../../../../utils/string';
import { useAppSelector } from '../../../../../redux/hooks';
import { isEmpty } from 'lodash';
import CheckboxCell from './CheckboxCell';
import { formatDate, getLastDateOfMonth, getMonthName } from '../../../../../libs/date/format';
import { useErrorBoundary, withErrorBoundary } from 'react-use-error-boundary';
import BackdropOverlay from '../../../../../components/shared/backdropOverlay';
import dummyMasterData from './master.json';

const MediaPlan = () => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();

  const hasAllPermissions = useHasAllPermission();

  const enqueueSnackbar = useEnqueueSnackbar();

  const gridTableRef = useRef(null);

  const [mediaPlanId, setMediaPlanId] = useState(null);
  const [mediaPlanDetails, setMediaPlanDetails] = useState(null);

  const [rowData, setRowData] = useState();
  const [masterData, setMasterData] = useState([]);
  const [mediaPlanValidationErrors, setMediaPlanValidationErrors] = useState(null);
  const [deletingRowId, setDeletingRowId] = useState(-1);
  const [cancelingRowId, setCancelingRowId] = useState(-1);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isMasterDataLoading, setIsMasterDataLoading] = useState(true);
  const [anyBBGCreated, setAnyBBGCreated] = useState(false);

  const [brandId, setBrandId] = useState(null);
  const [buId, setBuId] = useState(null);
  const [activityInputIds, setActivityInputIds] = useState({});
  const [durationIds, setDurationIds] = useState([]);
  const [durationTVIds, setDurationTVIds] = useState([]);

  const [error, resetError] = useErrorBoundary((error, errorInfo) => console.log(error, errorInfo));

  const nonEditableCells = useMemo(() => {
    const baseNonEditableCells = [
      'category',
      'brand',
      'campaign',
      'brandFinance',
      'campaign',
      'variantFinanceCode',
      'variantFinance',
      'Media',
      'expenditureType',
      'startDate',
      'endDate',
      'month',
      'quarter',
      'half',
      'ioNumber',
    ];

    if (mediaPlanDetails?.typeId === 4) return baseNonEditableCells;
    else {
      baseNonEditableCells.push('Format');
      baseNonEditableCells.push('Placement');
    }
    return baseNonEditableCells;
  }, [mediaPlanDetails]);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      flex: 1,
      cellStyle: params => {
        let isCellLocked = false;
        if (params.data?.mediaPlanRowsStatus === 'CANCELLED') {
          if (params?.colDef?.colId === 'checkbox') {
            isCellLocked = false;
          } else {
            isCellLocked = true;
          }
        } else if (!!params.data?.bbgCreated) {
          if (!nonEditableCells.includes(params?.colDef?.colId)) {
            isCellLocked = !!params.data.isLocked;
          } else if (params?.colDef?.colId === 'checkbox') {
            isCellLocked = false;
          } else {
            isCellLocked = true;
          }
        } else if (!!params.data?.externalId) {
          if (['startDate', 'endDate'].includes(params?.colDef?.colId)) {
            isCellLocked = true;
          }
          if (
            [AppConstants.MEDIA_PLAN_VALIDATION_IN_PROGRESS].includes(
              mediaPlanDetails?.validationStatus,
            )
          ) {
            isCellLocked = true;
          }
        } else {
          isCellLocked = false;
        }

        if (
          params?.data?.projectId &&
          !params?.data?.isLocked &&
          (params?.colDef?.colId === 'Type' || params?.colDef?.colId === 'Partner')
        ) {
          isCellLocked = true;
        }

        return {
          background: isCellLocked ? 'var(--text-600)' : 'transparent',
          cursor: isCellLocked ? 'not-allowed' : 'pointer',
        };
      },
    }),
    [mediaPlanDetails?.validationStatus, nonEditableCells],
  );

  useEffect(() => {
    resetError();
  }, [error, resetError]);

  const loggedInUserId = useAppSelector(state => state.auth.loggedInUserId);
  const userBusinessUnit = useAppSelector(state => state.auth.businessUnit);

  const backdropOpen = useMemo(() => {
    const { businessUnit = null } = mediaPlanDetails || {};
    const user_bu = userBusinessUnit.map(bu => bu.name);
    return !isLoading && !user_bu.includes(businessUnit);
  }, [mediaPlanDetails]);

  const isLoggedInUserManager = useMemo(
    () => mediaPlanDetails?.approvalManagerId === loggedInUserId,
    [mediaPlanDetails?.approvalManagerId, loggedInUserId],
  );

  const isMediaPlanEditable = useMemo(
    () =>
      mediaPlanDetails?.activeVersionId === mediaPlanDetails?.currentVersionId &&
      !isLoggedInUserManager &&
      hasAllPermissions([PermissionsConstants.UPDATE_MEDIA_PLAN]) &&
      [
        AppConstants.MEDIA_PLAN_VALIDATION_NOT_INITIATED,
        AppConstants.MEDIA_PLAN_VALIDATION_CANCELLED,
        AppConstants.MEDIA_PLAN_VALIDATION_NOT_VALIDATED,
        AppConstants.MEDIA_PLAN_VALIDATION_VALIDATED,
      ].includes(mediaPlanDetails?.validationStatus),
    [
      mediaPlanDetails?.activeVersionId,
      mediaPlanDetails?.currentVersionId,
      mediaPlanDetails?.validationStatus,
      isLoggedInUserManager,
    ],
  );

  const handleUpdateMediaPlanDetails = props => {
    setMediaPlanDetails(prev => ({
      ...prev,
      ...props,
    }));
  };

  const handleSetMediaPlanData = (
    mediaPlanData,
    activeVersionId,
    currentVersionId,
    loadTable = false,
  ) => {
    if (mediaPlanData) {
      setMediaPlanId(mediaPlanData.id);
      setMediaPlanDetails({
        id: mediaPlanData.id,
        typeId: mediaPlanData.mediaPlanType.id,
        name: mediaPlanData?.name,
        brand: mediaPlanData.brand,
        year: mediaPlanData.year,
        businessUnit: mediaPlanData.businessUnit,
        planner: mediaPlanData.planner,
        category: mediaPlanData.category,
        startDate: mediaPlanData.startDate,
        endDate: mediaPlanData.endDate,
        totalBudget: mediaPlanData.totalBudget,
        calculatedBudget: mediaPlanData.givenBudget,
        status: mediaPlanData.activeVersion?.status,
        approvalId: mediaPlanData.activeVersion?.approvalStatus?.id,
        activeVersionId: activeVersionId,
        currentVersionId: currentVersionId,
        approvalStatus:
          mediaPlanData.activeVersion?.approvalStatus?.requestStatus ??
          AppConstants.MEDIA_PLAN_STATUS_NOT_INITIATED,
        approvalManagerId: mediaPlanData.activeVersion?.approvalStatus?.managerId,
        activeVersionNumber: mediaPlanData.activeVersion?.versionNumber,
        validationStatus: mediaPlanData.activeVersion?.validationStatus,
        isBbgCreatedOnMinder: mediaPlanData.activeVersion?.isBbgCreatedOnMinder,
        isEditing: false,
      });
      if (loadTable) {
        if (
          mediaPlanData.activeVersion?.mediaPlanRows &&
          mediaPlanData.activeVersion?.mediaPlanRows.length > 0
        ) {
          setRowData(
            mediaPlanData.activeVersion?.mediaPlanRows.map((x, i) => ({
              ...emptyMediaPlanDetailsData[0],
              ...x,
              id: i,
              rowId: x.id,
              materialCode: { materialCode: x.materialCode, id: x.materialCode },
            })),
          );
        } else {
          setRowData(
            emptyMediaPlanDetailsData.map(x => ({
              ...x,
              id: 0,
              brand: mediaPlanData.brand,
              category: mediaPlanData.category,
            })),
          );
        }
      }
    }
  };

  useEffect(() => {
    if (hasAllPermissions([PermissionsConstants.READ_MEDIA_PLAN])) fetchData(true);
  }, []);

  const fetchData = async (loadTable = false) => {
    setIsLoading(true);
    const id = params.id;
    await getMediaPlan(id)
      .then(async response => {
        const { data: mediaPlanData } = response.data;
        const mediaPlanBrandId = mediaPlanData?.brand?.id || null;
        const activeVersionId = mediaPlanData.activeVersion.id;
        const currentVersionId = mediaPlanData.activeVersion.id;
        const businessUnit = mediaPlanData.businessUnit || '';
        handleSetMediaPlanData(mediaPlanData, activeVersionId, currentVersionId, loadTable);

        const [{ id: businessId = '' } = {}] = userBusinessUnit.filter(
          x => x.name === businessUnit,
        );

        setBrandId(mediaPlanBrandId);
        setBuId(businessId);

        return {
          validationStatus:
            mediaPlanData?.activeVersion?.validationStatus ??
            AppConstants.MEDIA_PLAN_STATUS_NOT_INITIATED,
          mediaPlanVersionId: currentVersionId,
          mediaPlanId: mediaPlanData.id,
        };
      })
      .then(params => {
        if (![AppConstants.MEDIA_PLAN_STATUS_NOT_INITIATED].includes(params.validationStatus)) {
          delete params['validationStatus'];
          getMediaPlanValidations(params)
            .then(response => {
              const { data: mediaPlanValidationDetails } = response?.data ?? {};
              if (mediaPlanValidationDetails?.mediaPlanValidationErrors) {
                const errorDetails = mediaPlanValidationDetails?.mediaPlanValidationErrors;
                setMediaPlanValidationErrors(
                  errorDetails.sort((a, b) => a.externalId.localeCompare(b.externalId)),
                );
              }
            })
            .catch(error => {
              setMediaPlanValidationErrors([]);
            });
        }
      })
      .catch(error => {
        const { message = AppConstants.SOMETHING_WENT_WRONG } = error;
        enqueueSnackbar(message, 'error');
        setMediaPlanId(0);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (brandId && buId) {
      setIsMasterDataLoading(true);
      setMasterData([]);
      getMediaPlanMasterData(brandId)
        .then(response => {
          const masterDataJSON = response.data;
          // const masterDataJSON = dummyMasterData;
          // console.log(masterDataJSON, response.data);
          if (masterDataJSON?.data && masterDataJSON?.data[0]?.child)
            setMasterData(prev => {
              const newMasterData = prev
                ? [...prev, ...flattenMasterDataHierarchy(masterDataJSON.data[0].child)]
                : flattenMasterDataHierarchy(masterDataJSON.data[0].child);
              return newMasterData;
            });
        })
        .then(() => {
          getCreateMediaPlanMasterData(buId)
            .then(response => {
              const masterDataJSON = response.data;
              if (masterDataJSON?.data?.child) {
                setMasterData(prev => {
                  const newMasterData = prev
                    ? [...prev, ...flattenMasterDataHierarchy(masterDataJSON.data.child)]
                    : flattenMasterDataHierarchy(masterDataJSON.data.child);
                  return newMasterData;
                });
              }
              if (mediaPlanDetails) {
                getMediaPlanValidations({
                  mediaPlanVersionId: mediaPlanDetails?.activeVersionId,
                  mediaPlanId: mediaPlanDetails?.id,
                })
                  .then(response => {
                    const { data: mediaPlanValidationDetails } = response?.data ?? {};
                    if (mediaPlanValidationDetails?.mediaPlanValidationErrors) {
                      const errorDetails = mediaPlanValidationDetails?.mediaPlanValidationErrors;
                      setMediaPlanValidationErrors(
                        errorDetails.sort((a, b) => a.externalId.localeCompare(b.externalId)),
                      );
                    }
                  })
                  .catch(error => {
                    setMediaPlanValidationErrors(null);
                  });
              }
            })
            .catch(error => {
              const { message = AppConstants.SOMETHING_WENT_WRONG } = error;
              enqueueSnackbar(message, 'error');
            });
        })
        .catch(error => {
          const { message = AppConstants.SOMETHING_WENT_WRONG } = error;
          enqueueSnackbar(message, 'error');
        })
        .finally(() => setIsMasterDataLoading(false));
    }
  }, [brandId, buId]);

  useEffect(() => {
    let acId = {};
    let durationArr = [];
    let durationTV = [];

    masterData.forEach(x => {
      if (x.type === 'Duration') {
        durationArr.push(x.id);
        if (x.name === '15s' || x.name === '30s') {
          durationTV.push(x.id);
        }
      } else if (x.type === 'Activity Input') {
        if (x.name === 'MB INTERNET') {
          acId['MB_INTERNET_ID'] = x.id;
        } else if (x.name === 'MB SEARCH') {
          acId['MB_SEARCH_ID'] = x.id;
        }
      }
    });

    setActivityInputIds(acId);
    setDurationIds(durationArr);
    setDurationTVIds(durationTV);
  }, [masterData]);

  const getRowId = useMemo(() => {
    return params => {
      return params?.data?.id;
    };
  }, []);

  const handleMediaPlanStatusChanged = useCallback(
    (status, callBackFunction = () => {}) => {
      if (status === 'FINALIZED' || status === 'DRAFT') {
        setIsLoading(true);
        changeMediaPlanStatus({
          mediaPlanId: mediaPlanDetails?.id,
          status: status,
        })
          .then(response => {
            if (response.status === 200 && response.data?.data?.activeVersion?.status) {
              setMediaPlanDetails(prev => ({
                ...prev,
                status: response.data?.data?.activeVersion?.status,
              }));
              enqueueSnackbar(
                `Media plan status changed to ${response.data?.data?.activeVersion?.status} Successfully`,
              );
              fetchData();
            } else {
              const { message = AppConstants.SOMETHING_WENT_WRONG } = response.data;
              enqueueSnackbar(message, 'error');
            }
          })
          .catch(error => {
            const { message = AppConstants.SOMETHING_WENT_WRONG } = error;
            callBackFunction();
            enqueueSnackbar(message, 'error');
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    [
      mediaPlanDetails?.id,
      mediaPlanDetails?.currentVersionId,
      enqueueSnackbar,
      setIsLoading,
      setMediaPlanDetails,
    ],
  );

  const handleMediaPlanApprovalStatusChanged = useCallback(
    (approvalStatus, managerId) => {
      setIsLoading(true);
      updateApprovalStatus({
        mediaPlanId: mediaPlanDetails?.id,
        managerId,
        approvalId:
          mediaPlanDetails?.approvalStatus ===
          AppConstants.MEDIA_PLAN_STATUS_MANAGER_APPROVAL_PENDING
            ? mediaPlanDetails?.approvalId
            : undefined,
        status: approvalStatus,
      })
        .then(response => {
          if (response.status === 200) {
            setMediaPlanDetails(prev => ({
              ...prev,
              approvalStatus: response.data?.data?.activeVersion?.managerApprovalstatus,
              approvalManagerId: response.data?.data?.activeVersion?.approvalStatus?.managerId,
              approvalId: response.data?.data?.activeVersion?.approvalStatus?.id,
            }));

            if (approvalStatus === AppConstants.MEDIA_PLAN_STATUS_MANAGER_APPROVAL_PENDING) {
              enqueueSnackbar(`Media plan sent to manager for approval`);
            } else {
              enqueueSnackbar(
                `Media plan ${approvalStatus === 'MANAGER_APPROVED' ? 'approved' : 'rejected'} by manager`,
              );
            }
            fetchData();
          } else {
            const { message = AppConstants.SOMETHING_WENT_WRONG } = response.data;
            console.log(response);
            enqueueSnackbar(message, 'error');
          }
        })
        .catch(error => {
          const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data;
          console.log(error);

          enqueueSnackbar(message, 'error');
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [setMediaPlanDetails, mediaPlanDetails, enqueueSnackbar, setIsLoading, updateApprovalStatus],
  );

  const handleMenuItemClick = useCallback(
    (column, checked) => {
      if (gridTableRef.current && gridTableRef.current.api) {
        gridTableRef.current.api.applyColumnState({ state: [{ colId: column, hide: !checked }] });
      }
    },
    [gridTableRef],
  );

  const handleDeleteConfirmationOpen = id => {
    setDeletingRowId(id);
  };

  const handleCancelConfirmationOpen = id => {
    setCancelingRowId(id);
  };

  const handleDeleteConfirmationClose = () => {
    setDeletingRowId(-1);
  };
  const handleCancelConfirmationClose = () => {
    setCancelingRowId(-1);
  };

  const handleCheckboxChange = useCallback(selectedRows => {
    setSelectedCheckboxes(selectedRows.map(row => row.id));
    const hasBBGCreated = selectedRows.reduce((acc, row) => acc || row.data.bbgCreated, false);
    setAnyBBGCreated(hasBBGCreated);
  }, []);

  const addRow = async () => {
    if (hasAllPermissions([PermissionsConstants.UPDATE_MEDIA_PLAN])) {
      let totalRows = 0;

      gridTableRef.current?.api.forEachNode(x => {
        totalRows = totalRows + 1;
      });

      let newData;

      if (totalRows) {
        newData = {
          ...emptyMediaPlanDetailsData[0],
          brand: mediaPlanDetails?.brand,
          category: mediaPlanDetails?.category,
          id: totalRows,
          externalId: '',
          bbgCreated: false,
          isLocked: false,
          mediaPlanRowsStatus: null,
        };

        const transactions = await gridTableRef.current.api.applyTransaction({
          add: [newData],
          addIndex: -1,
        });

        setMediaPlanDetails(prev => ({
          ...prev,
          isEditing: true,
        }));

        gridTableRef.current.api.ensureNodeVisible(
          gridTableRef.current.api.getRowNode(transactions?.add[0]?.id),
          'top',
        );
      }
    }
  };

  const duplicateRow = async rowId => {
    if (hasAllPermissions([PermissionsConstants.UPDATE_MEDIA_PLAN])) {
      let rowDetails;
      let totalRows = 0;
      gridTableRef.current?.api.forEachNode(x => {
        if (x.data.id === rowId) rowDetails = x;
        totalRows = totalRows + 1;
      });

      const currentRowData = rowDetails.data;

      const newData = {
        ...currentRowData,
        rowId: null,
        bbNumberMinder: '',
        id: totalRows,
        externalId: '',
        bbgCreated: false,
        isLocked: false,
        mediaPlanRowsStatus: null,
      };

      const transactions = await gridTableRef.current.api.applyTransaction({
        add: [newData],
        addIndex: -1,
      });

      setMediaPlanDetails(prev => ({
        ...prev,
        isEditing: true,
        calculatedBudget: rowDetails.data.planBudget
          ? prev.calculatedBudget + rowDetails.data.planBudget
          : prev.calculatedBudget,
      }));

      gridTableRef.current.api.ensureNodeVisible(
        gridTableRef.current.api.getRowNode(transactions?.add[0]?.id),
        'top',
      );
    }
  };

  const deleteRow = useCallback(() => {
    if (hasAllPermissions([PermissionsConstants.UPDATE_MEDIA_PLAN])) {
      if (deletingRowId > -1) {
        const selectedNode = gridTableRef.current?.api?.getRowNode(deletingRowId);
        if (!selectedNode) {
          return;
        }

        gridTableRef.current.api.applyTransactionAsync({ remove: [{ id: selectedNode.id }] });
        setDeletingRowId(-1);

        setMediaPlanDetails(prev => ({
          ...prev,
          isEditing: true,
          totalBudget: selectedNode.data.planBudget
            ? prev.totalBudget - selectedNode.data.planBudget
            : prev.totalBudget,
        }));
      }
    }
  }, [deletingRowId]);

  const deleteMultipleRows = useCallback(
    rowIds => {
      let calculateTotalBudgetToRemove = 0;
      const selectedNodeIds = [];
      if (hasAllPermissions([PermissionsConstants.UPDATE_MEDIA_PLAN])) {
        rowIds.forEach(rowId => {
          const selectedNode = gridTableRef.current?.api?.getRowNode(rowId);

          if (!selectedNode) {
            return;
          }
          calculateTotalBudgetToRemove += selectedNode.data.planBudget ?? 0;
          selectedNodeIds.push({ id: selectedNode.id });
        });

        if (selectedNodeIds && selectedNodeIds.length > 0) {
          gridTableRef.current.api.applyTransactionAsync({ remove: selectedNodeIds }, res => {});
          setMediaPlanDetails(prev => ({
            ...prev,
            isEditing: true,
            totalBudget: calculateTotalBudgetToRemove
              ? prev.totalBudget - calculateTotalBudgetToRemove
              : prev.totalBudget,
          }));
        }
      } else {
      }
    },
    [gridTableRef, hasAllPermissions, setMediaPlanDetails],
  );

  const cancelRow = useCallback(() => {
    if (hasAllPermissions([PermissionsConstants.UPDATE_MEDIA_PLAN])) {
      if (cancelingRowId > -1) {
        const selectedNode = gridTableRef.current?.api?.getRowNode(cancelingRowId);
        if (!selectedNode) {
          enqueueSnackbar(AppConstants.SOMETHING_WENT_WRONG, 'error');
          return;
        }

        const mediaPlanRowId = selectedNode?.data?.rowId;

        if (mediaPlanRowId) {
          setIsLoading(true);
          cancelMediaPlanRow({
            mediaPlanRowId,
          })
            .then(() => {
              fetchData();
              enqueueSnackbar('Media plan row cancelled successfully!', 'success');
            })
            .catch(error => {
              const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data ?? {};
              enqueueSnackbar(message, 'error');
            })
            .finally(() => setIsLoading(false));

          setCancelingRowId(-1);

          setMediaPlanDetails(prev => ({
            ...prev,
            isEditing: true,
          }));
        }
      }
    }
  }, [cancelingRowId]);

  const onMenuClick = useCallback((option, rowId) => {
    switch (option) {
      case 'Add New Row':
        addRow();
        break;
      case 'Duplicate Row':
        duplicateRow(rowId);
        break;
      case 'Delete Row':
        handleDeleteConfirmationOpen(rowId);
        break;
      case 'Cancel Row':
        handleCancelConfirmationOpen(rowId);
        break;
      default:
        break;
    }
  });

  const columns = useMemo(() => columnData(onMenuClick), []);

  const mediaPlanHeaderOptions = useMemo(() => {
    return columns?.reduce(
      (acc, x) =>
        x.headerName
          ? [
              ...acc,
              {
                label: x?.headerName,
                value: x?.colId,
                checked: !x.hide,
              },
            ]
          : acc,
      [],
    );
  }, [columns]);

  const calculateNGRPReport = useCallback(
    params => {
      let reportTA = 'WHH';
      if (['B&W', 'PC'].includes(mediaPlanDetails.businessUnit)) reportTA = 'F 15+';
      return getNGRPReportData({
        year: mediaPlanDetails?.endDate?.split('-')[0],
        clusterTA: encodeURIComponent(
          lookupValue(masterData, 'id', params.data.clusterTA.id)?.name,
        ),
        reportTA: encodeURIComponent(reportTA),
        ngrp: params.data.ngrp,
      })
        .then(response => {
          const { data } = response.data;
          return parseFloat(data.result).toFixed(2);
        })
        .catch(error => {
          const { message = 'NGRP Report Not Found!' } = error?.response?.data || {};
          enqueueSnackbar(message, 'error');
        });
    },
    [mediaPlanDetails, masterData],
  );

  const calculatePlanBudget = useCallback(
    params => {
      return getPlanBudgetData({
        year: mediaPlanDetails?.endDate?.split('-')[0],
        clusterTA: encodeURIComponent(
          lookupValue(masterData, 'id', params.data.clusterTA.id)?.name,
        ),
        month: encodeURIComponent(
          lookupValue(masterData, 'id', params.data.month.id)?.name.slice(0, 3).toUpperCase(),
        ),
        duration: encodeURIComponent(lookupValue(masterData, 'id', params.data.duration.id)?.name),
        grp: params.data.grp,
      })
        .then(response => {
          const { data } = response.data;
          return parseFloat(data.result).toFixed(2);
        })
        .catch(error => {
          const { message = 'Plan Budget Not Found!' } = error?.response;
          enqueueSnackbar(message, 'error');
        });
    },
    [mediaPlanDetails, masterData],
  );

  const calculateACD = params => {
    if ((params.data.grp || params.data.grp === 0) && params.data.duration.id) {
      let sumProdDurAndGRP = 0;
      let sumGRP = 0;
      const data = [];
      params.api.forEachNode(node => {
        if (!!node.data?.duration?.id && !!node.data.grp) {
          const duration = lookupValue(masterData, 'id', node.data.duration.id);
          if (duration && lookupValue(masterData, 'id', node.data.mediaType.id)?.name === 'TV') {
            const durationValue = duration?.name.split('s')[0];
            if (parseInt(durationValue)) {
              sumProdDurAndGRP =
                sumProdDurAndGRP + parseInt(durationValue) * parseFloat(node.data.grp);
              sumGRP = sumGRP + (!!node.data.grp ? parseFloat(node.data.grp) : 0);
            }
          }
        }
      });
      let acd = 0;
      if (sumGRP > 0) acd = sumProdDurAndGRP / sumGRP;
      params.api.forEachNode(node => {
        data.push({
          ...node.data,
          acd:
            lookupValue(masterData, 'id', node.data.mediaType.id)?.name === 'TV' &&
            node.data.duration.id &&
            node.data.grp
              ? !!acd
                ? parseFloat(acd).toFixed(2)
                : null
              : null,
        });
      });

      params?.api?.setGridOption('rowData', data);
    }
  };

  const checkIfCellEditable = params => {
    if (params.data?.mediaPlanRowsStatus === 'CANCELLED') {
      if (params?.colDef?.colId === 'checkbox') {
        return true;
      } else {
        return false;
      }
    } else if (!!params.data?.bbgCreated) {
      if (!nonEditableCells.includes(params?.colDef?.colId)) {
        if (
          params?.data?.projectId &&
          (params?.colDef?.colId === 'Type' || params?.colDef?.colId === 'Partner')
        ) {
          return false;
        } else {
          return !params.data.isLocked;
        }
      } else {
        return false;
      }
    } else {
      if (['month'].includes(params?.colDef?.colId)) {
        return !params.data.externalId;
      }
      return true;
    }
  };

  const handleGridReady = e => {
    const existingColumnDefs = e.api.getGridOption('columnDefs');
    e?.api.ensureNodeVisible(-1);
    let updatedColumnDefs = [];
    if (mediaPlanDetails)
      updatedColumnDefs = existingColumnDefs.map(col => {
        const fieldId = col.colId;
        switch (fieldId) {
          case 'status':
            return {
              ...col,
              filterParams: {
                values: ['DRAFT', 'LIVE', 'PARTIAL_ACTUAL', 'FULLY_ACTUAL', 'CANCELLED'],
                valueFormatter: value => capitalize(value),
              },
            };
          case 'category':
            return {
              ...col,
              valueFormatter: () => mediaPlanDetails?.category?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'category')),
                valueFormatter: value =>
                  lookupValue(
                    masterData?.filter(x => x.type === 'category'),
                    'id',
                    value,
                  )?.name,
              },
            };
          case 'brand':
            return {
              ...col,
              valueFormatter: () => mediaPlanDetails?.brand?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'brand')),
                valueFormatter: value =>
                  lookupValue(
                    masterData?.filter(x => x.type === 'brand'),
                    'id',
                    value,
                  )?.name,
              },
            };
          case 'brandFinance':
            return {
              ...col,
              editable: checkIfCellEditable,
              cellEditor: 'agSelectCellEditor',
              valueListMaxHeight: 60,
              cellEditorParams: {
                values: getIdList(
                  masterData?.filter(
                    x => x.type === 'brandFinance' && x.parentId === mediaPlanDetails?.brand?.id,
                  ),
                ),
              },
              valueFormatter: params =>
                lookupValue(
                  masterData.filter(x => x.type === 'brandFinance'),
                  'id',
                  params.value,
                )?.name,
              filterParams: {
                values: getIdList(
                  masterData?.filter(
                    x => x.type === 'brandFinance' && x.parentId === mediaPlanDetails?.brand?.id,
                  ),
                ),
                valueFormatter: value =>
                  lookupValue(
                    masterData.filter(x => x.type === 'brandFinance'),
                    'id',
                    value,
                  )?.name,
              },
            };
          case 'campaign':
            return {
              ...col,
              editable: checkIfCellEditable,
              cellEditor: 'agSelectCellEditor',
              valueListMaxHeight: 60,
              cellEditorParams: {
                values: getIdList(
                  masterData?.filter(
                    x => x.type === 'campaign' && x.parentId === mediaPlanDetails?.brand?.id,
                  ),
                ),
              },
              valueFormatter: params =>
                lookupValue(
                  masterData.filter(x => x.type === 'campaign'),
                  'id',
                  params.value,
                )?.name,
              valueSetter: params => {
                const t = masterData?.filter(
                  x => x.type === 'campaign' && x.id === params.newValue,
                );
                params.data.campaign = {
                  externalId: t[0]?.externalId,
                  id: params?.newValue,
                };

                const varietnCodes = masterData?.filter(x => x.type === 'Variant(Finance)Name');
                if (varietnCodes.length === 0) {
                  enqueueSnackbar(
                    'There is no variant Finance Codes for this brand mapping. Kindly contact the Minder team',
                    'error',
                  );
                }

                return true;
              },
              filterParams: {
                values: getIdList(
                  masterData?.filter(
                    x => x.type === 'campaign' && x.parentId === mediaPlanDetails?.brand?.id,
                  ),
                ),
                valueFormatter: value =>
                  lookupValue(
                    masterData.filter(x => x.type === 'campaign'),
                    'id',
                    value,
                  )?.name,
              },
            };
          case 'variantFinanceCode':
            return {
              ...col,
              editable: checkIfCellEditable,
              cellEditor: 'agSelectCellEditor',
              valueListMaxHeight: 60,
              cellEditorParams: {
                values: getExternalIdList(
                  masterData?.filter(x => x.type === 'Variant(Finance)Name'),
                ),
              },
              valueSetter: params => {
                params.data.variantFinanceName = {
                  externalId: params.newValue,
                  id: lookupValue(
                    masterData.filter(x => x.type === 'Variant(Finance)Name'),
                    'externalId',
                    params.newValue,
                  )?.id,
                };
                const material = lookupValue(
                  masterData?.filter(x => x.type === 'Material Name'),
                  'parentId',
                  params.data.variantFinanceName.id,
                );
                if (material) {
                  params.data.materialName = {
                    externalId: material.externalId,
                    id: material.id,
                  };

                  const [materialCode = null] = masterData
                    ?.filter(x => x.type === 'Material Code')
                    .filter(z => z.parentId === material.id);
                  if (materialCode) {
                    params.data.materialCode = {
                      materialCode: materialCode.name,
                      id: materialCode.name,
                    };
                  }
                } else {
                  enqueueSnackbar(
                    'There is no material for this brand-variant finance mapping. Kindly contact the Minder team',
                    'error',
                  );
                }

                // TODO: remove comment after [MAR-1067] is done
                // params.data.minderTA = {
                //   id: lookupValue(
                //     masterData?.filter(x => x.type === 'targetID'),
                //     'parentId',
                //     params.data.variantFinanceName.id,
                //   )?.id,
                // };
                return true;
              },
              filterParams: {
                values: getExternalIdList(
                  masterData?.filter(x => x.type === 'Variant(Finance)Name'),
                ),
              },
            };
          case 'variantFinance':
            return {
              ...col,
              cellEditor: 'agSelectCellEditor',
              valueListMaxHeight: 60,
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'Variant(Finance)Name')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'materialName':
            return {
              ...col,
              editable: checkIfCellEditable,
              cellEditor: 'agSelectCellEditor',
              valueListMaxHeight: 60,
              cellEditorParams: params => {
                return {
                  values: getIdList(
                    masterData?.filter(
                      x =>
                        x.parentId === params.data.variantFinanceName.id &&
                        x.type === 'Material Name',
                    ),
                  ),
                };
              },
              valueSetter: params => {
                params.data.materialName = {
                  externalId: lookupValue(
                    masterData.filter(x => x.type === 'Material Name'),
                    'id',
                    params.newValue,
                  )?.externalId,
                  id: params?.newValue,
                };

                const [materialCode = null] = masterData
                  ?.filter(x => x.type === 'Material Code')
                  .filter(z => z.parentId === params?.newValue);
                if (materialCode) {
                  params.data.materialCode = {
                    materialCode: materialCode.name,
                    id: materialCode.name,
                  };
                }
                return true;
              },
              valueFormatter: params =>
                lookupValue(
                  masterData?.filter(x => x.type === 'Material Name'),
                  'id',
                  params.value,
                )?.name,
            };

          case 'materialCode':
            return {
              ...col,
              editable: checkIfCellEditable,
              cellEditor: 'agSelectCellEditor',
              valueListMaxHeight: 60,
              cellEditorParams: params => {
                const temp = masterData?.filter(
                  x => x.parentId === params.data.materialName.id && x.type === 'Material Code',
                );
                return {
                  values: getMaterialCodeList(temp),
                };
              },
              valueSetter: params => {
                params.data.materialCode = {
                  materialCode: lookupValue(
                    masterData.filter(x => x.type === 'Material Code'),
                    'name',
                    params.newValue,
                  )?.id,
                  id: params?.newValue,
                };
                return true;
              },
              valueFormatter: params =>
                lookupValue(
                  masterData?.filter(x => x.type === 'Material Code'),
                  'id',
                  params.value,
                )?.name,
            };
          case 'minderTA':
            return {
              ...col,
              editable: checkIfCellEditable,
              cellEditor: 'agSelectCellEditor',
              valueListMaxHeight: 60,
              cellEditorParams: {
                values: getIdList(
                  masterData?.filter(
                    x =>
                      x.parentId === AppConstants.MASTER_DATA_CONSTANT_TARGET_ID &&
                      x.type === 'targetID',
                  ),
                ),
              },
              valueSetter: params => {
                const t = masterData?.filter(
                  x => x.type === 'targetID' && x.id === params.newValue,
                );
                params.data.minderTA = {
                  externalId: t[0]?.externalId,
                  id: params?.newValue,
                };
                // console.log(params.newValue, t);
                return true;
              },
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'targetID')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'clusterTA':
            return {
              ...col,
              editable: checkIfCellEditable,
              cellEditor: 'agSelectCellEditor',
              valueListMaxHeight: 60,
              cellEditorParams: params => {
                return {
                  values: getIdList(masterData?.filter(x => x.type === 'Cluster TA')),
                };
              },
              valueSetter: async params => {
                params.data.clusterTA = {
                  id: params.newValue,
                };

                if ((params.data.grp || params.data.grp === 0) && params.data?.duration?.id) {
                  if (params.data.grp === 0) {
                    params.data.planBudget = null;
                  } else {
                    if (
                      params.data?.month?.id &&
                      lookupValue(masterData, 'id', params.data.mediaType.id)?.name === 'TV'
                    ) {
                      const planBudget = await calculatePlanBudget(params);
                      params.data.planBudget = planBudget;
                    }
                  }
                }
                params.api?.refreshCells();
                return true;
              },
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'Cluster TA')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'Media':
            return {
              ...col,
              editable: checkIfCellEditable,
              cellEditor: 'agSelectCellEditor',
              valueListMaxHeight: 60,
              cellEditorParams: {
                values: getIdList(masterData?.filter(x => x.type === 'media')),
              },
              valueSetter: async params => {
                params.data.mediaType = {
                  id: params.newValue,
                };
                params.data.format = {
                  id: lookupValue(
                    masterData.filter(x => x.type === 'format'),
                    'parentId',
                    params.data.mediaType.id,
                  )?.id,
                };
                params.data.expenditureType = {
                  id: lookupValue(
                    masterData.filter(x => x.type === 'Expenditure Type'),
                    'parentId',
                    params.data.mediaType.id,
                  )?.id,
                };
                const activityInputMatches = masterData
                  .filter(x => x.type === 'Activity Input')
                  ?.filter(x => x['parentId'] === params.data.expenditureType.id);
                if (activityInputMatches.length > 1) {
                  console.log(activityInputIds);
                  if (mediaPlanDetails?.typeId === 3) {
                    params.data.activityInput = {
                      id: activityInputIds?.MB_SEARCH_ID, //|| AppConstants.MASTER_DATA_MB_SEARCH_ID,
                    };
                  } else {
                    params.data.activityInput = {
                      id: activityInputIds?.MB_INTERNET_ID, //||AppConstants.MASTER_DATA_MB_INTERNET_ID,
                    };
                  }
                } else {
                  params.data.activityInput = {
                    id: lookupValue(
                      masterData.filter(x => x.type === 'Activity Input'),
                      'parentId',
                      params.data.expenditureType.id,
                    )?.id,
                  };
                }
                params.data.placement = {
                  id: lookupValue(masterData, 'parentId', params.data.format.id)?.id,
                };
                params.data.type = {
                  id: lookupValue(masterData, 'parentId', params.data.placement.id)?.id,
                };
                params.data.partner = {
                  id: lookupValue(masterData, 'parentId', params.data.type.id)?.id,
                };
                // TODO: remove this once [MAR-1060] is resolved.
                // if ((params.data.grp || params.data.grp === 0) && params.data?.duration?.id) {
                //   if (params.data.grp === 0) {
                //     params.data.ngrpReport = null;
                //     params.data.planBudget = null;
                //   } else {
                //     const ngrpReport = await calculateNGRPReport(params);
                //     params.data.ngrpReport = ngrpReport;
                //     if (
                //       params.data?.month?.id &&
                //       lookupValue(masterData, 'id', params.data.mediaType.id)?.name === 'TV'
                //     ) {
                //       const planBudget = await calculatePlanBudget(params);
                //       params.data.planBudget = planBudget;
                //     }
                //   }
                // }
                if (lookupValue(masterData, 'id', params.data.mediaType.id)?.name !== 'TV') {
                  params.data.tvReach = null;
                  params.data.duration = { id: null };
                  params.data.grp = null;
                  params.data.ngrp = null;
                  params.data.ngrpReport = null;
                  params.data.planBudget = null;
                  params.data.acd = null;
                } else {
                  params.data.duration = { id: null };
                  params.data.ngrp = null;
                  params.data.ngrpReport = null;
                  params.data.planBudget = null;
                  params.data.acd = null;
                }
                return true;
              },
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'media')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'Format':
            return {
              ...col,
              editable: checkIfCellEditable,
              cellEditor: 'agSelectCellEditor',
              valueListMaxHeight: 60,
              cellEditorParams: params => {
                return {
                  values: getIdList(
                    masterData?.filter(
                      x => x.parentId === params.data.mediaType.id && x.type === 'format',
                    ),
                  ),
                };
              },
              valueSetter: params => {
                params.data.format = {
                  id: params.newValue,
                };
                params.data.placement = {
                  id: lookupValue(masterData, 'parentId', params.data.format.id)?.id,
                };
                params.data.type = {
                  id: lookupValue(masterData, 'parentId', params.data.placement.id)?.id,
                };
                params.data.partner = {
                  id: lookupValue(masterData, 'parentId', params.data.type.id)?.id,
                };
                return true;
              },
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'format')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'expenditureType':
            return {
              ...col,
              editable: checkIfCellEditable,
              cellEditor: 'agSelectCellEditor',
              valueListMaxHeight: 60,
              cellEditorParams: params => {
                return {
                  values: getIdList(
                    masterData?.filter(
                      x => x.parentId === params.data.mediaType.id && x.type === 'Expenditure Type',
                    ),
                  ),
                };
              },
              valueFormatter: params =>
                lookupValue(
                  masterData?.filter(x => x.type === 'Expenditure Type'),
                  'id',
                  params.value,
                )?.externalId,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'Expenditure Type')),
                valueFormatter: value =>
                  lookupValue(
                    masterData?.filter(x => x.type === 'Expenditure Type'),
                    'id',
                    value,
                  )?.externalId,
              },
            };
          case 'activityInput':
            return {
              ...col,
              valueFormatter: params =>
                `${lookupValue(masterData, 'id', params.value)?.name} ${
                  lookupValue(
                    masterData?.filter(x => x.type === 'campaign'),
                    'id',
                    params.data?.campaign?.id,
                  )?.name
                }`,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'Activity Input')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'Placement':
            return {
              ...col,
              editable: checkIfCellEditable,
              cellEditor: 'agSelectCellEditor',
              valueListMaxHeight: 60,
              cellEditorParams: params => {
                return {
                  values: getIdList(
                    masterData?.filter(
                      x => x.parentId === params.data.format.id && x.type === 'placement',
                    ),
                  ),
                };
              },
              valueSetter: params => {
                params.data.placement = {
                  id: params.newValue,
                };
                params.data.type = {
                  id: lookupValue(masterData, 'parentId', params.data.placement.id)?.id,
                };
                params.data.partner = {
                  id: lookupValue(masterData, 'parentId', params.data.type.id)?.id,
                };
                return true;
              },
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'placement')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'Type':
            return {
              ...col,
              editable: checkIfCellEditable,
              cellEditor: 'agSelectCellEditor',
              valueListMaxHeight: 60,
              cellEditorParams: params => {
                return {
                  values: getIdList(
                    masterData?.filter(
                      x => x.parentId === params.data.placement.id && x.type === 'type',
                    ),
                  ),
                };
              },
              valueSetter: params => {
                params.data.type = {
                  id: params.newValue,
                };
                params.data.partner = {
                  id: lookupValue(masterData, 'parentId', params.data.type.id)?.id,
                };
                return true;
              },
              valueFormatter: params =>
                lookupValue(
                  masterData?.filter(x => x.type === 'type'),
                  'id',
                  params.value,
                )?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'type')),
                valueFormatter: value =>
                  lookupValue(
                    masterData?.filter(x => x.type === 'type'),
                    'id',
                    value,
                  )?.name,
              },
            };
          case 'Partner':
            return {
              ...col,
              editable: checkIfCellEditable,
              cellEditor: 'agSelectCellEditor',
              valueListMaxHeight: 60,
              cellEditorParams: params => {
                return {
                  values: getIdList(
                    masterData?.filter(
                      x => x.parentId === params.data.type.id && x.type === 'partner',
                    ),
                  ),
                };
              },
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'partner')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'duration':
            return {
              ...col,
              editable: checkIfCellEditable,
              cellEditor: 'agSelectCellEditor',
              valueListMaxHeight: 60,
              cellEditorParams: params => {
                return {
                  values:
                    lookupValue(masterData, 'id', params.data?.mediaType?.id)?.name === 'TV'
                      ? durationTVIds
                      : durationIds.sort((a, b) => a - b),
                };
              },
              valueSetter: async params => {
                params.data.duration = {
                  id: params.newValue,
                };

                if (params.data.grp || params.data.grp === 0) {
                  if (params.data.grp === 0) {
                    params.data.ngrp = 0;
                  } else {
                    if (
                      lookupValue(
                        masterData.filter(x => x.type === 'Duration'),
                        'id',
                        params.newValue,
                      )?.name === '30s'
                    )
                      params.data.ngrp = params.data.grp;
                    if (
                      lookupValue(
                        masterData.filter(x => x.type === 'Duration'),
                        'id',
                        params.newValue,
                      )?.name === '15s'
                    )
                      params.data.ngrp = params.data.grp / 2;
                  }
                  if (params.data.clusterTA.id) {
                    if (params.data.grp === 0) {
                      params.data.ngrpReport = 0;
                      params.data.planBudget = 0;
                    } else {
                      const ngrpReport = await calculateNGRPReport(params);
                      params.data.ngrpReport = ngrpReport;
                      if (
                        params.data?.month?.id &&
                        lookupValue(masterData, 'id', params.data.mediaType.id)?.name === 'TV'
                      ) {
                        const planBudget = await calculatePlanBudget(params);
                        params.data.planBudget = planBudget;
                      }
                    }
                  }
                }
                return true;
              },
              valueFormatter: params =>
                params.value ? lookupValue(masterData, 'id', params.value)?.name : '-',
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'Duration')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'frequency':
            return {
              ...col,
              editable: checkIfCellEditable,
              cellEditor: 'agSelectCellEditor',
              valueListMaxHeight: 60,
              cellEditorParams: {
                values: getIdList(masterData?.filter(x => x.type === 'Freq')),
              },
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'Freq')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'startDate':
            return {
              ...col,
              editable: params => !params.data?.externalId && checkIfCellEditable(params),
              cellEditor: 'agDateCellEditor',
              valueListMaxHeight: 60,
              cellEditorParams: {
                max: mediaPlanDetails?.endDate,
                min: mediaPlanDetails?.startDate,
              },
              valueSetter: async params => {
                const startDate = params.newValue ? new Date(params.newValue) : null;
                params.data.startDate = startDate;
                params.data.endDate = null;

                if (startDate) {
                  const monthName = getMonthName(startDate.getMonth());
                  const monthId = masterData?.find(
                    x => x.type === 'Month' && x?.name === monthName,
                  )?.id;

                  params.data.month = {
                    id: monthId,
                  };
                  params.data.quarter = {
                    id: lookupValue(
                      masterData,
                      'id',
                      lookupValue(masterData, 'id', monthId)?.parentId,
                    )?.id,
                  };
                  params.data.half = {
                    id: lookupValue(
                      masterData,
                      'id',
                      lookupValue(masterData, 'id', params.data.quarter.id)?.parentId,
                    )?.id,
                  };
                  if (
                    params.data?.clusterTA?.id &&
                    params.data?.grp &&
                    params.data?.month?.id &&
                    lookupValue(masterData, 'id', params.data.mediaType.id)?.name === 'TV'
                  ) {
                    const planBudget = await calculatePlanBudget(params);
                    params.data.planBudget = planBudget;
                  }
                } else {
                  params.data.month = null;
                  params.data.quarter = null;
                  params.data.half = null;
                  params.data.acd = null;
                  params.data.planBudget = null;
                }
                params.api?.refreshCells();
                return true;
              },
              valueFormatter: params =>
                params.value ? formatDate(params.value, 'dd/MM/yyyy') : null,
            };
          case 'endDate':
            return {
              ...col,
              editable: params =>
                !params.data?.externalId && !!params.data?.startDate && checkIfCellEditable(params),
              cellEditor: 'agDateCellEditor',
              valueListMaxHeight: 60,
              cellEditorParams: params => {
                let maxDate = getLastDateOfMonth(params.data?.startDate);
                if (
                  new Date(mediaPlanDetails?.endDate) < getLastDateOfMonth(params.data?.startDate)
                )
                  maxDate = mediaPlanDetails?.endDate;
                return {
                  max: maxDate,
                  min: new Date(params.data?.startDate),
                };
              },
              valueFormatter: params =>
                params.value ? formatDate(params.value, 'dd/MM/yyyy') : null,
            };
          case 'month':
            return {
              ...col,
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'Month')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'quarter':
            return {
              ...col,
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'Quarter')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'half':
            return {
              ...col,
              valueFormatter: params => lookupValue(masterData, 'id', params.value)?.name,
              filterParams: {
                values: getIdList(masterData?.filter(x => x.type === 'Half')),
                valueFormatter: value => lookupValue(masterData, 'id', value)?.name,
              },
            };
          case 'grp':
            return {
              ...col,
              editable: checkIfCellEditable,
              cellEditor: 'agNumberCellEditor',
              cellEditorParams: {
                precision: 2,
                preventStepping: true,
                min: 0,
                max: 10 ** 32,
              },
              valueSetter: async params => {
                params.data.grp = params.newValue;

                const duration = lookupValue(masterData, 'id', params.data.duration.id);
                if (duration && (params.data.grp || params.data.grp === 0)) {
                  if (lookupValue(masterData, 'id', params.data.mediaType.id)?.name === 'TV') {
                    if (duration?.name === '30s') params.data.ngrp = params.data.grp;
                    if (duration?.name === '15s') params.data.ngrp = params.data.grp / 2;
                  } else {
                    params.data.ngrp = 0;
                  }

                  if (params.data.grp === 0) {
                    params.data.ngrpReport = null;
                    params.data.planBudget = null;
                  } else {
                    if (params.data?.clusterTA?.id) {
                      const ngrpReport = await calculateNGRPReport(params);
                      params.data.ngrpReport = ngrpReport;
                      if (
                        params.data?.month?.id &&
                        lookupValue(masterData, 'id', params.data.mediaType.id)?.name === 'TV'
                      ) {
                        const planBudget = await calculatePlanBudget(params);
                        params.data.planBudget = planBudget;
                      }
                      params.api?.refreshCells();
                    }
                  }
                } else {
                  params.data.ngrp = null;
                  params.data.ngrpReport = null;
                  params.data.acd = null;
                }
                return true;
              },
            };
          case 'plannedBudget':
            return {
              ...col,
              editable: checkIfCellEditable,
              cellEditor: 'agNumberCellEditor',
              cellEditorParams: {
                precision: 2,
                preventStepping: true,
                min: 0,
                max: 10 ** 32,
              },
            };
          case 'jtbd':
            return {
              ...col,
              editable: checkIfCellEditable,
            };
          default:
            return col;
        }
      });

    if (isMediaPlanEditable) {
      updatedColumnDefs = updatedColumnDefs.map(col => {
        const fieldId = col.colId;
        if (fieldId === 'checkbox') {
          return {
            ...col,
            cellRenderer: params => <CheckboxCell params={params} onMenuClick={onMenuClick} />,
          };
        }
        return col;
      });
      e?.api.setGridOption('columnDefs', updatedColumnDefs);
    } else {
      updatedColumnDefs = updatedColumnDefs.map(col => {
        const fieldId = col.colId;
        if (fieldId === 'checkbox') {
          return {
            ...col,
            cellRenderer: () => <Box as="span" sx={{ width: '34px', display: 'flex' }}></Box>,
          };
        }
        return col;
      });
      e?.api.setGridOption(
        'columnDefs',
        updatedColumnDefs.map(x => ({ ...x, editable: false })),
      );
    }
  };

  const handleCellValueChanged = params => {
    const changedColId = params?.colDef.colId;

    if (['Media', 'duration', 'grp'].includes(changedColId)) {
      setTimeout(() => {
        if (lookupValue(masterData, 'id', params.node.data.mediaType.id)?.name === 'TV')
          calculateACD(params);
      }, [2000]);
    }

    setMediaPlanDetails(prev => ({
      ...prev,
      isEditing: true,
    }));
  };

  const handleDragComplete = () => {
    setMediaPlanDetails(prev => ({
      ...prev,
      isEditing: true,
    }));
  };

  const handleSaveCurrentVersion = () => {
    setIsLoading(true);
    let payload = [];
    gridTableRef.current?.api.forEachNode(params => {
      payload.push({
        rowNumber: (params?.rowIndex ?? 0) + 1,
        id: params.data?.rowId,
        externalId: isEmpty(params.data?.externalId) ? null : params.data?.externalId,
        bbgCreated: params.data?.bbgCreated,
        isLocked: params.data?.isLocked,
        statusId: params.data?.status?.id,
        brandId: params.data?.brand?.id,
        brandFinanceId: params.data?.brandFinance?.id,
        variantFinanceCodeId: params.data?.variantFinanceName?.id,
        variantFinanceNameId: params.data?.variantFinanceName?.id,
        categoryId: params.data?.category?.id,
        materialCodeId: lookupValue(
          masterData.filter(x => x.type === 'Material Code'),
          'name',
          params.data?.materialCode?.id,
        )?.id,
        materialNameId: params.data?.materialName?.id,
        campaignId: params.data?.campaign?.id,
        formatId: params.data?.format?.id,
        clusterTAId: params.data?.clusterTA?.id,
        minderTAId: params.data?.minderTA?.id,
        expenditureTypeId: params.data?.expenditureType?.id,
        mediaTypeId: params.data?.mediaType?.id,
        placementId: params.data?.placement?.id,
        partnerId: params.data?.partner?.id,
        typeId: params.data?.type?.id,
        taDigital: params.data?.taDigital ?? '',
        startDate: params.data?.startDate
          ? `${formatDate(new Date(params.data?.startDate), 'yyyy-MM-dd')}T00:00:00Z`
          : null,
        endDate: params.data?.endDate
          ? `${formatDate(new Date(params.data?.endDate), 'yyyy-MM-dd')}T00:00:00Z`
          : null,
        monthId: params.data?.month?.id,
        quarterId: params.data?.quarter?.id,
        halfId: params.data?.half?.id,
        frequencyId: params.data?.frequency?.id,
        tvReach: params.data?.tvReach,
        durationId: params.data?.duration?.id,
        grp: params.data?.grp ? parseFloat(params.data?.grp) : 0,
        ngrp: params.data?.ngrp ? parseFloat(params.data.ngrp) : 0,
        ngrpReport: params.data?.ngrpReport ? parseFloat(params.data.ngrpReport) : 0,
        acd: params.data?.acd ? parseFloat(params.data?.acd) : 0,
        planBudget: params.data?.planBudget ? parseFloat(params.data?.planBudget) : 0,
        ioNumber: params.data?.ioNumber,
        notes: params.data?.notes,
        bbNumberMinder: params.data?.bbNumberMinder,
        clientPONumber: params.data?.clientPONumber,
        activityInputId: params.data?.activityInput.id,
        jtbd: params.data?.jtbd,
        mediaPlanRowsStatus: params.data?.mediaPlanRowsStatus ?? null,
      });
    });

    // console.log('payload: ', payload);
    createMediaPlanRow(
      {
        versionId: mediaPlanDetails.currentVersionId,
        mediaPlanId: mediaPlanDetails.id,
      },
      payload,
    )
      .then(response => {
        const { data: mediaPlanData } = response.data;
        if (mediaPlanData?.activeVersion?.versionNumber) {
          setMediaPlanDetails(prev => ({
            ...prev,
            approvalId: mediaPlanData.activeVersion?.approvalStatus?.id,
            activeVersionId: mediaPlanData.activeVersion.id,
            currentVersionId: mediaPlanData.activeVersion.id,
            approvalStatus:
              response.data?.data?.activeVersion?.approvalStatus?.requestStatus ??
              AppConstants.MEDIA_PLAN_STATUS_NOT_INITIATED,
            activeVersionNumber: mediaPlanData.activeVersion?.versionNumber,
            totalBudget: mediaPlanData.totalBudget,
            givenBudget: mediaPlanData.givenBudget,
          }));
        }
        setMediaPlanDetails(prev => ({
          ...prev,
          isEditing: false,
        }));

        const mediaPlanRows = mediaPlanData?.activeVersion?.mediaPlanRows;

        if (mediaPlanRows && mediaPlanRows.length > 0) {
          gridTableRef.current?.api?.setGridOption(
            'rowData',
            mediaPlanRows.map((x, i) => ({
              ...emptyMediaPlanDetailsData[0],
              ...x,
              id: i,
              rowId: x.id,
              materialCode: { materialCode: x.materialCode, id: x.materialCode },
            })),
          );
          fetchData();
        } else {
          fetchData(true);
        }

        enqueueSnackbar('Media plan updated successfully!', 'success');
      })
      .catch(error => {
        const { response = null } = error;
        const { message = AppConstants.SOMETHING_WENT_WRONG } = error;
        if (response) {
          const { message = AppConstants.SOMETHING_WENT_WRONG } = response?.data || {};
          enqueueSnackbar(message, 'error');
        } else {
          enqueueSnackbar(message, 'error');
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleSaveAsNewVersion = data => {
    setIsLoading(true);
    let mediaPlanrows = [];
    gridTableRef.current?.api.forEachNode(params =>
      mediaPlanrows.push({
        rowNumber: (params?.rowIndex ?? 0) + 1,
        externalId: params.data?.externalId,
        bbgCreated: params.data?.bbgCreated,
        isLocked: params.data?.isLocked,
        statusId: params.data?.status?.id,
        brandId: params.data?.brand?.id,
        brandFinanceId: params.data?.brandFinance?.id,
        variantFinanceCodeId: params.data?.variantFinanceName?.id,
        variantFinanceNameId: params.data?.variantFinanceName?.id,
        categoryId: params.data?.category?.id,
        materialCodeId: lookupValue(
          masterData.filter(x => x.type === 'Material Code'),
          'name',
          params.data?.materialCode.id,
        )?.id,
        materialNameId: params.data?.materialName?.id,
        campaignId: params.data?.campaign?.id,
        formatId: params.data?.format?.id,
        clusterTAId: params.data?.clusterTA?.id,
        minderTAId: params.data?.minderTA?.id,
        expenditureTypeId: params.data?.expenditureType?.id,
        mediaTypeId: params.data?.mediaType?.id,
        placementId: params.data?.placement?.id,
        partnerId: params.data?.partner?.id,
        typeId: params.data?.type?.id,
        taDigital: params.data?.taDigital ?? '',
        startDate: params.data?.startDate
          ? `${formatDate(new Date(params.data?.startDate), 'yyyy-MM-dd')}T00:00:00Z`
          : null,
        endDate: params.data?.endDate
          ? `${formatDate(new Date(params.data?.endDate), 'yyyy-MM-dd')}T00:00:00Z`
          : null,
        monthId: params.data?.month?.id,
        quarterId: params.data?.quarter?.id,
        halfId: params.data?.half?.id,
        frequencyId: params.data?.frequency?.id,
        tvReach: params.data?.tvReach,
        durationId: params.data?.duration?.id,
        grp: params.data?.grp ? parseFloat(params.data?.grp) : 0,
        ngrp: params.data?.ngrp ? parseFloat(params.data.ngrp) : 0,
        ngrpReport: params.data?.ngrpReport ? parseFloat(params.data.ngrpReport) : 0,
        acd: params.data?.acd ? parseFloat(params.data?.acd) : 0,
        planBudget: params.data?.planBudget ? parseFloat(params.data?.planBudget) : 0,
        ioNumber: params.data?.ioNumber,
        notes: params.data?.notes,
        bbNumberMinder: params.data?.bbNumberMinder,
        clientPONumber: params.data?.clientPONumber,
        activityInputId: params.data?.activityInput.id,
        jtbd: params.data?.jtbd,
        mediaPlanRowsStatus: params.data?.mediaPlanRowsStatus ?? null,
      }),
    );
    saveNewVersionForMediaPlan(
      {
        mediaPlanId: mediaPlanDetails.id,
      },
      {
        remarks: data.remarks,
        versionId: mediaPlanDetails.activeVersionId,
        isEdited: mediaPlanDetails.isEditing,
        mediaPlanrows,
      },
    )
      .then(response => {
        const { data: mediaPlanData } = response.data;
        enqueueSnackbar('Media plan updated successfully!', 'success');
        if (mediaPlanData?.activeVersion?.versionNumber) {
          setMediaPlanDetails(prev => ({
            ...prev,
            approvalId: mediaPlanData.activeVersion?.approvalStatus?.id,
            activeVersionId: mediaPlanData.activeVersion.id,
            currentVersionId: mediaPlanData.activeVersion.id,
            approvalStatus:
              response.data?.data?.activeVersion?.approvalStatus?.requestStatus ??
              AppConstants.MEDIA_PLAN_STATUS_NOT_INITIATED,
            activeVersionNumber: mediaPlanData.activeVersion?.versionNumber,
            totalBudget: mediaPlanData.totalBudget,
            givenBudget: mediaPlanData.givenBudget,
          }));
        }
        fetchData();
      })
      .catch(error => {
        const { response = null } = error;
        const { message = AppConstants.SOMETHING_WENT_WRONG } = error;
        if (response) {
          const { message = AppConstants.SOMETHING_WENT_WRONG } = response?.data || {};
          enqueueSnackbar(message, 'error');
        } else {
          enqueueSnackbar(message, 'error');
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleSetMediaPlanVersion = versionId => {
    setIsLoading(true);
    getMediaPlanVersionDetails({
      versionId,
      mediaPlanId: mediaPlanDetails.id,
    })
      .then(async response => {
        const { data: mediaPlanData } = response.data;
        const activeVersionId = mediaPlanDetails.activeVersionId;
        const currentVersionId = mediaPlanData.activeVersion.id;
        handleSetMediaPlanData(mediaPlanData, activeVersionId, currentVersionId, true);
        return {
          validationStatus:
            mediaPlanData?.activeVersion?.validationStatus ??
            AppConstants.MEDIA_PLAN_STATUS_NOT_INITIATED,
          mediaPlanVersionId: currentVersionId,
          mediaPlanId: mediaPlanData.id,
        };
      })
      .then(params => {
        if (![AppConstants.MEDIA_PLAN_STATUS_NOT_INITIATED].includes(params.validationStatus)) {
          delete params['validationStatus'];
          getMediaPlanValidations(params)
            .then(response => {
              const { data: mediaPlanValidationDetails } = response?.data ?? {};
              if (mediaPlanValidationDetails?.mediaPlanValidationErrors) {
                const errorDetails = mediaPlanValidationDetails?.mediaPlanValidationErrors;
                setMediaPlanValidationErrors(
                  errorDetails.sort((a, b) => a.externalId.localeCompare(b.externalId)),
                );
              }
            })
            .catch(error => {
              setMediaPlanValidationErrors(null);
            });
        }
      })
      .catch(error => {
        const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
        enqueueSnackbar(message, 'error');
        setMediaPlanId(0);
      })
      .finally(() => setIsLoading(false));
  };

  const handleCheckIsRowSelectable = useCallback(
    params => {
      const isRowCancelled = params.data?.mediaPlanRowsStatus === 'CANCELLED';
      if (isRowCancelled) return false;
      if (isMediaPlanEditable) return true;
      return false;
    },
    [isMediaPlanEditable],
  );

  const handleRequestMediaPlanValidation = useCallback(() => {
    if (hasAllPermissions([PermissionsConstants.VALIDATE_MEDIA_PLAN])) {
      setIsLoading(true);
      const params = {
        mediaPlanId: mediaPlanDetails?.id,
      };
      const payload = {
        mediaPlanVersionId: mediaPlanDetails?.currentVersionId,
      };

      validateMediaPlan(params, payload)
        .then(() => setMediaPlanValidationErrors())
        .then(() => fetchData())
        .then(() => {
          enqueueSnackbar('Media Plan details is sent for validation.', 'success');
        })
        .catch(error => {
          const { response } = error || {};
          const { message = AppConstants.SOMETHING_WENT_WRONG } = response?.data || {};
          enqueueSnackbar(message, 'error');
        })
        .finally(() => setIsLoading(false));
    }
  }, [hasAllPermissions, mediaPlanDetails?.id, mediaPlanDetails?.activeVersionId]);

  const handleRequestMediaPlanValidationCancel = useCallback(() => {
    if (hasAllPermissions([PermissionsConstants.VALIDATE_MEDIA_PLAN])) {
      setIsLoading(true);
      const params = {
        mediaPlanId: mediaPlanDetails?.id,
      };
      const payload = {
        mediaPlanVersionId: mediaPlanDetails?.currentVersionId,
      };

      cancelValidateMediaPlan(params, payload)
        .then(() => setMediaPlanValidationErrors())
        .then(() => fetchData())
        .then(() => {
          enqueueSnackbar('Media Plan details validation is cancelled.', 'success');
        })
        .catch(error => {
          const { response } = error || {};
          const { message = AppConstants.SOMETHING_WENT_WRONG } = response?.data || {};
          enqueueSnackbar(message, 'error');
        })
        .finally(() => setIsLoading(false));
    }
  }, [hasAllPermissions, mediaPlanDetails?.id, mediaPlanDetails?.activeVersionId]);

  const handleCreateBuyingBriefGroup = () => {
    setIsLoading(true);
    const mediaPlanRowIds = [];

    gridTableRef.current?.api.forEachNode(x => {
      if (selectedCheckboxes.includes(x.id)) mediaPlanRowIds.push(x?.data?.rowId);
    });
    const payload = {
      mediaPlanId: mediaPlanDetails?.id,
      mediaPlanRowIds,
    };

    createBuyingBriefGroup(payload)
      .then(() => {
        enqueueSnackbar('Buying Brief Group created successfully!', 'success');
        navigate(`/app/media-plans/${params.id}/buying-group`);
      })
      .catch(error => {
        const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
        enqueueSnackbar(message, 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const ViewMediaPlanTable = useMemo(() => {
    try {
      return (
        <Table
          ref={gridTableRef}
          isLoading={isLoading || isMasterDataLoading || !brandId}
          initialRowCount={5}
          data={rowData}
          getRowId={getRowId}
          rowSelection={'multiple'}
          columns={columns}
          defaultColDef={defaultColDef}
          height={`max(calc((${Math.min(rowData?.length ? rowData.length : 5, 20) * 3.75}rem) + 98px), calc(100vh - 520px))`}
          rowDragEntireRow={true}
          onCellValueChanged={handleCellValueChanged}
          onRowSelected={handleCheckboxChange}
          onGridReady={handleGridReady}
          isRowSelectable={handleCheckIsRowSelectable}
          onDragStopped={handleDragComplete}
        />
      );
    } catch {
      enqueueSnackbar(AppConstants.SOMETHING_WENT_WRONG, 'error');
    }
  }, [
    brandId,
    columns,
    getRowId,
    handleCheckboxChange,
    rowData,
    masterData,
    handleCheckIsRowSelectable,
    isMediaPlanEditable,
  ]);

  useEffect(() => {
    // console.log('demo:ads ', gridTableRef, mediaPlanValidationErrors);
    // if (gridTableRef.current) {
    //   const requiredNodesDataWithError = [];
    //   gridTableRef.current.api?.forEachNode(node => {
    //     const requiredErrorsInNode = mediaPlanValidationErrors?.filter(
    //       x =>
    //         x.externalId === node.data?.externalId &&
    //         x.errorLevel === 'column' &&
    //         x.errorType === 'error',
    //     );
    //     const requiredWarningInNode = mediaPlanValidationErrors?.filter(
    //       x =>
    //         x.externalId === node.data?.externalId &&
    //         x.errorLevel === 'column' &&
    //         x.errorType === 'warnings',
    //     );
    //     console.log('requiredErrorsInNode: ', requiredErrorsInNode);
    //     if (
    //       (requiredErrorsInNode && requiredErrorsInNode.length > 0) ||
    //       (requiredWarningInNode && requiredWarningInNode.length > 0)
    //     ) {
    //       const requiredNodeDataWithError = {
    //         ...node.data,
    //         errorFields: requiredErrorsInNode?.reduce(
    //           (acc, error) => ({
    //             ...acc,
    //             [error.column]: error.message,
    //           }),
    //           {},
    //         ),
    //         warningFields: requiredWarningInNode?.reduce(
    //           (acc, error) => ({
    //             ...acc,
    //             [error.column]: error.message,
    //           }),
    //           {},
    //         ),
    //       };
    //       requiredNodesDataWithError.push(requiredNodeDataWithError);
    //     }
    //   });
    //   gridTableRef.current.api.applyTransaction({
    //     update: requiredNodesDataWithError,
    //   });
    //   gridTableRef.current.api.refreshCells({ force: true });
    // }
  }, [ViewMediaPlanTable, gridTableRef, mediaPlanValidationErrors]);

  const DeleteRowDialog = useMemo(
    () => (
      <Dialog
        open={deletingRowId > -1}
        onClose={handleDeleteConfirmationClose}
        icon={
          <Box
            sx={{
              width: '52px',
              height: '52px',
              borderRadius: '50%',
              background: theme.palette.background,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 auto',
              marginBottom: '24px',
            }}
          >
            <DeleteIcon fill="var(--deep-navy)" width="32px" height="32px" />
          </Box>
        }
        headline={<Typography variant="SemiBold-22">Delete Row</Typography>}
        description={
          <Typography variant="Regular-16" sx={{ marginBottom: '24px' }}>
            Are you sure you want to delete this row? This action can’t be undone.
          </Typography>
        }
        buttons={[
          <Button
            key="button1"
            width="176px"
            variant="outlined"
            label="Cancel"
            onClick={handleDeleteConfirmationClose}
          />,
          <Button
            key="button2"
            width="176px"
            variant="contained"
            label="Delete"
            onClick={deleteRow}
          />,
        ]}
        width="420px"
        headlineAlignment="center"
      />
    ),
    [deleteRow, deletingRowId, theme],
  );

  const CancelRowDialog = useMemo(
    () => (
      <Dialog
        open={cancelingRowId > -1}
        onClose={handleDeleteConfirmationClose}
        icon={
          <Box
            sx={{
              width: '52px',
              height: '52px',
              borderRadius: '50%',
              background: theme.palette.background,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 auto',
              marginBottom: '24px',
            }}
          >
            <DeleteIcon fill="var(--deep-navy)" width="32px" height="32px" />
          </Box>
        }
        headline={<Typography variant="SemiBold-22">Cancel Row</Typography>}
        description={
          <Typography variant="Regular-16" sx={{ marginBottom: '24px' }}>
            Are you sure you want to cancel this row? This action can’t be undone.
          </Typography>
        }
        buttons={[
          <Button
            key="button1"
            width="176px"
            variant="outlined"
            label="No"
            onClick={handleCancelConfirmationClose}
          />,
          <Button
            key="button2"
            width="176px"
            variant="contained"
            label="Yes"
            onClick={cancelRow}
          />,
        ]}
        width="420px"
        headlineAlignment="center"
      />
    ),
    [cancelRow, cancelingRowId, theme],
  );

  const handleClearAll = () => {
    gridTableRef.current?.api?.forEachNode(node => {
      node.setSelected(false);
    });
  };

  const duplicateSelectedRows = useCallback(() => {
    if (selectedCheckboxes.length > 0) {
      let totalRows = 0;
      const rowDetails = [];
      gridTableRef.current?.api.forEachNode(x => {
        totalRows = totalRows + 1;
      });

      gridTableRef.current?.api.forEachNode(x => {
        if (selectedCheckboxes.includes(x.id)) rowDetails.push(x.data);
      });

      const duplicatedRows = rowDetails.map((row, index) => {
        const newId = totalRows + index;

        return {
          ...row,
          id: newId,
          rowId: null,
          bbNumberMinder: '',
          externalId: '',
          bbgCreated: false,
          isLocked: false,
        };
      });

      gridTableRef.current.api.applyTransactionAsync({
        add: [...duplicatedRows],
        addIndex: -1,
      });

      setMediaPlanDetails(prev => ({
        ...prev,
        isEditing: true,
      }));

      handleClearAll();
    }
  });

  const MediaPlanHeaderActions = useMemo(
    () => (
      <HeaderActions
        tableRef={gridTableRef}
        mediaPlanDetails={mediaPlanDetails}
        handleMediaPlanApprovalStatusChanged={handleMediaPlanApprovalStatusChanged}
        handleRequestMediaPlanValidation={handleRequestMediaPlanValidation}
        handleRequestMediaPlanValidationCancel={handleRequestMediaPlanValidationCancel}
        mediaPlanValidationErrors={mediaPlanValidationErrors}
        rowData={rowData}
        isBBGCreated={mediaPlanDetails?.isBbgCreatedOnMinder}
        businessUnitId={buId}
      />
    ),
    [mediaPlanDetails, mediaPlanValidationErrors, handleRequestMediaPlanValidation],
  );

  const MediaPlanInfo = useMemo(
    () =>
      mediaPlanDetails ? (
        <MediaPlanDetails
          mediaPlanDetails={mediaPlanDetails}
          showDetails
          disableGivenBudget={
            !hasAllPermissions([PermissionsConstants.UPDATE_GIVEN_BUDGET]) || !isMediaPlanEditable
          }
          handleUpdateMediaPlanDetails={handleUpdateMediaPlanDetails}
          errorDetails={mediaPlanValidationErrors?.filter(ele => ele.errorLevel === 'media plan')}
        />
      ) : null,
    [mediaPlanDetails, mediaPlanValidationErrors],
  );

  const TableUtils = useMemo(() => {
    if (selectedCheckboxes && selectedCheckboxes.length > 0) {
      return (
        <ViewMediaPlanCheckbox
          handleCheckboxChange={handleCheckboxChange}
          selectedCheckboxes={selectedCheckboxes}
          gridTableRef={gridTableRef}
          onClearAll={handleClearAll}
          setSelectedCheckboxes={setSelectedCheckboxes}
          duplicateSelectedRows={duplicateSelectedRows}
          createBuyingBriefGroup={handleCreateBuyingBriefGroup}
          mediaPlanDetails={mediaPlanDetails}
          anyBBGCreated={anyBBGCreated}
          deleteMultipleRows={deleteMultipleRows}
          isLoading={isLoading}
        />
      );
    } else {
      return (
        <ViewMediaPlanTableUtils
          gridTableRef={gridTableRef}
          handleSaveCurrentVersion={handleSaveCurrentVersion}
          mediaPlanHeaderOptions={mediaPlanHeaderOptions}
          handleMenuItemClick={handleMenuItemClick}
          mediaPlanDetails={mediaPlanDetails}
          handleMediaPlanStatusChanged={handleMediaPlanStatusChanged}
          handleMediaPlanApprovalStatusChanged={handleMediaPlanApprovalStatusChanged}
          handleSaveAsNewVersion={handleSaveAsNewVersion}
          handleSetMediaPlanVersion={handleSetMediaPlanVersion}
          isMediaPlanEditable={isMediaPlanEditable}
        />
      );
    }
  }, [
    selectedCheckboxes,
    handleCheckboxChange,
    rowData?.length,
    duplicateSelectedRows,
    mediaPlanHeaderOptions,
    handleMenuItemClick,
    isMediaPlanEditable,
  ]);

  if (mediaPlanId === 0 || !hasAllPermissions([PermissionsConstants.READ_MEDIA_PLAN]))
    return <PageNotFound />;

  return (
    <>
      <BackdropOverlay
        open={backdropOpen}
        buttonLabel="Redirect To Media Plan List"
        clickAction={() => {
          navigate(`/app/media-plans`);
        }}
        autharizationWarning={true}
      />
      <Loader isLoading={isLoading || isMasterDataLoading} />
      <Card sx={{ alignItems: 'flex-start' }}>
        {mediaPlanDetails?.approvalStatus ===
          AppConstants.MEDIA_PLAN_STATUS_MANAGER_APPROVAL_PENDING &&
          isLoggedInUserManager &&
          hasAllPermissions([PermissionsConstants.APPROVE_MEDIA_PLAN]) && (
            <ApproveRejectAction
              onApprove={() =>
                handleMediaPlanApprovalStatusChanged(
                  AppConstants.MEDIA_PLAN_STATUS_MANAGER_APPROVED,
                  mediaPlanDetails.approvalManagerId,
                  // enqueueSnackbar('Media Plan Approved By Manager'),
                )
              }
              onReject={() => {
                handleMediaPlanApprovalStatusChanged(
                  AppConstants.MEDIA_PLAN_STATUS_MANAGER_REJECTED,
                  mediaPlanDetails.approvalManagerId,
                );
                // enqueueSnackbar('Media Plan Rejected By Manager');
              }}
            />
          )}
        <Header breadcrumbs={[{ name: 'View Media Plan' }]} rightActions={MediaPlanHeaderActions} />
        <Tabs
          items={[
            {
              value: 'media-plan',
              label: 'Media Plan',
              link: `/app/media-plans/${params.id}/media-plan`,
            },
            {
              value: 'summary',
              label: 'Summary',
              link: `/app/media-plans/${params.id}/summary/${mediaPlanDetails?.currentVersionId}`,
            },
            {
              value: 'buying-group',
              label: 'Buying Brief Group',
              link: `/app/media-plans/${params.id}/buying-group`,
            },
          ]}
          active="media-plan"
        />
        {MediaPlanInfo}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            mt: '20px',
            flexDirection: 'column',
            gap: selectedCheckboxes && selectedCheckboxes.length > 0 ? '6px' : '24px',
          }}
        >
          {TableUtils}
          {DeleteRowDialog}
          {CancelRowDialog}
          {ViewMediaPlanTable}
        </Box>
        <Comments entityId={mediaPlanDetails?.id} entityType="MEDIA_PLAN" />
      </Card>
    </>
  );
};

export default withErrorBoundary(MediaPlan);
