import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import { CrossIcon, ErrorIcon, SearchIcon, SuccesIcon } from '../../../../libs/svg-icons/icons';
import Tooltip from '../../tooltip/index';
import OverflowTooltip from '../../tooltip/OverflowTooltip';

/**
 * Custom input field over MUI OutlinedInput.
 *
 * @param {string} id - The `id` of `input` element.
 * @param {string} inputHeight - The `height` of `input` element.
 * @param {string} type - The `type` of `input` element.
 * @param {string} label - The `label` of `input` element.
 * @param {string} name - The `name` of `input` element.
 * @param {string} value - The `value` of `input` element.
 * @param {function} onChange - The function that is triggered when there is a change in the `input` element.
 * @param {function} onBlur - The function that is triggered when the`input` element is blurred.
 * @param {function} onKeyDown - The function that is triggered when any key id pressend inside the`input` element.
 * @param {boolean} required - If `required` is true, the `input` element will require a value and an asteric will be shown after the label.
 * @param {string} placeholder - The short hint displayed in the `input` element before the user enters a value.
 * @param {boolean} disabled - If `disabled` is true, the `input` element will be disabled.
 * @param {Element} startIcon - The icon that is shown at the beginning or left end of the `input` element.
 * @param {Element} endIcon - The icon that is shown at the right end of the `input` element.
 * @param {string} color - The `color` for the `input` element.
 * @param {string} error - If `error` message is present, it will error success message at the bottom of the `input` element and the border of the `input` element will be of color error.
 * @param {string} success - If `success` message is present, it will show success message at the bottom of the `input` element and the border of the `input` element will be of color success.
 * @param {boolean} showErrorIcon - If `showErrorIcon` is true, it will show error icon at the right end of the `input` element.
 * @param {boolean} showSuccessIcon - If `showSuccessIcon` is true, it will show success icon at the right end of the `input` element.
 * @param {string} helperText - Any text that we want to show at the bottom of the `input` element, as a description.
 * @param {string} iconInfo - If `startIcon` or `endIcon` is present,`iconInfo` contents will be shown in a tooltip if the `startIcon` or `endIcon` is hovered.
 * @param {boolean} searchable - If `true`, the `input` element will have a search icon at the end.
 * @param {function} onSearchCloseClick - If `input` element is searchable, then this function will be triggered if clicking the cross icon is clicked while searching.
 * @param {boolean} autoFocus - If `true`, the `input` element is focused during the first mount.
 * @returns {Element} The `input` element.
 */

const Input = (
  {
    id = '',
    inputHeight = '',
    type = 'text',
    label = '',
    name = '',
    value = '',
    onChange = () => null,
    onBlur = () => null,
    onKeyDown = () => null,
    required = false,
    placeholder = '',
    disabled = false,
    startIcon = null,
    endIcon = null,
    color = null,
    error = '',
    warning = '',
    success = '',
    showErrorIcon = false,
    showWarningIcon = false,
    showSuccessIcon = false,
    helperText = '',
    iconInfo = null,
    searchable = false,
    onSearchCloseClick = null,
    autoFocus = false,
  },
  ref,
) => {
  const [inputValue, setInputValue] = useState();

  useEffect(() => {
    if (value) setInputValue(value);
    else setInputValue('');
  }, [value]);
  const theme = useTheme();

  const handleChange = e => {
    setInputValue(e.target.value);
    onChange(e);
  };

  const handleSearchCloseClicked = () => {
    onSearchCloseClick && onSearchCloseClick();
    setInputValue('');
  };

  const handleKeyDown = e => {
    if (type === 'number') {
      const utilsKeyCode = [8, 13, 37, 38, 39, 40];
      const acceptedKeyCodes = [8, 13, 37, 38, 39, 40, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
      if (!e.target.value?.includes('.')) acceptedKeyCodes.push(190);
      else {
        acceptedKeyCodes.filter(x => x !== 190);
        if (e.target.value?.split('.')[1].length >= 2) {
          if (!utilsKeyCode.includes(e.keyCode)) {
            e.preventDefault();
          }
        }
      }
      if (!acceptedKeyCodes.includes(e.keyCode)) {
        e.preventDefault();
      }
    }
    onKeyDown(e);
  };

  const startIconComponent = useMemo(
    () =>
      startIcon ? (
        <Tooltip placement="top" title={iconInfo}>
          <InputAdornment position="start">{startIcon}</InputAdornment>
        </Tooltip>
      ) : null,
    [iconInfo, startIcon],
  );

  const endIconComponent = useMemo(() => {
    if (searchable)
      if (!!inputValue)
        return (
          <InputAdornment
            position="end"
            sx={{ cursor: 'pointer' }}
            onClick={handleSearchCloseClicked}
          >
            <CrossIcon fill="var(--text-400)" />
          </InputAdornment>
        );
      else
        return (
          <InputAdornment position="end">
            <SearchIcon fill="var(--text-400)" />
          </InputAdornment>
        );
    if (!!error && showErrorIcon)
      return (
        <Tooltip placement="top" title={iconInfo}>
          <InputAdornment position="end">
            <ErrorIcon fill="var(--error)" />
          </InputAdornment>
        </Tooltip>
      );
    if (!!warning && showWarningIcon)
      return (
        <Tooltip placement="top" title={iconInfo}>
          <InputAdornment position="end">
            <ErrorIcon fill="var(--warning)" />
          </InputAdornment>
        </Tooltip>
      );
    if (!!success && showSuccessIcon)
      return (
        <Tooltip placement="top" title={iconInfo}>
          <InputAdornment position="end">
            <SuccesIcon fill="var(--success)" />
          </InputAdornment>
        </Tooltip>
      );
    if (endIcon)
      return (
        <Tooltip placement="top" title={iconInfo}>
          <InputAdornment position="end">{endIcon}</InputAdornment>
        </Tooltip>
      );
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchable, inputValue, error, showErrorIcon, iconInfo, success, showSuccessIcon, endIcon]);

  const inputColor = useMemo(() => {
    if (color) return color;
    if (!!error) return 'error';
    if (!!warning) return 'warning';
    if (!!success) return 'success';
    return 'primary';
  }, [color, error, success, warning]);

  const helperTextContent = useMemo(() => {
    if (helperText) return helperText;
    if (!!error) return error;
    if (!!warning) return warning;
    if (!!success) return success;
    return null;
  }, [helperText, error, success, warning]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: '4px',
        textAlign: 'left !important',
      }}
    >
      {label && (
        <OverflowTooltip text={label}>
          <Typography color={theme.palette.text[100]} component="span" variant="Medium-16">
            {label} {required && '*'}
          </Typography>
        </OverflowTooltip>
      )}
      <FormControl error={!!error} color={inputColor} disabled={disabled}>
        <OutlinedInput
          ref={ref}
          id={id ?? undefined}
          sx={{
            height: `${inputHeight} !important`,
          }}
          type={type}
          autoFocus={autoFocus}
          variant="outlined"
          name={name}
          onChange={handleChange}
          onBlur={onBlur}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          value={inputValue}
          required={required}
          disabled={disabled}
          startAdornment={startIconComponent}
          endAdornment={endIconComponent}
          aria-describedby="component-helper-text"
        />
        <FormHelperText
          id="component-helper-text"
          sx={{
            color: !!success && 'var(--success)',
            margin: '0 !important',
          }}
        >
          {helperTextContent}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

export default forwardRef(Input);
