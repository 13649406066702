import Chip from '../../../../../../components/shared/chip';
import UpdateProjectDialog from './UpdateProjectDialog.jsx';
import CreateProjectDialog from './CreateProjectDialog.jsx';
import BBMenu from './BBMenu.jsx';
import UserDetails from '../../../../../../components/shared/user/index.jsx';
import { capitalize } from '../../../../../../utils/string.js';
import ViewMaterial from '../../../../../../components/shared/viewMaterial/index.jsx';

export const columns = onMenuClick => [
  {
    id: 'menu',
    headerName: '',
    field: 'menu',
    minWidth: 50,
    maxWidth: 50,
    cellRenderer: params => {
      if (params.name === 'Web App') return <BBMenu params={params} onMenuClick={onMenuClick} />;
    },
  },
  {
    id: 'name',
    headerName: '',
    field: 'name',
    minWidth: 300,
    maxWidth: 300,
  },
  {
    id: 'buyingBriefStatus',
    headerName: 'Buying Brief Status',
    field: 'buyingBriefStatus',
    minWidth: 300,
    maxWidth: 300,
    colSpan: 2,
    cellRenderer: params => {
      let status = 'warning';
      switch (params.buyingBriefStatus) {
        case 'LIVE':
          status = 'success';
          break;
        case 'CANCELLED':
          status = 'error';
          break;
        case 'APPROVED':
          status = 'success';
          break;
        default:
          break;
      }
      if (params.buyingBriefStatus) {
        return (
          <Chip
            label={capitalize(params.buyingBriefStatus)}
            status={status}
            styles={{ margin: 'auto' }}
          />
        );
      } else {
        return <Chip label="Draft" status={status} styles={{ margin: 'auto' }} />;
      }
    },
  },
  {
    id: 'cancelStatus',
    headerName: 'Cancelled Status',
    field: 'cancelStatus',
    minWidth: 300,
    maxWidth: 300,
    colSpan: 2,
  },
  {
    id: 'agency',
    headerName: 'Agency',
    field: 'agency',
    minWidth: 300,
    maxWidth: 300,
  },
  {
    id: 'client',
    headerName: 'Client',
    field: 'client',
    minWidth: 300,
    maxWidth: 300,
  },
  {
    id: 'businessUnit',
    headerName: 'Business Unit',
    field: 'businessUnit',
    minWidth: 300,
    maxWidth: 300,
  },
  {
    id: 'category',
    headerName: 'Category',
    field: 'category',
    minWidth: 300,
    maxWidth: 300,
  },
  {
    id: 'brand',
    headerName: 'Brand',
    field: 'brand',
    colSpan: 2,
    minWidth: 300,
    maxWidth: 300,
  },

  {
    id: 'variantFinanceName',
    headerName: 'Variant Finance',
    field: 'variantFinanceName',
    colSpan: 2,
    minWidth: 300,
    maxWidth: 300,
  },
  {
    id: 'mediaType',
    headerName: 'Media Type',
    field: 'mediaType',
    colSpan: 2,
    minWidth: 300,
    maxWidth: 300,
  },
  {
    id: 'bbNumber',
    headerName: 'BB Number / Marvel ID',
    field: 'marvelBBId',
    colSpan: 2,
    minWidth: 350,
    maxWidth: 350,
  },
  {
    id: 'campaignName',
    headerName: 'Campaign Name',
    field: 'campaignName',
    colSpan: 2,
    minWidth: 500,
    maxWidth: 500,
  },
  {
    id: 'startDate',
    headerName: 'Start Date (DD/MM/YY)',
    field: 'startDate',
    colSpan: 2,
    minWidth: 300,
    maxWidth: 300,
  },
  {
    id: 'endDate',
    headerName: 'End Date (DD/MM/YY)',
    field: 'endDate',
    colSpan: 2,
    minWidth: 300,
    maxWidth: 300,
  },
  {
    id: 'targetAudience',
    headerName: 'Minder Target Audience',
    field: 'targetAudience',
    colSpan: 2,
    minWidth: 300,
    maxWidth: 300,
  },
  {
    id: 'material',
    headerName: 'Material (String / List)',
    field: 'material',
    colSpan: 2,
    minWidth: 300,
    maxWidth: 300,
    cellRenderer: params => {
      const { material = [] } = params;
      return <ViewMaterial materialData={material} />;
    },
  },
  {
    id: 'totalBudget',
    headerName: 'Total Budget',
    field: 'totalBudget',
    colSpan: 2,
    minWidth: 300,
    maxWidth: 300,
  },
  {
    id: 'actualCost',
    headerName: 'Actual Cost',
    field: 'actualCost',
    colSpan: 2,
    minWidth: 300,
    maxWidth: 300,
  },
  {
    id: 'remarks',
    headerName: 'Remark',
    field: 'remarks',
    colSpan: 3,
    minWidth: 500,
    maxWidth: 500,
  },
  {
    id: 'attachment',
    headerName: 'Attachment',
    field: 'attachment',
    colSpan: 3,
    minWidth: 500,
    maxWidth: 500,
  },

  // {
  //   id: 'buyingBriefExternalUID',
  //   headerName: 'Buying Brief External UID',
  //   field: 'buyingBriefExternalUID',
  //   colSpan: 2,
  //   minWidth: 300,
  //   maxWidth: 300,
  // },
];

export const childColumns = fetchData => [
  {
    id: 'createProject',
    headerName: 'Create Project',
    field: 'projectId',
    minWidth: 350,
    maxWidth: 350,
    colSpan: 4,
    align: 'center',
    cellRenderer: data => {
      console.log(data);
      if (data.projectId) return <UpdateProjectDialog data={data} fetchData={fetchData} />;
      else return <CreateProjectDialog data={data} />;
    },
  },
  {
    colId: 'externalId',
    headerName: 'ID',
    field: 'externalId',
    minWidth: 200,
    width: 200,
    colSpan: 2,
    suppressSizeToFit: true,
  },
  {
    colId: 'category',
    headerName: 'Category',
    field: 'category',
    minWidth: 150,
    suppressSizeToFit: true,
    clipboard: false,
  },
  {
    colId: 'brand',
    headerName: 'Brand',
    field: 'brand',
    minWidth: 150,
    suppressSizeToFit: true,
    clipboard: false,
  },
  {
    colId: 'brandFinance',
    headerName: 'Brand Finance',
    field: 'brandFinance',
    minWidth: 150,
    suppressSizeToFit: true,
    clipboard: false,
  },
  {
    colId: 'campaign',
    headerName: 'Campaign',
    field: 'campaign',
    minWidth: 200,
    suppressSizeToFit: true,
    clipboard: false,
    suppressFiltersToolPanel: true,
  },
  {
    colId: 'variantFinanceCode',
    headerName: 'Variant (Finance) Code',
    field: 'variantFinanceCode',
    minWidth: 250,
    suppressSizeToFit: true,
  },
  {
    colId: 'variantFinance',
    headerName: 'Variant (Finance) Name',
    field: 'variantFinanceName',
    minWidth: 250,
    suppressSizeToFit: true,
  },
  {
    colId: 'material',
    headerName: 'Material Name',
    field: 'materialName',
    minWidth: 250,
    suppressSizeToFit: true,
  },
  {
    colId: 'materialCode',
    headerName: 'Material Code',
    field: 'materialCode',
    minWidth: 250,
    suppressSizeToFit: true,
  },
  {
    colId: 'clusterTA',
    headerName: 'Cluster TA',
    field: 'clusterTA',
    minWidth: 200,
    suppressSizeToFit: true,
  },
  {
    colId: 'minderTA',
    headerName: 'Minder TA',
    field: 'minderTA',
    minWidth: 200,
    suppressSizeToFit: true,
    clipboard: false,
    suppressFiltersToolPanel: true,
  },
  {
    colId: 'media',
    headerName: 'Media',
    field: 'mediaType',
    minWidth: 120,
    suppressSizeToFit: true,
  },
  {
    colId: 'format',
    headerName: 'Format',
    field: 'format',
    minWidth: 160,
    suppressSizeToFit: true,
  },
  {
    colId: 'placement',
    headerName: 'Placement',
    field: 'placement',
    minWidth: 160,
    suppressSizeToFit: true,
  },
  {
    colId: 'type',
    headerName: 'Type',
    field: 'type',
    minWidth: 160,
    suppressSizeToFit: true,
  },
  {
    colId: 'partner',
    headerName: 'Partner',
    field: 'partner',
    minWidth: 160,
    suppressSizeToFit: true,
  },
  {
    colId: 'taDigital',
    headerName: 'TA Digital',
    field: 'taDigital',
    minWidth: 160,
    suppressSizeToFit: true,
  },
  {
    colId: 'month',
    headerName: 'Month',
    field: 'month',
    minWidth: 160,
    suppressSizeToFit: true,
  },
  {
    colId: 'quarter',
    headerName: 'Quarter',
    field: 'quarter',
    minWidth: 100,
    suppressSizeToFit: true,
  },
  {
    colId: 'half',
    headerName: 'Half',
    field: 'half',
    minWidth: 100,
    suppressSizeToFit: true,
  },
  {
    colId: 'frequency',
    headerName: 'Frequency',
    field: 'frequency',
    minWidth: 150,
    suppressSizeToFit: true,
  },
  {
    colId: 'tvReach',
    headerName: 'TV Reach',
    field: 'tvReach',
    minWidth: 150,
    suppressSizeToFit: true,
  },
  {
    colId: 'duration',
    headerName: 'Duration',
    field: 'duration',
    minWidth: 150,
    suppressSizeToFit: true,
  },
  {
    colId: 'grp',
    headerName: 'GRP',
    field: 'grp',
    minWidth: 100,
    suppressSizeToFit: true,
  },
  {
    colId: 'ngrp',
    headerName: 'NGRP',
    field: 'ngrp',
    minWidth: 100,
    suppressSizeToFit: true,
  },
  {
    colId: 'ngrpReport',
    headerName: 'NGRP Report',
    field: 'ngrpReport',
    minWidth: 150,
    suppressSizeToFit: true,
  },
  {
    colId: 'acd',
    headerName: 'ACD',
    field: 'acd',
    minWidth: 150,
    suppressSizeToFit: true,
  },
  {
    colId: 'planBudget',
    headerName: 'Plan Budget',
    field: 'planBudget',
    minWidth: 150,
    suppressSizeToFit: true,
  },
  {
    colId: 'ioNumber',
    headerName: 'IO Number',
    field: 'ioNumber',
    minWidth: 150,
    suppressSizeToFit: true,
  },
  {
    colId: 'bbNumberMinder',
    headerName: 'BB Number Minder',
    field: 'bbNumberMinder',
    minWidth: 250,
    suppressSizeToFit: true,
  },
  {
    colId: 'notes',
    headerName: 'Notes',
    field: 'notes',
    minWidth: 300,
    maxWidth: 300,
    suppressSizeToFit: true,
  },
  {
    colId: 'status',
    headerName: 'Status',
    field: 'mediaPlanRowsStatus',
    cellRenderer: params => {
      if (!params.mediaPlanRowsStatus) return '-';
      let status = 'warning';
      switch (params.mediaPlanRowsStatus) {
        case 'LIVE':
          status = 'success';
          break;
        case 'CANCELLED':
          status = 'error';
          break;
        default:
          break;
      }
      return (
        <Chip status={status} label={params.mediaPlanRowsStatus} styles={{ margin: 'auto' }} />
      );
    },
    minWidth: 150,
  },
  {
    colId: 'planer',
    headerName: 'Planner',
    field: 'plannerName',
    minWidth: 200,
    cellRenderer: params => (
      <UserDetails
        fullName={params?.plannerName}
        fullNameColor="var(--text-100)"
        initials={params?.plannerName?.split(' ').map(x => x[0])}
        initialsBackgroundColor="var(--primary)"
        initialsColor="var(--white)"
        initialsVariant="SemiBold-12"
        initailsContainer="32px"
        fullNameVariant="Medium-14"
      />
    ),
  },
  {
    colId: 'expenditureType',
    headerName: 'CBT Code',
    field: 'expenditureType',
    minWidth: 250,
    suppressSizeToFit: true,
  },
  {
    colId: 'activityInput',
    headerName: 'Activity Input',
    field: 'activityInput',
    minWidth: 250,
    suppressSizeToFit: true,
  },
  {
    colId: 'jtbd',
    headerName: 'JTBD',
    field: 'jtbd',
    minWidth: 450,
    suppressSizeToFit: true,
  },
];
