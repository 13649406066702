import { createIcon } from '../createSVGIcon';

const path = (
  <g>
    <path d="M3.02464 6.0006L7.14964 10.1256L5.9713 11.3039L0.667969 6.0006L5.9713 0.697266L7.14964 1.8756L3.02464 6.0006Z" />
  </g>
);

export const ChevronLeftIcon = createIcon(path, {
  width: 8,
  height: 12,
  viewBox: '0 0 8 12',
  fill: 'none',
});
