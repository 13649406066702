import React, { useState } from 'react';
import { Box, Divider, List, ListItem } from '@mui/material';
import Input from '../../form/Input/input';
import Select from '../../form/Select/select';

const FilterOptions = [
  { value: 'contains', label: 'Contains' },
  { value: 'notContains', label: 'Does Not Contain' },
  { value: 'equals', label: 'Equals' },
  { value: 'notEquals', label: 'Does Not Equal' },
  { value: 'startsWith', label: 'Begins With' },
  { value: 'endsWith', label: 'Ends With' },
  { value: 'blank', label: 'Blank' },
  { value: 'notBlank', label: 'Not Blank' },
];

const MuiTextFilter = ({ column = null, onRequestFilterChange = () => null }) => {
  const [filterType, setFilterType] = useState(FilterOptions[0].value);
  const [filterValue, setFilterValue] = useState('');

  const handleSearchTextChange = e => {
    setFilterValue(e.target.value);
    onRequestFilterChange(column.id, 'ALL');
  };

  const handleSearchCloseClick = () => {
    setFilterValue('');
  };

  const onFilterChanged = (type, value) => {
    onRequestFilterChange(column.id, 'ALL');
  };

  return (
    <>
      <ListItem
        sx={{ width: '100%', backgroundColor: '#FFFFFF', position: 'sticky', py: 0, px: 0, pt: 1 }}
      >
        <Select
          value={filterType}
          onChange={e => {
            setFilterType(e.target.value);
            onFilterChanged(e.target.value, filterValue);
          }}
          options={FilterOptions}
          selectHeight="36px"
          onSearchCloseClick={handleSearchCloseClick}
          onKeyDown={e => {
            if (e.key !== 'Escape') {
              e.stopPropagation();
            }
          }}
        />
      </ListItem>
      {filterType === FilterOptions[6].value || filterType === FilterOptions[7].value ? null : (
        <ListItem sx={{ backgroundColor: '#FFFFFF', position: 'sticky', py: 0, px: 0, pb: 1 }}>
          <Input
            value={filterValue}
            inputHeight="36px"
            onChange={handleSearchTextChange}
            onSearchCloseClick={handleSearchCloseClick}
            placeholder="Search here"
            searchable
            sx={{ position: 'sticky' }}
            autoFocus
            onKeyDown={e => {
              if (e.key !== 'Escape') {
                e.stopPropagation();
              }
            }}
          />
        </ListItem>
      )}
      <Divider />
    </>
  );
};

export default MuiTextFilter;
