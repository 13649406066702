import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';

import ReactSignatureCanvas from 'react-signature-canvas';
import domtoimage from 'dom-to-image';

import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEnqueueSnackbar } from '../../../../../../components/shared/toast-provider/toastHook';
import { useTheme } from '@emotion/react';
import { AppConstants } from '../../../../../../constants/app-constants';
import Loader from '../../../../../../components/shared/loader';
import Dialog from '../../../../../../components/shared/dialog';
import UserDetails from '../../../../../../components/shared/user';
import { themePalette } from '../../../../../../constants/theme/color';
import { MailSignIcon, PenSignIcon, UploadIcon } from '../../../../../../libs/svg-icons/icons';
import Button from '../../../../../../components/shared/buttons';
import ImgCropper from '../../../../../../components/shared/imgCropper';
import Textarea from '../../../../../../components/shared/form/textarea';
import DragAndDropFilePicker from '../../../../../../components/shared/form/fileupload';
import InputField from '../../../../../../components/shared/form/Input';
import quillPenIcon from '../../../../../../libs/svg-icons/icons/quill-pen.svg';
import ballPenIcon from '../../../../../../libs/svg-icons/icons/ball-pen.svg';

const schema = yup.object().shape({
  signatureType: yup.string(),
  signatureText: yup
    .string()
    .test({
      name: 'is-required',
      message: 'Sign is required.',
      test: (val, ctx) => (ctx.parent.signatureType === 'text' ? !!val : true),
    })
    .max(50, 'Sign must not exceed 50 characters')
    .matches(/^\S*$/, 'No spaces are allowed in the signature text'),
  signatureDrawing: yup.mixed().test({
    name: 'is-required',
    message: 'Sign is required.',
    test: (val, ctx) => (ctx.parent.signatureType === 'draw' ? !!val : true),
  }),
  signatureImage: yup.mixed(),
  comment: yup.string().max(250, 'Keyword must not exceed 200 characters'),
});

export default function SignPurchaseOrder({
  open = false,
  onClose = () => {},
  handleIBSignData = () => {},
  showTeamDetails = false,
}) {
  const signatureRef = useRef();
  const enqueueSnackbar = useEnqueueSnackbar();
  const theme = useTheme();

  const [isEsign, setIsEsign] = useState(true);

  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      signatureType: 'text',
      signatureText: '',
      signatureImage: null,
      comment: '',
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [croppedImg, setCroppedImg] = useState(null);

  useEffect(() => {
    if (!open) {
      methods.reset();
    }
  }, [methods, open]);

  const setImageToElement = baseImg => {
    const domElement = document.getElementById('person-1-signature');
    const domProgramType = document.getElementById('program-type');
    const domRemark = document.getElementById('remark');

    domElement.style.backgroundImage = `url(${baseImg})`;
    domElement.style.backgroundSize = '100% 95%';
  };

  const saveSignature = () => {
    const signatureData = signatureRef.current.toDataURL();

    setImageToElement(signatureData);

    onClose();
    setIsLoading(false);
  };

  const strToImg = () => {
    const temp = document.getElementById('text-signature');

    domtoimage
      .toPng(temp)
      .then(function (dataUrl) {
        setImageToElement(dataUrl);
      })
      .catch(function (error) {
        enqueueSnackbar(AppConstants.SOMETHING_WENT_WRONG, 'error');
      })
      .finally(() => {
        onClose();
        setIsLoading(false);
      });
  };

  const handleSubmitSignIB = () => {
    setIsLoading(true);
    if (methods.watch('signatureType') === 'text') {
      strToImg();
    } else {
      saveSignature();
    }
    handleIBSignData(true, methods.watch('remark'), methods.watch('progTypeConsideration'));
  };

  const styleClass = {
    teamContainer: {
      height: '60px',
      textAlign: 'center',
      display: 'flex',
      gap: 15,
      fontFamily: 'poppins',
    },
    teamBox: {
      background: '#F4F4F4',
      lineHeight: '60px',
      width: '49%',
      fontSize: '16px',
      fontWeight: '400',
    },
    userStyle: {
      width: '49%',
      height: '59px',
      border: '1px solid #CDD1D7',
      padding: '12px 10px 0px 15px',
    },
    selectedSignType: {
      width: '49%',
      height: '100px',
      background: '#0A2756',
      color: '#F4F4F4',
      textAlign: 'center',
      borderRadius: '6px',
      padding: '20px',
      cursor: 'pointer',
    },
    nonSelectedSignType: {
      width: '49%',
      height: '100px',
      color: '#0A2756',
      textAlign: 'center',
      borderRadius: '6px',
      border: '1px solid #CDD1D7',
      padding: '20px',
      cursor: 'pointer',
    },
  };

  const cropImage = img => {
    console.log(img);
  };

  useEffect(() => {
    cropImage(methods.watch('signatureImage'));
  }, [methods.watch('signatureImage')]);

  const handleCroppedImage = croppedImage => {
    const url = URL.createObjectURL(croppedImage);
    setCroppedImg(url);
    console.log('Cropped Image File:', croppedImage, url);
    // You can now use the cropped image file object as needed
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <FormProvider {...methods}>
        <Dialog
          open={open}
          fullBodyScroll={true}
          onClose={onClose}
          headline={
            <Typography variant="SemiBold-22" alignItems="flex-start" justifyContent="flex-start">
              E Signature Required for Approval{' '}
            </Typography>
          }
          description={
            <Box sx={{ width: '100%', minHeight: '300px', textAlign: 'left' }}>
              {showTeamDetails && (
                <Box>
                  <div style={styleClass.teamContainer}>
                    <div style={styleClass.teamBox}>Buying Team Executive</div>
                    <div style={styleClass.userStyle}>
                      <div style={{ margin: 'auto' }}>
                        <UserDetails
                          sx={{ width: '100%' }}
                          fullName={'Lark Mccarthy'}
                          fullNameColor={themePalette.palette.text['100']}
                          initials={'Lark Mccarthy'
                            .split(' ')
                            .map(x => x[0])
                            .join('')}
                          initialsBackgroundColor={themePalette.palette.primary.main}
                          initialsColor={themePalette.palette.white}
                          initialsVariant="SemiBold-12"
                          initailsContainer="32px"
                          fullNameVariant="Medium-14"
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ ...styleClass.teamContainer, marginTop: '15px' }}>
                    <div style={styleClass.teamBox}>Campaign Delivery Manager</div>
                    <div style={styleClass.userStyle}>
                      <UserDetails
                        sx={{ width: '100%' }}
                        fullName={'Woody Marshall'}
                        fullNameColor={themePalette.palette.text['100']}
                        initials={'Woody Marshall'
                          .split(' ')
                          .map(x => x[0])
                          .join('')}
                        initialsBackgroundColor={themePalette.palette.primary.main}
                        initialsColor={themePalette.palette.white}
                        initialsVariant="SemiBold-12"
                        initailsContainer="32px"
                        fullNameVariant="Medium-14"
                      />
                    </div>
                  </div>
                </Box>
              )}

              <Box style={{ marginTop: '40px', marginBottom: '30px' }}>
                <Typography variant="Medium-16">Select Type of Approval</Typography>

                <div style={{ display: 'flex', gap: 15, marginTop: '5px' }}>
                  <div
                    style={isEsign ? styleClass.selectedSignType : styleClass.nonSelectedSignType}
                    onClick={() => {
                      setIsEsign(true);
                      methods.setValue('signatureImage', null);
                    }}
                  >
                    <PenSignIcon fill="var(--text-400)" style={{ marginBottom: '5px' }} />
                    <br />
                    <Typography variant="Medium-14">Sign on platform</Typography>
                  </div>
                  <div
                    style={!isEsign ? styleClass.selectedSignType : styleClass.nonSelectedSignType}
                    onClick={() => {
                      setIsEsign(false);
                      methods.setValue('signatureText', '');
                      methods.setValue('signatureDrawing', null);
                      methods.setValue('signatureType', 'text');
                    }}
                  >
                    <MailSignIcon fill="var(--text-400)" style={{ marginBottom: '5px' }} />
                    <br />
                    <Typography variant="Medium-14">Via Manual Upload</Typography>
                  </div>
                </div>
              </Box>

              {isEsign ? (
                <Box>
                  {methods.watch('signatureType') === 'text' ? (
                    <>
                      <Box sx={{ width: '100%', marginBottom: '20px' }}>
                        <InputField
                          id="text-signature-input"
                          inputHeight="50px"
                          type="text"
                          label="Type your Signature"
                          placeholder="Enter your name or initials"
                          {...methods.register('signatureText')}
                        />
                      </Box>
                    </>
                  ) : (
                    <Typography
                      variant="Medium-16"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                    >
                      Draw your Signature
                    </Typography>
                  )}

                  <Box
                    sx={{
                      width: '100%',
                      background: '#F4F4F4',
                      height: methods.watch('signatureType') === 'text' ? '130px' : '200px',
                      marginTop: '5px',
                    }}
                  >
                    {methods.watch('signatureType') === 'text' ? (
                      <div className="text-sign-box">
                        <div
                          id="text-signature"
                          key={'text-signature'}
                          style={{
                            fontFamily: 'mysignaturefont',
                            fontSize: '60px',
                            textAlign: 'center',
                            minWidth: '400px',
                            height: '100px',
                          }}
                        >
                          {methods.watch('signatureText')}
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{ height: '170px' }}
                        onMouseUp={() =>
                          methods.setValue('signatureDrawing', signatureRef.current.toDataURL(), {
                            shouldValidate: true,
                          })
                        }
                        onTouchStart={() =>
                          methods.setValue('signatureDrawing', signatureRef.current.toDataURL(), {
                            shouldValidate: true,
                          })
                        }
                      >
                        <ReactSignatureCanvas
                          penColor="black"
                          canvasProps={{
                            width: '600px',
                            height: '150px',
                          }}
                          ref={signatureRef}
                        />
                      </div>
                    )}
                    <div style={{ textAlign: 'center' }}>
                      <Typography
                        variant="Regular-14"
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        color={'#6D7581'}
                      >
                        Your preview here
                      </Typography>
                    </div>
                  </Box>

                  <Box style={{ display: 'flex' }}>
                    <div
                      style={{
                        borderTop: '1px dashed #CDD1D7',
                        width: '100%',
                        height: '1px',
                        margin: 'auto',
                      }}
                    />
                    <Typography
                      variant="Medium-16"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      color={'#6D7581'}
                      sx={{ padding: '25px' }}
                    >
                      Or
                    </Typography>
                    <div
                      style={{
                        borderTop: '1px dashed #CDD1D7',
                        width: '100%',
                        height: '1px',
                        margin: 'auto',
                      }}
                    />
                  </Box>

                  <Box style={{ width: '100%' }}>
                    <Button
                      key="button-1"
                      type="submit"
                      variant="outlined"
                      label={
                        methods.watch('signatureType') === 'text'
                          ? 'Draw your signature'
                          : 'Type your signature'
                      }
                      onClick={() => {
                        methods.reset({
                          signatureType:
                            methods.watch('signatureType') === 'text' ? 'draw' : 'text',
                          signatureText: '',
                        });
                      }}
                      disabled={false}
                      width="100%"
                      startIcon={
                        <img
                          src={
                            methods.watch('signatureType') === 'text' ? quillPenIcon : ballPenIcon
                          }
                          alt="icon"
                        />
                      }
                    />
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    gap: '4px',
                    width: '35%',
                  }}
                >
                  <Typography variant="Medium-16">Upload Signature</Typography>
                  <Box
                    sx={{
                      width: '625px',
                    }}
                  >
                    {!croppedImg &&
                      (methods.watch('signatureImage') ? (
                        <ImgCropper
                          img={methods.watch('signatureImage')}
                          onCroppedImage={handleCroppedImage}
                        />
                      ) : (
                        <DragAndDropFilePicker
                          multi={false}
                          icon={<UploadIcon fill={theme.palette.primary.main} />}
                          headline="Upload Signature"
                          description="(Maximum 5MB file size)"
                          onUpload={e => {
                            console.log(e);
                          }}
                          onRemove={() => {}}
                          acceptedfileExtensions={['png']}
                          draggable={true}
                          maxFileSize={5}
                          {...methods.register('signatureImage')}
                        />
                      ))}

                    {croppedImg && (
                      <Box
                        sx={{
                          width: '100%',
                          background: '#F4F4F4',
                          height: '200px',
                          marginTop: '5px',
                        }}
                      >
                        <div className="text-sign-box">
                          <div
                            key={'signature-img'}
                            style={{
                              fontFamily: 'mysignaturefont',
                              fontSize: '60px',
                              textAlign: 'center',
                              minWidth: '400px',
                              minHeight: '100px',
                              marginTop: '45px',
                            }}
                          >
                            <img
                              id="signature-img"
                              src={croppedImg}
                              style={{ objectFit: 'fill' }}
                            />
                          </div>
                        </div>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
              <Box sx={{ width: '100%', marginTop: '16px' }}>
                <Textarea
                  id="comment"
                  inputHeight="130px"
                  type="text"
                  label="Comment"
                  placeholder="Add comment"
                  {...methods.register('comment')}
                />
              </Box>
            </Box>
          }
          buttons={
            <Button
              key="button-1"
              type="submit"
              variant="contained"
              label="Sign IB"
              onClick={handleSubmitSignIB}
              disabled={
                !methods.watch('signatureDrawing') &&
                !methods.watch('signatureText') &&
                !methods.watch('signatureImage')
              }
              height="40px"
            />
          }
          width="668px"
          backgroundColor="white"
        />
      </FormProvider>
    </>
  );
}
