import { createIcon } from '../createSVGIcon';

const path = (
  <g>
    <path d="M41.1663 47.6673H10.833C9.1091 47.6673 7.4558 46.9825 6.23681 45.7635C5.01783 44.5445 4.33301 42.8912 4.33301 41.1673V6.50065C4.33301 5.92602 4.56128 5.37492 4.96761 4.96859C5.37394 4.56226 5.92504 4.33398 6.49967 4.33398H36.833C37.4076 4.33398 37.9587 4.56226 38.3651 4.96859C38.7714 5.37492 38.9997 5.92602 38.9997 6.50065V32.5007H47.6663V41.1673C47.6663 42.8912 46.9815 44.5445 45.7625 45.7635C44.5436 46.9825 42.8903 47.6673 41.1663 47.6673ZM38.9997 36.834V41.1673C38.9997 41.742 39.2279 42.2931 39.6343 42.6994C40.0406 43.1057 40.5917 43.334 41.1663 43.334C41.741 43.334 42.2921 43.1057 42.6984 42.6994C43.1047 42.2931 43.333 41.742 43.333 41.1673V36.834H38.9997ZM34.6663 43.334V8.66732H8.66634V41.1673C8.66634 41.742 8.89461 42.2931 9.30094 42.6994C9.70727 43.1057 10.2584 43.334 10.833 43.334H34.6663ZM12.9997 15.1673H30.333V19.5007H12.9997V15.1673ZM12.9997 23.834H30.333V28.1673H12.9997V23.834ZM12.9997 32.5007H23.833V36.834H12.9997V32.5007Z" />
  </g>
);

export const InvoiceIcon = createIcon(path, {
  width: 52,
  height: 52,
  viewBox: '0 0 52 52',
  fill: 'none',
});
