import React, { useEffect, useMemo, useState } from 'react';
import { PinIcon } from '../../../libs/svg-icons/icons';
import { Box, Checkbox, Divider, List, ListItem, ListSubheader, Popover } from '@mui/material';
import { styled, alpha, useTheme } from '@mui/material/styles';
import MuiSetFilter from './filters/MuiSetFilter';
import MuiTextFilter from './filters/MuiTextFilter';

const StyledPopover = styled(Popover)(({ theme }) => ({
  elevation: 0,
  '& .MuiPaper-root': {
    backgroundColor: '#FFFFFF',
    width: '250px',
    maxHeight: '50px',
    overflowY: 'auto',
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    padding: '0px 12px',
    color: theme.palette.text['100'],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiList-root': {
      padding: '0 !important',
      '& .MuiListItem-root': {
        cursor: 'pointer',
        paddingTop: 0,
        paddingBottom: 0,
        marginBottom: '4px',
        '&:active': {
          backgroundColor: alpha('#0A275629', theme.palette.action.selectedOpacity),
        },
        '&:hover': {
          backgroundColor: '#0A275629',
          borderRadius: '8px',
        },
      },
    },
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '150px',
      transform: 'rotate(180deg)',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#CDD1D7',
      borderRadius: '6px',
    },
    '@media (max-height: 960px)': {
      '&': {
        height: '35vh',
      },
    },
  },
}));

const MuiMenu = ({
  column = null,
  anchorEl = null,
  onRequestPinColumn = () => null,
  onRequestFilterChange = () => null,
  onClose = () => null,
  filterProps = {},
  filterModel = {},
}) => {
  const theme = useTheme();
  const [isColPinned, setIsColPinned] = useState(false);

  useEffect(() => {
    setIsColPinned(!!column?.pinned);
  }, [column?.pinned]);

  const handleColumnPinClicked = () => {
    if (anchorEl && column?.id) {
      if (isColPinned) {
        onRequestPinColumn(column?.id, false);
      } else {
        onRequestPinColumn(column?.id, true);
      }
    }
    onClose();
  };

  const Filter = useMemo(() => {
    switch (filterProps.type) {
      case 'set-filter':
        return (
          <MuiSetFilter
            column={column}
            filterProps={filterProps}
            filterModel={filterModel}
            onRequestFilterChange={onRequestFilterChange}
          />
        );
      default:
        return (
          <MuiTextFilter
            column={column}
            filterModel={filterModel}
            onRequestFilterChange={onRequestFilterChange}
          />
        );
    }
  }, [filterProps, column]);

  return (
    <StyledPopover
      id="checkbox-menu"
      sx={{ transform: 'translateX(calc(100%-100px))' }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {/* {Filter} */}
      <List sx={{ p: 1, pt: 0, width: 'auto', maxHeight: '370px' }}>
        <ListItem
          sx={{
            backgroundColor: '#FFFFFF',
            position: 'sticky',
            mt: 0.5,
            py: 1,
            px: 0,
            '&:hover': {
              backgroundColor: '#0A275629',
              borderRadius: '8px',
            },
            marginTop: '10px',
          }}
          onClick={handleColumnPinClicked}
        >
          <Box
            sx={{
              height: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '10px',
              cursor: 'pointer',
              padding: '0 28px',
            }}
          >
            <PinIcon fill={theme} />
            {isColPinned ? 'Unpin' : 'Pin'} Column
          </Box>
        </ListItem>
      </List>
    </StyledPopover>
  );
};

export default MuiMenu;
