import { createIcon } from '../createSVGIcon';

const path = (
  <g>
    <path d="M15.9999 16.7817L21.6572 22.4377L19.7705 24.3243L17.3332 21.887V29.3337H14.6665V21.8843L12.2292 24.3243L10.3425 22.4377L15.9999 16.7817ZM15.9999 2.66699C18.2892 2.6671 20.4986 3.50848 22.2081 5.03115C23.9176 6.55382 25.008 8.65161 25.2719 10.9257C26.9309 11.3781 28.3782 12.3991 29.3607 13.8104C30.3433 15.2216 30.7987 16.9332 30.6474 18.6462C30.496 20.3591 29.7475 21.9644 28.5326 23.1814C27.3178 24.3985 25.7138 25.1499 24.0012 25.3043V22.619C24.6148 22.5314 25.2047 22.3224 25.7366 22.0043C26.2686 21.6862 26.7318 21.2653 27.0993 20.7662C27.4668 20.2671 27.7312 19.6998 27.877 19.0974C28.0229 18.4951 28.0473 17.8697 27.9488 17.2578C27.8503 16.6458 27.631 16.0597 27.3035 15.5335C26.976 15.0073 26.547 14.5515 26.0415 14.193C25.536 13.8344 24.9641 13.58 24.3592 13.4449C23.7543 13.3097 23.1286 13.2964 22.5185 13.4057C22.7273 12.4335 22.716 11.427 22.4855 10.4597C22.2549 9.49251 21.8109 8.5891 21.186 7.8157C20.5611 7.04229 19.7711 6.41847 18.8738 5.98992C17.9766 5.56137 16.9949 5.33895 16.0005 5.33895C15.0062 5.33895 14.0245 5.56137 13.1272 5.98992C12.23 6.41847 11.44 7.04229 10.8151 7.8157C10.1901 8.5891 9.74613 9.49251 9.51557 10.4597C9.285 11.427 9.27371 12.4335 9.48253 13.4057C8.26607 13.1772 7.00868 13.4414 5.98698 14.14C4.96529 14.8386 4.26297 15.9145 4.03453 17.131C3.80609 18.3475 4.07024 19.6048 4.76887 20.6265C5.46751 21.6482 6.5434 22.3506 7.75986 22.579L7.99986 22.619V25.3043C6.28713 25.1501 4.68307 24.3989 3.46804 23.1819C2.25301 21.965 1.50429 20.3597 1.35276 18.6468C1.20122 16.9338 1.6565 15.222 2.63902 13.8107C3.62154 12.3993 5.0688 11.3782 6.72786 10.9257C6.99147 8.65149 8.08173 6.55354 9.7913 5.03081C11.5009 3.50809 13.7105 2.66681 15.9999 2.66699Z" />
  </g>
);

export const UploadIcon = createIcon(path, {
  width: 32,
  height: 32,
  viewBox: '0 0 32 32',
  fill: 'none',
});
