import React from 'react';
import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Select,
  MenuItem,
  Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../buttons';

const Dialog = ({
  open = false,
  onClose = () => null,
  icon = null,
  headline = '',
  description = null,
  dropdown = null,
  buttons = null,
  width = '',
  height = '',
  headlineAlignment,
  table = null,
  showDialogActions = true,
  backgroundColor = null,
  cards = null,
  SelectTaskToReset = null,
  fullBodyScroll = false,
  descriptionStyle = {},
  editButton = false,
}) => {
  const theme = useTheme();

  const getTitleAlignmentStyle = () => {
    switch (headlineAlignment) {
      case 'center':
        return { textAlign: 'center' };
      default:
        return {};
    }
  };

  return (
    <MuiDialog
      scroll={fullBodyScroll ? 'body' : 'paper'}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={false}
      PaperProps={{
        style: {
          width: width,
          overflow: table ? 'hidden' : 'visible',
          borderRadius: '8px',
          backgroundColor: backgroundColor ?? theme.palette.white,
          gap: cards ? '24px' : '0px',
        },
      }}
    >
      <DialogTitle
        style={{
          background: 'white',
          borderRadius: '8px 8px 0 0',
          ...getTitleAlignmentStyle(),
        }}
      >
        {!icon && headline && onClose && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        )}

        {icon}

        <Box>{headline}</Box>

        {editButton && (
          <Box
            sx={{ position: 'absolute', right: 48, top: 8, marginRight: '20px', marginTop: '10px' }}
          >
            <Button label="Edit" variant="contained" />
          </Box>
        )}
      </DialogTitle>
      <DialogContent
        className="custom-scroller"
        style={{
          background: 'white',
          width: width,
          height: height,
          display: 'flex',
          flexDirection: cards ? 'row' : 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          padding: showDialogActions ? '0px 20px' : '0px 24px 24px 24px',
          borderRadius: showDialogActions ? 0 : '0 0 8px 8px',
          overflowX: 'hidden',
          gap: cards ? '24px' : '0px',
          ...descriptionStyle,
        }}
      >
        {description && !table && description}
        {table && (
          <Box
            style={{
              width: '100%',
              height: '100%',
              borderBottom: !table ? `1px solid ${theme.palette.text['500']}` : null,
              textAlign: 'left',
            }}
          >
            {table}
          </Box>
        )}
        {dropdown && (
          <Select value={dropdown.value} onChange={dropdown.onChange}>
            {dropdown.options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
        {cards &&
          cards.map((card, index) => (
            <Box key={index} style={{ maxWidth: 300 }}>
              {card}
            </Box>
          ))}
        {SelectTaskToReset}
      </DialogContent>
      {showDialogActions && (
        <DialogActions
          style={{
            display: 'flex',
            padding: '20px',
            alignItems: 'flex-start',
            gap: '20px',
            borderRadius: '0 0 8px 8px',
            background: backgroundColor ?? theme.palette.background,
          }}
        >
          {buttons}
        </DialogActions>
      )}
    </MuiDialog>
  );
};

export default Dialog;
