import { createTheme } from '@mui/material';
import { themePalette } from './color';

export const themeTextarea = createTheme(themePalette, {
  components: {
    MuiTextarea: {
      styleOverrides: {
        root: {
          width: '100%',
          fontFamily: 'Poppins',
          borderRadius: '8px',
          border: `1px solid ${themePalette.palette.text[500]}`,
          backgroundColor: themePalette.palette.white,
          '&:hover': {
            borderColor: themePalette.palette.primary.main,
          },
          '& fieldset': {
            border: 'none',
          },
          '& textarea': {
            height: 'inherit',
            color: themePalette.palette.text[100],
            '&::placeholder': {
              color: themePalette.palette.text[300],
              fontSize: '14px',
              lineHeight: '23px',
              fontWeight: 500,
            },
          },
          '&:hover fieldset': {
            border: 'none',
          },
          '&.Mui-focused fieldset': {
            border: 'none',
          },
          fontSize: '14px',
          lineHeight: '23px',
          fontWeight: 500,
        },
      },
    },
  },
});
