import { createIcon } from '../createSVGIcon';

const path = (
  <g>
    <path d="M9.875 6.25H14.25L9 11.5L3.75 6.25H8.125V0.125H9.875V6.25ZM2 14.125H16V8H17.75V15C17.75 15.2321 17.6578 15.4546 17.4937 15.6187C17.3296 15.7828 17.1071 15.875 16.875 15.875H1.125C0.892936 15.875 0.670376 15.7828 0.506282 15.6187C0.342187 15.4546 0.25 15.2321 0.25 15V8H2V14.125Z" />
  </g>
);

export const DownloadIcon = createIcon(path, {
  width: 18,
  height: 16,
  viewBox: '0 0 18 16',
  fill: 'none',
});
