import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './main';

import { ThemeProvider } from '@mui/material/styles';
import theme from './constants/theme';

import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import OIDCAuthProvider from './pages/auth/OIDC/OIDCAuthProvider';
import ToastProvider from './components/shared/toast-provider';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <OIDCAuthProvider>
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ToastProvider>
            <App />
          </ToastProvider>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </OIDCAuthProvider>,
);
