import axiosInstance from './api';
import { ApiURLConstants } from '../constants/api-url-constants';

const API_BASE_URL = process.env.REACT_APP_FILE_UPLOAD_API_BASE_URL;
const API = axiosInstance;

export const downloadFile = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.DOWNLOAD_FILE(payload)}`;
  const response = await API.get(uri, payload);
  return response;
};

export const getSignURL = async link => {
  const uri = `${API_BASE_URL}${ApiURLConstants.GET_SIGN_URL(link)}`;
  const headers = {
    'Content-Type': 'application/json',
  };
  const response = await API.get(uri, { headers });
  return response;
};
