import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { CircularProgress, LinearProgress, linearProgressClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.text['500'],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.success.main,
  },
}));

const Progress = ({ variant = 'circular', current = null, total = 100 }) => {
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    if (current && total > 0) {
      setProgress((current / total) * 100);
    }
  }, [current, total]);

  if (variant === 'linear')
    return (
      <Box sx={{ width: '100%' }}>
        <BorderLinearProgress
          variant={current ? 'determinate' : 'indeterminate'}
          value={progress}
        />
      </Box>
    );
  else
    return (
      <CircularProgress variant={current ? 'indeterminate' : 'determinate'} value={progress} />
    );
};

export default Progress;
