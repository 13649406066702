import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './pdf.css';
import { useEffect, useState } from 'react';
import { CircularProgress, IconButton } from '@mui/material';
import { ZoomIn, ZoomOut, ArrowForward, ArrowBack } from '@mui/icons-material';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

export const PDFViewer = ({ link, defaultScale = 0.7, height = '530px' }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(defaultScale); // For zooming

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const goToPrevPage = () => setPageNumber(prevPageNumber => Math.max(prevPageNumber - 1, 1));
  const goToNextPage = () =>
    setPageNumber(prevPageNumber => Math.min(prevPageNumber + 1, numPages));

  const zoomIn = () => {
    setScale(prevScale => prevScale + 0.05);
  };
  const zoomOut = () => setScale(prevScale => Math.max(prevScale - 0.05, 0.5));

  useEffect(() => {
    setScale(defaultScale);
  }, [defaultScale]);

  return (
    <div className="pdf-container">
      <div className="controls">
        <div style={{ flex: '1', fontFamily: 'Poppins', fontSize: '15px', lineHeight: '40px' }}>
          Page {pageNumber} of {numPages}
        </div>
        <IconButton onClick={goToPrevPage} disabled={pageNumber <= 1}>
          <ArrowBack />
        </IconButton>
        <IconButton onClick={goToNextPage} disabled={pageNumber >= numPages}>
          <ArrowForward />
        </IconButton>
        <IconButton onClick={zoomOut} disabled={scale === 0.5}>
          <ZoomOut fontSize="20px" />
        </IconButton>
        <IconButton onClick={zoomIn}>
          <ZoomIn />
        </IconButton>
      </div>
      <div className="pdf-viewer" style={{ height: height }}>
        <Document
          file={link}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={() => (
            <div
              style={{ width: '100%', textAlign: 'center', marginTop: '150px', height: '300px' }}
            >
              <CircularProgress color="inherit" />
            </div>
          )}
        >
          <Page pageNumber={pageNumber} scale={scale} />
        </Document>
      </div>
    </div>
  );
};
