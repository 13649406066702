import Button from '../../../../components/shared/buttons';
import Chip from '../../../../components/shared/chip';
import UploadBastDocument from './UploadBastDocument';
import ViewInvoiceDailog from './ViewInvoiceDailog';
import ViewPoClientDialog from './ViewPoClientDialog';

export const columns = [
  {
    id: 'srno',
    headerName: 'Sr. No.',
    field: 'srno',
    minWidth: 90,
    maxWidth: 90,
    cellStyle: paraps => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
  },
  {
    id: 'poclientnumber',
    headerName: 'PO Client Number',
    field: 'poclientnumber',
    minWidth: 226,
    maxWidth: 226,
  },
  {
    id: 'poclient',
    headerName: 'PO Client',
    field: 'poclient',
    minWidth: 226,
    maxWidth: 226,
    cellRenderer: params => {
      return <ViewPoClientDialog />;
    },
  },
  {
    id: 'poamount',
    headerName: 'PO Amount',
    field: 'poamount',
    minWidth: 226,
    maxWidth: 226,
  },
  {
    id: 'status',
    headerName: 'Status',
    field: 'status',
    minWidth: 226,
    maxWidth: 226,
    cellRenderer: params => {
      if (params.value) {
        return (
          <Chip label={params.value} styles={{ margin: 'auto' }} status="success" height="30px" />
        );
      } else {
        return <Chip label="Draft" styles={{ margin: 'auto' }} />;
      }
    },
  },
  {
    id: 'agency',
    headerName: 'Agency',
    field: 'agency',
    minWidth: 226,
    maxWidth: 226,
  },
  {
    id: 'client',
    headerName: 'Client',
    field: 'client',
    minWidth: 226,
    maxWidth: 226,
  },
  {
    id: 'productname',
    headerName: 'Product Name',
    field: 'productname',
    minWidth: 226,
    maxWidth: 226,
  },
  {
    id: 'campaigntitle',
    headerName: 'Campaign Title',
    field: 'campaigntitle',
    minWidth: 226,
    maxWidth: 226,
  },
  {
    id: 'type',
    headerName: 'Type',
    field: 'type',
    minWidth: 226,
    maxWidth: 226,
  },
  {
    id: 'media',
    headerName: 'Media',
    field: 'media',
    minWidth: 226,
    maxWidth: 226,
  },
  {
    id: 'month',
    headerName: 'Month',
    field: 'month',
    minWidth: 226,
    maxWidth: 226,
  },
  {
    id: 'year',
    headerName: 'Year',
    field: 'year',
    minWidth: 226,
    maxWidth: 226,
  },
];

export const data = [
  {
    id: 1,
    srno: '1',
    poclientnumber: 'PO567890',
    poclient: 'View',
    poamount: '555,000,000 IDR',
    status: 'PO Received',
    agency: 'Mindshare',
    client: 'Unilever',
    productname: 'Lifebuoy Shampoo',
    campaigntitle: 'Sun',
    type: 'Video',
    media: 'Youtube',
    month: 'August',
    year: '2023',
  },
];

export const invoiceColumns = [
  {
    id: 'invoicenumber',
    headerName: 'Invoice Number',
    field: 'invoicenumber',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'invoice',
    headerName: 'Invoice',
    field: 'invoice',
    minWidth: 200,
    maxWidth: 200,
    cellRenderer: params => {
      return <ViewInvoiceDailog />;
    },
  },
  {
    id: 'bastdocument',
    headerName: 'BAST Document',
    field: 'bastdocument',
    minWidth: 200,
    maxWidth: 200,
    cellRenderer: params => {
      return <UploadBastDocument />;
    },
  },
  {
    id: 'clientpo',
    headerName: 'Client PO',
    field: 'clientpo',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'buyingbriefgroupid',
    headerName: 'Buying Brief Group ID',
    field: 'buyingbriefgroupid',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'buyingbriefnumber',
    headerName: 'Buying Brief Number',
    field: 'buyingbriefnumber',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'issuedate',
    headerName: 'Issue date',
    field: 'issuedate',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'currency',
    headerName: 'Currency',
    field: 'currency',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'ponumber',
    headerName: 'PO Number',
    field: 'ponumber',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'poorderissuedate',
    headerName: 'PO Order Issue Date',
    field: 'poorderissuedate',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'itemid',
    headerName: 'Item ID',
    field: 'itemid',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'quantity',
    headerName: 'Quantity',
    field: 'quantity',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'unit',
    headerName: 'Unit',
    field: 'unit',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'priceperunit',
    headerName: 'Price Per Unit',
    field: 'priceperunit',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'tax',
    headerName: 'Tax %',
    field: 'tax',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'totalexculsivetax',
    headerName: 'Total Exculsive Tax',
    field: 'totalexculsivetax',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'taxnumber',
    headerName: 'Tax Number',
    field: 'taxnumber',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'subtotalexculsivetaxes',
    headerName: 'Subtotal Exculsive Taxes',
    field: 'subtotalexculsivetaxes',
    minWidth: 250,
    maxWidth: 250,
  },

  {
    id: 'idvat',
    headerName: 'ID VAT Standard 11%',
    field: 'idvat',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'totalidr',
    headerName: 'Total IDR',
    field: 'totalidr',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    id: 'comments',
    headerName: 'Comments',
    field: 'comments',
    minWidth: 400,
    maxWidth: 400,
  },
  {
    id: 'taxx',
    headerName: 'Tax',
    field: 'taxx',
    minWidth: 200,
    maxWidth: 200,
    cellRenderer: params => {
      return (
        <Button label={params.value} styles={{ margin: 'auto' }} variant="outlined" height="30px" />
      );
    },
  },
  {
    id: 'logproof',
    headerName: 'Log Proof/Proof of Airing',
    field: 'logproof',
    minWidth: 200,
    maxWidth: 200,
    cellRenderer: params => {
      return (
        <Button label={params.value} styles={{ margin: 'auto' }} variant="outlined" height="30px" />
      );
    },
  },
  {
    id: 'otherdocumnets',
    headerName: 'Other Documents',
    field: 'otherdocumnets',
    minWidth: 200,
    maxWidth: 200,
    cellRenderer: params => {
      return (
        <Button label={params.value} styles={{ margin: 'auto' }} variant="outlined" height="30px" />
      );
    },
  },
];

export const invoiceData = [
  {
    id: 1,
    invoicenumber: '0012973870284722',
    invoice: 'View',
    bastdocument: 'Upload',
    clientpo: '01-08-2023',
    buyingbriefgroupid: 'BB01',
    buyingbriefnumber: 'BB347854907',
    issuedate: '01-08-2023',
    currency: 'IDR',
    ponumber: 'PO567890',
    poorderissuedate: '29-09-2023',
    itemid: '012345',
    quantity: '100,000,000',
    unit: 'pcs',
    priceperunit: '1',
    tax: '11%',
    totalexculsivetax: '7,480,000',
    taxnumber: '12345678901234567',
    subtotalexculsivetaxes: '68,000,000.00',
    idvat: '7,480,000.00 IDR',
    totalidr: '7,480,000.00 IDR',
    comments: 'Nulla  faucibus utrics faucibus utrics',
    taxx: 'View',
    logproof: 'View',
    otherdocumnets: 'View',
  },
  {
    id: 2,
    invoicenumber: '0012973870284722',
    invoice: 'View',
    bastdocument: 'Upload',
    clientpo: '01-08-2023',
    buyingbriefgroupid: 'BB01',
    buyingbriefnumber: 'BB347854907',
    issuedate: '01-08-2023',
    currency: 'IDR',
    ponumber: 'PO567890',
    poorderissuedate: '29-09-2023',
    itemid: '012345',
    quantity: '100,000,000',
    unit: 'pcs',
    priceperunit: '1',
    tax: '11%',
    totalexculsivetax: '7,480,000',
    taxnumber: '12345678901234567',
    subtotalexculsivetaxes: '68,000,000.00',
    idvat: '7,480,000.00 IDR',
    totalidr: '7,480,000.00 IDR',
    comments: 'Nulla  faucibus utrics faucibus utrics',
    taxx: 'View',
    logproof: 'View',
    otherdocumnets: 'View',
  },
  {
    id: 3,
    invoicenumber: '0012973870284722',
    invoice: 'View',
    bastdocument: 'Upload',
    clientpo: '01-08-2023',
    buyingbriefgroupid: 'BB01',
    buyingbriefnumber: 'BB347854907',
    issuedate: '01-08-2023',
    currency: 'IDR',
    ponumber: 'PO567890',
    poorderissuedate: '29-09-2023',
    itemid: '012345',
    quantity: '100,000,000',
    unit: 'pcs',
    priceperunit: '1',
    tax: '11%',
    totalexculsivetax: '7,480,000',
    taxnumber: '12345678901234567',
    subtotalexculsivetaxes: '68,000,000.00',
    idvat: '7,480,000.00 IDR',
    totalidr: '7,480,000.00 IDR',
    comments: 'Nulla  faucibus utrics faucibus utrics',
    taxx: 'View',
    logproof: 'View',
    otherdocumnets: 'View',
  },
  {
    id: 4,
    invoicenumber: '0012973870284722',
    invoice: 'View',
    bastdocument: 'Upload',
    clientpo: '01-08-2023',
    buyingbriefgroupid: 'BB01',
    buyingbriefnumber: 'BB347854907',
    issuedate: '01-08-2023',
    currency: 'IDR',
    ponumber: 'PO567890',
    poorderissuedate: '29-09-2023',
    itemid: '012345',
    quantity: '100,000,000',
    unit: 'pcs',
    priceperunit: '1',
    tax: '11%',
    totalexculsivetax: '7,480,000',
    taxnumber: '12345678901234567',
    subtotalexculsivetaxes: '68,000,000.00',
    idvat: '7,480,000.00 IDR',
    totalidr: '7,480,000.00 IDR',
    comments: 'Nulla  faucibus utrics faucibus utrics',
    taxx: 'View',
    logproof: 'View',
    otherdocumnets: 'View',
  },
];
