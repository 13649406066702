import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Comments from '../../../../components/shared/comments';
import BackdropOverlay from '../../../../components/shared/backdropOverlay';
import { AppConstants } from '../../../../constants/app-constants';
import Loader from '../../../../components/shared/loader';
import Card from '../../../../components/shared/card';
import Header from '../../../../components/shared/header';
import { Box, Typography, useTheme } from '@mui/material';
import Chip from '../../../../components/shared/chip';
import ViewInvoiceEditView from './ViewInvoiceEditView';
import Button from '../../../../components/shared/buttons';
import { EditIcon } from '../../../../libs/svg-icons/icons';
import { Padding } from '@mui/icons-material';

export default function VendorInvoiceDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [readOnly, setReadOnly] = useState(true);

  const pageAccess = useMemo(() => {
    return false;
  }, []);

  const breadcrumpString = useMemo(() => {
    if (readOnly) {
      return `Invoice: ${'0012973870284722'}`;
    } else {
      return 'Create New Invoice';
    }
  }, [params, readOnly]);

  return (
    <>
      <BackdropOverlay
        open={pageAccess}
        pageWarning={AppConstants.PAGE_ACCESS_ERROR}
        buttonLabel="Redirect To Task"
        clickAction={() => {
          navigate(`/app/vendor-invoice`);
        }}
      />

      <Loader isLoading={isLoading} />

      <Card sx={{ alignItems: 'flex-start' }}>
        <Header
          breadcrumbs={[{ name: breadcrumpString }]}
          backHref={`/app/vendor-invoice`}
          rightActions={
            <Box sx={{ display: 'flex', gap: '20px' }}>
              <Button
                width="40px"
                height="40px"
                sx={{
                  minWidth: '40px',
                  Padding: '5px',
                }}
                variant="outlined"
                startIcon={
                  <Box
                    component={'span'}
                    sx={{
                      width: '40px',
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <EditIcon fill={theme.palette.primary.main} />
                  </Box>
                }
                onClick={() => setReadOnly(!readOnly)}
              />
              <Chip status={''} label={<Typography variant="Medium-14">Draft</Typography>} />
            </Box>
          }
        />

        <ViewInvoiceEditView readOnly={readOnly} />

        <Comments entityId={params?.id} entityType="PROJECT_SUB_TASK" />
        {/* INVOICE_DOCUMENT */}
      </Card>
    </>
  );
}
