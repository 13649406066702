import React, { useEffect, useRef, useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Button from '../buttons';
import { Divider } from '@mui/material';

const ImgCropper = ({ onCroppedImage, img }) => {
  const cropperRef = useRef(null);
  const [image, setImage] = useState(null);

  const onChange = e => {
    let reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(e);
  };

  const cropImage = () => {
    if (typeof cropperRef.current.cropper !== 'undefined') {
      cropperRef.current.cropper.getCroppedCanvas().toBlob(blob => {
        const croppedImageFile = new File([blob], 'croppedImage.png', { type: 'image/png' });
        onCroppedImage(croppedImageFile);
      }, 'image/png');
    }
  };

  useEffect(() => {
    onChange(img);
  }, [img]);

  return (
    <div>
      {image && (
        <div>
          <Cropper
            src={image}
            style={{ height: 400, width: '100%' }}
            initialAspectRatio={NaN}
            aspectRatio={NaN}
            guides={false}
            ref={cropperRef}
          />
          <div style={{ width: '100%', textAlign: 'center', marginTop: '10px' }}>
            <Button
              key="crop-btn"
              type="submit"
              variant="outlined"
              label="Crop Image"
              onClick={cropImage}
              height="30px"
            />
            <Divider width="100%" style={{ marginTop: '10px' }} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImgCropper;
