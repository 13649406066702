import RouteHandler from '../../../components/router/handler';
import NotificationsList from './NotificationsList';

const NotificationsRoutes = [
  {
    index: true,
    path: '',
    component: <NotificationsList />,
  },
];

const NotificationsRouter = () => {
  return <RouteHandler routes={NotificationsRoutes} />;
};

export default NotificationsRouter;
