import { Box, Typography } from '@mui/material';
import React from 'react';
import UserDetails from '../user';
import { themePalette } from '../../../constants/theme/color';
import Chip from '../chip';
import { AppConstants } from '../../../constants/app-constants';
import { useTheme } from '@mui/material/styles';
import { FileUploadIcon } from '../../../libs/svg-icons/icons';
import {
  convertURLToArrayBlob,
  downloadFileFromBlob,
  getFileType,
} from '../../../libs/document/file';
import { useEnqueueSnackbar } from '../toast-provider/toastHook';
import { getSignURL } from '../../../services/file-download-service';
import { formatDate } from '../../../libs/date/format';
import { capitalize } from '../../../utils/string';

const approvalStatusColor = {
  'APPROVED': 'success',
  'PENDING': 'warning',
  'REJECTED': 'error',
};

const ViewPoExceptionRequestToClient = ({
  requestStatus,
  teamDetails,
  exceptionDetails,
  clientConfirmationUrl,
  exceptionRequestDate,
}) => {
  const theme = useTheme();

  const enqueueSnackbar = useEnqueueSnackbar();

  const requestDetails =
    requestStatus === AppConstants.PO_CLIENT_EXCEPTION_REQUEST_APPROVED
      ? `Approved on ${formatDate(exceptionRequestDate, 'dd/MM/yyyy')} at ${formatDate(exceptionRequestDate, 'hh:mm a')}`
      : `Requested on ${formatDate(exceptionRequestDate, 'dd/MM/yyyy')} at ${formatDate(exceptionRequestDate, 'hh:mm a')}`;

  const handleClientConfirmationDocumentClicked = async () => {
    if (clientConfirmationUrl) {
      const fileName = 'Client Confirmation';
      getSignURL(clientConfirmationUrl)
        .then(response => {
          const { data = null } = response?.data || {};
          if (data?.url)
            convertURLToArrayBlob(data?.url).then(fileBlob => {
              if (fileBlob) {
                downloadFileFromBlob(fileBlob, `${fileName}.${getFileType(clientConfirmationUrl)}`);
              }
            });
        })
        .catch(error => {
          enqueueSnackbar(AppConstants.SOMETHING_WENT_WRONG, 'error');
        });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        gap: '24px',
        overflowY: 'auto',
        WebkitOverflowScrolling: 'touch',
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#FFFFFF',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#CDD1D7',
          borderRadius: '4px',
        },
        paddingRight: '13px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
      >
        <Typography variant="Medium-16">Leaders</Typography>
        <Typography
          variant="Regular-14"
          color={
            requestStatus === AppConstants.PO_CLIENT_EXCEPTION_REQUEST_APPROVED
              ? theme.palette.success.main
              : theme.palette.text['100']
          }
        >
          {requestDetails}
        </Typography>

        <Box
          sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', gap: '16px' }}
        >
          {teamDetails?.map((detail, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'row',
                width: '616px',
              }}
            >
              <Box
                sx={{
                  height: '58px',
                  width: '432px',
                  flexGrow: 1,
                  border: '1px solid #CDD1D7',
                  padding: '13px 16px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <UserDetails
                  sx={{ width: '100%' }}
                  fullName={detail.value}
                  fullNameColor={themePalette.palette.text['100']}
                  initials={detail.value
                    .split(' ')
                    .map(x => x[0])
                    .join('')}
                  initialsBackgroundColor={themePalette.palette.primary.main}
                  initialsColor={themePalette.palette.white}
                  initialsVariant="SemiBold-12"
                  initailsContainer="32px"
                  fullNameVariant="Medium-14"
                />
                <Box>
                  <Chip
                    height="30px"
                    label={capitalize(detail.status)}
                    status={approvalStatusColor[detail.status]}
                  />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          padding: '24px',
          backgroundColor: theme.palette.background,
          width: '620px',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          {exceptionDetails?.map((detail, index) => (
            <Box
              key={index}
              sx={{
                width: '100%',
                display: 'flex',
                gap: detail.direction === 'column' ? '8px' : '24px',
                alignItems: detail.direction === 'column' ? 'flex-start' : 'center',
                justifyContent: detail.direction === 'column' ? 'center' : 'flex-start',
                flexDirection: detail.direction ?? 'row',
              }}
            >
              <Box
                sx={{
                  width: detail.direction === 'column' ? '100%' : 'calc(60% - 24px)',
                  display: 'flex',
                }}
              >
                <Typography variant="Medium-16" align="left">
                  {detail.label}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: detail.direction === 'column' ? '100%' : 'calc(40% - 24px)',
                  display: 'flex',
                }}
              >
                <Typography
                  variant={detail?.variant ?? 'Medium-16'}
                  align="left"
                  sx={{ wordWrap: 'break-word', wordBreak: 'break-all' }}
                >
                  {detail.value}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={handleClientConfirmationDocumentClicked}
      >
        <Box>
          <FileUploadIcon fill={theme.palette.text['100']} />
        </Box>
        <Box>
          <Typography>Client Confirmation.pdf</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewPoExceptionRequestToClient;
