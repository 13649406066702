import { createIcon } from '../createSVGIcon';

const path = (
  <g>
    <path d="M11.46 17.083C11.2909 16.3716 11.2909 15.6304 11.46 14.919L10.1373 14.155L11.4707 11.8457L12.7933 12.6097C13.3242 12.1068 13.9659 11.736 14.6667 11.527V10.0003H17.3333V11.527C18.0427 11.7377 18.6827 12.1137 19.2067 12.6097L20.5293 11.8457L21.8627 14.155L20.54 14.919C20.7088 15.63 20.7088 16.3707 20.54 17.0817L21.8627 17.8457L20.5293 20.155L19.2067 19.391C18.6758 19.8938 18.0341 20.2647 17.3333 20.4737V22.0003H14.6667V20.4737C13.9659 20.2647 13.3242 19.8938 12.7933 19.391L11.4707 20.155L10.1373 17.8457L11.46 17.083ZM16 18.0003C16.5304 18.0003 17.0391 17.7896 17.4142 17.4145C17.7893 17.0395 18 16.5308 18 16.0003C18 15.4699 17.7893 14.9612 17.4142 14.5861C17.0391 14.211 16.5304 14.0003 16 14.0003C15.4696 14.0003 14.9609 14.211 14.5858 14.5861C14.2107 14.9612 14 15.4699 14 16.0003C14 16.5308 14.2107 17.0395 14.5858 17.4145C14.9609 17.7896 15.4696 18.0003 16 18.0003ZM20 5.33366H6.66667V26.667H25.3333V10.667H20V5.33366ZM4 3.98966C4 3.25899 4.596 2.66699 5.332 2.66699H21.3333L28 9.33366V27.991C28.0012 28.1661 27.968 28.3397 27.9021 28.502C27.8362 28.6642 27.739 28.8119 27.6161 28.9365C27.4931 29.0612 27.3468 29.1605 27.1855 29.2286C27.0242 29.2967 26.8511 29.3324 26.676 29.3337H5.324C4.97384 29.3312 4.63869 29.1911 4.39096 28.9436C4.14322 28.6962 4.00279 28.3612 4 28.011V3.98966Z" />
  </g>
);

export const TemplateIcon = createIcon(path, {
  width: 32,
  height: 32,
  viewBox: '0 0 32 32',
  fill: 'none',
});
