import RouteHandler from '../../components/router/handler';
import Login from './Login';
import Callback from './OIDC/Callback';

const AuthRoutes = [
  {
    path: '/login',
    component: <Login />,
  },
  {
    path: '/callback',
    component: <Callback />,
  },
];

const AuthRouter = () => {
  return <RouteHandler routes={AuthRoutes} />;
};

export default AuthRouter;
