import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FixedSizeList as List } from 'react-window';
import axios from 'axios'; // We'll use axios for API calls
import Button from '../buttons';
import Input from '../form/Input/input';
import { isEqual } from 'lodash';

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-family: Poppins;
`;

const FilterTypesList = styled.div`
  flex: 1;
  margin-right: 20px;
  padding-right: 15px;
  border-right: 1px solid #cdd1d7;
`;

const FilterTypeButton = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: ${({ active }) => (active ? '#0A2756' : '#fff')};
  color: ${({ active }) => (active ? 'white' : 'black')};
  border-radius: 6px;
  text-align: left;

  &:hover {
    background-color: #d3d9f2;
    color: black;
  }
`;

const FilterOptionsSection = styled.div`
  flex: 2;
`;

const FilterTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const SearchBox = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

// Styled checkbox
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  input {
    opacity: 0;
    position: absolute;
  }

  label {
    display: inline-block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: #333;
  }

  label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 2px solid #0a2756;
    border-radius: 3px;
    background-color: white;
    transition: all 0.2s;
  }

  input:checked + label:before {
    background-color: #0a2756;
    border-color: #0a2756;
  }

  label:after {
    content: '';
    position: absolute;
    left: 6px;
    top: 2px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    opacity: 0;
    transform: rotate(45deg);
    transition: opacity 0.2s;
  }

  input:checked + label:after {
    opacity: 1;
  }
`;

// Virtualized List Item
const CheckboxListItem = ({ index, style, data }) => {
  const { reorderedOptions, selectedData, handleCheckboxChange } = data;
  const item = reorderedOptions[index];

  return (
    <div style={style}>
      <CheckboxContainer>
        <input
          type="checkbox"
          id={`checkbox-${item}`}
          checked={selectedData?.includes(item)}
          onChange={() => handleCheckboxChange(item)}
        />
        <label htmlFor={`checkbox-${item}`}>{item}</label>
      </CheckboxContainer>
    </div>
  );
};

const FilterOptionListComponent = ({
  options,
  selectedFilter,
  selectedData,
  isApiSearch = false,
  apiFetchFunction = null,
  updateSelectionData = () => {},
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const reorderedOptions = [
    ...selectedData,
    ...filteredOptions?.filter(item => !selectedData.includes(item)),
  ];

  const handleCheckboxChange = item => {
    if (selectedData.includes(item)) {
      updateSelectionData(selectedData.filter(m => m !== item));
    } else {
      updateSelectionData([...selectedData, item]);
    }
  };

  useEffect(() => {
    if (isApiSearch && apiFetchFunction) {
      setLoading(true);
      apiFetchFunction(searchTerm)
        .then(response => {
          setFilteredOptions(selectedFilter?.formatResponse(response.data) || []);
          setLoading(false);
        })
        .catch(error => {
          setFilteredOptions([]);
          setLoading(false);
        });
    } else {
      const data = options?.filter(x => x.toLowerCase().includes(searchTerm.toLowerCase()));
      setFilteredOptions(data);
    }
  }, [searchTerm, selectedFilter]);

  return (
    <FilterOptionsSection>
      <Input
        id="media-plan-list-search"
        inputHeight="40px"
        type="text"
        placeholder={`Search by ${selectedFilter?.key === 'month' ? 'Invoice Month' : 'Invoice Year'}...`}
        searchable
        onBlur={event => {
          setSearchTerm(event.target.value);
        }}
        onChange={event => {
          setSearchTerm(event.target.value);
        }}
        onSearchCloseClick={() => setSearchTerm('')}
      />
      {loading ? (
        <div style={{ paddingTop: '50px', minHeight: '440px' }}>
          <p>Loading...</p>
        </div>
      ) : (
        <div style={{ paddingTop: '15px', paddingLeft: '5px', minHeight: '440px' }}>
          {reorderedOptions.length ? (
            <List
              height={440} // Height of the virtualized list (e.g., 400px)
              itemCount={reorderedOptions.length} // Number of items
              itemSize={50} // Size of each item in pixels
              width={'100%'} // Width of the list
              itemData={{
                reorderedOptions,
                selectedData,
                handleCheckboxChange,
              }}
            >
              {CheckboxListItem}
            </List>
          ) : (
            <p>No Options Found...</p>
          )}
        </div>
      )}
    </FilterOptionsSection>
  );
};

const FilterListing = ({
  filterState = {},
  filterData = [],
  setFilterState = () => {},
  handleFilterClose = () => {},
}) => {
  const [selectedFilter, setSelectedFilter] = useState({}); // 'month' or 'year'
  const [thisFilterState, setThisFilterState] = useState(filterState);

  const resetFilters = () => {
    let t = {};
    filterData.forEach(x => {
      t[x.key] = [];
    });

    thisFilterState(t);
    setFilterState(t);
  };

  const applyFilters = () => {
    setFilterState({ ...thisFilterState });
    handleFilterClose();
  };

  useEffect(() => {
    setSelectedFilter(filterData[0]);
  }, [filterData]);

  const updateSelectionData = (data = []) => {
    let tempFilterState = thisFilterState;
    tempFilterState[selectedFilter.key] = data;
    setThisFilterState({ ...tempFilterState });
  };

  const disableApplyFilter = useMemo(() => {
    let t = isEqual(filterState, thisFilterState);
  }, []);

  const sampleData = Array.from({ length: 10000 }, (_, i) => `Year ${i + 1}`);

  return (
    <>
      <FilterContainer>
        {/* Left: Filter Types (Month/Year) */}
        <FilterTypesList>
          {filterData.map(ele => {
            return (
              <FilterTypeButton
                active={selectedFilter.key === ele.key}
                onClick={() => setSelectedFilter(ele)}
              >
                {ele.title} ({thisFilterState[ele?.key]?.length})
              </FilterTypeButton>
            );
          })}
        </FilterTypesList>

        {/* Right: Filter Options */}
        <FilterOptionListComponent
          options={selectedFilter?.options || []}
          selectedFilter={selectedFilter}
          selectedData={selectedFilter?.key ? thisFilterState[selectedFilter?.key] : []}
          isApiSearch={selectedFilter?.isApiSearch}
          apiFetchFunction={selectedFilter?.apiFetchFunction}
          updateSelectionData={updateSelectionData}
        />
      </FilterContainer>
      <ButtonContainer>
        <Button
          variant="outlined"
          label="Reset Filters"
          onClick={resetFilters}
          sx={{ marginTop: '-5px', marginRight: '15px', marginLeft: 'auto' }}
        />
        <Button
          variant="contained"
          label="Apply Filters"
          onClick={applyFilters}
          sx={{ marginTop: '-5px', marginRight: '0' }}
        />
      </ButtonContainer>
    </>
  );
};

// Example of an API fetch function

export default FilterListing;
