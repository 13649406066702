import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { ClipboardIcon } from '../../../libs/svg-icons/icons';

const CellWithClipboard = ({ showClipboard = false, valueFormatted, value, children }) => {
  const copyText = useMemo(() => {
    if (valueFormatted) return valueFormatted;
    if (value) return value;
    return '';
  }, [value, valueFormatted]);

  const writeClipboardText = () => {
    if (copyText) {
      navigator.clipboard.writeText(copyText).then(() => console.log('text copied:', copyText));
    }
  };
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',

        '& .clipboard-icon': {
          position: 'absolute',
          right: '-28px',
          top: '50%',
          transform: 'translateY(-50%)',
          opacity: 0,
          cursor: 'pointer',
        },

        '&:hover': {
          width: showClipboard ? 'calc(100% - 28px)' : '100%',
        },

        '&:hover .clipboard-icon': {
          opacity: 1,
        },
      }}
    >
      {children}
      {showClipboard && (
        <ClipboardIcon
          className="clipboard-icon"
          fill="var(--text-300)"
          width="14px"
          height="14px"
          onClick={writeClipboardText}
        />
      )}
    </Box>
  );
};

export default CellWithClipboard;
