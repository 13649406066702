import React from 'react';
import { Navigate } from 'react-router-dom';
import { isLoggedInSelector } from '../../redux/slice';
import { useAppSelector } from '../../redux/hooks';

const ProtectedRoute = ({ children }) => {
  const isLoggedIn = useAppSelector(isLoggedInSelector);

  if (!isLoggedIn) {
    return <Navigate to="/auth/login" replace />;
  }
  return <div>{children}</div>;
};

export default ProtectedRoute;
