import axiosInstance from './api';
import { ApiURLConstants } from '../constants/api-url-constants';

const globalHeaders = {
  'Content-Type': 'application/json',
};

const API_BASE_URL = process.env.REACT_APP_MEDIA_API_BASE_URL;
const API_USER_BASE_API = process.env.REACT_APP_USER_API_BASE_URL;
const API = axiosInstance;

export const listProject = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.GET_PROJECT_LIST}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const createProject = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.CREATE_PROJECT}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const getProjectDetails = async id => {
  const uri = `${API_BASE_URL}${ApiURLConstants.GET_PROJECT_DETAILS(id)}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const getProjectTaskDetails = async id => {
  const uri = `${API_BASE_URL}${ApiURLConstants.GET_PROJECT_TASK_DETAILS(id)}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const getProjectSubTaskDetails = async id => {
  const uri = `${API_BASE_URL}${ApiURLConstants.GET_PROJECT_SUB_TASK_DETAILS(id)}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const getProjectStatusList = async type => {
  const uri = `${API_BASE_URL}${ApiURLConstants.GET_PROJECT_STATUS_LIST(type)}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const updateProjectTaskRemark = async (params, payload) => {
  const uri = `${API_BASE_URL}${ApiURLConstants.UPDATE_PROJECT_REMARK(params)}`;
  const headers = { ...globalHeaders };
  const response = await API.put(uri, payload, {
    headers,
  });
  return response;
};

export const updateProject = async projectId => {
  const uri = `${API_BASE_URL}${ApiURLConstants.UPDATE_PROJECT(projectId)}`;
  const headers = { ...globalHeaders };
  const response = await API.put(
    uri,
    {},
    {
      headers,
    },
  );
  return response;
};

export const getMasterDataProjectList = async type => {
  try {
    let uri = '';
    if (type === 'media') {
      uri = `${API_BASE_URL}${ApiURLConstants.GET_PROJECT_MASTER_DATA(type)}`;
    } else if (['category', 'brand'].includes(type)) {
      uri = `${API_BASE_URL}${ApiURLConstants.GET_PROJECT_USER_MASTER_DATA(type)}`;
    } else if (type === 'business-unit') {
      uri = `${API_USER_BASE_API}${ApiURLConstants.GET_MASTER_DATA_BU}`;
    }

    const headers = { ...globalHeaders };
    const response = await API.get(uri, {
      headers,
    });
    return response;
  } catch (error) {
    console.error(`Error fetching data:`, error.message);
    return null;
  }
};

export const updateProjectStatus = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.UPDATE_PROJECT_STATUS}`;
  const headers = { ...globalHeaders };
  const response = await API.put(uri, payload, {
    headers,
  });
  return response;
};

export const projectApprovalRequest = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.PROJECT_APPROVAL}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const subtaskEditRequest = async (subtaskId, payload) => {
  const uri = `${API_BASE_URL}${ApiURLConstants.SUBTASK_EDIT_REQUEST(subtaskId)}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const subtaskApproveRejectEditRequest = async (subtaskId, payload) => {
  const uri = `${API_BASE_URL}${ApiURLConstants.SUBTASK_EDIT_REQUEST(subtaskId)}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const createUpdateProjectSubmission = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.CREATE_UPDATE_PROJECT_SUBMISSION}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const getProjectSubmissionData = async id => {
  const uri = `${API_BASE_URL}${ApiURLConstants.GET_PROJECT_SUBMISSION_DETAILS(id)}`;
  const headers = { ...globalHeaders };
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const taskApproveRejectRequest = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.TASK_APPROVAL_STATUS}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const calculateSubtaskDetailsRequest = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.CALCULATE_SUBTASK_DETAILS}`;
  const headers = { ...globalHeaders };
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};
