import React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, alpha, useTheme } from '@mui/material/styles';
import { MenuIcon } from '../../../libs/svg-icons/icons/More';

const StyledMenu = styled(Menu)(({ theme }) => ({
  elevation: 0,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  '& .MuiPaper-root': {
    backgroundColor: '#FFFFFF',
    width: '244px',
    maxHeight: 'auto',
    overflowY: 'auto',
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    padding: '8px',
    color: theme.palette.text.primary,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: ' 0',
    },
    '& .MuiMenuItem-root': {
      '&:active': {
        backgroundColor: alpha('#0A275629', theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const ButtonMenu = ({ options = [], icons, onMenuClick = () => null, rowId }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onItemClicked = option => {
    onMenuClick(option, rowId);
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuIcon fill="var(--text-300)" />
      </IconButton>
      <StyledMenu
        sx={{ transform: 'translateX(calc(100%-50px))' }}
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            onClick={() => onItemClicked(option)}
            sx={{
              borderBottom:
                index !== options.length - 1 ? `1px solid ${theme.palette.text[600]}` : 'none',
              padding: '8px',
            }}
          >
            {icons[index] && React.cloneElement(icons[index], { style: { marginRight: '8px' } })}
            {option}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};

export default ButtonMenu;
