import React from 'react';
import { SVGIcon } from './SVGIcon';

export const createIcon = (path, defaultProps = {}) => {
  return props => {
    const {
      width = defaultProps.width,
      height = defaultProps.height,
      display = defaultProps.display,
      viewBox = defaultProps.viewBox,
      className = defaultProps.className,
      ...rest
    } = props;

    return (
      <SVGIcon
        {...defaultProps}
        width={width}
        height={height}
        display={display}
        viewBox={viewBox}
        className={className}
        {...rest}
      >
        {path}
      </SVGIcon>
    );
  };
};
