import React from 'react';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '27.2px',
    marginRight: '16px',
  },
}));

const CustomRadioGroup = ({ value, onChange, labels }) => {
  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="invoice-status"
        name="radio-buttons-group"
        value={value}
        onChange={onChange}
      >
        {labels.map((label, index) => (
          <CustomFormControlLabel
            key={index}
            value={label.value}
            control={<Radio />}
            label={label.text}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default CustomRadioGroup;
