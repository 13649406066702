import React from 'react';
import Box from '@mui/material/Box';
import { LogoutIcon } from '../../../libs/svg-icons/icons';
import Typography from '@mui/material/Typography';
import Dialog from '../../shared/dialog';
import Button from '../../shared/buttons';
import { useTheme } from '@mui/material/styles';
import { dispatcher } from '../../../redux/store';
import { authActions } from '../../../redux/slice';
import { useAuth } from 'react-oidc-context';

const LogoutModal = ({ open = false, handleClose = () => null }) => {
  const theme = useTheme();
  const auth = useAuth();

  const handleLogout = () => {
    auth.signoutRedirect();
    dispatcher(authActions.loggedOut());
    localStorage.removeItem('permissions');
    localStorage.removeItem('masterData');
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      icon={
        <Box
          sx={{
            width: '52px',
            height: '52px',
            borderRadius: '50%',
            background: theme.palette.background,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto',
            marginBottom: '24px',
          }}
        >
          <LogoutIcon width="32px" height="32px" fill="var(--text-100)" />
        </Box>
      }
      headline={
        <Box
          sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Typography variant="SemiBold-22" color={theme.palette.text['100']}>
            Logout
          </Typography>
        </Box>
      }
      description={
        <Typography variant="Regular-16" sx={{ marginBottom: '24px' }}>
          Are you sure you want to logout from the app?
        </Typography>
      }
      buttons={[
        <Button
          key="button1"
          width="50%"
          onClick={handleClose}
          variant="outlined"
          label="Cancel"
        />,
        <Button
          key="button2"
          width="50%"
          onClick={handleLogout}
          variant="contained"
          label="Yes, Logout"
        />,
      ]}
      width="430px"
      headlineAlignment="center"
    />
  );
};

export default LogoutModal;
