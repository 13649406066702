import React, { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Input from './input';

/**
 * Custom input field over MUI OutlinedInput.
 *
 * @param {string} id - The `id` of `input` element.
 * @param {string} inputHeight - The `height` of `input` element.
 * @param {string} type - The `type` of `input` element.
 * @param {string} label - The `label` of `input` element.
 * @param {string} name - The `name` of `input` element.
 * @param {string} value - The `value` of `input` element.
 * @param {function} onChange - The function that is triggered when there is a change in the `input` element.
 * @param {function} onBlur - The function that is triggered when the`input` element is blurred.
 * @param {function} onKeyDown - The function that is triggered when any key id pressend inside the`input` element.
 * @param {boolean} required - If `required` is true, the `input` element will require a value and an asteric will be shown after the label.
 * @param {string} placeholder - The short hint displayed in the `input` element before the user enters a value.
 * @param {boolean} disabled - If `disabled` is true, the `input` element will be disabled.
 * @param {Element} startIcon - The icon that is shown at the beginning or left end of the `input` element.
 * @param {Element} endIcon - The icon that is shown at the right end of the `input` element.
 * @param {string} color - The `color` for the `input` element.
 * @param {string} error - If `error` message is present, it will error success message at the bottom of the `input` element and the border of the `input` element will be of color error.
 * @param {string} success - If `success` message is present, it will show success message at the bottom of the `input` element and the border of the `input` element will be of color success.
 * @param {boolean} showErrorIcon - If `showErrorIcon` is true, it will show error icon at the right end of the `input` element.
 * @param {boolean} showSuccessIcon - If `showSuccessIcon` is true, it will show success icon at the right end of the `input` element.
 * @param {string} helperText - Any text that we want to show at the bottom of the `input` element, as a description.
 * @param {string} iconInfo - If `startIcon` or `endIcon` is present,`iconInfo` contents will be shown in a tooltip if the `startIcon` or `endIcon` is hovered.
 * @param {boolean} searchable - If `true`, the `input` element will have a search icon at the end.
 * @param {function} onSearchCloseClick - If `input` element is searchable, then this function will be triggered if clicking the cross icon is clicked while searching.
 * @param {boolean} autoFocus - If `true`, the `input` element is focused during the first mount.
 * @returns {Element} The `input` element.
 */

const InputField = (
  {
    id = '',
    inputHeight = '',
    type = 'text',
    label = '',
    name = '',
    value = '',
    onKeyDown = () => null,
    required = false,
    placeholder = '',
    disabled = false,
    startIcon = null,
    endIcon = null,
    color = null,
    success = '',
    error = null,
    warning = '',
    showWarningIcon = false,
    showErrorIcon = false,
    showSuccessIcon = false,
    helperText = '',
    iconInfo = null,
    searchable = false,
    onSearchCloseClick = null,
    autoFocus = false,
  },
  ref,
) => {
  const {
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      defaultValue={value}
      control={control}
      render={({ field: { ref, value, onChange, onBlur }, formState: { submitCount } }) => {
        return (
          <Input
            ref={ref}
            id={id}
            name={name}
            inputHeight={inputHeight}
            type={type}
            label={label}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            disabled={disabled}
            required={required}
            onKeyDown={onKeyDown}
            submitCount={submitCount}
            startIcon={startIcon}
            endIcon={endIcon}
            color={color}
            error={error ?? errors[name]?.message}
            warning={warning ?? warning[name]?.message}
            showWarningIcon={showWarningIcon}
            success={success}
            showErrorIcon={showErrorIcon}
            showSuccessIcon={showSuccessIcon}
            helperText={helperText}
            iconInfo={iconInfo}
            searchable={searchable}
            onSearchCloseClick={() => {
              setValue(name, '');
              trigger(name);
              onSearchCloseClick();
            }}
            autoFocus={autoFocus}
          />
        );
      }}
    />
  );
};

export default forwardRef(InputField);
