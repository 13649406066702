import React, { useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import MuiPopover from '@mui/material/Popover';
import Checkbox from '@mui/material/Checkbox';
import { List, ListItem, ListSubheader } from '@mui/material';
import Input from '../form/Input/input';

const StyledPopover = styled(MuiPopover)(({ theme }) => ({
  elevation: 0,
  '& .MuiPaper-root': {
    backgroundColor: '#FFFFFF',
    width: '336px',
    maxHeight: '513px',
    overflowY: 'auto',
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    padding: '0px 12px',
    color: theme.palette.text['100'],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiList-root': {
      padding: '0 !important',
      '& .MuiListItem-root': {
        cursor: 'pointer',
        paddingTop: 0,
        paddingBottom: 0,
        marginBottom: '4px',
        '&:active': {
          backgroundColor: alpha('#0A275629', theme.palette.action.selectedOpacity),
        },
        '&:hover': {
          backgroundColor: '#0A275629',
          borderRadius: '8px',
        },
      },
    },
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '150px',
      transform: 'rotate(180deg)',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#CDD1D7',
      borderRadius: '6px',
    },
    '@media (max-height: 960px)': {
      '&': {
        height: '35vh',
      },
    },
  },
}));

const CheckboxMenu = ({
  anchorEl = null,
  values = [],
  onMenuItemClick = () => null,
  onClose = () => null,
  searchPlaceholder = 'Search here',
}) => {
  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (values) setOptions(values);
  }, [values]);

  const handleSearchTextChange = e => {
    setSearchText(e.target.value);
  };

  const handleSearchCloseClick = () => {
    setSearchText('');
  };

  const handleMenuItemClick = option => {
    const isChecked = option.checked;
    const newOptions = options.map(x =>
      x.value === option.value ? { ...x, checked: !x.checked } : x,
    );
    setOptions(newOptions);
    onMenuItemClick(option.value, !isChecked);
  };

  return (
    <StyledPopover
      id="checkbox-menu"
      sx={{ transform: 'translateX(calc(100%-100px))' }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <List>
        <ListSubheader sx={{ backgroundColor: '#FFFFFF', position: 'sticky', py: 1, px: 0 }}>
          <Input
            placeholder={searchPlaceholder}
            inputHeight="40px"
            value={searchText}
            onChange={handleSearchTextChange}
            onSearchCloseClick={handleSearchCloseClick}
            searchable
            sx={{ position: 'sticky' }}
            onKeyDown={e => {
              if (e.key !== 'Escape') {
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>

        {options
          .filter(x =>
            !!searchText ? x?.label?.toLowerCase().includes(searchText?.toLowerCase()) : true,
          )
          .map((option, index) => (
            <ListItem key={option.value} onClick={() => handleMenuItemClick(option)}>
              <Checkbox checked={option.checked} />
              {option.label}
            </ListItem>
          ))}
      </List>
    </StyledPopover>
  );
};

export default CheckboxMenu;
