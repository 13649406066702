import { IconButton } from '@mui/material';
import { SnackbarProvider, useSnackbar, MaterialDesignContent } from 'notistack';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: '#00B5B1',
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#EF5B2B',
  },
}));

export default function ToastProvider({ children }) {
  function SnackbarCloseButton({ snackbarKey }) {
    const { closeSnackbar } = useSnackbar();

    return (
      <IconButton aria-label="close" onClick={() => closeSnackbar(snackbarKey)}>
        <CloseIcon sx={{ color: '#fff' }} />
      </IconButton>
    );
  }
  return (
    <SnackbarProvider
      maxSnack={3}
      action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
      }}
    >
      {children}
    </SnackbarProvider>
  );
}
