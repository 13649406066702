import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import MuiSelect from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListSubheader from '@mui/material/ListSubheader';
import { ChevronDownIcon, ErrorIcon, SuccesIcon } from '../../../../libs/svg-icons/icons';
import InputAdornment from '@mui/material/InputAdornment';
import { debounce } from 'lodash';
import Input from '../Input/input';
import Tooltip from '../../tooltip';
import OverflowTooltip from '../../tooltip/OverflowTooltip';
import CloseIcon from '@mui/icons-material/Close';

const DEFAULT_VALUE = '';

const containsText = (text, searchText) =>
  text.toLowerCase()?.indexOf(searchText.toLowerCase()) > -1;

/**
 * Custom select field over MUI Select.
 *
 * @param {string} id - The `id` of `select` element.
 * @param {string} selectHeight - The `height` of `select` element.
 * @param {string} label - The `label` of `select` element.
 * @param {string} name - The `name` of `select` element.
 * @param {string} value - The `value` of `select` element.
 * @param {Array<object>} options - The `options` of `select` element.
 * @param {function} optionMapFunction - If `optionMapFunction` is present the function will be called to map option data.
 * @param {function} onOptionSearch - If `onOptionSearch` is present the function will is triggered when a option is searched in the menu search box of `select` element.
 * @param {function} onChange - The function that is triggered when there is a change in the `select` element.
 * @param {function} onBlur - The function that is triggered when the`select` element is blurred.
 * @param {boolean} required - If `required` is true, the `select` element will require a value and an asteric will be shown after the label.
 * @param {string} placeholder - The short hint displayed in the `select` before the user enters a value..
 * @param {boolean} disabled - If `disabled` is true, the `select` element will be disabled.
 * @param {Element} startIcon - The icon that is shown at the beginning or left end of the `select` element.
 * @param {Element} endIcon - The icon that is shown at the right end of the `select` element.
 * @param {string} color - The `color` for the `select` element.
 * @param {string} error - If `error` message is present, it will error success message at the bottom of the `select` element and the border of the `select` element will be of color error.
 * @param {string} success - If `success` message is present, it will show success message at the bottom of the `select` element and the border of the `select` element will be of color success.
 * @param {boolean} showErrorIcon - If `showErrorIcon` is true, it will show error icon at the right end of the `select` element.
 * @param {boolean} showSuccessIcon - If `showSuccessIcon` is true, it will show success icon at the right end of the `select` element.
 * @param {string} helperText - Any text that we want to show at the bottom of the `select` element, as a description.
 * @param {string} iconInfo - If `startIcon` or `endIcon` is present,`iconInfo` contents will be shown in a tooltip if the `startIcon` or `endIcon` is hovered.
 * @param {boolean} searchable - If `true`, the `select` element will have a search bar at the beginning of the options.
 * @param {string} searchInputPlaceholder - The short hint displayed in the search input of `select` element menu.
 * @param {function} onSearchCloseClick - If `select` element is searchable, then this function will be triggered if clicking the cross icon is clicked while searching.
 * @param {string} menuHeight - The `menuHeight` of `select` element.
 * @param {string} menuWidth - The `menuWidth` of `select` element.
 * @param {Element} emptySearchComponent - The empty search component shown if no search result found in the menu search of the `select` element.
 * @param {Element} emptyOptionsComponent - The empty options component shown if no options in the menu of the `select` element.
 * @param {boolean} clearSelectionButton - If `clearSelectionButton` is true, it will show clear icon after an option is selected.
 * @returns {Element} The `select` element.
 */

const Select = (
  {
    id = '',
    selectHeight = '40px',
    label = '',
    name = '',
    value = DEFAULT_VALUE,
    optionMapFunction = null,
    onOptionSearch = null,
    options = [],
    onChange = () => null,
    onBlur = () => null,
    required = false,
    placeholder = '',
    disabled = false,
    startIcon = null,
    endIcon = null,
    color = null,
    error = '',
    success = '',
    showErrorIcon = false,
    showSuccessIcon = false,
    helperText = '',
    iconInfo = null,
    searchable = false,
    searchInputPlaceholder = 'Search',
    menuHeight = '200px',
    menuWidth = '130px',
    emptySearchComponent = null,
    emptyOptionsComponent = null,
    clearSelectionButton = false,
    onClearSelection = () => null,
  },
  ref,
) => {
  const theme = useTheme();

  const [inputValue, setInputValue] = useState(value);
  const [isOptionLoading, setIsOptionLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedOptions, setSearchedOptions] = useState([]);
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    if (value) setInputValue(value);
    else setInputValue(DEFAULT_VALUE);
  }, [value]);

  useImperativeHandle(
    ref,
    () => {
      return {
        getMenuOpenState() {
          return openMenu;
        },
      };
    },
    [openMenu],
  );

  const mappedOptions = useMemo(
    () => (optionMapFunction ? optionMapFunction(options) : options),
    [optionMapFunction, options],
  );

  useEffect(() => {
    if (searchable && mappedOptions && mappedOptions.length > 0) {
      setSearchedOptions(mappedOptions);
    }
  }, [mappedOptions, searchable]);

  const optionSearch = useCallback(
    (availableOptions, text) => {
      if (searchable) {
        if (onOptionSearch && !!text) {
          onOptionSearch(text).then(res => {
            setIsOptionLoading(false);
            setSearchedOptions(optionMapFunction ? optionMapFunction(res) : res);
          });
        } else {
          setSearchedOptions(availableOptions.filter(option => containsText(option.label, text)));
          setIsOptionLoading(false);
        }
      }
    },
    [searchable, onOptionSearch, optionMapFunction],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOptionSearch = useCallback(debounce(optionSearch, 500), []);

  const handleOptionSearched = e => {
    const text = e.target.value;

    setSearchText(text);
    setIsOptionLoading(true);
    debouncedOptionSearch(mappedOptions, text);
  };

  const handleOptionsSearchCloseClicked = () => {
    setSearchedOptions(mappedOptions);
  };

  const displayedOptionComponents = useMemo(() => {
    if (searchable) {
      if (searchedOptions && searchedOptions.length > 0) {
        return searchedOptions.map(option => (
          <MenuItem
            key={option.id ?? option.label}
            value={option.value}
            sx={{
              borderRadius: '8px',
              backgroundColor: theme.palette.white,
              color: theme.palette.text[100],
              margin: '0.5rem',
              '&:hover': {
                backgroundColor: theme.palette.text[500],
              },
              '&.Mui-selected, &.Mui-selected:hover': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.text[600],
              },
            }}
          >
            <OverflowTooltip text={option.label}>{option.label}</OverflowTooltip>
          </MenuItem>
        ));
      } else {
        if (!!searchText) {
          if (emptySearchComponent) return emptySearchComponent;
          else
            return (
              <Box
                sx={{
                  height: 100,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                No search result found
              </Box>
            );
        } else {
          if (emptyOptionsComponent) return emptyOptionsComponent;
          else
            return (
              <Box
                sx={{
                  height: 100,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                No options to show
              </Box>
            );
        }
      }
    } else {
      return mappedOptions?.map(option => (
        <MenuItem
          key={option.value}
          value={option.value}
          onClick={() => (inputValue && clearSelectionButton ? null : setOpenMenu(true))}
          sx={{
            borderRadius: '8px',
            backgroundColor: theme.palette.white,
            color: theme.palette.text[100],
            margin: '0.5rem',
            '&:hover': {
              backgroundColor: theme.palette.text[500],
            },
            '&.Mui-selected, &.Mui-selected:hover': {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.text[600],
            },
          }}
        >
          {option.label}
        </MenuItem>
      ));
    }
  }, [
    searchable,
    searchedOptions,
    theme,
    searchText,
    emptySearchComponent,
    emptyOptionsComponent,
    mappedOptions,
  ]);

  const handleChange = e => {
    setInputValue(e.target.value);
    onChange(e);
  };

  const clearSelection = () => {
    setInputValue(DEFAULT_VALUE);
    onChange({ target: { value: '' } });
  };

  const startIconComponent = useMemo(
    () =>
      startIcon ? (
        <Tooltip placement="top" title={iconInfo}>
          <InputAdornment position="start">{startIcon}</InputAdornment>
        </Tooltip>
      ) : null,
    [iconInfo, startIcon],
  );

  const endIconComponent = useMemo(() => {
    if (!!error && showErrorIcon)
      return (
        <Tooltip placement="top" title={iconInfo}>
          <InputAdornment position="end">
            <ErrorIcon fill="var(--error)" />
          </InputAdornment>
        </Tooltip>
      );
    if (!!success && showSuccessIcon)
      return (
        <Tooltip placement="top" title={iconInfo}>
          <InputAdornment position="end">
            <SuccesIcon fill="var(--success)" />
          </InputAdornment>
        </Tooltip>
      );
    if (endIcon)
      return (
        <Tooltip placement="top" title={iconInfo}>
          <InputAdornment position="end">{endIcon}</InputAdornment>
        </Tooltip>
      );
    return null;
  }, [endIcon, error, iconInfo, showErrorIcon, showSuccessIcon, success]);

  const inputColor = useMemo(() => {
    if (color) return color;
    if (!!error) return 'error';
    if (!!success) return 'success';
    return 'primary';
  }, [color, error, success]);

  const helperTextContent = useMemo(() => {
    if (helperText) return helperText;
    if (!!error) return error;
    if (!!success) return success;
    return null;
  }, [helperText, error, success]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: '4px',
      }}
    >
      {label && (
        <OverflowTooltip text={label}>
          <Typography color={theme.palette.text[100]} component="span" variant="Medium-16">
            {label} {required && '*'}
          </Typography>
        </OverflowTooltip>
      )}
      <FormControl
        sx={{ cursor: 'pointer' }}
        error={!!error}
        color={inputColor}
        disabled={disabled}
      >
        {!value && (
          <Box
            onClick={() =>
              disabled ? null : inputValue && clearSelectionButton ? null : setOpenMenu(true)
            }
            sx={{
              position: 'absolute',
              width: '100%',
              height: selectHeight ? `${selectHeight} !important` : '46px',
              left: 0,
              top: 0,
              zIndex: 10,
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '16px',
              cursor: 'pointer',
              marginTop: '2px',
            }}
          >
            <Typography variant="Regular-14" color={theme.palette.text['500']}>
              {placeholder}
            </Typography>
          </Box>
        )}
        <MuiSelect
          ref={ref}
          id={id ?? undefined}
          name={name}
          sx={{
            height: selectHeight && `${selectHeight} !important`,
            '& .MuiSelect-select': {
              color: inputValue === undefined ? theme.palette.text[500] : theme.palette.text[100],
            },
          }}
          onChange={handleChange}
          onOpen={() => (inputValue && clearSelectionButton ? null : setOpenMenu(true))}
          open={openMenu}
          onBlur={onBlur}
          onClose={e => {
            setOpenMenu(false);
            onBlur(e);
            handleOptionsSearchCloseClicked();
          }}
          value={inputValue}
          required={required}
          disabled={disabled}
          startAdornment={startIconComponent}
          endAdornment={endIconComponent}
          aria-describedby="component-helper-text"
          input={<OutlinedInput />}
          IconComponent={() => (
            <Box
              sx={{
                px: 2,
                cursor: 'pointer',
                height: selectHeight,
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() =>
                disabled ? null : inputValue && clearSelectionButton ? null : setOpenMenu(true)
              }
            >
              {inputValue && clearSelectionButton ? (
                <CloseIcon onClick={clearSelection} sx={{ width: '18px' }} />
              ) : (
                <ChevronDownIcon fill="var(--text-400)" />
              )}
            </Box>
          )}
          inputProps={{
            'aria-label': 'Without label',
            placeholder,
          }}
          MenuProps={{
            autoFocus: false,
            PopoverClasses: {
              paper: 'custom-scroller',
            },
            sx: {
              '& .MuiPaper-root': {
                borderRadius: '8px',
                backgroundColor: `${theme.palette.white} !important`,
                maxHeight: menuHeight,
                width: menuWidth,
                overflowY: 'scroll',
                '& .MuiList-root': {
                  p: 0,
                },
              },
            },
          }}
        >
          {searchable && (
            <ListSubheader
              sx={{
                pt: 1,
                px: 1,
                backgroundColor: theme.palette.white,
              }}
            >
              <Input
                id={`${id}-search-input`}
                inputHeight="36px"
                type="text"
                placeholder={searchInputPlaceholder}
                onChange={handleOptionSearched}
                searchable
                onSearchCloseClick={handleOptionsSearchCloseClicked}
                autoFocus
                onKeyDown={e => {
                  if (e.key !== 'Escape') {
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
          )}
          {isOptionLoading ? (
            <Box
              sx={{ height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              Loading ...
            </Box>
          ) : (
            displayedOptionComponents
          )}
        </MuiSelect>
        <FormHelperText
          id="component-helper-text"
          sx={{
            color: !!success && 'var(--success)',
          }}
        >
          {helperTextContent}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

export default forwardRef(Select);
