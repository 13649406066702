import React from 'react';
import { Box, Typography } from '@mui/material';
import Button from '../buttons';

export default function ApproveRejectAction({
  onReject = () => {},
  onApprove = () => {},
  approveLabel = 'Approve',
  title = '',
}) {
  return (
    <Box
      sx={{
        width: '100%',
        textAlign: 'end',
        padding: '24px',
        background: '#F4F4F4',
        borderRadius: '8px',
        display: 'flex',
      }}
    >
      <div style={{ flex: '1', textAlign: 'left' }}>
        <Typography varient="Medium-16" style={{ fontFamily: 'Poppins', marginTop: '9px' }}>
          {title}
        </Typography>
      </div>
      <Button
        key="button_reject"
        width="176px"
        variant="outlined"
        label="Reject"
        onClick={onReject}
        sx={{ maxWidth: '90px', marginRight: '20px' }}
      />
      <Button key="button_approve" variant="contained" label={approveLabel} onClick={onApprove} />
    </Box>
  );
}
