/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Card from '../../../../../../components/shared/card';
import Comments from '../../../../../../components/shared/comments';

import HeaderActions from './HeaderActions';
import Button from '../../../../../../components/shared/buttons';
import { useParams } from 'react-router-dom';
import {
  saveInternalBrief,
  viewInternalBrief,
} from '../../../../../../services/media-plan-service';
import Loader from '../../../../../../components/shared/loader';
import { AppConstants } from '../../../../../../constants/app-constants';
import { useHasAllPermission } from '../../../../../../hooks/permissions';
import { PermissionsConstants } from '../../../../../../constants/permissions-constants';
import { useEnqueueSnackbar } from '../../../../../../components/shared/toast-provider/toastHook';
import { uploadFile } from '../../../../../../services/file-upload-service';
import PageNotFound from '../../../../../404';
import { getSignURL } from '../../../../../../services/file-download-service';
import { IBPdfViewer } from '../../../../../../components/shared/document/IBPdfViwer';
import { getLocalTimezone } from '../../../../../../utils/validation';
import { formatDate } from '../../../../../../libs/date/format';

const InternalBrief = () => {
  const params = useParams();
  const [internalBriefId, setInternalBriefId] = useState(null);
  const [htmlFileString, setHtmlFileString] = useState(null);
  const [IBSignData, setIBSignData] = useState({
    hasSignature: false,
    remark: '',
    consideration: '',
  });
  const [downloadPath, setDownloadPath] = useState(null);
  const [signDownloadPath, setSignDownloadPath] = useState(null);
  const [status, setStatus] = useState('');
  const [createdAtIB, setCreatedAtIB] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const enqueueSnackbar = useEnqueueSnackbar();

  const hasAllPermissions = useHasAllPermission();

  const getInternalBriefData = async () => {
    viewInternalBrief(params?.ibid)
      .then(response => {
        const { data = null } = response?.data || {};
        if (data) {
          const { id, htmlContent, downloadPath, isSigned, createdAt = '' } = data;
          setInternalBriefId(id);
          setCreatedAtIB(createdAt);
          if (downloadPath) {
            setStatus(AppConstants.IB_STATUS_CLIENT_UPLOADED);
          } else if (isSigned) {
            setStatus(AppConstants.IB_STATUS_SAVED);
          } else {
            setStatus(AppConstants.IB_STATUS_NOT_SAVED);
          }
          setHtmlFileString(htmlContent);
          setDownloadPath(downloadPath);
          if (downloadPath) {
            getSignURLForDoc(downloadPath);
          }
        }
      })
      .catch(error => {
        const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
        enqueueSnackbar(message, 'error');
        setInternalBriefId(0);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const saveInternalBriefData = async payload => {
    setIsLoading(true);
    saveInternalBrief(params?.ibid, payload)
      .then(response => {
        enqueueSnackbar('Internal brief saved successfully!', 'success');
        setIBSignData({
          hasSignature: false,
          remark: '',
          consideration: '',
        });
        getInternalBriefData();
      })
      .catch(error => {
        const { message = AppConstants.SOMETHING_WENT_WRONG } = error.response?.data || {};
        enqueueSnackbar(message, 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (params?.ibid) {
      setIsLoading(true);
      getInternalBriefData();
    }
  }, [params?.ibid]);

  const handleSaveIB = () => {
    document.getElementById('date-id').innerHTML = formatDate(new Date(), 'dd-MM-yyyy hh:mm:ss');

    const htmlDom = document.getElementById('ib-html-box');
    const domStr = htmlDom.innerHTML;
    const timezone = getLocalTimezone();
    const payload = { htmlContent: `${domStr}`, timeZone: timezone };
    saveInternalBriefData(payload);
  };

  const handleIBSignData = (hasSignature, remark, consideration) => {
    setIBSignData({
      hasSignature,
      remark,
      consideration,
    });
  };

  const handleUploadSignedIB = (file, approvalDate = null) => {
    if ((hasAllPermissions([PermissionsConstants?.CREATE_MEDIA_PLAN]), approvalDate)) {
      const fileUploadDto = {
        id: params?.ibid,
        fileOrigin: 'INTERNAL_BRIEF',
      };
      const formData = new FormData();
      formData.append(
        'fileUploadDto',
        new Blob([JSON.stringify(fileUploadDto)], {
          type: 'application/json',
        }),
      );
      formData.append('file', file);

      setIsLoading(true);

      uploadFile(formData)
        .then(response => {
          const { data } = response.data;
          if (data) {
            return data;
          }
          return null;
        })
        .then(data => {
          if (data) {
            const timezone = getLocalTimezone();
            const payload = {
              file: data,
              htmlContent: htmlFileString,
              timeZone: timezone,
              approvedAt: approvalDate ? formatDate(new Date(approvalDate), 'yyyy-MM-dd') : null,
            };
            saveInternalBrief(params?.ibid, payload)
              .then(() => {
                enqueueSnackbar('Signed IB uploaded successfully!', 'success');
                getInternalBriefData();
              })
              .catch(error => {
                const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
                enqueueSnackbar(message, 'error');
              });
          }
        })
        .catch(error => {
          const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
          enqueueSnackbar(message, 'error');
        })
        .finally(() => setIsLoading(false));
    }
  };

  if (internalBriefId === 0 || !hasAllPermissions([PermissionsConstants.READ_INTERNAL_BRIEF]))
    return <PageNotFound />;

  const getSignURLForDoc = async link => {
    setIsLoading(true);
    const d = link.split('gcsbucket-uploads/');
    await getSignURL(link)
      .then(response => {
        const { data = null } = response?.data || {};
        if (data) {
          setSignDownloadPath(data.url);
        }
      })
      .catch(error => {
        const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
        enqueueSnackbar(message, 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Card sx={{ alignItems: 'flex-start' }}>
        <HeaderActions
          handleIBSignData={handleIBSignData}
          handleUploadSignedIB={handleUploadSignedIB}
          status={status}
          hasDownloadPath={!!downloadPath}
          signDownloadPath={signDownloadPath}
          createdAtIB={createdAtIB}
        />
        {downloadPath ? (
          <Box
            sx={{
              p: 0,
              width: '100%',
              margin: 'auto',
              minHeight: 'calc(100vh - 80px)',
            }}
          >
            {signDownloadPath && (
              // <PDFViewer link={signDownloadPath} defaultScale={0.75} height={'100%'} />
              <IBPdfViewer pdfUrl={signDownloadPath} />
            )}
          </Box>
        ) : (
          <div style={{ width: '100%', backgroundColor: '#F4F4F4' }}>
            <div
              style={{
                width: '1270px',
                maxWidth: '100%',
                margin: 'auto',
                overflowX: 'hidden',
              }}
            >
              <Box
                id="ib-html-box"
                sx={{ width: '100%', backgroundColor: '#F4F4F4' }}
                dangerouslySetInnerHTML={{ __html: htmlFileString }}
              />
            </div>
          </div>
        )}

        {[AppConstants.IB_STATUS_NOT_SAVED].includes(status) && (
          <Button
            variant="contained"
            label="Save IB"
            onClick={handleSaveIB}
            sx={{ width: 'fit-content', marginTop: '10px' }}
            disabled={!IBSignData.hasSignature}
          />
        )}

        <Comments entityId={params?.bgid} entityType="INTERNAL_BRIEF" />
      </Card>
    </>
  );
};

export default InternalBrief;
