import { Avatar, Box, Divider, Typography } from '@mui/material';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import Button from '../../../../components/shared/buttons';
import Card from '../../../../components/shared/card';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { DownloadIcon } from '../../../../libs/svg-icons/icons';
import TouchRipple from '@mui/material/ButtonBase/TouchRipple';

const NotificationCard = ({ index = 0, data = null }) => {
  const theme = useTheme();

  const {
    userName = 'TM',
    title = 'LBAUG 2 - 2023_August_Lifebuoy_Sun_Digital_KOL_BB1234567890',
    description = 'Tagged you in a comment: Lorem ipsum dolor sit amet, consectetur adipiscing elit @harrysetyono',
    date = new Date('Wed Apr 03 2024 00:16:20 GMT+0530 (India Standard Time)'),
    isRead = index < 5, // this temp for data representation
  } = data || {};

  const getTimeDifference = targetTime => {
    const currentTime = new Date();
    const targetDateTime = new Date(targetTime);
    const timeDifference = currentTime - targetDateTime;
    const hoursDifference = timeDifference / (1000 * 60 * 60);

    if (hoursDifference >= 24) {
      const daysDifference = Math.floor(hoursDifference / 24);
      return `${Math.round(daysDifference)}d`;
    } else {
      return `${Math.round(hoursDifference)}h`;
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        background: isRead ? '#F7F7F7' : '',
        height: '90px',
        display: 'flex',
        cursor: 'pointer',
        padding: '16px',
      }}
    >
      <div style={{ display: 'contents' }}>
        <Avatar
          sx={{
            background: index < 5 ? '#0A2756' : '#f4f4f4',
            color: '#fff',
            margin: 'auto 0',
          }}
        >
          {index < 5 ? (
            <Typography variant="Bold-14"> {userName} </Typography>
          ) : (
            <DownloadIcon fill={theme.palette.primary.main} />
          )}
        </Avatar>
      </div>
      <div style={{ paddingLeft: '19px' }}>
        <div style={{ marginBottom: '10px', display: 'flex' }}>
          <Typography variant="Medium-16">{title}</Typography>
          <span
            style={{
              borderRadius: '10px',
              height: '5px',
              width: '5px',
              background: '#0080FF',
              margin: 'auto 10px',
              display: 'inline-block',
            }}
          >
            {' '}
          </span>
          <Typography variant="Regular-14" sx={{ margin: 'auto 0px' }}>
            {getTimeDifference(date)} ago
          </Typography>
        </div>
        <Typography variant="Medium-14">{description}</Typography>
      </div>
    </Box>
  );
};

const NotificationsList = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        minHeight: 'calc(100vh - 130px)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Typography variant="Bold-28">NOTIFICATIONS</Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1.25rem',
          }}
        >
          <Button
            key="button2"
            width="160px"
            onClick={() => {
              navigate('/app/media-plans/1/buying-group/2');
            }}
            label="Mark all as Read"
          />
        </Box>
      </Box>

      <Box sx={{ width: '100%', paddingBottom: '50px' }}>
        {[1, 2, 3, 4, 5, 6, 7, 8].map((i, index) => {
          return (
            <>
              <NotificationCard index={index} data={null} />
              {index !== 7 && (
                <Divider sx={{ borderBottom: '1px solid #CDD1D7', margin: '8px 0px' }} />
              )}
            </>
          );
        })}
      </Box>
    </Card>
  );
};

export default NotificationsList;
