import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notificationCount: 0,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotificationCount: (state, action) => {
      state.notificationCount = action.payload.notificationCount;
    },
    resetNotificationCount: state => {
      state.notificationCount = 0;
    },
  },
});

export const notificationActions = notificationSlice.actions;
export const notificationReducer = notificationSlice.reducer;
