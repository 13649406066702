export const PermissionsConstants = {
  // media plan permissions
  READ_MEDIA_PLAN: 'read-media-plan',
  UPDATE_MEDIA_PLAN: 'update-media-plan',
  DELETE_MEDIA_PLAN: 'delete-media-plan',
  UPDATE_GIVEN_BUDGET: 'update-given-budget',
  SEND_FOR_APPROVAL_MEDIA_PLAN: 'send-for-approval-media-plan',
  READ_MEDIA_PLAN_VERSION: 'read-media-plan-version',
  CREATE_MEDIA_PLAN_VERSION: 'create-media-plan-version',
  CREATE_MEDIA_PLAN: 'create-media-plan',
  APPROVE_MEDIA_PLAN: 'approve-media-plan',
  VALIDATE_MEDIA_PLAN: 'validate-media-plan',

  // BBG permissions
  READ_BUYING_BRIEF_GROUP: 'read-buying-brief-group',
  CREATE_BUYING_BRIEF_GROUP: 'create-buying-brief-group',
  UPDATE_BUYING_BRIEF_GROUP: 'update-buying-brief-group',

  // IB permissions
  CREATE_INTERNAL_BRIEF: 'create-internal-brief',
  READ_INTERNAL_BRIEF: 'read-internal-brief',
  DELETE_INTERNAL_BRIEF: 'delete-internal-brief',
  SEND_FOR_APPROVAL_INTERNAL_BRIEF: 'send-for-approval-internal-brief',
  APPROVE_INTERNAL_BRIEF: 'approve-media-plan',
  SIGN_INTERNAL_BRIEF: 'sign-internal-brief',
  CREATE_MINDER_IB: 'create-minder-ib',

  // Project permission
  READ_PROJECT: 'read-project',
  CREATE_PROJECT: 'create-project',
  UPDATE_PROJECT: 'update-project',
  READ_PROJECT_TASK: 'read-project-task',
  READ_PROJECT_SUBTASK: 'read-project-sub-task',
  PROJECT_APPROVAL: 'approve-project',
  TASK_APPROVAL: 'approve-project-task',
  SUBTASK_APPROVAL: 'approve-project-sub-task',
};
