import React, { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import DatePicker from './input';
import SubtaskDate from './subtaskDate';

/**
 * Custom date-picker field over MUI OutlinedInput.
 *
 * @param {string} id - The `id` of `date-picker` element.
 * @param {string} inputHeight - The `height` of `date-picker` element.
 * @param {string} type - The `type` of `date-picker` element.
 * @param {string} label - The `label` of `date-picker` element.
 * @param {string} name - The `name` of `date-picker` element.
 * @param {string} value - The `value` of `date-picker` element.
 * @param {function} onChange - The function that is triggered when there is a change in the `date-picker` element.
 * @param {function} onBlur - The function that is triggered when the`date-picker` element is blurred.
 * @param {function} onKeyDown - The function that is triggered when any key id pressend inside the`date-picker` element.
 * @param {boolean} required - If `required` is true, the `date-picker` element will require a value and an asteric will be shown after the label.
 * @param {string} placeholder - The short hint displayed in the `date-picker` element before the user enters a value.
 * @param {boolean} disabled - If `disabled` is true, the `date-picker` element will be disabled.
 * @param {Element} startIcon - The icon that is shown at the beginning or left end of the `date-picker` element.
 * @param {Element} endIcon - The icon that is shown at the right end of the `date-picker` element.
 * @param {string} color - The `color` for the `date-picker` element.
 * @param {string} error - If `error` message is present, it will error success message at the bottom of the `date-picker` element and the border of the `date-picker` element will be of color error.
 * @param {string} success - If `success` message is present, it will show success message at the bottom of the `date-picker` element and the border of the `date-picker` element will be of color success.
 * @param {boolean} showErrorIcon - If `showErrorIcon` is true, it will show error icon at the right end of the `date-picker` element.
 * @param {boolean} showSuccessIcon - If `showSuccessIcon` is true, it will show success icon at the right end of the `date-picker` element.
 * @param {string} helperText - Any text that we want to show at the bottom of the `date-picker` element, as a description.
 * @param {string} iconInfo - If `startIcon` or `endIcon` is present,`iconInfo` contents will be shown in a tooltip if the `startIcon` or `endIcon` is hovered.
 * @param {boolean} searchable - If `true`, the `date-picker` element will have a search icon at the end.
 * @param {function} onSearchCloseClick - If `date-picker` element is searchable, then this function will be triggered if clicking the cross icon is clicked while searching.
 * @param {boolean} autoFocus - If `true`, the `date-picker` element is focused during the first mount.
 * @returns {Element} The `date-picker` element.
 */

const DatePickerField = (
  {
    id = '',
    inputHeight = '',
    type = 'text',
    label = '',
    name = '',
    value = '',
    onChange = () => null,
    onKeyDown = () => null,
    required = false,
    placeholder = '',
    disabled = false,
    startIcon = null,
    endIcon = null,
    color = null,
    success = '',
    showErrorIcon = false,
    warning = false,
    showWarningIcon = false,
    showSuccessIcon = false,
    helperText = '',
    iconInfo = null,
    searchable = false,
    onSearchCloseClick = null,
    autoFocus = false,
    views = 'MM/DD',
    maxDate = null,
    minDate = null,
    subtask = false,
    isYearPicker = false,
  },
  ref,
) => {
  const {
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      defaultValue={value}
      control={control}
      render={({
        field: { ref, value, onChange: defaultOnChange, onBlur },
        formState: { submitCount },
      }) => {
        return subtask ? (
          <SubtaskDate
            ref={ref}
            id={id}
            name={name}
            inputHeight={inputHeight}
            type={type}
            label={label}
            value={value}
            onChange={e => {
              defaultOnChange(e);
              onChange && onChange(e);
            }}
            onBlur={onBlur}
            placeholder={placeholder}
            disabled={disabled}
            required={required}
            onKeyDown={onKeyDown}
            submitCount={submitCount}
            startIcon={startIcon}
            endIcon={endIcon}
            color={color}
            error={errors[name]?.message}
            success={success}
            showErrorIcon={showErrorIcon}
            showSuccessIcon={showSuccessIcon}
            helperText={helperText}
            iconInfo={iconInfo}
            searchable={searchable}
            onSearchCloseClick={() => {
              setValue(name, '');
              trigger(name);
              onSearchCloseClick();
            }}
            autoFocus={autoFocus}
            views={views}
            maxDate={maxDate}
            minDate={minDate}
            warning={warning}
            showWarningIcon={showWarningIcon}
          />
        ) : (
          <DatePicker
            ref={ref}
            id={id}
            name={name}
            inputHeight={inputHeight}
            type={type}
            label={label}
            value={value}
            onChange={e => {
              defaultOnChange(e);
              onChange && onChange(e);
            }}
            onBlur={onBlur}
            placeholder={placeholder}
            disabled={disabled}
            required={required}
            onKeyDown={onKeyDown}
            submitCount={submitCount}
            startIcon={startIcon}
            endIcon={endIcon}
            color={color}
            error={errors[name]?.message}
            success={success}
            showErrorIcon={showErrorIcon}
            showSuccessIcon={showSuccessIcon}
            helperText={helperText}
            iconInfo={iconInfo}
            searchable={searchable}
            onSearchCloseClick={() => {
              setValue(name, '');
              trigger(name);
              onSearchCloseClick();
            }}
            autoFocus={autoFocus}
            views={views}
            maxDate={maxDate}
            minDate={minDate}
            isYearPicker={isYearPicker}
          />
        );
      }}
    />
  );
};

export default forwardRef(DatePickerField);
