import React from 'react';
import cn from 'classnames';
import './SVGIcon.css';

export const SVGIcon = props => {
  const {
    children = null,
    width = 16,
    height = 16,
    viewBox = '0 0 16 16',
    className = '',
    display,
    style,
    ...rest
  } = props;
  return (
    <svg
      className={cn('SVGIcon', className)}
      viewBox={viewBox}
      width={width}
      height={height}
      focusable={false}
      display={display}
      style={{
        width,
        height,
        ...style,
      }}
      {...rest}
    >
      {children}
    </svg>
  );
};
