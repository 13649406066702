import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

export const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    update: (state, action) => {
      state = action.payload;
    },
    // action if of type array
    push: (state, action) => {
      state = [...state, ...action.payload];
    },
    pop: state => {
      state = state.slice(0, state.length - 1);
    },
  },
});

export const breadcrumbsActions = breadcrumbsSlice.actions;
export const breadcrumbsReducer = breadcrumbsSlice.reducer;
