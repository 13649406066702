import React from 'react';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

const Card = ({ count, externalId, field, type, message }) => {
  const theme = useTheme();
  return (
    <Box style={{ marginTop: count === 1 ? 0 : '40px' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          gap: '8px',
          marginBottom: '16px',
        }}
      >
        <Typography variant="Medium-16">#{count}</Typography>
        <Box
          sx={{
            width: '4px',
            height: '4px',
            borderRadius: '50%',
            background: theme.palette.text['500'],
          }}
        />
        <Typography variant="Medium-16">{externalId}</Typography>
      </Box>

      <Box
        sx={{
          border: '1px solid #CDD1D7 ',
          borderBottom: 'none',
          display: 'flex',
          height: '58px',
          backgroundColor: theme.palette.text[600],
          padding: '18px 16px ',
        }}
      >
        <Typography variant="Medium-14" style={{ marginBottom: '4px' }}>
          {field}
        </Typography>
      </Box>
      <Box
        style={{
          border: '1px solid #CDD1D7 ',
          display: 'flex',
          minHeight: '58px',
          backgroundColor:
            type === 'error' ? theme.palette.error.light : theme.palette.warning.light,
          padding: '18px 16px ',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Typography
          variant="Medium-14"
          style={{
            marginBottom: '4px',
          }}
        >
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

const Drawer = ({
  open = false,
  onClose = () => null,
  headline = '',
  crossIcon = null,
  width = '',
  height = '',
  cardData = [],
}) => {
  const theme = useTheme();
  return (
    <MuiDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.background,
          width: width,
          height: height,
          overflow: 'auto',
        },

        sx: {
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.text[500],
            borderRadius: '4px',
          },
        },
      }}
    >
      <Box>
        <Box
          style={{
            position: 'sticky',
            top: 0,
            right: 0,
            display: 'flex',
            width: '100%',
            padding: '24px',
            justifyContent: 'space-between',
            alignItems: 'center',
            background: theme.palette.background,
            zIndex: 100,
          }}
        >
          <Typography variant="SemiBold-18">{headline}</Typography>
          {crossIcon && <IconButton onClick={onClose}>{crossIcon}</IconButton>}
        </Box>

        <Box
          sx={{
            padding: '24px',
          }}
        >
          {cardData?.map((card, index) => (
            <Card
              key={index}
              count={index + 1}
              externalId={card.externalId}
              field={card.field}
              type={card.type}
              message={card.message}
            />
          ))}
        </Box>
      </Box>
    </MuiDrawer>
  );
};

export default Drawer;
