import React, { forwardRef } from 'react';
import GridTable from './GridTable';
import MuiTable from './MuiTable';

const Table = (
  {
    variant = 'ag-grid',
    isLoading = false,
    data = [],
    pinnedBottomRowData = [],
    datasource = null,
    onGridReady = () => null,
    columns = [],
    defaultColDef = {},
    childData = [],
    childColumns = [],
    childStyles = {},
    hasChild = false,
    hiddenColumns = [],
    rowSelection = 'single',
    onRowClick = () => null,
    onRowSelected = () => null,
    onCellValueChanged = () => null,
    rowDragEntireRow = false,
    height = null,
    rowModelType = 'clientSide',
    getRowId = null,
    maxBlocksInCache = null,
    rowBuffer = 10,
    cacheBlockSize = 20,
    cacheOverflowSize = 2,
    maxConcurrentDatasourceRequests = 2,
    infiniteInitialRowCount = 1,
    initialRowCount = 10,
    suppressRowClickSelection = true,
    blockLoadDebounceMillis = 500,
    expandAll = false,
    showChildHeader = true,
    isRowSelectable = () => true,
    onDragStopped = () => null,
    filterProps = {},
  },
  ref,
) => {
  if (variant === 'mui-table')
    return (
      <MuiTable
        ref={ref}
        data={data}
        columns={columns}
        childData={childData}
        childStyles={childStyles}
        childColumns={childColumns}
        hasChild={hasChild}
        height={height}
        showChildHeader={showChildHeader}
        rowModelType={rowModelType}
        initialRowCount={initialRowCount}
        infiniteInitialRowCount={infiniteInitialRowCount}
        datasource={datasource}
        filterProps={filterProps}
        expandAll={expandAll}
      />
    );

  if (variant === 'ag-grid')
    return (
      <GridTable
        ref={ref}
        isLoading={isLoading}
        data={data}
        datasource={datasource}
        onGridReady={onGridReady}
        columns={columns}
        defaultColDef={defaultColDef}
        hiddenColumns={hiddenColumns}
        rowSelection={rowSelection}
        onRowClick={onRowClick}
        onRowSelected={onRowSelected}
        onCellValueChanged={onCellValueChanged}
        rowDragEntireRow={rowDragEntireRow}
        height={height}
        rowModelType={rowModelType}
        getRowId={getRowId}
        maxBlocksInCache={maxBlocksInCache}
        rowBuffer={rowBuffer}
        cacheBlockSize={cacheBlockSize}
        cacheOverflowSize={cacheOverflowSize}
        maxConcurrentDatasourceRequests={maxConcurrentDatasourceRequests}
        infiniteInitialRowCount={infiniteInitialRowCount}
        initialRowCount={initialRowCount}
        suppressRowClickSelection={suppressRowClickSelection}
        blockLoadDebounceMillis={blockLoadDebounceMillis}
        pinnedBottomRowData={pinnedBottomRowData}
        isRowSelectable={isRowSelectable}
        onDragStopped={onDragStopped}
      />
    );
};

export default forwardRef(Table);
