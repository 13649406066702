import axiosInstance from './api';
import { ApiURLConstants } from '../constants/api-url-constants';

const API_BASE_URL = process.env.REACT_APP_FILE_UPLOAD_API_BASE_URL;
const API = axiosInstance;

export const uploadFile = async payload => {
  const uri = `${API_BASE_URL}${ApiURLConstants.UPLOAD_FILE}`;
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  const response = await API.post(uri, payload, { headers });
  return response;
};
