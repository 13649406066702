import { formatCurrency } from '../../../../../utils/string';

export const getSummaryTableHeader = (tab, timeSpan) => {
  switch (summaryTabItems[tab].value) {
    case 'GRP_BY_DURATION': // GRP by Duration
      switch (timeSpan) {
        case 'semester':
          return [
            {
              id: 'media',
              headerName: 'Media',
              field: 'media',
            },
            { id: 'campaign', headerName: 'Campaign', field: 'campaign' },
            { id: 'material', headerName: 'Material', field: 'material' },
            { id: 'duration', headerName: 'Duration', field: 'duration' },
            {
              id: 'h1',
              headerName: 'H1',
              field: 'h1',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'h2',
              headerName: 'H2',
              field: 'h2',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'grandTotal',
              headerName: 'Grand Total',
              field: 'grandTotal',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
          ];
        case 'quarter':
          return [
            {
              id: 'media',
              headerName: 'Media',
              field: 'media',
            },
            {
              id: 'campaign',
              headerName: 'Campaign',
              field: 'campaign',
            },
            {
              id: 'material',
              headerName: 'Material',
              field: 'material',
            },
            {
              id: 'duration',
              headerName: 'Duration',
              field: 'duration',
            },
            {
              id: 'q1',
              headerName: 'Q1',
              field: 'q1',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'q2',
              headerName: 'Q2',
              field: 'q2',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'q3',
              headerName: 'Q3',
              field: 'q3',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'q4',
              headerName: 'Q4',
              field: 'q4',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'grandTotal',
              headerName: 'Grand Total',
              field: 'grandTotal',
              maxWidth: 200,
              minWidth: 146,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
          ];
        case 'month':
          return [
            {
              id: 'media',
              headerName: 'Media',
              field: 'media',
              minWidth: 200,
              maxWidth: 200,
            },
            {
              id: 'campaign',
              headerName: 'Campaign',
              field: 'campaign',
              minWidth: 200,
              maxWidth: 200,
            },
            {
              id: 'material',
              headerName: 'Material',
              field: 'material',
              minWidth: 250,
              maxWidth: 250,
            },
            {
              id: 'duration',
              headerName: 'Duration',
              field: 'duration',
              minWidth: 200,
              maxWidth: 200,
            },
            {
              id: 'january',
              headerName: 'Jan',
              field: 'january',
              minWidth: 115,
              maxWidth: 115,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'february',
              headerName: 'Feb',
              field: 'february',
              minWidth: 115,
              maxWidth: 115,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'march',
              headerName: 'Mar',
              field: 'march',
              minWidth: 115,
              maxWidth: 115,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'april',
              headerName: 'Apr',
              field: 'april',
              minWidth: 133,
              maxWidth: 133,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'may',
              headerName: 'May',
              field: 'may',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'june',
              headerName: 'Jun',
              field: 'june',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'july',
              headerName: 'Jul',
              field: 'july',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'august',
              headerName: 'Aug',
              field: 'august',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'september',
              headerName: 'Sep',
              field: 'september',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'october',
              headerName: 'Oct',
              field: 'october',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'november',
              headerName: 'Nov',
              field: 'november',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'december',
              headerName: 'Dec',
              field: 'december',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'grandTotal',
              headerName: 'Grand Total',
              field: 'grandTotal',
              minWidth: 200,
              maxWidth: 200,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
          ];
        default:
          return [];
      }
    case 'BUDGET': // Budget/Media
      switch (timeSpan) {
        case 'semester':
          return [
            { id: 'campaign', headerName: 'Campaign', field: 'campaign' },
            { id: 'media', headerName: 'Media', field: 'media' },
            {
              id: 'h1',
              headerName: 'H1',
              field: 'h1',
              valueFormatter: params => formatCurrency(params.value) ?? '-',
            },
            {
              id: 'h2',
              headerName: 'H2',
              field: 'h2',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'grandTotal',
              headerName: 'Grand Total',
              field: 'grandTotal',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
          ];
        case 'quarter':
          return [
            {
              id: 'campaign',
              headerName: 'Campaign',
              field: 'campaign',
            },
            {
              id: 'media',
              headerName: 'Media',
              field: 'media',
            },
            {
              id: 'q1',
              headerName: 'Q1',
              field: 'q1',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'q2',
              headerName: 'Q2',
              field: 'q2',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'q3',
              headerName: 'Q3',
              field: 'q3',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'q4',
              headerName: 'Q4',
              field: 'q4',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'grandTotal',
              headerName: 'Grand Total',
              field: 'grandTotal',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
              maxWidth: 200,
              minWidth: 146,
            },
          ];
        case 'month':
          return [
            {
              id: 'campaign',
              headerName: 'Campaign',
              field: 'campaign',
              minWidth: 200,
              maxWidth: 200,
            },
            {
              id: 'media',
              headerName: 'Media',
              field: 'media',
              minWidth: 250,
              maxWidth: 250,
            },
            {
              id: 'january',
              headerName: 'Jan',
              field: 'january',
              minWidth: 115,
              maxWidth: 115,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'february',
              headerName: 'Feb',
              field: 'february',
              minWidth: 115,
              maxWidth: 115,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'march',
              headerName: 'Mar',
              field: 'march',
              minWidth: 115,
              maxWidth: 115,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'april',
              headerName: 'Apr',
              field: 'april',
              minWidth: 133,
              maxWidth: 133,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'may',
              headerName: 'May',
              field: 'may',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'june',
              headerName: 'Jun',
              field: 'june',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'july',
              headerName: 'Jul',
              field: 'july',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'august',
              headerName: 'Aug',
              field: 'august',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'september',
              headerName: 'Sep',
              field: 'september',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'october',
              headerName: 'Oct',
              field: 'october',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'november',
              headerName: 'Nov',
              field: 'november',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'december',
              headerName: 'Dec',
              field: 'december',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'grandTotal',
              headerName: 'Grand Total',
              field: 'grandTotal',
              minWidth: 200,
              maxWidth: 200,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
          ];
        default:
          return [];
      }

    case 'REACH_TV': // Reach TV
      switch (timeSpan) {
        case 'semester':
          return [
            { id: 'media', headerName: 'Media', field: 'media' },
            { id: 'campaign', headerName: 'Campaign', field: 'campaign' },
            {
              id: 'h1',
              headerName: 'H1',
              field: 'h1',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'h2',
              headerName: 'H2',
              field: 'h2',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'grandTotal',
              headerName: 'Grand Total',
              field: 'grandTotal',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
          ];
        case 'quarter':
          return [
            {
              id: 'media',
              headerName: 'Media',
              field: 'media',
            },
            {
              id: 'campaign',
              headerName: 'Campaign',
              field: 'campaign',
            },
            {
              id: 'q1',
              headerName: 'Q1',
              field: 'q1',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'q2',
              headerName: 'Q2',
              field: 'q2',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'q3',
              headerName: 'Q3',
              field: 'q3',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'q4',
              headerName: 'Q4',
              field: 'q4',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'grandTotal',
              headerName: 'Grand Total',
              field: 'grandTotal',
              maxWidth: 200,
              minWidth: 146,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
          ];
        case 'month':
          return [
            {
              id: 'media',
              headerName: 'Media',
              field: 'media',
              minWidth: 250,
              maxWidth: 250,
            },
            {
              id: 'campaign',
              headerName: 'Campaign',
              field: 'campaign',
              minWidth: 200,
              maxWidth: 200,
            },
            {
              id: 'january',
              headerName: 'Jan',
              field: 'january',
              minWidth: 115,
              maxWidth: 115,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'february',
              headerName: 'Feb',
              field: 'february',
              minWidth: 115,
              maxWidth: 115,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'march',
              headerName: 'Mar',
              field: 'march',
              minWidth: 115,
              maxWidth: 115,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'april',
              headerName: 'Apr',
              field: 'april',
              minWidth: 133,
              maxWidth: 133,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'may',
              headerName: 'May',
              field: 'may',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'june',
              headerName: 'Jun',
              field: 'june',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'july',
              headerName: 'Jul',
              field: 'july',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'august',
              headerName: 'Aug',
              field: 'august',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'september',
              headerName: 'Sep',
              field: 'september',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'october',
              headerName: 'Oct',
              field: 'october',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'november',
              headerName: 'Nov',
              field: 'november',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'december',
              headerName: 'Dec',
              field: 'december',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'grandTotal',
              headerName: 'Grand Total',
              field: 'grandTotal',
              minWidth: 200,
              maxWidth: 200,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
          ];
        default:
          return [];
      }

    case 'DIGITAL_BREAKDOWN': //Digital Breakdown
      switch (timeSpan) {
        case 'semester':
          return [
            { id: 'media', headerName: 'Media', field: 'media' },
            { id: 'campaign', headerName: 'Campaign', field: 'campaign' },
            { id: 'type', headerName: 'Type', field: 'type' },
            {
              id: 'h1',
              headerName: 'H1',
              field: 'h1',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'h2',
              headerName: 'H2',
              field: 'h2',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'grandTotal',
              headerName: 'Grand Total',
              field: 'grandTotal',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
          ];
        case 'quarter':
          return [
            {
              id: 'media',
              headerName: 'Media',
              field: 'media',
            },
            {
              id: 'campaign',
              headerName: 'Campaign',
              field: 'campaign',
            },
            { id: 'type', headerName: 'Type', field: 'type' },

            {
              id: 'q1',
              headerName: 'Q1',
              field: 'q1',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'q2',
              headerName: 'Q2',
              field: 'q2',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'q3',
              headerName: 'Q3',
              field: 'q3',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'q4',
              headerName: 'Q4',
              field: 'q4',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'grandTotal',
              headerName: 'Grand Total',
              field: 'grandTotal',
              maxWidth: 200,
              minWidth: 146,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
          ];
        case 'month':
          return [
            {
              id: 'media',
              headerName: 'Media',
              field: 'media',
              minWidth: 250,
              maxWidth: 250,
            },
            {
              id: 'campaign',
              headerName: 'Campaign',
              field: 'campaign',
              minWidth: 200,
              maxWidth: 200,
            },
            {
              id: 'january',
              headerName: 'Jan',
              field: 'january',
              minWidth: 115,
              maxWidth: 115,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'february',
              headerName: 'Feb',
              field: 'february',
              minWidth: 115,
              maxWidth: 115,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'march',
              headerName: 'Mar',
              field: 'march',
              minWidth: 115,
              maxWidth: 115,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'april',
              headerName: 'Apr',
              field: 'april',
              minWidth: 133,
              maxWidth: 133,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'may',
              headerName: 'May',
              field: 'may',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'june',
              headerName: 'Jun',
              field: 'june',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'july',
              headerName: 'Jul',
              field: 'july',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'august',
              headerName: 'Aug',
              field: 'august',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'september',
              headerName: 'Sep',
              field: 'september',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'october',
              headerName: 'Oct',
              field: 'october',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'november',
              headerName: 'Nov',
              field: 'november',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'december',
              headerName: 'Dec',
              field: 'december',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'grandTotal',
              headerName: 'Grand Total',
              field: 'grandTotal',
              minWidth: 200,
              maxWidth: 200,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
          ];
        default:
          return [];
      }

    case 'ACD': //ACD Campaign
      switch (timeSpan) {
        case 'semester':
          return [
            { id: 'media', headerName: 'Media', field: 'media' },
            { id: 'campaign', headerName: 'Campaign', field: 'campaign' },
            {
              id: 'h1',
              headerName: 'H1',
              field: 'h1',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'h2',
              headerName: 'H2',
              field: 'h2',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'grandTotal',
              headerName: 'Grand Total',
              field: 'grandTotal',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
          ];
        case 'quarter':
          return [
            {
              id: 'media',
              headerName: 'Media',
              field: 'media',
            },
            {
              id: 'campaign',
              headerName: 'Campaign',
              field: 'campaign',
            },

            {
              id: 'q1',
              headerName: 'Q1',
              field: 'q1',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'q2',
              headerName: 'Q2',
              field: 'q2',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'q3',
              headerName: 'Q3',
              field: 'q3',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'q4',
              headerName: 'Q4',
              field: 'q4',
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'grandTotal',
              headerName: 'Grand Total',
              field: 'grandTotal',
              maxWidth: 200,
              minWidth: 146,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
          ];
        case 'month':
          return [
            {
              id: 'media',
              headerName: 'Media',
              field: 'media',
              minWidth: 250,
              maxWidth: 250,
            },
            {
              id: 'campaign',
              headerName: 'Campaign',
              field: 'campaign',
              minWidth: 200,
              maxWidth: 200,
            },

            {
              id: 'january',
              headerName: 'Jan',
              field: 'january',
              minWidth: 115,
              maxWidth: 115,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'february',
              headerName: 'Feb',
              field: 'february',
              minWidth: 115,
              maxWidth: 115,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'march',
              headerName: 'Mar',
              field: 'march',
              minWidth: 115,
              maxWidth: 115,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'april',
              headerName: 'Apr',
              field: 'april',
              minWidth: 133,
              maxWidth: 133,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'may',
              headerName: 'May',
              field: 'may',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'june',
              headerName: 'Jun',
              field: 'june',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'july',
              headerName: 'Jul',
              field: 'july',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'august',
              headerName: 'Aug',
              field: 'august',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'september',
              headerName: 'Sep',
              field: 'september',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'october',
              headerName: 'Oct',
              field: 'october',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'november',
              headerName: 'Nov',
              field: 'november',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'december',
              headerName: 'Dec',
              field: 'december',
              minWidth: 130,
              maxWidth: 130,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
            {
              id: 'grandTotal',
              headerName: 'Grand Total',
              field: 'grandTotal',
              minWidth: 200,
              maxWidth: 200,
              valueFormatter: params => (params.value ? formatCurrency(params.value) : '-'),
            },
          ];
        default:
          return [];
      }
    default:
      return [];
  }
};

export const summaryTabItems = [
  { label: 'GRP by Duration', value: 'GRP_BY_DURATION' },
  { label: 'Budget/Media', value: 'BUDGET' },
  { label: 'Reach TV', value: 'REACH_TV' },
  { label: 'Digital Breakdown', value: 'DIGITAL_BREAKDOWN' },
  { label: 'ACD / Campaign', value: 'ACD' },
];
