import React, { useEffect, useMemo, useState } from 'react';
import Dialog from '../../../../../../components/shared/dialog';
import Button from '../../../../../../components/shared/buttons';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { ErrorIcon } from '../../../../../../libs/svg-icons/icons';
import SelectTaskToReset from '../../../../../../components/shared/dialog/SelectTaskToReset';
import { AppConstants } from '../../../../../../constants/app-constants';
import { useNavigate, useParams } from 'react-router-dom';
import { useEnqueueSnackbar } from '../../../../../../components/shared/toast-provider/toastHook';
import { updateProject } from '../../../../../../services/project-service';
import { PermissionsConstants } from '../../../../../../constants/permissions-constants';
import { useHasAllPermission } from '../../../../../../hooks/permissions';
import { handleError } from '../../../../../../utils/errorHandling';

const UpdateProjectDialog = ({ data = {}, fetchData = () => {} }) => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const enqueueSnackbar = useEnqueueSnackbar();
  const hasAllPermissions = useHasAllPermission();

  const [isLoading, setIsloading] = useState(false);
  const [openUpdateProjectDialog, setOpenUpdateProjectDialog] = useState(false);
  const [openResetProjectDialog, setOpenResetProjectDialog] = useState(false);

  const handleOpenUpdateProjectDialog = () => {
    setOpenUpdateProjectDialog(true);
  };

  const handleCloseUpdateProjectDialog = () => {
    setOpenUpdateProjectDialog(false);
  };

  const handleOpenResetProjectDialog = () => {
    handleCloseUpdateProjectDialog();
    setOpenResetProjectDialog(true);
  };

  const handleCloseResetProjectDialog = () => {
    setOpenResetProjectDialog(false);
  };

  const UpdateProjectAPICall = async () => {
    const { projectId } = data || {};

    if (projectId) {
      setIsloading(true);
      await updateProject(projectId)
        .then(res => {
          // const { data } = res.data;
          fetchData();
          enqueueSnackbar('Project updated successfully.', 'success');
        })
        .catch(error => {
          let msg = handleError(error);
          enqueueSnackbar(msg, 'error');
        })
        .finally(() => {
          setIsloading(false);
        });
    }
  };

  const isDisable = useMemo(() => {
    const {
      minderBuyingBriefStatus = '',
      webAppBuyingBriefStatus = '',
      discrepancyStatus = '',
      projectId = '',
      isBbUpdatedOnMinder = false,
      isMediaPlanRowUpdated = false,
    } = data || {};

    return (
      isBbUpdatedOnMinder === false ||
      isMediaPlanRowUpdated === false ||
      discrepancyStatus !== AppConstants.CREATED_AND_MATCHED ||
      !projectId
    );

    // This condition has been remove as per diccussion with team on 25th Sep 2024
    // minderBuyingBriefStatus === AppConstants.MEDIA_PLAN_STATUS_CANCELLED ||
    // webAppBuyingBriefStatus === AppConstants.MEDIA_PLAN_STATUS_CANCELLED ||
  }, [data]);

  return (
    <>
      <Button
        label="Update Project"
        variant="contained"
        height="32px"
        // onClick={handleOpenUpdateProjectDialog}
        onClick={UpdateProjectAPICall}
        disabled={isDisable || !hasAllPermissions([PermissionsConstants.UPDATE_PROJECT])}
      />
      <Dialog
        open={openUpdateProjectDialog}
        onClose={handleCloseUpdateProjectDialog}
        icon={
          <Box
            sx={{
              width: '52px',
              height: '52px',
              borderRadius: '50%',
              background: theme.palette.background,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 auto',
              marginBottom: '24px',
            }}
          >
            <ErrorIcon fill="var(--deep-navy)" width="32px" height="32px" />
          </Box>
        }
        headline={<Typography variant="SemiBold-22">Some Task will be Reset</Typography>}
        description={
          <Typography variant="Regular-16" sx={{ marginBottom: '24px' }}>
            Are you sure you want to update this project? You need to reset task in order to update
            the project. This action can’t be undone.
          </Typography>
        }
        buttons={[
          <Button
            key="button1"
            width="176px"
            variant="outlined"
            label="Cancel"
            onClick={handleCloseUpdateProjectDialog}
          />,
          <Button
            type="submit"
            key="button2"
            width="176px"
            variant="contained"
            label="Reset Task"
            onClick={handleOpenResetProjectDialog}
          />,
        ]}
        width="420px"
        headlineAlignment="center"
      />

      <Dialog
        open={openResetProjectDialog}
        onClose={handleCloseResetProjectDialog}
        headline={<Typography variant="SemiBold-22">Select Task to Reset</Typography>}
        SelectTaskToReset={<SelectTaskToReset />}
        width="720px"
        showDialogActions={false}
        fullBodyScroll={true}
      />
    </>
  );
};

export default UpdateProjectDialog;
