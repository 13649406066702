import React, { useState } from 'react';
import Button from '../../../../components/shared/buttons';
import DocumentViewer from '../../../../components/shared/document/viewer';
import Dialog from '../../../../components/shared/dialog';
import { Box, Typography } from '@mui/material';

const ViewPoClientDialog = () => {
  const [openPoClientDialog, setOpenPoClientDialog] = useState(false);

  const handleOpenPoClientDialog = () => {
    setOpenPoClientDialog(true);
  };

  const handleClosePoClientDialog = () => {
    setOpenPoClientDialog(false);
  };
  return (
    <>
      <Button
        label="View"
        styles={{ margin: 'auto' }}
        variant="outlined"
        height="30px"
        onClick={handleOpenPoClientDialog}
      />
      <Dialog
        open={openPoClientDialog}
        onClose={handleClosePoClientDialog}
        headline={<Typography variant="SemiBold-22">PO Client</Typography>}
        description={
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            <DocumentViewer />
          </Box>
        }
        width="1154px"
        showDialogActions={false}
      />
    </>
  );
};

export default ViewPoClientDialog;
