import React, { useState } from 'react';
import Button from '../../../../components/shared/buttons';
import Dialog from '../../../../components/shared/dialog';
import { Box, Divider, Typography } from '@mui/material';
import { InvoiceIcon } from '../../../../libs/svg-icons/icons';

const ViewInvoiceDailog = () => {
  const [openViewInvoiceDialog, setOpenViewInvoiceDailog] = useState(false);

  const handleOpenViewInvoiceDailog = () => {
    setOpenViewInvoiceDailog(true);
  };

  const handleCloseViewInvoiceDailog = () => {
    setOpenViewInvoiceDailog(false);
  };

  const details = [
    { label: 'Invoice Number', value: '0012973870284722' },
    { label: 'Issue Date', value: '01-08-2023' },
    { label: 'Currency', value: 'IDR' },
    { label: 'Purchase Order Number', value: 'PO567890' },
    { label: 'Purchase Order Issue Date', value: '24-09-2023' },
    { label: 'Start Date', value: '01-09-2023' },
    { label: 'End Date', value: '30-09-2023' },
  ];

  const popdetails = [
    { label: 'Item ID', value: '012345' },
    { label: 'Quantity', value: '1' },
    { label: 'Unit', value: 'pcs' },
    { label: 'Price Per Unit', value: '100,000,000' },
    { label: 'Tax %', value: '11%' },
    { label: 'Total Exclusive Tax', value: '7,480,000' },
    { label: 'Tax Number', value: '1234567890123456' },
  ];

  const totalDetails = [
    { label: 'Subtotal exclusive taxes', value: '68,000,000.00 IDR' },
    {
      label: 'ID VAT Standard 11% of 68,000,000.00 IDR (Valid from 01-08-2023)',
      value: '7,480,000.00 IDR',
    },
  ];
  return (
    <>
      <Button
        label="View"
        styles={{ margin: 'auto' }}
        variant="outlined"
        height="30px"
        onClick={handleOpenViewInvoiceDailog}
      />
      <Dialog
        open={openViewInvoiceDialog}
        onClose={handleCloseViewInvoiceDailog}
        headline={<Typography variant="SemiBold-22">View Invoice</Typography>}
        description={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              width: '100%',
              flexDirection: 'column',
              gap: '40px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '4px',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              {details?.map(detail => (
                <Box
                  sx={{
                    display: 'flex',
                    gap: '24px',
                    flexDirection: 'row',
                    width: '100%',
                    flex: '1 1 30%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      width: '300px',
                      gap: '4px',
                      alignItems: 'flex-start',
                      flexDirection: 'column',
                      marginTop: '16px',
                    }}
                  >
                    <Typography variant="Medium-16">{detail.label}</Typography>
                    <Typography variant="Regular-14">{detail.value}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
            <Divider sx={{ width: '100%' }} />

            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '4px',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              {popdetails?.map(detail => (
                <Box
                  sx={{
                    display: 'flex',
                    gap: '24px',
                    flexDirection: 'row',
                    width: '100%',
                    flex: '1 1 30%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      width: '300px',
                      gap: '4px',
                      alignItems: 'flex-start',
                      flexDirection: 'column',
                      marginTop: '16px',
                    }}
                  >
                    <Typography variant="Medium-16">{detail.label}</Typography>
                    <Typography variant="Regular-14">{detail.value}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                height: '191px',
                borderRadius: '8px',
                border: '1px solid #CDD1D7',
                backgroundColor: '#F4F4F4',
                padding: '16px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  gap: '20px',
                  flexWrap: 'wrap',
                }}
              >
                {totalDetails?.map(detail => (
                  <Box sx={{ display: 'flex', flexDirection: 'row', gap: '80px' }}>
                    <Box sx={{ display: 'flex', width: '665px' }}>
                      <Typography variant="Regular-18">{detail.label}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        width: '170px',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Typography variant="Regular-18">{detail.value}</Typography>
                    </Box>
                  </Box>
                ))}

                <Divider sx={{ width: '100%' }} />

                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '80px' }}>
                  <Box sx={{ display: 'flex', width: '665px' }}>
                    <Typography variant="Medium-18">Total IDR</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '170px',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Typography variant="Medium-18">75,480,000.00 IDR</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: '242px',
                height: '244px',
                display: 'flex',
                flexDirection: 'column',
                border: '1px solid #CDD1D7',
                borderRadius: '8px',
                gap: '80px',
                padding: '24px 0px 0px 0px ',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  padding: '0px 24px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100px',
                  height: '52px',
                }}
              >
                <InvoiceIcon fill="" />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  flexDirection: 'column',
                  border: '1px solid #CDD1D7',
                  backgroundColor: '#F4F4F4',
                  height: '88px',
                  width: '100%',
                  padding: '16px',
                  gap: '5px',
                }}
              >
                <Typography variant="Medium-16">Invoice</Typography>
                <Typography variant="Regular-14">18th Aug, 2023 at 12:00 PM</Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                gap: '4px',
              }}
            >
              <Typography variant="Medium-16">Comments</Typography>
              <Typography variant="Regular-14">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla faucibus ultrices dui
                sit amet aliquam.
              </Typography>
            </Box>
          </Box>
        }
        width="988px"
        showDialogActions={false}
        fullBodyScroll={true}
      />
    </>
  );
};

export default ViewInvoiceDailog;
