import { Box, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from '../../../../components/shared/buttons';
import Card from '../../../../components/shared/card';
import { useNavigate } from 'react-router-dom';
import Table from '../../../../components/shared/table';
import Input from '../../../../components/shared/form/Input/input';
import Select from '../../../../components/shared/form/Select/select';
import EmptyTable from '../../../../components/shared/table/EmptyTable';
import { debounce } from 'lodash';
import { childColumns as childColumnsGetter, columnFilterProps, columns } from './ColumnData';
import { formatDate } from '../../../../libs/date/format';
import { formatCurrency } from '../../../../utils/string';
import { AddIcon } from '../../../../libs/svg-icons/icons';
import { useTheme } from '@mui/material/styles';
import CheckboxMenu from '../../../../components/shared/menu/CheckboxMenu';
import Loader from '../../../../components/shared/loader';
import { listVendorPo } from '../../../../services/vendor-po-service';
import { useEnqueueSnackbar } from '../../../../components/shared/toast-provider/toastHook';
import { AppConstants } from '../../../../constants/app-constants';

const PAGE_SIZE = 10;

const filterOptions = [
  { label: 'Buying Brief Number', value: 'minderMarvelId' },
  { label: 'Campaign Title', value: 'minderCampaignName' },
  { label: 'Client PO Number', value: 'clientPoNumber' },
  { label: 'Media Type', value: 'minderMediaType' },
  { label: 'Variant (Finance) Code', value: 'product' },
  { label: 'PO Vendor Number', value: 'vendorPoNumber' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const VendorsList = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleViewPOClientDetails = useCallback(
    event => {
      if (event?.id) {
        navigate(`view/${event?.id}`, {
          replace: false,
        });
      }
    },
    [navigate],
  );

  const childColumns = useMemo(
    () => childColumnsGetter(handleViewPOClientDetails, theme),
    [handleViewPOClientDetails],
  );

  const [fieldAnchorEl, setFieldAnchorEl] = useState(null);
  // const [filterType, setFilterType] = useState(childColumns[1]?.headerName);
  const [filterType, setFilterType] = useState(filterOptions[0]?.label);
  const [searchText, setSearchText] = useState('');
  const [searchError, setSearchError] = useState('');
  const [sortOn, setSortOn] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [showEmptyTableComponent, setShowEmptyTableComponent] = useState(false);
  const [vendorColumns, setVendorColumns] = useState(columns);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [expandAll, setExpandAll] = useState(false);

  const enqueueSnackbar = useEnqueueSnackbar();

  const datasource = useCallback(
    async (amount = PAGE_SIZE, colId, direction, filter = null, filterTypes = 'column-filter') => {
      setIsLoading(true);

      const params = {
        pageNo: Math.floor(amount / PAGE_SIZE),
        pageSize: PAGE_SIZE,
        filterBy: '',
        sortingField: 'createdAt',
        searchText: '',
        sortBy: '',
      };
      if (colId && direction) {
        setSortBy(direction);
        setSortOn(colId);
      }

      const demoText = document.getElementById('media-plan-list-search').value;

      params['filterBy'] = filterOptions?.find(x => x?.label === filterType)?.value;
      params['searchText'] = demoText;

      if (colId || sortOn) {
        params['sortType'] = colId || sortOn;
        params['sortBy'] = (direction || sortBy) === 'asc' ? 'ASC' : 'DESC';
        if (!colId && !direction && filterTypes === 'column-filter') {
          params['sortType'] = '';
          params['sortBy'] = '';
        }
      }

      const payload = {
        'filterBy': params?.filterBy || '',
        'searchText': params?.searchText || '',
        'pageNo': params?.pageNo || '',
        'pageSize': params?.pageSize || '',
        'sortingField': params?.sortType || '',
        'sortBy': params?.sortBy || '',
      };

      if (!isLoading)
        return listVendorPo(payload)
          .then(response => {
            const { data } = response.data;
            if (data?.isFirst) {
              setRows(data.models);
            } else {
              setRows(prev => [...prev, ...data.models]);
            }
            // if (filterTypes === 'text-search') {
            //   setExpandAll(true);
            // } else {
            //   setExpandAll(false);
            // }
            if (data?.isLast) return -1;
            else return 1;
          })
          .catch(error => {
            console.log(error);
            const { detail = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
            enqueueSnackbar(detail, 'error');
          })
          .finally(data => {
            setIsLoading(false);
          });
    },
    [rows, childColumns, searchText],
  );

  useEffect(() => {
    if (!searchError) {
      datasource(
        PAGE_SIZE,
        null,
        null,
        !!searchText ? { col: filterType, text: searchText } : null,
        'text-search',
      );
    }

    if (searchError && !searchText) {
      datasource(
        PAGE_SIZE,
        null,
        null,
        !!searchText ? { col: filterType, text: searchText } : null,
        'text-search',
      );
    }
  }, [searchText]);

  // useEffect(() => {
  //   datasource(PAGE_SIZE, null, null, { [filterType]: searchText }, 'text-search');
  // }, []);

  useEffect(() => {
    setVendorColumns(columns);
  }, [columns]);

  const getRowId = useMemo(() => {
    return params => {
      return params.data.id;
    };
  }, []);

  const mediaPlanHeaderOptions = useMemo(() => {
    return columns?.reduce(
      (acc, x) =>
        x.headerName
          ? [
              ...acc,
              {
                label: x.headerName,
                value: x.id,
                checked: !x.hide,
              },
            ]
          : acc,
      [],
    );
  }, [columns]);

  const handleMenuItemClick = useCallback((colId, checked) => {
    // if (gridTableRef.current && gridTableRef.current.api) {
    //   gridTableRef.current.api.applyColumnState({ state: [{ colId: column, hide: !checked }] });
    // }
    // remove column
    setVendorColumns(prev => prev.map(x => (x.id === colId ? { ...x, hide: !checked } : x)));
  }, []);

  const handleOpenFieldsMenu = event => {
    setFieldAnchorEl(prev => (prev ? null : event.currentTarget));
  };

  const handleCloseFieldsMenu = () => {
    setFieldAnchorEl(null);
  };

  const handleSearched = e => {
    const text = e.target.value;

    debounce(() => setSearchText(text), 500, { trailing: true })();
  };

  const handleSearchCleared = () => {
    setSearchError('');
    setSearchText('');
  };

  const getRemainingBudget = (total, actual) => {
    if (total && actual) {
      return total - actual;
    } else {
      return '-';
    }
  };

  const data = useMemo(
    () =>
      rows?.map((datum, index) => {
        let vendorInvoiceAmt = 0;
        let billToClientAmt = 0;
        let paidFromClientAmt = 0;

        datum?.vendorPOs?.forEach(ele => {
          vendorInvoiceAmt += ele?.vendorPoAmount || 0;
          // billToClientAmt += ele?.vendorPoAmount || 0;
          // paidFromClientAmt += ele?.vendorPoAmount || 0;
        });

        return {
          id: index,
          buyingBriefStatus: datum?.buyingBriefStatus || '-',
          agency: datum?.agency || '-',
          client: datum?.client || '-',
          clientPoNumber: datum?.clientPoNumber || '-',
          businessUnit: datum?.businessUnit || '-',
          category: datum?.category?.name || '-',
          brand: datum?.brand?.name || '-',
          // variantFinanceCode: datum?.variantFinanceName?.externalId || '-',
          // variantFinanceName: datum?.variantFinanceName?.name || '-',
          productCode: datum?.productCode || '-',
          productName: datum?.productName || '-',
          ioNumber: datum?.ioNumber || '-',
          mediaType: datum?.mediaType || '-',
          campaignName: datum?.campaignName || '-',
          startDate: datum?.startDate ? formatDate(datum?.startDate, 'dd/MM/yyyy') : '-',
          endDate: datum?.endDate ? formatDate(datum?.endDate, 'dd/MM/yyyy') : '-',
          issueDate: datum?.issueDate ? formatDate(datum?.issueDate, 'dd/MM/yyyy') : '-',
          targetAudience: datum?.targetAudience || '-',
          marvelBBId: datum?.marvelBBId || '-',
          material: datum?.material || '-',
          totalBudget: datum?.totalBudget ? formatCurrency(datum?.totalBudget) : '-',
          actualCost: datum?.actualCost ? formatCurrency(datum?.actualCost) : '-',
          remainingBbBudget: getRemainingBudget(datum?.totalBudget, datum?.actualCost),
          vendorInvoiceAmount: vendorInvoiceAmt ? formatCurrency(vendorInvoiceAmt) : '-', // vendorPoAmount
          billToClientAmount: billToClientAmt ? formatCurrency(billToClientAmt) : '-',
          paidFromClientAmount: paidFromClientAmt ? formatCurrency(paidFromClientAmt) : '-',
          remarks: datum?.remarks || '-',
          attachment: datum?.attachment || '-',
          currency: 'IDR',
          cancelStatus: datum?.buyingBriefStatus
            ? datum?.buyingBriefStatus === 'CANCELLED'
              ? 'Yes'
              : 'No'
            : '-',
          lastUpdated: datum?.lastUpdated ? formatDate(datum?.lastUpdated, 'dd/MM/yyyy') : '-',
          child: datum?.vendorPOs?.map((vendorPO, index2) => ({
            id: index2 + 1,
            parentId: index,
            agency: vendorPO?.agency || '-',
            client: vendorPO?.client || '-',
            productName: vendorPO?.variantFinanceName?.name,
            campaignTitle: vendorPO?.campaign?.name,
            marvelBBId: vendorPO?.marvelBBId,
            vendorPoNumber: vendorPO?.vendorPoNumber,
            poAmount: vendorPO?.vendorPoAmount ? formatCurrency(vendorPO?.vendorPoAmount) : '-',
            vendorPoAmount: vendorPO?.vendorPoAmount
              ? formatCurrency(vendorPO?.vendorPoAmount)
              : '-',
            type: vendorPO?.campaign?.type,
            media: vendorPO?.media || '-',
            month: vendorPO?.month?.name,
            year: vendorPO?.year,
            vendorName: vendorPO?.vendorName || '-',
            vendorPoInvoiceDate: vendorPO?.vendorPoInvoiceDate
              ? formatDate(vendorPO?.vendorPoInvoiceDate)
              : '-',
            paidFromClient: vendorPO?.paidFromClient || '-',
            paidToVendor: vendorPO?.paidToVendor || '-',
            invoiceNumber: vendorPO?.invoiceNumber || '-',
            clientInvoiceNumber: vendorPO?.clientInvoiceNumber || '-',
          })),
        };
      }),
    [rows],
  );

  const getUniqueOptions = (data, col) => {
    return data.reduce((a, x) => (a.includes(x[col]) ? a : [...a, x[col]]), []);
  };

  const filterProps = useMemo(
    () =>
      Object.keys(columnFilterProps)?.reduce(
        (acc, col) =>
          columnFilterProps[col].type === 'set-filter' &&
          columnFilterProps[col].options &&
          columnFilterProps[col].options.length === 0
            ? { ...acc, [col]: { type: 'set-filter', options: getUniqueOptions(data, col) } }
            : { ...acc, [col]: columnFilterProps[col] },
        {},
      ),
    [columnFilterProps, data, searchText, datasource],
  );

  const defaultColDef = useMemo(
    () => ({
      resizable: false,
      editable: false,
      flex: 1,
    }),
    [],
  );

  const MediaPlanListTable = useMemo(() => {
    return !showEmptyTableComponent ? (
      <Table
        variant="mui-table"
        data={data}
        datasource={datasource}
        columns={vendorColumns}
        filterProps={filterProps}
        childData={data?.reduce((a, x) => [...a, ...(x?.child ?? [])], [])}
        childColumns={childColumns}
        childStyles={{
          padding: '8px',
          paddingLeft: '60px',
        }}
        rowModelType="infinite"
        height="calc(100vh - 312px)"
        initialRowCount={PAGE_SIZE}
        hasChild
        expandAll={expandAll}
      />
    ) : null;
  }, [
    vendorColumns,
    datasource,
    searchText,
    filterProps,
    defaultColDef,
    getRowId,
    showEmptyTableComponent,
    expandAll,
  ]);

  return (
    <>
      <Loader isLoading={isLoading} />

      <Card
        sx={{
          minHeight: 'calc(100vh - 130px)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography variant="Bold-28">VENDORS</Typography>
        </Box>
        {showEmptyTableComponent ? (
          <EmptyTable
            title="No Vendors Created Yet!"
            description='Click the "Add New" button and commence your media planning journey.'
            height="calc(100vh - 244px)"
          />
        ) : (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'column',
              gap: '24px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '20px',
              }}
            >
              <Box sx={{ width: '15rem', display: 'block' }}>
                {/* This field has been remove from UI for now we will enable this after BE api changes (8th July 2024) */}
                <Select
                  id="child-col-filter"
                  // options={childColumns?.map(x => x.headerName)}
                  options={filterOptions?.map(x => x.label)}
                  value={filterType}
                  onChange={e => {
                    setFilterType(e.target.value);
                  }}
                  optionMapFunction={options => options?.map(x => ({ id: x, label: x, value: x }))}
                  menuHeight="400px"
                  selectHeight="40px"
                />
              </Box>

              <Box sx={{ width: '24.75rem', marginBottom: searchError ? '-23px' : '0px' }}>
                <Input
                  id="media-plan-list-search"
                  inputHeight="40px"
                  type="text"
                  placeholder={`Search by ${filterType.toLocaleLowerCase()}...`}
                  searchable
                  onBlur={handleSearched}
                  onKeyDown={event => {
                    if (event.key === 'Enter' || event.key === 'Tab') {
                      handleSearched(event);
                      event.target.blur();
                    }
                  }}
                  onChange={event => {
                    if (
                      /[!@#$%^&*(),.?":{}|<>]/.test(event.target.value) ||
                      /[+\-_=\/\*]/.test(event.target.value)
                    ) {
                      setSearchError('Special characters are not allowed in search');
                      return;
                    } else {
                      setSearchError('');
                    }
                  }}
                  onSearchCloseClick={handleSearchCleared}
                  error={searchError}
                />
              </Box>

              <Box sx={{ width: '8rem' }}>
                <Button
                  label={expandAll ? 'Collapse All' : 'Expand All'}
                  onClick={() => setExpandAll(prev => !prev)}
                  height="40px"
                />
              </Box>

              <Box sx={{ width: 'auto', marginLeft: 'auto' }}>
                <Button
                  aria-controls={fieldAnchorEl ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={fieldAnchorEl ? 'true' : undefined}
                  variant="outlined"
                  label="Field"
                  onClick={handleOpenFieldsMenu}
                  startIcon={<AddIcon fill={theme.palette.primary.main} />}
                />
                <CheckboxMenu
                  anchorEl={fieldAnchorEl}
                  values={mediaPlanHeaderOptions}
                  onMenuItemClick={handleMenuItemClick}
                  onClose={handleCloseFieldsMenu}
                  checkboxProps={{ checked: true }}
                  searchPlaceholder="Select Column"
                />
              </Box>
            </Box>
            {MediaPlanListTable}
          </Box>
        )}
      </Card>
    </>
  );
};

export default VendorsList;
