import { Box, Typography } from '@mui/material';
import Chip from '../../../../components/shared/chip';
import { capitalize } from '../../../../utils/string';
import Button from '../../../../components/shared/buttons';

const chipStatus = {
  NEW: '',
  ACCEPTED: '',
  READY_TO_ACTUALIES: 'success',
  REJECTED: 'error',
};

export const columnData = handleViewInvoiceDetails => [
  {
    id: 'invoiceNumber',
    headerName: 'Invoice Number',
    field: 'name',
    sortable: false,
    minWidth: 230,
    maxWidth: 230,
    onCellClicked: e => {
      console.log(e);
      handleViewInvoiceDetails(e);
    },
    cellRenderer: params => (
      <div style={{ cursor: 'pointer' }}>PO{Math.floor(Math.random() * 1000000000)}</div>
    ),
  },
  {
    id: 'status',
    headerName: 'Status',
    field: 'status',
    cellStyle: params => ({
      display: 'flex',
      alignItems: 'left',
      justifyContent: 'left',
    }),
    cellRenderer: params => {
      const val = Object.keys(chipStatus)[Math.floor(Math.random() * 4)]; // params.value;
      return params?.value ? <Chip status={chipStatus[val]} label={capitalize(val)} /> : '-';
    },
    minWidth: 200,
  },
  {
    id: 'invoiceDate',
    headerName: 'Invoice Date',
    field: 'species',
    sortable: false,
    minWidth: 150,
    maxWidth: 150,
    cellRenderer: params => <>10/08/24</>,
  },
  {
    id: 'invoideMonth',
    headerName: 'Invoice Month',
    field: 'name',
    sortable: false,
    minWidth: 150,
    maxWidth: 150,
    cellRenderer: params => <>September</>,
  },
  {
    id: 'invoiceYear',
    headerName: 'Invoice Year',
    field: 'type',
    sortable: false,
    minWidth: 150,
    maxWidth: 150,
    cellRenderer: params => <>2024</>,
  },
  {
    id: 'invoiceAmount',
    headerName: 'Invoice Amount',
    field: 'name',
    sortable: false,
    minWidth: 200,
    maxWidth: 200,
    cellRenderer: params => <>324,234,234</>,
  },
  {
    id: 'invoiceCurrency',
    headerName: 'Invoice Currency',
    field: 'name',
    sortable: false,
    minWidth: 160,
    maxWidth: 160,
    cellRenderer: params => <>IDR</>,
  },
  {
    id: 'vatAmount',
    headerName: 'VAT Amount',
    field: 'name',
    sortable: false,
    minWidth: 150,
    maxWidth: 150,
    cellRenderer: params => <>234,233</>,
  },
  {
    id: 'taxInvoiceNumber',
    headerName: 'Tax Invoice Number',
    field: 'name',
    sortable: false,
    minWidth: 180,
    maxWidth: 180,
    cellRenderer: params => <>TAX{Math.floor(Math.random() * 10000)}</>,
  },
  {
    id: 'taxInvoiceDate',
    headerName: 'Tax Invoice Date',
    field: 'species',
    sortable: false,
    minWidth: 150,
    maxWidth: 150,
    cellRenderer: params => <>10/08/24</>,
  },
  {
    id: 'poVendorDetails',
    headerName: 'PO Vendor Details',
    sortable: false,
    minWidth: 170,
    cellStyle: params => ({
      display: 'flex',
    }),
    cellRenderer: params => (
      <Button
        height="30px"
        sx={{
          minWidth: '21px',
          padding: '6px 14px',
        }}
        variant="outlined"
        label={<Typography variant="Medium-12">View</Typography>}
        // onClick={}
      />
    ),
  },
  {
    id: 'subTotle',
    headerName: 'Sub Total',
    field: 'name',
    sortable: false,
    minWidth: 180,
    maxWidth: 180,
    cellRenderer: params => <>324,234,234</>,
  },
  {
    id: 'vatStandard',
    headerName: 'VAT Standard',
    field: 'name',
    sortable: false,
    minWidth: 150,
    maxWidth: 150,
    cellRenderer: params => <>{Math.floor(Math.random() * 100)}%</>,
  },
  {
    id: 'totalInvoiceAmount',
    headerName: 'Total Invoice Amount',
    field: 'name',
    sortable: false,
    minWidth: 200,
    maxWidth: 200,
    cellRenderer: params => <>324,234,234</>,
  },
];
