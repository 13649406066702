import React, { useRef, useEffect, useState } from 'react';
import Tooltip from '.';
import { Box } from '@mui/material';

const OverflowTooltip = ({ text = '', sx = null, tooltipPlacement = 'top', children }) => {
  const textElementRef = useRef();

  // Define state and function to update the value
  const [hoverStatus, setHover] = useState(false);

  const compareSize = () => {
    const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    setHover(compare);
  };

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
    return () => {
      window.removeEventListener('resize', compareSize);
    };
  }, []);

  return (
    <Tooltip
      title={text}
      placement={tooltipPlacement}
      disableHoverListener={!hoverStatus}
      style={{ fontSize: '2em' }}
    >
      <Box
        ref={textElementRef}
        style={{
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontFamily: 'Poppins',
          fontSize: '0.875rem',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '117%',
          ...sx,
        }}
      >
        {children}
      </Box>
    </Tooltip>
  );
};

export default OverflowTooltip;
