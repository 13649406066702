import React, { useEffect, useState } from 'react';
import Button from '../../../../../../components/shared/buttons';
import Dialog from '../../../../../../components/shared/dialog';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LockIcon, UnLockIcon } from '../../../../../../libs/svg-icons/icons';
import { useParams, useNavigate } from 'react-router-dom';
import {
  lockMediaPlanRows,
  unlockMediaPlanRows,
} from '../../../../../../services/media-plan-service';
import { useEnqueueSnackbar } from '../../../../../../components/shared/toast-provider/toastHook';
import { AppConstants } from '../../../../../../constants/app-constants';
import { useHasAllPermission } from '../../../../../../hooks/permissions';
import { PermissionsConstants } from '../../../../../../constants/permissions-constants';
import { handleError } from '../../../../../../utils/errorHandling';

const LockUnlockMediaPlanRow = ({ ...props }) => {
  const theme = useTheme();
  const urlParams = useParams();

  const isRowCancelled = props.data?.mediaPlanRowsStatus === 'CANCELLED';

  const hasAllPermissions = useHasAllPermission();

  const [locked, setLocked] = useState(true);
  const [openLockUnLockDialog, setOpenLockUnLockDialog] = useState(false);

  const navigate = useNavigate();
  const enqueueSnackbar = useEnqueueSnackbar();

  useEffect(() => {
    if (props?.data.isLocked) setLocked(true);
    else setLocked(false);
  }, [props?.data.isLocked]);

  const handleConfirmUnlockMediaPlanRow = () => {
    const payload = {
      mediaPlanRowIds: [props.data.rowId],
    };
    const params = {
      bbgId: urlParams.bgid,
    };
    unlockMediaPlanRows(params, payload)
      .then(() => {
        enqueueSnackbar('Media plan row unlocked successfully!', 'success');
      })
      .then(() => {
        navigate(`/app/media-plans/${urlParams.id}/media-plan`);
      })
      .catch(err => {
        let msg = handleError(err);
        enqueueSnackbar(msg, 'error');
      });
  };

  const handleLockMediaPlanRow = () => {
    if (!isRowCancelled) {
      const payload = {
        mediaPlanRowIds: [props.data.rowId],
      };
      const params = {
        bbgId: urlParams.bgid,
      };
      lockMediaPlanRows(params, payload)
        .then(() => {
          enqueueSnackbar('Media plan row locked successfully!', 'success');
        })
        .then(() => {
          setLocked(true);
        })
        .catch(error => {
          const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
          enqueueSnackbar(message, 'error');
        });
    }
  };

  const handleShowLockUnLockDialog = () => {
    if (!isRowCancelled) {
      if (locked) {
        setOpenLockUnLockDialog(true);
      } else {
        // handleLockMediaPlanRow();
        // do nothing
      }
    }
  };

  const handleCloseLockUnLockDialog = () => {
    setOpenLockUnLockDialog(false);
  };

  return (
    <>
      {hasAllPermissions([PermissionsConstants.UPDATE_BUYING_BRIEF_GROUP]) ? (
        locked ? (
          <LockIcon fill="var(--deepNavy)" onClick={handleShowLockUnLockDialog} />
        ) : (
          <UnLockIcon
            fill="var(--deepNavy)"
            onClick={handleShowLockUnLockDialog}
            style={{ cursor: 'pointer' }}
          />
        )
      ) : (
        <LockIcon fill="var(--text-500)" style={{ cursor: 'not-allowed' }} />
      )}

      {hasAllPermissions([PermissionsConstants.UPDATE_BUYING_BRIEF_GROUP]) && (
        <Dialog
          open={openLockUnLockDialog}
          onClose={handleCloseLockUnLockDialog}
          icon={
            <Box
              sx={{
                width: '52px',
                height: '52px',
                borderRadius: '50%',
                background: theme.palette.background,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto',
                marginBottom: '24px',
              }}
            >
              {locked ? (
                <UnLockIcon fill="var(--deepNavy)" width="32px" height="32px" />
              ) : (
                <LockIcon fill="var(--deepNavy)" width="32px" height="32px" />
              )}
            </Box>
          }
          headline={
            <Typography variant="SemiBold-22">
              {locked ? 'Unlock Media Plan Row' : 'Lock Media Plan Row'}
            </Typography>
          }
          description={
            <Typography variant="Regular-16" sx={{ marginBottom: '24px' }}>
              {locked
                ? 'Are you sure you want to unlock this media plan row?'
                : 'Are you sure you want to lock this media plan row?'}
            </Typography>
          }
          buttons={[
            <Button
              key="cancel"
              width="176px"
              variant="outlined"
              label="Cancel"
              onClick={handleCloseLockUnLockDialog}
            />,
            <Button
              key="yes"
              width="176px"
              variant="contained"
              label={locked ? 'Yes, Unlock' : 'Yes, Lock'}
              onClick={handleConfirmUnlockMediaPlanRow}
            />,
          ]}
          width="420px"
          headlineAlignment="center"
        />
      )}
    </>
  );
};
export default LockUnlockMediaPlanRow;
