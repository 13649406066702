import React, { useState } from 'react';
import { DownloadIcon, ErrorIcon } from '../../../../../libs/svg-icons/icons';
import Button from '../../../../../components/shared/buttons';
import { Box, Typography } from '@mui/material';
import Dialog from '../../../../../components/shared/dialog';
import { useTheme } from '@emotion/react';
import Textarea from '../../../../../components/shared/form/textarea';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import Input from '../../../../../components/shared/form/Input';
import CustomRadioGroup from '../../../../../components/shared/form/radio-button';
import Chip from '../../../../../components/shared/chip';

const schema = yup.object().shape({
  reasonsOfRejection: yup.string('Required'),
  amount: yup.string(''),
});

const VendorInvoiceDocumentButtons = () => {
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      reasonsOfRejection: '',
      amount: '',
    },
  });
  const reasonsOfRejection = useWatch({
    control: methods.control,
    name: 'reasonsOfRejection',
  });
  const theme = useTheme();
  const [openMessagefromGcpDailog, setopenFromGcpDailog] = useState(false);
  const [openRejectDailog, setOpenRejectDailog] = useState(false);
  const [openApproveDailog, setOpenApproveDailog] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [chipVisible, setChipVisible] = useState(false);

  const handleRadioChange = event => {
    setSelectedValue(event.target.value);
  };

  const handleOpenMessageFromGcpDialog = () => {
    setopenFromGcpDailog(true);
  };

  const handleCloseMessageFromGcpDialog = () => {
    setopenFromGcpDailog(false);
  };

  const handleOpenRejectDialog = () => {
    setOpenRejectDailog(true);
  };

  const handleCloseRejectDialog = () => {
    setOpenRejectDailog(false);
  };

  const handleOpenApproveDialog = () => {
    if (!chipVisible) {
      setChipVisible(true);
    } else {
      setOpenApproveDailog(true);
    }
  };

  const handleCloseApproveDialog = () => {
    setOpenApproveDailog(false);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '100%',
          flexDirection: 'row',
          gap: '40px',
          marginTop: '16px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '30%',
            flexDirection: 'row',
            gap: '20px',
          }}
        >
          <Button
            width="40px"
            height="40px"
            sx={{
              minWidth: '21px',
              padding: '5px',
            }}
            variant="outlined"
            startIcon={
              <Box
                component={'span'}
                sx={{
                  width: '21px',
                  height: '21px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <DownloadIcon fill={theme.palette.primary.main} />
              </Box>
            }
          />
          <Button label="Status Log" variant="outlined" />
          <Button
            label="Message from GCP"
            variant="outlined"
            onClick={handleOpenMessageFromGcpDialog}
            sx={{
              background: theme.palette.success.light,
            }}
          />
          <Dialog
            open={openMessagefromGcpDailog}
            onClose={handleCloseMessageFromGcpDialog}
            headline={<Typography variant="SemiBold-22">Message from GCP</Typography>}
            description={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  gap: '10px',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="Regular-14" sx={{ textAlign: 'left', marginTop: '20px' }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla faucibus ultrices
                  dui sit amet aliquam. Proin at nibh euismod, lobortis nisl at, tempor enim.{' '}
                </Typography>
                <Typography variant="Regular-14" sx={{ textAlign: 'left' }}>
                  Fusce nisi dui, faucibus id commodo eget, volutpat vitae lectus. Aliquam lacinia,
                  elit nec molestie accumsan, diam nunc imperdiet mi, at luctus metus dui sed nisi.
                </Typography>
                <Typography variant="Regular-14" sx={{ textAlign: 'left' }}>
                  Proin tristique nunc lacus, vitae scelerisque velit ornare sit amet. Mauris quis
                  justo quis felis tristique aliquam. Ut non justo tellus. Vestibulum condimentum
                  vitae velit nec bibendum. In nibh lacus, hendrerit sit amet tincidunt non,
                  venenatis et mi. Etiam tincidunt pharetra felis, non viverra erat consectetur eu.
                  Aenean maximus fermentum velit ac fermentum. Nullam non augue quis lacus venenatis
                  volutpat.
                </Typography>
              </Box>
            }
            width="920px"
            showDialogActions={false}
            fullBodyScroll={true}
          />
        </Box>
        <Box
          sx={{
            width: '70%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: '20px',
          }}
        >
          {chipVisible && <Chip label="Approved by AP Team" status="success" />}
          <Button label="Reject" variant="outlined" onClick={handleOpenRejectDialog} />
          <Dialog
            open={openRejectDailog}
            onClose={handleCloseRejectDialog}
            width="668px"
            headline={
              <Typography variant="SemiBold-22">Reason for Rejecting the Invoice</Typography>
            }
            description={
              <>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                  }}
                >
                  <FormProvider {...methods}>
                    <Box
                      sx={{
                        width: '100%',
                      }}
                    >
                      <Textarea
                        id="reasonsOfRejection"
                        inputHeight="130px"
                        type="text"
                        label="Reasons Of Rejection"
                        placeholder="Enter your reason here..."
                        {...methods.register('reasonsOfRejection')}
                      />
                    </Box>
                  </FormProvider>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                    marginTop: '24px',
                  }}
                >
                  <Button label="Reject" variant="contained" disabled={!reasonsOfRejection} />
                </Box>
              </>
            }
            showDialogActions={false}
          />

          <Button
            label="Approve"
            variant="contained"
            onClick={() => {
              if (chipVisible) {
                setOpenApproveDailog(true);
              } else {
                setChipVisible(true);
              }
            }}
          />

          <Dialog
            open={openApproveDailog}
            onClose={handleCloseApproveDialog}
            icon={
              <Box
                sx={{
                  width: '52px',
                  height: '52px',
                  borderRadius: '50%',
                  background: theme.palette.background,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '0 auto',
                  marginBottom: '24px',
                }}
              >
                <ErrorIcon fill="var(--deep-navy)" width="32px" height="32px" />
              </Box>
            }
            headline={<Typography variant="SemiBold-22">Approve Vendor Invoice</Typography>}
            description={
              <>
                <Typography variant="Regular-16" sx={{ marginBottom: '24px' }}>
                  Are you sure you want to approve the vendor invoice? Please select the status of
                  the invoice.
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <CustomRadioGroup
                    value={selectedValue}
                    onChange={handleRadioChange}
                    labels={[
                      { value: 'invoice', text: 'Actualised as per invoice' },
                      { value: 'manual', text: 'Actualised (input manual)' },
                    ]}
                  />
                </Box>
                {selectedValue === 'manual' && (
                  <FormProvider {...methods}>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        marginTop: '20px',
                      }}
                    >
                      <Input
                        id="amount"
                        inputHeight="48px"
                        type="text"
                        placeholder="Enter the actualized amount"
                        label="Actualized Amount"
                        {...methods.register('amount')}
                      />
                    </Box>
                  </FormProvider>
                )}
              </>
            }
            buttons={[
              <Button
                key="button1"
                width="176px"
                variant="outlined"
                label="Cancel"
                onClick={handleCloseApproveDialog}
              />,
              <Button
                type="submit"
                key="button2"
                width="176px"
                variant="contained"
                label="Submit"
                disabled={!methods.formState.isValid || !selectedValue}
                onClick={handleCloseApproveDialog}
              />,
            ]}
            width="430px"
            headlineAlignment="center"
            height={selectedValue === 'manual' ? '300px' : '201px'}
          />
        </Box>
      </Box>
    </>
  );
};

export default VendorInvoiceDocumentButtons;
