import { WebStorageStateStore } from 'oidc-client';
import React from 'react';
import { AuthProvider } from 'react-oidc-context';

const mindshareApi = process.env.REACT_APP_MINDSHARE_AUTH_API || '';
// const mindshareApi = 'https://auth-uat.mindshareapps.com';
// const mindshareApi = 'https://auth.mindshareapps.com';

const oidcConfig = {
  authority: `${mindshareApi}/`,
  post_logout_redirect_uri: `${window.location.origin}/logout`,
  client_id: 'pkce-aad-okta',
  scope: 'openid mindshareApi',
  redirect_uri: `${window.location.origin}/auth/callback`,
  response_type: 'code',
  loadUserInfo: true,
  userStore: new WebStorageStateStore({
    store: localStorage,
  }),
  end_session_endpoint: `${mindshareApi}/connect/endsession`,
};

const OIDCAuthProvider = ({ children }) => <AuthProvider {...oidcConfig}>{children}</AuthProvider>;

export default OIDCAuthProvider;
