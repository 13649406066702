import { Box, Typography } from '@mui/material';
import React from 'react';
import EmptyTableIllustration from '../../../assets/images/empty-table-illustration.svg';
import { useTheme } from '@mui/material/styles';

const EmptyTable = ({ title, description, actionItems, height }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: '100%',
        height,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      }}
    >
      <Box
        sx={{
          width: '30%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '30px',
        }}
      >
        <img src={EmptyTableIllustration} alt="No data present" width={300} height={300} />
        <Box
          sx={{
            width: '100%',
            minWidth: '386px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1rem',
          }}
        >
          <Typography variant="Medium-18" color={theme.palette.text['100']}>
            {title}
          </Typography>
          <Typography variant="Regular-14" textAlign="center" color={theme.palette.text['300']}>
            {description}
          </Typography>
        </Box>
        <Box>{actionItems}</Box>
      </Box>
    </Box>
  );
};

export default EmptyTable;
