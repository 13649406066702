import RouteHandler from '../../../components/router/handler';
import { PermissionsConstants } from '../../../constants/permissions-constants';
import MediaPlanCreate from './MediaPlanCreate';
import MapColumns from './MediaPlanCreate/MapColumns';
import BuyingGroups from './MediaPlanDetails/BuyingGroups';
import BuyingBrief from './MediaPlanDetails/BuyingGroups/BuyingBrief';
import BuyingGroup from './MediaPlanDetails/BuyingGroups/BuyingGroup';
import InternalBrief from './MediaPlanDetails/BuyingGroups/InternalBrief';
import MediaPlan from './MediaPlanDetails/MediaPlan';
import Summary from './MediaPlanDetails/Summary';
import MediaPlansList from './MediaPlansList';
import BuyingBriefDetails from './MediaPlanDetails/BuyingGroups/BuyingBrief/BuyingBriefDetails';

export const MediaPlansRoutes = [
  {
    index: true,
    path: '',
    component: <MediaPlansList />,
    permissions: [PermissionsConstants.READ_MEDIA_PLAN],
  },
  {
    path: ':id/media-plan',
    component: <MediaPlan />,
    permissions: [PermissionsConstants.READ_MEDIA_PLAN],
  },
  {
    path: ':id/buying-group',
    component: <BuyingGroups />,
  },
  {
    path: ':id/buying-group/:bgid',
    component: <BuyingGroup />,
  },
  {
    path: ':id/buying-group/:bgid/buying-brief/:bbid',
    component: <BuyingBriefDetails />,
  },
  {
    path: ':id/buying-group/:bgid/buying-brief',
    component: <BuyingBrief />,
  },
  {
    path: ':id/buying-group/:bgid/internal-brief/:ibid',
    component: <InternalBrief />,
  },
  {
    path: ':id/summary',
    component: <Summary />,
  },
  {
    path: ':id/summary/:currentVersionId',
    component: <Summary />,
  },
  {
    path: 'create/:method',
    component: <MediaPlanCreate />,
    permissions: [PermissionsConstants?.CREATE_MEDIA_PLAN],
  },
  {
    path: 'create/:method/map-columns',
    component: <MapColumns />,
    permissions: [PermissionsConstants?.CREATE_MEDIA_PLAN],
  },
];

const MediaPlansRouter = () => {
  return <RouteHandler routes={MediaPlansRoutes} />;
};

export default MediaPlansRouter;
