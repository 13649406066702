export const flattenMasterDataHierarchy = obj => {
  return obj?.reduce((result, item) => {
    const { child, ...newItem } = item;
    result.push(newItem);
    if (item.child && item.child.length > 0) {
      result = result.concat(flattenMasterDataHierarchy(item.child));
    }

    return result;
  }, []);
};

export const getIdList = obj => obj?.map(x => x.id);

export const getMaterialCodeList = obj => obj?.map(x => x.name);

export const getExternalIdList = obj => obj?.map(x => x.externalId);

export const getNameList = obj => obj?.map(x => x.name);

export const lookupValue = (obj, key, value) => obj?.find(x => x[key] === value);
