import React, { useEffect, useState } from 'react';
import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@emotion/react';
import DocumentViewer from '../document/viewer';
import { PDFViewer } from '../document/pdfViewer';
import { getSignURL } from '../../../services/file-download-service';
import { AppConstants } from '../../../constants/app-constants';
import Loader from '../loader';

const ViewPOClientDialog = ({
  open = false,
  onClose = () => null,
  img = '',
  chips = null,
  buttons = null,
  label = '',
  width = '',
  height = '',
  details = [],
  backgroundColor = null,
  showDialogActions = true,
  documentLink = '',
}) => {
  const theme = useTheme();
  const str = '';
  const isHTML = false;

  const [signDownloadPath, setSignDownloadPath] = useState('');
  const [fileType, setFileType] = useState('pdf');
  const [isLoading, setIsLoading] = useState(false);

  const getSignURLForDoc = async link => {
    setIsLoading(true);
    await getSignURL(link)
      .then(response => {
        const { data = null } = response?.data || {};
        if (data) {
          setSignDownloadPath(data.url);
          setFileType(data.type);
        }
      })
      .catch(error => {
        const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
        // enqueueSnackbar(message, 'error');
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (documentLink) getSignURLForDoc(documentLink);
  }, [documentLink]);

  return (
    <>
      <Loader isLoading={isLoading} />
      <MuiDialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: width,
            borderRadius: '8px',
            gap: '24px',
            backgroundColor: backgroundColor ?? theme.palette.white,
          },
        }}
        scroll="body"
      >
        <DialogTitle
          style={{
            background: 'white',
            borderRadius: '8px 8px 0 0',
          }}
        >
          {onClose && (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{ position: 'absolute', right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          )}

          <Typography variant="SemiBold-22">View Client PO</Typography>
        </DialogTitle>
        <DialogContent
          style={{
            background: 'white',
            width: '100%',
            height: height,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            textAlign: 'center',
            padding: '0px 25px',
            borderRadius: showDialogActions ? 0 : '0 0 8px 8px',
            overflow: 'hidden',
            gap: '24px',
          }}
        >
          <Box
            style={{
              width: '100%',
              overflow: isHTML ? 'auto' : 'hidden',
              minHeight: fileType === 'pdf' ? '500px' : '300px',
              marginBottom: '10px',
            }}
          >
            <Box sx={{ p: 0, width: '100%' }}>
              {fileType === 'pdf' ? (
                <PDFViewer link={signDownloadPath} defaultScale={0.7} />
              ) : (
                <>
                  <div style={{ marginTop: '130px' }}>
                    <Typography variant="Medium-16">
                      File type{' '}
                      <span style={{ textTransform: 'uppercase', fontWeight: '700' }}>
                        {fileType}
                      </span>{' '}
                      is not supported to view please{' '}
                      <span
                        style={{ color: '#212194', cursor: 'pointer', fontWeight: '700' }}
                        onClick={() => window.open(signDownloadPath, '_blank')}
                      >
                        <u>click here</u>
                      </span>{' '}
                      to redirect or download the document.
                    </Typography>
                  </div>
                </>
              )}
            </Box>
          </Box>
        </DialogContent>
        {showDialogActions && (
          <DialogActions
            style={{
              display: 'flex',
              padding: '15px 24px',
              paddingTop: '0px',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              gap: '10px',
            }}
          >
            {signDownloadPath && <Box>{buttons(signDownloadPath)}</Box>}
            {/* <Box>{chips}</Box> */}
          </DialogActions>
        )}
        <Box
          sx={{
            display: 'flex',
            padding: !!details.length ? '24px' : '0px',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          {details?.map(detail => (
            <Box
              sx={{ display: 'flex', gap: '24px', alignItems: 'flex-start', flexDirection: 'row' }}
            >
              <Box
                sx={{
                  minWidth: '200px',
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                }}
              >
                <Typography variant="Medium-14">{detail.label}</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <Typography variant="Medium-14">{detail.value}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </MuiDialog>
    </>
  );
};

export default ViewPOClientDialog;
