import { createIcon } from '../createSVGIcon';

const path = (
  <g>
    <path d="M13.75 0V1.5H13L9.25 7.125V13.5H4.75V7.125L1 1.5H0.25V0H13.75ZM2.803 1.5L6.25 6.6705V12H7.75V6.6705L11.197 1.5H2.803Z" />
  </g>
);

export const FilterJarIcon = createIcon(path, {
  width: 13,
  height: 13,
  viewBox: '0 0 13 13',
  fill: 'none',
});
