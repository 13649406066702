import React, { useMemo } from 'react';
import { CrossIcon, UndoIcon } from '../../../../../../libs/svg-icons/icons';
import ButtonMenu from '../../../../../../components/shared/menu/ButtonMenu';

const BBMenu = ({ params, onMenuClick }) => {
  const isCancelled = useMemo(
    () => params?.buyingBriefStatus === 'CANCELLED',
    [params?.buyingBriefStatus],
  );

  const options = useMemo(() => {
    if (isCancelled) {
      return ['Activate'];
    } else {
      return ['Cancel Row'];
    }
  }, [isCancelled]);

  const icons = useMemo(() => {
    if (isCancelled) {
      return [<UndoIcon fill="var(--text-300)" />];
    } else {
      return [<CrossIcon fill="var(--text-300)" />];
    }
  }, [isCancelled]);

  return (
    <div style={{ marginLeft: '-8px' }}>
      <ButtonMenu
        options={options}
        icons={icons}
        onMenuClick={onMenuClick}
        rowId={params?.id}
        params={params}
      />
    </div>
  );
};

export default BBMenu;
