import { createIcon } from '../createSVGIcon';

const path = (
  <g>
    <path d="M17 0V12H20L16 17L12 12H15V0H17ZM9 14V16H0V14H9ZM11 7V9H0V7H11ZM11 0V2H0V0H11Z" />
  </g>
);

export const SortDescIcon = createIcon(path, {
  width: 20,
  height: 17,
  viewBox: '0 0 20 17',
  fill: 'none',
});
