import React, { useState } from 'react';
import Dialog from '../../../../components/shared/dialog';
import { Box, Typography } from '@mui/material';
import { InvoiceIcon } from '../../../../libs/svg-icons/icons';
import Button from '../../../../components/shared/buttons';

const ViewInvoiceCardsDialogs = ({ headline, cards, buttonLabel = 'View' }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        label={buttonLabel}
        styles={{ margin: 'auto' }}
        variant="outlined"
        height="30px"
        onClick={handleOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        headline={<Typography variant="SemiBold-22">{headline}</Typography>}
        description={
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'flex-start',
              marginTop: '16px',
              gap: '20px',
            }}
          >
            {cards.map((item, index) => (
              <Box
                key={index}
                sx={{
                  width: '242px',
                  height: '244px',
                  display: 'flex',
                  flexDirection: 'column',
                  border: '1px solid #CDD1D7',
                  borderRadius: '8px',
                  gap: '80px',
                  padding: '24px 0px 0px 0px ',
                  boxSizing: 'border-box',
                  marginTop: index >= 3 ? '24px' : '0px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    padding: '0px 24px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100px',
                    height: '52px',
                  }}
                >
                  <InvoiceIcon fill="" />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    flexDirection: 'column',
                    border: '1px solid #CDD1D7',
                    backgroundColor: '#F4F4F4',
                    height: '86px',
                    width: '100%',
                    padding: '16px',
                    gap: '5px',
                    borderBottomLeftRadius: '8px',
                    borderBottomRightRadius: '8px',
                  }}
                >
                  <Typography variant="Medium-16">{item.title}</Typography>
                  <Typography variant="Regular-14">{item.content}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        }
        width="988px"
        showDialogActions={false}
        fullBodyScroll={true}
      />
    </>
  );
};

export default ViewInvoiceCardsDialogs;
