import { hasAllPermissions, hasAnyPermission } from '../../libs/permissions';
import { useAppSelector } from '../../redux/hooks';

export const useHasAllPermission = () => {
  const userPermissions = useAppSelector(store => store.auth.permissions);

  return permissions => {
    return hasAllPermissions(userPermissions, permissions);
  };
};

export const useHasAnyPermission = () => {
  const userPermissions = useAppSelector(store => store.auth.permissions);

  return permissions => {
    return hasAnyPermission(userPermissions, permissions);
  };
};
