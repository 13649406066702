import { createIcon } from '../createSVGIcon';

const path = (
  <g>
    <path d="M16 2.66667H2.66667V24H21.3333V8H16V2.66667ZM0 1.32267C0 0.592 0.596 0 1.332 0H17.3333L24 6.66667V25.324C24.0012 25.4991 23.968 25.6727 23.9021 25.835C23.8362 25.9972 23.739 26.1449 23.6161 26.2696C23.4931 26.3942 23.3468 26.4935 23.1855 26.5616C23.0242 26.6297 22.8511 26.6654 22.676 26.6667H1.324C0.973838 26.6642 0.63869 26.5241 0.390957 26.2767C0.143224 26.0292 0.00279287 25.6942 0 25.344V1.32267ZM13.3333 13.3333V18.6667H10.6667V13.3333H6.66667L12 8L17.3333 13.3333H13.3333Z" />
  </g>
);

export const FileUploadIcon = createIcon(path, {
  width: 24,
  height: 27,
  viewBox: '0 0 24 27',
  fill: 'none',
});
