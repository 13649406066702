import RouteHandler from '../../../components/router/handler';
import VendorInvoiceDetails from './VendorInvoiceDetails';
import VendorInvoiceList from './VendorInvoiceList';

const VendorInvoiceRoutes = [
  {
    index: true,
    path: '',
    component: <VendorInvoiceList />,
  },
  {
    path: 'view/:id',
    component: <VendorInvoiceDetails />,
  },
];

const VendorInvoiceRouter = () => {
  return <RouteHandler routes={VendorInvoiceRoutes} />;
};

export default VendorInvoiceRouter;
